import Component from '@glimmer/component';

// Components
import {
  HdsBadge,
  HdsTable,
} from '@hashicorp/design-system-components/components';
import RoleBadge from '../role-badge.gts';
import ServiceBadge from '../service-badge.gts';

// Helpers
import { get } from '@ember/helper';
import { t } from 'ember-intl';
import { or } from 'ember-truth-helpers';
import iamFindRoleFromRoles from '../../../helpers/iam-find-role-from-roles.ts';

// Utils
import {
  CHANGE_SUMMARY_MAP_COLOR,
  CHANGE_SUMMARY_MAP_ICON,
  CHANGE_SUMMARY_MAP_LABEL,
  CHANGE_SUMMARY_TYPE_UNKNOWN,
} from '../../../constants/change-summary.ts';

// Types
import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';
import type { PolicyChange } from '../../../types/iam-policy-summary-changes.ts';

export interface ManageAccessTableChangeSummarySignature {
  Element: HTMLElement;
  Args: {
    changes: Array<PolicyChange>;
    roles: Array<HashicorpCloudResourcemanagerRole>;
  };
  Blocks: {
    default: [];
  };
}

export default class ManageAccessTableChangeSummaryComponent extends Component<ManageAccessTableChangeSummarySignature> {
  get changes() {
    const { changes = [] } = this.args;
    return changes;
  }

  <template>
    <HdsTable
      @caption={{t
        "manage-access.components.table.change-summary.policy-change-summary"
      }}
      data-test-manage-access-change-summary-table
      ...attributes
    >
      <:head as |H|>
        <H.Tr>
          <H.Th @width="135px" data-test-manage-access-change-summary-header>
            {{t "manage-access.components.table.change-summary.change"}}
          </H.Th>
          <H.Th @width="230px" data-test-manage-access-change-summary-header>
            {{t "manage-access.components.table.change-summary.role"}}
          </H.Th>
          <H.Th data-test-manage-access-change-summary-header>
            {{t "manage-access.components.table.change-summary.description"}}
          </H.Th>
          <H.Th @width="200px" data-test-manage-access-change-summary-header>
            {{t "manage-access.components.table.change-summary.service"}}
          </H.Th>
        </H.Tr>
      </:head>
      <:body as |B|>
        {{#each this.changes as |change|}}
          {{#let
            (iamFindRoleFromRoles @roles roleId=change.roleId)
            (get CHANGE_SUMMARY_MAP_COLOR change.type)
            (get CHANGE_SUMMARY_MAP_ICON change.type)
            (get CHANGE_SUMMARY_MAP_LABEL change.type)
            as |role badgeColor badgeIcon badgeText|
          }}
            <B.Tr data-test-manage-access-change-summary-row>
              <B.Td
                data-test-manage-access-change-summary-cell
                data-test-manage-access-change-summary-cell-type={{change.type}}
              >
                {{#if badgeText}}
                  <HdsBadge
                    {{! @glint-expect-error: fix incomming types }}
                    @color={{if
                      badgeColor
                      badgeColor
                      (get CHANGE_SUMMARY_MAP_COLOR CHANGE_SUMMARY_TYPE_UNKNOWN)
                    }}
                    {{! @glint-expect-error: fix incomming types }}
                    @icon={{badgeIcon}}
                    @size="small"
                    @text={{t badgeText}}
                    @type="outlined"
                  />
                {{/if}}
              </B.Td>
              <B.Td
                data-test-manage-access-change-summary-cell
                data-test-manage-access-change-summary-cell-role-id={{change.roleId}}
              >
                {{#if role}}
                  <RoleBadge @isTextOnly={{true}} @role={{role}} />
                {{else}}
                  {{change.roleId}}
                {{/if}}
              </B.Td>
              <B.Td
                data-test-manage-access-change-summary-cell
                data-test-manage-access-change-summary-cell-description={{role.description}}
              >
                {{or role.description "—"}}
              </B.Td>
              <B.Td
                data-test-manage-access-change-summary-cell
                data-test-manage-access-change-summary-cell-service
              >
                {{#if role}}
                  <ServiceBadge @role={{role}} />
                {{/if}}
              </B.Td>
            </B.Tr>
          {{/let}}
        {{/each}}
      </:body>
    </HdsTable>
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ManageAccessTableChangeSummaryComponent: typeof ManageAccessTableChangeSummaryComponent;
  }
}
