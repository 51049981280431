import Component from '@glimmer/component';
import { service } from '@ember/service';
import { on } from '@ember/modifier';
import { and } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import {
  HdsButton,
  HdsBadge,
  HdsLinkInline,
  HdsLinkStandalone,
} from '@hashicorp/design-system-components/components';
import FlexGrid from 'core/components/flex-grid';
import Typography from 'core/components/typography';
import boxPadding from 'core/modifiers/box-padding';
import templateString from 'core/utils/template-string';

import './automate.scss';

/**
 *
 * `NetworksPeeringAwsAutomate` description here.
 *
 *
 * ```
 * <Networks::Peering::Aws::Automate
 *   @peering={{@model.peering}}
 * />
 * ```
 *
 * @class NetworksPeeringAwsAutomate
 *
 */
let awsQuickCreateStackLink = templateString`https://${'awsTargetRegion'}.console.aws.amazon.com/cloudformation/home?region=${'awsTargetRegion'}#/stacks/create/review
?templateURL=${'templateURL'}
&stackName=${'stackName'}
&param_HvnCidrRange=${'hvnCidrRange'}
&param_HvnId=${'hvnId'}
&param_VpcId=${'vpcId'}
&param_VpcPeeringConnectionId=${'vpcPeeringConnectionId'}`;

export default class NetworksPeeringsAwsAutomate extends Component {
  /**
   * Peering is an object that contains the properties of a Peering connection created in HCP
   * @argument peering
   * @type {object}
   *
   *
   * Network is an object that contains the properties of the HVN that a Peering is connected to
   * @argument network
   * @type {object}
   */

  @service analytics;

  launchQuickCreateStack = () => {
    let { peering, network } = this.args;

    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_launch_quick_create_button_clicked',
      {
        ...this.args.peering,
      },
    );

    let quickCreateStackLink = awsQuickCreateStackLink({
      awsTargetRegion: peering.target.awsTarget.region,
      // When bumping this, check that it matches the latest (or previously supported) version of hcp-templates.
      // You can check that here: https://github.com/hashicorp/hcp-templates/blob/f0187080d9febc37a47482428a2000f1ec1a7b4a/.bumpversion.cfg#L2
      // Or here: https://github.com/hashicorp/hcp-templates/tags
      templateURL:
        'https://s3.us-west-2.amazonaws.com/hcp-peering/vpc-peering.0.2.11.template',
      stackName: `hcp-peering-${peering.providerPeeringId}`,
      hvnCidrRange: network.cidrBlock,
      hvnId: network.id,
      vpcId: peering.target.awsTarget.vpcId,
      vpcPeeringConnectionId: peering.providerPeeringId,
    });
    window.open(quickCreateStackLink);
  };

  goToFeedbackForm = () => {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_feedback_form_link_clicked',
      {
        ...this.args.peering,
      },
    );
  };

  goToExternalAwsQuickCreateLink = () => {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_aws_quick_create_link_clicked',
      {
        ...this.args.peering,
      },
    );
  };

  goToExternalAwsWorkingWithStacksLink = () => {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_aws_working_with_stacks_link_clicked',
      {
        ...this.args.peering,
      },
    );
  };

  <template>
    {{#if (and @peering @network)}}
      <div
        ...attributes
        data-test-networks-peerings-aws-automate
        class="networks-peerings-aws-automate"
        {{boxPadding "md 0 0 0"}}
      >
        <FlexGrid as |G|>
          <G.Item @xs="12" @sm="12" @md="8" @lg="8">
            <Typography
              data-test-networks-peerings-aws-automate-title
              @variant="h3"
            >
              {{t
                "components.networks.peerings.aws.automate.intro.message"
              }}<span {{boxPadding "0 0 0 sm"}}>
                <HdsBadge
                  data-test-networks-peerings-aws-automate-title-badge
                  @text={{t
                    "components.networks.peerings.aws.automate.intro.badge"
                  }}
                  @color="neutral"
                  @type="outlined"
                />
              </span>
            </Typography>
            <Typography @variant="body1">
              {{t
                "components.networks.peerings.aws.automate.intro.description"
              }}
            </Typography>
            <Typography @variant="h4">
              {{t "components.networks.peerings.aws.automate.steps.heading"}}
            </Typography>
            <Typography>
              {{t "components.networks.peerings.aws.automate.steps.click-cta"}}
            </Typography>
            <Typography
              data-test-networks-peerings-aws-automate-instructions-content
            >
              <p>
                {{t
                  "components.networks.peerings.aws.automate.steps.quick-create-page.heading"
                }}
              </p>
              <ol>
                <li>
                  {{t
                    "components.networks.peerings.aws.automate.steps.quick-create-page.step-1"
                  }}
                </li>
                <li>
                  {{t
                    "components.networks.peerings.aws.automate.steps.quick-create-page.step-2"
                  }}
                </li>
                <li>
                  {{t
                    "components.networks.peerings.aws.automate.steps.quick-create-page.step-3"
                  }}
                </li>
              </ol>
            </Typography>
          </G.Item>
          <G.Item @xs="12" @sm="12" @md="8" @lg="8">
            <div {{boxPadding "0 0 md 0"}}>
              <img
                src={{t
                  "components.networks.peerings.aws.automate.steps.quick-create-page.screenshot.imgSrc"
                }}
                width="100%"
                alt={{t
                  "components.networks.peerings.aws.automate.steps.quick-create-page.screenshot.alt"
                }}
                data-test-networks-peerings-aws-automate-instructions-screenshot
              />
            </div>
          </G.Item>
          <G.Item @xs="12" @sm="12" @md="8" @lg="8">
            <div {{boxPadding "sm 0 lg 0"}}>
              <HdsButton
                @text={{t "components.networks.peerings.aws.automate.cta-text"}}
                @icon="external-link"
                @iconPosition="trailing"
                data-test-networks-peerings-aws-automate-cta
                {{on "click" this.launchQuickCreateStack}}
              />
            </div>
            <div {{boxPadding "sm 0 lg 0"}}>
              {{t
                "components.networks.peerings.aws.automate.feedback-form.text"
              }}
              <HdsLinkInline
                data-test-networks-peerings-aws-automate-feedback-form
                @icon="external-link"
                @href={{t
                  "components.networks.peerings.aws.automate.feedback-form.inlineLink.href"
                }}
                {{on "click" this.goToFeedbackForm}}
              >
                {{t
                  "components.networks.peerings.aws.automate.feedback-form.inlineLink.text"
                }}
              </HdsLinkInline>
            </div>
            <hr />
            <Typography
              @variant="body1"
              data-test-networks-peerings-aws-automate-notes-content
            >
              <strong>
                {{t "components.networks.peerings.aws.automate.notes.heading"}}
              </strong>
              <ul>
                <li>
                  {{t "components.networks.peerings.aws.automate.notes.one"}}
                </li>
                <li>
                  {{t "components.networks.peerings.aws.automate.notes.two"}}
                </li>
              </ul>
            </Typography>
            <div
              class="networks-peerings-aws-automate__external-links"
              {{boxPadding "sm 0 sm lg"}}
            >
              <div {{boxPadding "0 md 0 0"}}>
                <HdsLinkStandalone
                  data-test-networks-peerings-aws-automate-notes-external-link-quick-create
                  @icon="external-link"
                  @iconPosition="trailing"
                  @text={{t
                    "components.networks.peerings.aws.automate.notes.links.quick-create.text"
                  }}
                  @href={{t
                    "components.networks.peerings.aws.automate.notes.links.quick-create.href"
                  }}
                  {{on "click" this.goToExternalAwsQuickCreateLink}}
                />
              </div>
              <div>
                <HdsLinkStandalone
                  data-test-networks-peerings-aws-automate-notes-external-link-stacks
                  @icon="external-link"
                  @iconPosition="trailing"
                  @text={{t
                    "components.networks.peerings.aws.automate.notes.links.stacks.text"
                  }}
                  @href={{t
                    "components.networks.peerings.aws.automate.notes.links.stacks.href"
                  }}
                  {{on "click" this.goToExternalAwsWorkingWithStacksLink}}
                />
              </div>
            </div>
          </G.Item>
        </FlexGrid>
      </div>
    {{/if}}
  </template>
}
