import Component from '@glimmer/component';
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { eq } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsFormRadioCardGroup } from '@hashicorp/design-system-components/components';

import {
  ALL_PROVIDERS,
  PROVIDERS_ICONS_MAPPING,
} from '../../../utils/networks.js';
/**
 *
 * `NetworksFormInputsProviderCards` renders the provider radio cards for AWS and Azure
 *
 *
 * ```
 * <Networks::FormInputs::ProviderCards
 *   @onChange={{onChange}}
 *   @selectedProvider='aws'
 * />
 * ```
 *
 * @class NetworksFormInputsProviderCards
 *
 */

export default class NetworksFormInputsProviderCards extends Component {
  get providers() {
    let { providers } = this.args;
    return providers || ALL_PROVIDERS;
  }

  get providerLogo() {
    return PROVIDERS_ICONS_MAPPING;
  }

  <template>
    <HdsFormRadioCardGroup
      @alignment="center"
      data-test-networks-form-inputs-provider-cards
      as |G|
    >
      {{#each this.providers as |providerKey|}}
        {{#let (get this.providerLogo providerKey) as |providerLogo|}}
          {{#if providerLogo}}
            <G.RadioCard
              @alignment="center"
              @checked={{eq @selectedProvider providerKey}}
              @disabled={{@disabled}}
              @value={{providerKey}}
              id={{providerKey}}
              data-test-networks-form-inputs-provider-cards-item={{providerKey}}
              {{on "change" @onChange}}
              as |R|
            >
              <R.Icon
                @name={{providerLogo.icon}}
                @size="24"
                data-test-networks-form-inputs-provider-cards-icon={{providerLogo.icon}}
              />
              <R.Label data-test-networks-form-inputs-provider-cards-label>
                {{t
                  "components.networks.form-inputs.provider-cards.provider-label"
                  provider=providerKey
                  htmlSafe=true
                }}
              </R.Label>
            </G.RadioCard>
          {{/if}}
        {{/let}}
      {{/each}}
    </HdsFormRadioCardGroup>
  </template>
}
