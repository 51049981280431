import templateOnlyComponent from '@ember/component/template-only';

import ModalDialogFooterActionsComponent from './footer/actions.ts';
import ModalDialogFooterCancelComponent from './footer/cancel.ts';

interface ModalDialogFooterSignature {
  Args: {
    closeModalDialog?: unknown;
  };
  Blocks: {
    default: [
      {
        Actions: typeof ModalDialogFooterActionsComponent;
        Cancel: typeof ModalDialogFooterCancelComponent;
      },
    ];
  };
}

const ModalDialogFooterComponent =
  templateOnlyComponent<ModalDialogFooterSignature>();

export default ModalDialogFooterComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ModalDialog::Footer': typeof ModalDialogFooterComponent;
    'modal-dialog/footer': typeof ModalDialogFooterComponent;
  }
}
