/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AddOnDefinitionRefWithoutIDFromJSON, AddOnDefinitionRefWithoutIDToJSON, HashicorpCloudWaypointAddOnDefinitionFromJSON, HashicorpCloudWaypointAddOnDefinitionToJSON, HashicorpCloudWaypointTFModuleVariableFromJSON, HashicorpCloudWaypointTFModuleVariableToJSON, HashicorpCloudWaypointTerraformCloudWorkspaceDetailsFromJSON, HashicorpCloudWaypointTerraformCloudWorkspaceDetailsToJSON, HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceFromJSON, HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceUpdateAddOnDefinitionBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceUpdateAddOnDefinitionBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceUpdateAddOnDefinitionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceFromJSON(json['namespace']),
        'existingAddOnDefinition': !exists(json, 'existing_add_on_definition') ? undefined : AddOnDefinitionRefWithoutIDFromJSON(json['existing_add_on_definition']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'readmeMarkdownTemplate': !exists(json, 'readme_markdown_template') ? undefined : json['readme_markdown_template'],
        'readmeTemplate': !exists(json, 'readme_template') ? undefined : json['readme_template'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'terraformCloudWorkspaceDetails': !exists(json, 'terraform_cloud_workspace_details') ? undefined : HashicorpCloudWaypointTerraformCloudWorkspaceDetailsFromJSON(json['terraform_cloud_workspace_details']),
        'variableOptions': !exists(json, 'variable_options') ? undefined : (json['variable_options'].map(HashicorpCloudWaypointTFModuleVariableFromJSON)),
        'moduleSource': !exists(json, 'module_source') ? undefined : json['module_source'],
        'tfExecutionMode': !exists(json, 'tf_execution_mode') ? undefined : json['tf_execution_mode'],
        'tfAgentPoolId': !exists(json, 'tf_agent_pool_id') ? undefined : json['tf_agent_pool_id'],
        'addOnDefinition': !exists(json, 'add_on_definition') ? undefined : HashicorpCloudWaypointAddOnDefinitionFromJSON(json['add_on_definition']),
    };
}
export function HashicorpCloudWaypointWaypointServiceUpdateAddOnDefinitionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceToJSON(value.namespace),
        'existing_add_on_definition': AddOnDefinitionRefWithoutIDToJSON(value.existingAddOnDefinition),
        'name': value.name,
        'summary': value.summary,
        'description': value.description,
        'readme_markdown_template': value.readmeMarkdownTemplate,
        'readme_template': value.readmeTemplate,
        'labels': value.labels,
        'terraform_cloud_workspace_details': HashicorpCloudWaypointTerraformCloudWorkspaceDetailsToJSON(value.terraformCloudWorkspaceDetails),
        'variable_options': value.variableOptions === undefined ? undefined : (value.variableOptions.map(HashicorpCloudWaypointTFModuleVariableToJSON)),
        'module_source': value.moduleSource,
        'tf_execution_mode': value.tfExecutionMode,
        'tf_agent_pool_id': value.tfAgentPoolId,
        'add_on_definition': HashicorpCloudWaypointAddOnDefinitionToJSON(value.addOnDefinition),
    };
}
