import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { action } from '@ember/object';
import { assert } from '@ember/debug';

import NetworkOption from '../../models/network-option.js';
import NetworkOptionGroup from '../../models/network-option-group.js';

import {
  compareNetworkOptionByIdAsc,
  decorateNetworkOptionWithDisabled,
  isNetworkOptionGroup,
  flattenNetworkOptionGroups,
  groupNetworkOptionsById,
  removeById,
} from '../../utils/hvn-select-network-options.js';
import type Owner from '@ember/owner';

interface HvnSelectSignature {
  Args: {
    disabled: boolean;
    networks: unknown[];
    selectedNetworkId: string | null;
    onChange: (network: unknown) => void;
  };
  Blocks: {
    default: [unknown];
    'zero-state': [];
  };
}

/**
 *
 * Contextual component allowing user selection of an existing HashiCorp Virtual Network (HVN) resource.
 * Yields several renderless sub-components which allow the caller to remove, disable, and group HVNs arbitrarily.
 *
 *
 * **NOTE:** _This is a facade component in front of [ember-power-select](https://ember-power-select.com/docs), which endeavours to simplify the interface for grouping/disabling options._
 *
 *
 * Example usage:
 *
 *
 * ```
 * <FormInputs::HvnSelect
 *  @networks={{networks}}
 *  @selectedNetworkId={{selectedNetworkId}}
 *  @onChange={{onChange}}
 *  @disabled={{false}}
 *  as |Hvns|
 * >
 *   <:zero-state>
 *     Nothing to see here ...
 *   </:zero-state>
 *
 *
 *   <Hvns.Remove @ids={{array 'network-which-failed-to-create'}} />
 *
 *
 *   <Hvns.Disable @ids={{array 'network-in-unsupported-region'}} />
 *
 *
 *   <Hvns.Group
 *     @name="Network used by Primary Consul cluster"
 *     @ids={{array 'primary-consul-clusters-network'}}
 *   />
 *
 *
 *   <Hvns.Group
 *     @name="Other available networks"
 *     @ids={{array 'available-network-1' 'available-network-2'}}
 *   />
 *
 *
 *   <Hvns.Group
 *     @name="Unavailable networks (incompatible region/CIDR block)"
 *     @ids={{array 'network-in-unsupported-region'}}
 *   />
 * </FormInputs::HvnSelect>
 * ```
 *
 * @class FormInputsHvnSelect
 *
 */

export default class HvnSelectComponent extends Component<HvnSelectSignature> {
  /**
   * @argument disabled
   * @type {Boolean}
   */

  /**
   * @argument networks - list of Networks from network API data
   * @type {Array.<Network>}
   */

  /**
   * @argument selectedNetworkId - the ID of the selected Network in @networks
   * @type {String}
   */

  /**
   * @argument onChange - callback handler function that accepts the selected Network object as 0th argument
   * @type {Function(Network)}
   * @callback
   */

  /** @type {Array.<NetworkOption,NetworkOptionGroup>} */
  @tracked _networkOptions = [];

  constructor(owner: Owner, args: HvnSelectSignature['Args']) {
    super(owner, args);

    const { networks = [] } = this.args;

    if (macroCondition(isDevelopingApp())) {
      assert(
        '<HvnSelect> argument `@networks` should be an Array of Network objects',
        Array.isArray(networks)
      );
    }

    this.networkOptions = networks.map((network) => {
      return new NetworkOption(network, false);
    });
  }

  get networkOptions() {
    return [
      ...this._networkOptions
        .filter((o) => !isNetworkOptionGroup(o))
        .sort(compareNetworkOptionByIdAsc),
      ...this._networkOptions.filter(isNetworkOptionGroup).map((g) => ({
        // @ts-expect-error
        ...g,
        // @ts-expect-error
        options: g.options.sort(compareNetworkOptionByIdAsc),
      })),
    ];
  }

  set networkOptions(value) {
    // @ts-expect-error
    this._networkOptions = value;
  }

  @action groupNetworkOptions(groupName = '', ids = []) {
    const group = this.networkOptions.reduce(
      // @ts-expect-error
      groupNetworkOptionsById(ids),
      new NetworkOptionGroup(groupName)
    );

    const notGroup = this.networkOptions.filter((candidate) => {
      if (isNetworkOptionGroup(candidate)) {
        return candidate?.groupName !== groupName;
      }
      // @ts-expect-error
      return !ids.includes(candidate?.network?.id);
    });

    this.networkOptions = [...notGroup, group];
  }

  @action removeNetworkOptions(ids = []) {
    const visibleNetworkOptions = this.networkOptions.reduce(
      // @ts-expect-error
      removeById(ids),
      []
    );

    this.networkOptions = visibleNetworkOptions;
  }

  @action disableNetworkOptions(ids = []) {
    const decoratedNetworkOptions = this.networkOptions.map(
      // @ts-expect-error
      decorateNetworkOptionWithDisabled(ids, true)
    );

    this.networkOptions = decoratedNetworkOptions;
  }

  /** @type {Network,null} */
  get selectedNetwork() {
    const flattenedNetworkOptions = this.networkOptions.reduce(
      flattenNetworkOptionGroups,
      []
    );

    const selectedNetwork = flattenedNetworkOptions.find(
      (option) => option?.network?.id === this.args?.selectedNetworkId
    );

    return selectedNetwork || null;
  }

  /**
   * Unwraps Network object from NetworkOption so that caller can remain agnostic of `ember-power-select`s bespoke grouped option schema.
   * @see https://ember-power-select.com/docs/groups
   * @param {NetworkOption,null} networkOption
   */
  // @ts-expect-error
  @action callOnChange(networkOption) {
    this.args.onChange(networkOption?.network || null);
  }

  @action updateNetworkList() {
    this.networkOptions = this.args.networks.map((network) => {
      return new NetworkOption(network, false);
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HvnSelect: typeof HvnSelectComponent;
    'hvn-select': typeof HvnSelectComponent;
  }
}
