import { variation } from 'ember-launch-darkly';

export default (): boolean => {
  // @ts-expect-error global type
  if (!window.__LD__) {
    // if Launch Darkly is not initialized, return the localStorage value...
    return localStorage.getItem('hcp-ui-cookie-auth') === 'true';
  }

  return (
    variation('hcp-ui-cookie-auth') ||
    localStorage.getItem('hcp-ui-cookie-auth') === 'true'
  );
};
