import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { concat } from '@ember/helper';
import { t } from 'ember-intl';
import { HdsAlert } from '@hashicorp/design-system-components/components';

import {
  PEERING_ROUTE_NAMES,
  TGW_ROUTE_NAMES,
} from '../../../utils/network-routes.js';

/**
 *
 * `NetworksCardsConnectionStatus` description here.
 *
 *
 * ```
 * <Networks::Cards::ConnectionStatus
 *   @state={{state}}
 *   @onDismiss={{function}}
 * />
 * ```
 *
 * @class NetworksCardsConnectionStatus
 *
 */

export default class NetworksCardsConnectionStatus extends Component {
  /**
   * Peering/TGW connection state string. supported [ ACTIVE, FAILED, EXPIRED]
   * @argument state
   * @type {string}
   */
  /**
   * callback function to be call on dismissal icon click
   * @argument onDismiss
   * @type {Function}
   */

  buildOption(state) {
    let failed = {
      icon: 'alert-diamond',
      type: 'connection',
      color: 'critical',
      routes: [
        {
          route: PEERING_ROUTE_NAMES.create,
          i18n: 'components.networks.common.connection.peering-connection',
        },
        {
          route: TGW_ROUTE_NAMES.create,
          i18n: 'components.networks.common.connection.transit-gateway-attachment',
        },
      ],
    };
    switch (state) {
      case 'ACTIVE':
        return {
          icon: 'check-circle',
          type: 'cluster',
          color: 'success',
          status: state ? state.toLowerCase() : '',
          routes: [
            {
              route: 'vault.clusters.list',
              i18n: 'components.networks.common.product.vault',
            },
            {
              route: 'consul.clusters.overview',
              i18n: 'components.networks.common.product.consul',
            },
          ],
        };
      case 'FAILED':
        return {
          ...failed,
          status: state.toLowerCase(),
        };
      case 'EXPIRED':
        return {
          ...failed,
          status: state.toLowerCase(),
        };
      default:
        assert('Unsupported state string was passed.', state === undefined);
    }
  }

  get option() {
    return this.buildOption(this.args.state);
  }

  get isSuccess() {
    return this.args.state === 'ACTIVE';
  }

  <template>
    <div
      class="networks-cards-connection-status"
      data-test-networks-cards-connection-status
      ...attributes
    >
      <HdsAlert
        class="networks-cards-connection-status"
        @type="inline"
        @icon={{this.option.icon}}
        @color={{this.option.color}}
        @onDismiss={{@onDismiss}}
        as |A|
      >
        <A.Title class="hds-typography-display-100 hds-font-weight-medium">
          {{t
            (concat
              "components.networks.cards.status.title." this.option.status
            )
            status=this.option.status
          }}
        </A.Title>
        <A.Description class="hds-typography-body-100 hds-foreground-faint">
          {{t
            (concat
              "components.networks.cards.status.description." this.option.status
            )
            htmlSafe=true
          }}
        </A.Description>
        {{#if this.isSuccess}}
          {{#each this.option.routes as |link|}}
            <A.LinkStandalone
              @text={{t
                "components.networks.cards.status.go-to-clusters"
                service=(t link.i18n)
              }}
              @route={{link.route}}
              @isRouteExternal={{true}}
              @icon="arrow-right"
              @iconPosition="trailing"
            />
          {{/each}}
        {{else}}
          {{#each this.option.routes as |link|}}
            <A.LinkStandalone
              @text={{t
                "components.networks.cards.status.cta"
                connection=(t link.i18n)
              }}
              @route={{link.route}}
              @icon="arrow-right"
              @iconPosition="trailing"
            />
          {{/each}}
        {{/if}}
      </HdsAlert>
    </div>
  </template>
}
