/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128LockStatusFromJSON, Secrets20231128LockStatusToJSON, } from './';
export function Secrets20231128GetProjectLockStatusResponseFromJSON(json) {
    return Secrets20231128GetProjectLockStatusResponseFromJSONTyped(json, false);
}
export function Secrets20231128GetProjectLockStatusResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationLock': !exists(json, 'organization_lock') ? undefined : Secrets20231128LockStatusFromJSON(json['organization_lock']),
        'locks': !exists(json, 'locks') ? undefined : (json['locks'].map(Secrets20231128LockStatusFromJSON)),
    };
}
export function Secrets20231128GetProjectLockStatusResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_lock': Secrets20231128LockStatusToJSON(value.organizationLock),
        'locks': value.locks === undefined ? undefined : (value.locks.map(Secrets20231128LockStatusToJSON)),
    };
}
