import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

// Components
import {
  HdsFormSuperSelectSingleField,
  HdsFormSuperSelectMultipleField,
  HdsFormSelectField,
  HdsTextBody,
} from '@hashicorp/design-system-components/components';

// Helpers
import { t } from 'ember-intl';
import { fn, get } from '@ember/helper';
import { and, eq, gt, gte, lte, not, or } from 'ember-truth-helpers';
import { includes } from '@nullvoxpopuli/ember-composable-helpers';
import iamFilterRolesByService from '../../../helpers/iam-filter-roles-by-service.ts';
import iamFilterDisabledRoles from '../../../helpers/iam-filter-disabled-roles.ts';
import iamGetLabelForService from '../../../helpers/iam-get-label-for-service.ts';
import iamKebabCaseToSentenceCase from '../../../helpers/iam-kebab-case-to-sentence-case.ts';
import iamPermissionsServiceCount from '../../../helpers/iam-permissions-service-count.ts';
import { uniqueServices } from '../../../helpers/iam-filter-available-roles.ts';

// Modifiers
import { on } from '@ember/modifier';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';

// Utils
import { BASIC_ROLE_KEY_UI_ONLY } from '../../../utils/parse-role-id.ts';

// Types
import type { IamEnrichedRole } from '../../../types/iam-enriched-role.ts';
import type {
  IPermission,
  PermissionsMap,
} from '../../../types/permissions.ts';
import type {
  OnChangeRoleFunction,
  OnChangeServiceFunction,
} from '../../../types/permission-select.ts';
import type { IntlService } from 'ember-intl';

export interface ManageAccessFormPermissionSelectSignature {
  Element: HTMLElement;
  Args: {
    availableRoles: IamEnrichedRole[];
    basicRolePrefix?: string;
    basicServiceLabel?: string;
    basicServices?: Array<IamEnrichedRole['service']>;
    enrichedRoles: IamEnrichedRole[];
    fineGrainedServices: Array<IamEnrichedRole['service']>;
    id: string;
    isPermissionDisabled: boolean;
    isRoleRequired?: boolean;
    isServiceRequired?: boolean;
    onChangeRole: OnChangeRoleFunction;
    onChangeService: OnChangeServiceFunction;
    permission: IPermission;
    permissions: PermissionsMap;
    roleHelperText?: string;
    roleLabel?: string;
    serviceHelperText?: string;
    serviceLabel?: string;
  };
  Blocks: {
    default: [];
  };
}

interface GroupedRole {
  groupName: string;
  options: Array<IamEnrichedRole>;
}

export default class ManageAccessFormPermissionSelectComponent extends Component<ManageAccessFormPermissionSelectSignature> {
  @service declare readonly intl: IntlService;
  BASIC_ROLE_KEY_UI_ONLY = BASIC_ROLE_KEY_UI_ONLY;

  didInsertSelectRole = () => {
    const { onChangeService, id, permission, availableRoles } = this.args;

    // Only auto-select the service when there's only one service between all of
    // the basic and fine-grained roles.
    if (!(this.allServices.length > 1) && !permission.service) {
      if (onChangeService && typeof onChangeService === 'function') {
        const event = new Event('change');

        // Since the `target` property on the standard `Event` object is
        // read-only, the method Object.defineProperty is used to safely add a
        // `target` property with the new value.
        Object.defineProperty(event, 'target', {
          writable: true,
          value: { value: this.allServices[0] },
        });

        onChangeService(id, permission, availableRoles, event);
      }
    }
  };

  getSelectedRoles = (
    roles: Array<IamEnrichedRole>,
  ): Array<IamEnrichedRole> => {
    if (roles.length === 0) {
      return [];
    }
    const selectedRoleIds = this.args.permission.roleIds ?? [];
    return roles.filter((role) => role.id && selectedRoleIds.includes(role.id));
  };

  onChangeSingleSuperSelect = (selection: IamEnrichedRole) => {
    const { onChangeRole, id, permission } = this.args;

    const newlySelectedRoleIds: string[] = [selection]
      .map((role) => role.id)
      .filter((roleId) => roleId !== undefined) as string[];

    if (onChangeRole && typeof onChangeRole === 'function') {
      onChangeRole(id, permission, newlySelectedRoleIds);
    }
  };

  onChangeMultipleSuperSelect = (selection: Array<IamEnrichedRole>) => {
    const { onChangeRole, id, permission } = this.args;

    const newlySelectedRoleIds: string[] = selection
      .map((role) => role.id)
      .filter((roleId) => roleId !== undefined) as string[];

    if (onChangeRole && typeof onChangeRole === 'function') {
      onChangeRole(id, permission, newlySelectedRoleIds);
    }
  };

  isSelectedRole = (
    role: IamEnrichedRole,
    roleIds: Array<string> | undefined,
  ) => {
    return (roleIds ?? []).includes(role.id ?? '');
  };

  superSelectAfterOptionsContent = (
    roles: Array<IamEnrichedRole>,
    selectedRoles: Array<IamEnrichedRole>,
  ) => {
    return this.intl.t(
      'manage-access.components.form.permission-select.super-select-after-text',
      {
        selectedCount: selectedRoles.length,
        totalCount: roles.length,
      },
    );
  };

  groupSuperSelectRoles = (
    roles: Array<IamEnrichedRole>,
  ): Array<GroupedRole> => {
    const groupRoles = (roleId: string) => {
      // To be built out more once we have actual groupings
      switch (roleId) {
        // Example of how to group roles - but could group off of any criteria
        // case 'roles/secrets.app-manager':
        // case 'roles/secrets.app-secret-reader':
        //   return 'Secrets';
        default:
          return this.intl.t(
            'manage-access.components.form.permission-select.roles-header',
          );
      }
    };
    const groupedRoles = Object.groupBy(roles, (role: IamEnrichedRole) => {
      return groupRoles(role.id ?? '');
    });
    return Object.entries(groupedRoles).map(([groupName, options]) => {
      return {
        groupName,
        options,
      };
    }) as Array<GroupedRole>;
  };

  get allServices() {
    const { basicServices = [], fineGrainedServices = [] } = this.args;
    return [...basicServices, ...fineGrainedServices];
  }

  get uniqueServices() {
    // Spread and cast an imported Map into an Array.
    return [...uniqueServices];
  }

  <template>
    {{#if (gt this.allServices.length 1)}}
      <HdsFormSelectField
        @isRequired={{@isServiceRequired}}
        {{on "change" (fn @onChangeService @id @permission @availableRoles)}}
        disabled={{@isPermissionDisabled}}
        data-test-manage-access-service-select={{@permission.service}}
        class="select-flex-item"
        as |F|
      >
        <F.Label>
          {{or
            @serviceLabel
            (t "manage-access.components.form.permission-select.select-service")
          }}
        </F.Label>
        {{#if @serviceHelperText}}
          <F.HelperText>
            {{@serviceHelperText}}
          </F.HelperText>
        {{/if}}
        <F.Options>
          <option value="">
            {{t "manage-access.components.form.permission-select.select"}}
          </option>
          {{#each @basicServices as |service|}}
            {{#let (eq @permission.service service) as |isSelected|}}
              <option
                value={{service}}
                selected={{isSelected}}
                disabled={{if
                  (and
                    (gte
                      (iamPermissionsServiceCount @permissions service=service)
                      1
                    )
                    (not isSelected)
                  )
                  "disabled"
                  undefined
                }}
              >
                {{#if @basicServiceLabel}}
                  {{@basicServiceLabel}}
                {{else}}
                  {{t
                    "manage-access.components.form.permission-select.type-roles"
                    type=(iamKebabCaseToSentenceCase service)
                  }}
                {{/if}}
              </option>
            {{/let}}
          {{/each}}
          <optgroup
            label={{t
              "manage-access.components.form.permission-select.type-roles"
              type=(t "manage-access.components.form.permission-select.service")
            }}
          >
            {{#each @fineGrainedServices as |service|}}
              {{#let (eq @permission.service service) as |isSelected|}}
                <option
                  value={{service}}
                  selected={{isSelected}}
                  disabled={{if
                    (and
                      (gte
                        (iamPermissionsServiceCount
                          @permissions service=service
                        )
                        1
                      )
                      (not isSelected)
                    )
                    "disabled"
                    undefined
                  }}
                >
                  {{iamGetLabelForService service}}
                </option>
              {{/let}}
            {{/each}}
          </optgroup>
        </F.Options>
      </HdsFormSelectField>
    {{/if}}
    {{#let
      (iamFilterRolesByService @enrichedRoles service=@permission.service)
      (iamKebabCaseToSentenceCase @permission.service)
      as |roles|
    }}
      {{#let (this.getSelectedRoles roles) as |selectedRoles|}}
        {{#if
          (and
            (includes @permission.service this.uniqueServices)
            (lte @permission.roleIds.length 1)
          )
        }}
          <HdsFormSuperSelectSingleField
            @afterOptionsContent={{this.superSelectAfterOptionsContent
              roles
              selectedRoles
            }}
            @disabled={{@isPermissionDisabled}}
            @onChange={{this.onChangeSingleSuperSelect}}
            @options={{if
              @isPermissionDisabled
              (this.groupSuperSelectRoles roles)
              (this.groupSuperSelectRoles (iamFilterDisabledRoles roles))
            }}
            @placeholder={{t
              "manage-access.components.form.permission-select.select"
            }}
            @searchEnabled={{true}}
            @searchField="title"
            @selected={{get selectedRoles 0}}
            data-test-manage-access-role-super-select={{@permission.service}}
            data-test-manage-access-role-single-super-select={{@permission.service}}
            {{didInsert this.didInsertSelectRole}}
            as |F|
          >
            <F.Label>
              {{or
                @roleLabel
                (t
                  "manage-access.components.form.permission-select.select-role"
                )
              }}
            </F.Label>
            <F.Options>
              {{#let F.options as |role|}}
                <HdsTextBody @size="200">
                  {{#if @basicRolePrefix}}
                    {{@basicRolePrefix}}
                  {{/if}}
                  {{! @glint-expect-error: fix incoming type }}
                  {{role.title}}
                </HdsTextBody>
              {{/let}}
            </F.Options>
          </HdsFormSuperSelectSingleField>
        {{else}}
          <HdsFormSuperSelectMultipleField
            @afterOptionsContent={{this.superSelectAfterOptionsContent
              roles
              selectedRoles
            }}
            @disabled={{@isPermissionDisabled}}
            @onChange={{this.onChangeMultipleSuperSelect}}
            @options={{if
              @isPermissionDisabled
              (this.groupSuperSelectRoles roles)
              (this.groupSuperSelectRoles (iamFilterDisabledRoles roles))
            }}
            @placeholder={{t
              "manage-access.components.form.permission-select.select"
            }}
            @searchEnabled={{true}}
            @searchField="title"
            @selected={{selectedRoles}}
            data-test-manage-access-role-super-select={{@permission.service}}
            data-test-manage-access-role-multiple-super-select={{@permission.service}}
            {{didInsert this.didInsertSelectRole}}
            as |F|
          >
            <F.Label>
              {{or
                @roleLabel
                (t
                  "manage-access.components.form.permission-select.select-roles"
                )
              }}
            </F.Label>
            <F.Options>
              {{#let F.options as |role|}}
                <HdsTextBody @size="200">
                  {{#if @basicRolePrefix}}
                    {{@basicRolePrefix}}
                  {{/if}}
                  {{! @glint-expect-error: fix incoming type }}
                  {{role.title}}
                </HdsTextBody>
              {{/let}}
            </F.Options>
          </HdsFormSuperSelectMultipleField>
        {{/if}}
      {{/let}}
    {{/let}}
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ManageAccessFormPermissionSelectComponent: typeof ManageAccessFormPermissionSelectComponent;
  }
}

/**
 * https://github.com/microsoft/TypeScript/pull/56805
 * groupBy has been added to typescript, but I can't seem to get glint to understand that this is fine
 * So for now, just copying in the type definition here from the typescript PR
 */
declare global {
  interface ObjectConstructor {
    groupBy<K extends PropertyKey, T>(
      items: Iterable<T>,
      keySelector: (item: T, index: number) => K,
    ): Partial<Record<K, T[]>>;
  }
}
