/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { macroCondition, isDevelopingApp } from '@embroider/macros';

/**
 *
 * `IamInvitationsModalsRevoke` renders the modal for revoking an invitation previously sent to a user.
 *
 *
 * ```
 * <Iam::Invitations::Modals::Revoke
 *   @invites={{invites}}
 *   @isActive={{isActive}}
 *   @onCancel={{onCancel}}
 *   @onSuccess={{onSuccess}}
 *   @organization={{organization}}
 * />
 * ```
 *
 * @class IamInvitationsModalsRevoke
 *
 */

export default class IamInvitationsModalsRevokeComponent extends Component {
  /**
   * A list of invites to revoke.
   * @argument invites
   * @type {Object}
   */

  /**
   * This shows or hides the modal based on truthiness.
   * @argument isActive
   * @type {Boolean}
   */

  /**
   * A callback function to be called when the modal is closed.
   * @argument onCancel
   * @type {Function}
   */

  /**
   * A callback function to be called on a successful revocation of invites.
   * @argument onSuccess
   * @type {Function}
   */

  /**
   * The organization context of the invites that are being revoked.
   * @argument organization
   * @type {Object}
   */

  constructor() {
    super(...arguments);

    assert(
      '<Iam::Invitations::Modals::Revoke> required argument @invites is a defined object',
      typeof this.args.invites === 'object'
    );

    assert(
      '<Iam::Invitations::Modals::Revoke> required argument @isActive is a defined boolean',
      typeof this.args.isActive === 'boolean'
    );

    assert(
      '<Iam::Invitations::Modals::Revoke> required argument @onCancel is a defined function',
      typeof this.args.onCancel === 'function'
    );

    assert(
      '<Iam::Invitations::Modals::Revoke> required argument @onSuccess is a defined function',
      typeof this.args.onSuccess === 'function'
    );

    assert(
      '<Iam::Invitations::Modals::Revoke> required argument @organization is a defined object',
      typeof this.args.organization === 'object'
    );
  }

  @service api;
  @tracked emails = null;
  @service flashMessages;
  @service intl;

  get inviteeEmail() {
    const { invites } = this.args;
    return invites[0].inviteeEmail;
  }

  @task
  *revokeInvites() {
    try {
      let { organization, invites } = this.args;
      let removals = Promise.allSettled(
        invites.map((invite) => {
          return this.api.invitation.invitationsServiceDeleteOrganizationInvitation(
            organization.id,
            invite.id
          );
        })
      );

      let resp = yield removals;
      let rejected = resp.filter((deleteInvitationResp) => {
        return deleteInvitationResp.status === 'rejected';
      });

      if (rejected.length) {
        this.flashMessages.error(
          this.intl.t(`iam.invitations.modals.revoke.error-title`),
          {
            content: this.intl.t(`iam.invitations.modals.revoke.error-body`, {
              user: invites[0].inviteeEmail,
              htmlSafe: true,
            }),
          }
        );
        throw rejected;
      }

      this.args.onSuccess({
        resp,
        invites,
      });
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }
}
