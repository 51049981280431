import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type FlashMessagesService from '../../services/flash-messages.ts';

interface SystemNotificationsFcpBalanceSignature {
  Args: {
    flash: unknown;
  };
}

export default class SystemNotificationsFcpBalanceComponent extends Component<SystemNotificationsFcpBalanceSignature> {
  @service declare readonly flashMessages: FlashMessagesService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SystemNotifications::FcpBalance': typeof SystemNotificationsFcpBalanceComponent;
    'system-notifications/fcp-balance': typeof SystemNotificationsFcpBalanceComponent;
  }
}
