import Component from '@glimmer/component';
import { eq } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsCardContainer } from '@hashicorp/design-system-components/components';
import Typography from 'core/components/typography';
import boxPadding from 'core/modifiers/box-padding';
import {
  TYPE_NETWORK,
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
  TYPE_NETWORK_ROUTE,
} from 'common/utils/cloud-resource-types';

import {
  NETWORK_CREATE_UP_NEXT_CONTENT,
  TARGET_CREATE_UP_NEXT_CONTENT,
  NETWORK_ROUTE_CREATE_UP_NEXT_CONTENT,
} from '../../../utils/cards-up-next.js';

/**
 *
 * `NetworksCardsUpNext` renders the Up next card that has a default title "Up next".  It also contains an optional name blocks :title :subtitle and :instructions so consumers have the option to replace these values with custom content.
 *
 *
 * ```
 * <Networks::Cards::UpNext @resourceType='hashicorp.network.hvn' @provider={{this.provider}}/>
 * ```
 *
 * @class NetworksCardsUpNext
 *
 */

/**
 * `resourceType` is a string specifying the type of HashiCorp resource.
 * @argument resourceType
 * @type {string}
 */
/**
 * `provider` is a string specifying the provider type, values can be aws or azure. This value is required when the resourceType is TYPE_NETWORK or TYPE_NETWORK_ROUTE
 * @argument provider
 * @type {string}
 */
export default class NetworksCardsUpNext extends Component {
  get target() {
    switch (this.args.resourceType) {
      case TYPE_NETWORK_PEERING:
        return 'peering';
      case TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT:
        return 'tgw';
      default:
        return null;
    }
  }

  get provider() {
    if (
      this.args.resourceType === TYPE_NETWORK ||
      this.args.resourceType === TYPE_NETWORK_ROUTE
    ) {
      return this.args.provider;
    } else {
      return null;
    }
  }

  get content() {
    switch (this.args.resourceType) {
      case TYPE_NETWORK:
        return NETWORK_CREATE_UP_NEXT_CONTENT;
      case TYPE_NETWORK_PEERING:
        return TARGET_CREATE_UP_NEXT_CONTENT;
      case TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT:
        return TARGET_CREATE_UP_NEXT_CONTENT;
      case TYPE_NETWORK_ROUTE:
        return NETWORK_ROUTE_CREATE_UP_NEXT_CONTENT;
      default:
        return null;
    }
  }

  <template>
    <article
      class="cards-up-next"
      data-test-cards-up-next
      {{boxPadding "0 0 lg 0"}}
    >
      <HdsCardContainer @level="mid" @hasBorder={{true}} {{boxPadding "lg"}}>
        <header {{boxPadding "0 0 md 0"}}>
          <Typography
            data-test-cards-up-next-title
            @variant="h5"
            class="hds-typography-display-400 hds-font-weight-semibold hds-foreground-strong"
          >
            {{#if (has-block "title")}}
              <span data-test-cards-up-next-custom-title>
                {{yield to="title"}}
              </span>
            {{else}}
              {{t "components.networks.cards.up-next.title"}}
            {{/if}}
          </Typography>
        </header>
        <div data-test-cards-up-next-content>
          {{#if this.content}}
            {{#each this.content as |content index|}}
              <div {{boxPadding (if (eq index 0) "0" "md 0 0 0")}}>
                {{#if content.subtitle}}
                  <Typography
                    data-test-cards-up-next-subtitle-item
                    @variant="body1"
                    class="hds-typography-body-200 hds-font-weight-regular hds-foreground-primary"
                    {{boxPadding "0 0 xs 0"}}
                  >
                    <span data-test-cards-up-next-subtitle-text>
                      {{#if this.target}}
                        {{t content.subtitle target=this.target}}
                      {{else if this.provider}}
                        {{t content.subtitle provider=this.provider}}
                      {{/if}}
                    </span>
                  </Typography>
                {{/if}}
                {{#if content.instructions}}
                  <Typography
                    data-test-cards-up-next-instructions-item
                    @variant="body1"
                    class="hds-typography-body-100 hds-font-weight-regular hds-foreground-faint"
                  >
                    <div data-test-cards-up-next-instructions-text>
                      {{#if this.target}}
                        {{t content.instructions target=this.target}}
                      {{else if this.provider}}
                        {{t content.instructions provider=this.provider}}
                      {{/if}}
                    </div>
                  </Typography>
                {{/if}}
              </div>
            {{/each}}
          {{else}}
            {{#if (has-block "subtitle")}}
              <Typography
                data-test-cards-up-next-custom-subtitle
                @variant="body1"
                class="hds-typography-body-200 hds-font-weight-regular hds-foreground-primary"
                {{boxPadding "0 0 xs 0"}}
              >
                {{yield to="subtitle"}}
              </Typography>
            {{/if}}
            {{#if (has-block "instructions")}}
              <Typography
                data-test-cards-up-next-custom-instructions
                @variant="body1"
                class="hds-typography-body-100 hds-font-weight-regular hds-foreground-faint"
              >
                {{yield to="instructions"}}
              </Typography>
            {{/if}}
          {{/if}}
        </div>
      </HdsCardContainer>
    </article>
  </template>
}
