import Service, { inject as service } from '@ember/service';

import {
  TYPE_PROJECT,
  TYPE_ORGANIZATION,
} from 'common/utils/cloud-resource-types';

import iamFetchUntilEmpty from '../helpers/iam-fetch-until-empty.ts';

import type ApiService from 'api/services/api';
import type AbilitiesService from 'ember-can/services/abilities';
import type { RolesLocation } from '../types/roles-location';
import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';
import type { IResourceContext } from '../types/role-assignments-config.ts';
import type { Organization, Project } from 'core/services/user-context';

export default class RolesLocations extends Service {
  @service declare readonly api: ApiService;
  @service declare readonly abilities: AbilitiesService;

  async fetch({
    organization,
    project,
    resource,
  }: {
    organization?: Organization;
    project?: Project;
    resource?: IResourceContext;
  }): Promise<RolesLocation[]> {
    const requests: Array<() => Promise<RolesLocation>> = [];

    if (
      this.abilities.can('list roles in organization') &&
      organization &&
      organization.id &&
      organization.name
    ) {
      requests.push(async () => {
        const fetchAllRoles = iamFetchUntilEmpty(
          (...args: [string]) =>
            this.api.resourceManager.org.organizationServiceListRoles(...args),
          'roles',
        );

        const { roles = [] } = await fetchAllRoles(organization.id);

        return {
          link: {
            type: TYPE_ORGANIZATION,
            id: organization.id,
            name: organization.name,
          },
          roles: roles as HashicorpCloudResourcemanagerRole[],
        } as RolesLocation;
      });
    }

    if (
      this.abilities.can('list roles in project') &&
      project &&
      project.id &&
      project.name
    ) {
      requests.push(async () => {
        const fetchAllRoles = iamFetchUntilEmpty(
          (...args: [string | undefined]) =>
            this.api.resourceManager.resources.resourceServiceListRoles(
              ...args,
            ),
          'roles',
        );

        const { roles = [] } = await fetchAllRoles(
          `project/${project.id}`,
          undefined,
          100,
        );

        return {
          link: {
            type: TYPE_PROJECT,
            id: project.id,
            name: project.name,
          },
          roles: roles as HashicorpCloudResourcemanagerRole[],
        } as RolesLocation;
      });
    }

    if (resource && resource.id && resource.resourceName && resource.type) {
      requests.push(async () => {
        const fetchAllRoles = iamFetchUntilEmpty(
          (...args: [string | undefined]) =>
            this.api.resourceManager.resources.resourceServiceListRoles(
              ...args,
            ),
          'roles',
        );

        const { roles = [] } = await fetchAllRoles(
          resource.resourceName,
          undefined,
          100,
        );

        return {
          link: {
            type: resource.type,
            id: resource.id,
            name: resource.resourceName,
          },
          roles: roles as HashicorpCloudResourcemanagerRole[],
        } as RolesLocation;
      });
    }

    return await Promise.all(requests.map((request) => request()));
  }
}
