/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `HcpMarketingProducts` renders the HDS flight icon and label for each HCP product defined in the hcpProducts object below.
 *
 *
 * ```
 * <HcpMarketing::Products />
 * ```
 *
 * @class HcpMarketingProducts
 *
 */
export default class HcpMarketingProducts extends Component {
  get hcpProducts() {
    const products = [
      {
        icon: 'terraform',
        label: 'nav.project.terraform.header',
      },
      {
        icon: 'vagrant',
        label: 'nav.project.vagrant-short',
      },
      {
        icon: 'packer',
        label: 'nav.project.packer',
      },
      {
        icon: 'waypoint',
        label: 'nav.project.waypoint',
      },
      {
        icon: 'vault',
        label: 'nav.project.vault',
      },
      {
        icon: 'boundary',
        label: 'nav.project.boundary',
      },
    ];

    return products;
  }
}
