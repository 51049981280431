/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { macroCondition, isDevelopingApp } from '@embroider/macros';

/**
 *
 * `PageAccountSettingsSecurity` renders the user's password and MFA reset functionality.
 *
 *
 * ```
 * <Page::AccountSettings::Security />
 * ```
 *
 * @class PageAccountSettingsSecurity
 *
 */

export default class PageAccountSettingsSecurityComponent extends Component {
  @service currentUser;
  @service api;
  @service intl;
  @service flashMessages;

  // Modal state
  @tracked showPasswordResetModal = false;
  @tracked showManageMFAModal = false;

  @task
  *resetUserPassword() {
    try {
      yield this.api.profile.profileServiceResetPassword();
      this.flashMessages.success(
        this.intl.t(
          'components.page.account-settings.security.passReset.flashMessage.success',
          {
            htmlSafe: true,
            userEmail: this.currentUser.user.email,
          }
        )
      );
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      this.flashMessages.error(
        this.intl.t(
          'components.page.account-settings.security.passReset.flashMessage.error'
        )
      );
    }
    this.showPasswordResetModal = false;
  }

  get MFAEnabled() {
    let { mfaStatus } = this.args.model;

    if (mfaStatus === 'ENABLED') {
      return true;
    } else {
      return false;
    }
  }
}
