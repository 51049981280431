import { OIDCClient } from '@hashicorp/oidc-client-js';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import BaseAuthenticator from 'ember-simple-auth/authenticators/base';
import { inject as service } from '@ember/service';

import config from '../config/environment';
import { AUTH_CHANNEL_NAME, AUTH_CHANNEL_EVENTS } from '../utils/constants';
import { PRIMARY_IDENTITY, CONNECTION_STRATEGY } from './auth0';
import { USER_LOGGED_IN } from 'core/utils/consts/analytics-events/platform';
import setCookie from 'hcp/utils/set-access-token-cookie';

import cookieAuthEnabled from 'core/helpers/cookie-auth-enabled';

export const UPSTREAM_IDP_CLAIM = 'https://auth.hashicorp.com/idp';

export default class CloudIDP extends BaseAuthenticator {
  @service analytics;
  @service api;
  @service currentUser;
  @service session;
  @service router;

  constructor() {
    super(...arguments);
    if (!config?.APP?.cloudIDP?.client_id) {
      return;
    }

    let logLevel = 'disabled';

    if (macroCondition(isDevelopingApp())) {
      logLevel = 'debug';
    }

    this.client = new OIDCClient(
      config?.APP?.cloudIDP?.client_id,
      `${window.location.origin}/login/callback`,
      config?.APP?.cloudIDP?.domain,
      {
        // 'disabled' value isn't in range of allowed values, so it effectively
        // disabled logging of the logger. We do this because the logs aren't
        // actionable, _and_ any log level still logs to the browser console
        level: logLevel,
      }
    );
  }

  channel = new BroadcastChannel(AUTH_CHANNEL_NAME);

  async getUser(accessToken) {
    let user = await this.client.getUserInfo(accessToken);
    return {
      ...user,
      isSSO:
        user[CONNECTION_STRATEGY] === 'samlp' ||
        user[UPSTREAM_IDP_CLAIM] === 'oidc',
      primaryIdentity: user[PRIMARY_IDENTITY],
    };
  }

  async silentAuth() {
    let data = await this.session.store.restore(true);
    return data?.authenticated;
  }

  async restore(data) {
    if (cookieAuthEnabled()) {
      await this.currentUser.load();
    }
    // just return the data we get back from the session store restore
    return data;
  }

  async authenticate(args = {}) {
    if (cookieAuthEnabled()) {
      const err = await this.currentUser.load();

      // assume we're logged in if we don't get an error from this call
      if (!err?.message) {
        return {};
      }

      const ROOT = config.isRunningAgainstRemoteDev
        ? window?.location?.origin
        : config?.APP?.baseServiceHost;
      const loginURI = `${ROOT}/auth/cloud-idp/login`;

      this.redirectTo(loginURI);

      return new Promise(() => {});
    } else {
      let tokens, user, campaignAnalytics;
      await this.client.waitUntilReady();
      if (args.isSilentAuth) {
        return await this.silentAuth();
      }
      if (this.analytics.context?.campaign) {
        campaignAnalytics = this.analytics.context.campaign;
      }

      if (!args.isCallback) {
        let loginRedirectArgs = loginRedirectArgsBuilder(
          args,
          campaignAnalytics
        );

        await this.client.loginRedirect(loginRedirectArgs);
        return new Promise(() => {});
      }

      // if we're in an iframe, that means we're doing silent auth,
      // and that finishes on client instantiation.
      // once that is done, we can return early _without_ doing other auth
      if (window.self !== window.top) {
        return new Promise(() => {});
      }
      try {
        tokens = await this.client.getTokens();
        user = await this.getUser(tokens.access_token);

        // We set these context values to null to avoid leaking any auth codes from
        // the callback URL to Segment
        this.analytics.trackEvent(
          USER_LOGGED_IN,
          {
            type: 'cloud-idp',
            session: {
              user: user,
            },
          },
          {
            context: {
              page: {
                url: null,
                path: null,
                search: null,
              },
            },
          }
        );
      } catch (e) {
        if (macroCondition(isDevelopingApp())) {
          console.error(e);
        }
        return Promise.reject();
      }

      return {
        accessToken: tokens.access_token,
        idToken: tokens.id_token,
        user,
      };
    }
  }

  clearCookie() {
    setCookie(false);
  }

  // wrapping for testing purposes
  redirectTo(url) {
    window.location.assign(url);
  }

  async invalidate({ idToken }, options) {
    await this.client.waitUntilReady();
    this.channel.postMessage({ type: AUTH_CHANNEL_EVENTS.INVALIDATE_SESSION });
    if (options?.skipLogout === true) {
      return Promise.resolve();
    }

    // delete hcp_access_token cookie
    this.clearCookie();

    this.client.logout({
      idTokenHint: idToken,
      postLogoutRedirectURI: window.location.origin,
    });

    // we don't resolve here because the logout call above will redirect the user
    return new Promise(() => {});
  }
}

export let loginRedirectArgsBuilder = (args = {}, campaignAnalytics = {}) => {
  let loginParams = ['connection', 'screen_hint', 'conn_id'];
  let loginRedirectArgs = {};

  loginParams.map((param) => {
    if (args[param]) {
      if (param === 'conn_id') {
        let dashParam = 'conn-id';
        loginRedirectArgs[dashParam] = args[param];
      } else {
        loginRedirectArgs[param] = args[param];
      }
    }
  });

  loginRedirectArgs = {
    ...loginRedirectArgs,
    ...campaignAnalytics,
  };

  return loginRedirectArgs;
};
