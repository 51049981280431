/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionCfgRefFromJSON, HashicorpCloudWaypointActionCfgRefToJSON, HashicorpCloudWaypointActionRunScopeFromJSON, HashicorpCloudWaypointActionRunScopeToJSON, HashicorpCloudWaypointRunActionRequestVariableOverrideFromJSON, HashicorpCloudWaypointRunActionRequestVariableOverrideToJSON, TheNamespaceTheActionToBeStoredInFromJSON, TheNamespaceTheActionToBeStoredInToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceRunActionBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceRunActionBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceRunActionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : TheNamespaceTheActionToBeStoredInFromJSON(json['namespace']),
        'actionRef': !exists(json, 'action_ref') ? undefined : HashicorpCloudWaypointActionCfgRefFromJSON(json['action_ref']),
        'scope': !exists(json, 'scope') ? undefined : HashicorpCloudWaypointActionRunScopeFromJSON(json['scope']),
        'variableOverrides': !exists(json, 'variable_overrides') ? undefined : (json['variable_overrides'].map(HashicorpCloudWaypointRunActionRequestVariableOverrideFromJSON)),
    };
}
export function HashicorpCloudWaypointWaypointServiceRunActionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': TheNamespaceTheActionToBeStoredInToJSON(value.namespace),
        'action_ref': HashicorpCloudWaypointActionCfgRefToJSON(value.actionRef),
        'scope': HashicorpCloudWaypointActionRunScopeToJSON(value.scope),
        'variable_overrides': value.variableOverrides === undefined ? undefined : (value.variableOverrides.map(HashicorpCloudWaypointRunActionRequestVariableOverrideToJSON)),
    };
}
