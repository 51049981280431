import ESASession from 'ember-simple-auth/services/session';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getRedirectTarget, clearRedirectTarget } from 'hcp/utils/redirect';

/**
 * `SessionService` extends the ember-simple-auth (ESA) Session service so that we can pull
 * the `accessToken` from memory from ESA's internal sessionStore.
 * We do this because the `accessToken` is not stored in local storage
 * (see: https://github.com/hashicorp/cloud-ui/blob/main/hcp/app/session-stores/application.js#L5-L23).
 *
 * @class SessionService
 * @module hcp/app/services/session
 * @extends ESASession
 */
export default class SessionService extends ESASession {
  @service router;
  @service config;

  timeoutID = undefined;

  @tracked
  _isSilentAuthTooLong = false;

  get isSilentAuthTooLong() {
    return this._isSilentAuthTooLong;
  }

  @action
  checkIfSilentAuthTooLong() {
    this.timeoutID = setTimeout(() => {
      this._isSilentAuthTooLong = true;
    }, 10 * 1000);
  }

  @action
  resetSilentAuthCheck() {
    this._isSilentAuthTooLong = false;
    clearTimeout(this.timeoutID);
  }

  /**
   * `handleAuthentication` (ember-simple-auth's) only uses a cookie to track redirect target if
   *  you're using fastboot, otherwise it keeps track of the redirect target as a parameter on the session service.
   *  Because we re-direct to cloud-idp and auth0 as part of our authentication flow, that parameter is reset.
   *  Instead, we keep track of the redirectTarget in browser localStorage and override
   * the handleAuthentication method as recommended by ember-simple-auth.
   * @method handleAuthentication
   * @param {String} routeAfterAuthentication
   * @returns {Transition}
   *
   */
  async handleAuthentication() {
    let redirectTarget = getRedirectTarget();
    let routeInfo = redirectTarget
      ? this.router.recognize(redirectTarget)
      : null;

    // use redirectTarget if the route is registered with the router
    // and if it's not a 404 otherwise use 'cloud'

    redirectTarget =
      routeInfo && routeInfo?.localName !== '404' ? redirectTarget : 'cloud';

    const transition = this.router.transitionTo(redirectTarget);

    return transition.followRedirects().finally(() => {
      clearRedirectTarget();
    });
  }
}
