import Component from '@glimmer/component';
import { service } from '@ember/service';
import { assert } from '@ember/debug';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import {
  HdsButton,
  HdsLinkStandalone,
} from '@hashicorp/design-system-components/components';
import * as Sentry from '@sentry/ember';
import Typography from 'core/components/typography';
import boxPadding from 'core/modifiers/box-padding';

import templateString from 'core/utils/template-string';

const awsQuickCreateStackLink = templateString`https://${'awsTargetRegion'}.console.aws.amazon.com/cloudformation/home?region=${'awsTargetRegion'}#/stacks/create/review
?templateURL=${'templateURL'}
&stackName=${'stackName'}
&param_hcpApiAddr=${'hcpApiAddr'}
&param_hvnAccount=${'hvnAccount'}
&param_hvnCidrRange=${'hvnCidrRange'}
&param_hvnId=${'hvnId'}
&param_hvnOrganization=${'hvnOrganization'}
&param_hvnProject=${'hvnProject'}
&param_hvnRegion=${'hvnRegion'}
&param_hvnToken=${'hvnToken'}
`;

export default class NetworksPeeringsAwsCreateAutomate extends Component {
  @service('config') appConfig;
  @service analytics;
  @service api;

  launchQuickCreateStack = async () => {
    try {
      this.analytics.trackEvent(
        'external_ui_hashicorp_network_peering_launch_quick_create_button_clicked',
        {
          ...this.args.network,
        },
      );

      let { network } = this.args;

      // Create a single-use token for creating peering.
      const tokenResp = this.api.network.createToken(
        network.location.organizationId,
        network.location.projectId,
        network.id,
        {},
      );

      const { token } = await tokenResp;
      if (!token) {
        throw Error(`failed to create a single-use peering token: ${token}`);
      }
      if (
        !network ||
        !network.cidrBlock ||
        !network.providerNetworkData?.awsNetworkData?.accountId ||
        !network.location?.region?.region
      ) {
        throw new Error(`missing fields in network response: ${network}`);
      }

      // Populate a link to initiate the peering. Open in a new tab.
      const quickCreateStackLink = awsQuickCreateStackLink({
        // TODO: support for cross-region peering. Requires more space than the dropdown.
        awsTargetRegion: network.location.region.region,
        // When bumping this, check that it matches the latest (or previously supported) version of hcp-templates.
        // You can check that here: https://github.com/hashicorp/hcp-templates/blob/f0187080d9febc37a47482428a2000f1ec1a7b4a/.bumpversion.cfg#L2
        // Or here: https://github.com/hashicorp/hcp-templates/tags
        templateURL:
          'https://s3.us-west-2.amazonaws.com/hcp-peering/auto-vpc-peering.0.2.11.template',
        // We're taking the end of the token is here to add a bit of noise to avoid duplicate stack names.
        // A user might peer one HVN to multiple VPCs in a single account, and we don't have other unique info
        // here to make each stack name unique.
        stackName: `hcp-peering-${network.id}-${token
          .substring(token.length - 5)
          .replace('_', '')
          .replace('-', '')
          .toLowerCase()}`,
        hcpApiAddr:
          this.appConfig?.app?.baseServiceHost || 'http://localhost:28081',
        hvnAccount: network.providerNetworkData.awsNetworkData.accountId,
        hvnCidrRange: network.cidrBlock,
        hvnId: network.id,
        hvnOrganization: network.location.organizationId,
        hvnProject: network.location.projectId,
        hvnRegion: network.location.region.region,
        hvnToken: token,
      });

      window.open(quickCreateStackLink);
    } catch (e) {
      assert(e);
      Sentry.eventFromException(e);
    }
  };

  goToFeedbackForm = () => {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_feedback_form_link_clicked',
      {
        ...this.args.network,
      },
    );
  };

  goToExternalAwsQuickCreateLink = () => {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_aws_quick_create_link_clicked',
      {
        ...this.args.network,
      },
    );
  };

  goToExternalAwsWorkingWithStacksLink = () => {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_aws_working_with_stacks_link_clicked',
      {
        ...this.args.network,
      },
    );
  };

  <template>
    {{#let @item as |Item|}}
      <Item @xs="12" @sm="12" @md="8" @lg="8">
        <Typography @variant="h4">
          {{t
            "components.networks.peerings.aws.automate.phase-2.permissions.title"
          }}
        </Typography>
        <Typography>
          {{t
            "components.networks.peerings.aws.automate.phase-2.permissions.text"
          }}
        </Typography>
        <Typography @variant="h4">
          {{t "components.networks.peerings.aws.automate.phase-2.steps.title"}}
        </Typography>
        <Typography>
          <p>
            {{t "components.networks.peerings.aws.automate.phase-2.steps.text"}}
          </p>
          <ol>
            <li>
              {{t
                "components.networks.peerings.aws.automate.phase-2.steps.step-1"
              }}
            </li>
            <li>
              {{t
                "components.networks.peerings.aws.automate.phase-2.steps.step-2"
              }}
            </li>
            <li>
              {{t
                "components.networks.peerings.aws.automate.phase-2.steps.step-3"
              }}
            </li>
            <li>
              {{t
                "components.networks.peerings.aws.automate.phase-2.steps.step-4"
              }}
            </li>
          </ol>
        </Typography>
        <div {{boxPadding "0 0 md 0"}}>
          <img
            src={{t
              "components.networks.peerings.aws.automate.steps.quick-create-page.screenshot.imgSrc"
            }}
            width="100%"
            alt={{t
              "components.networks.peerings.aws.automate.steps.quick-create-page.screenshot.alt"
            }}
            data-test-networks-peerings-aws-automate-create-instructions-screenshot
          />
        </div>
        <Typography
          @variant="body1"
          data-test-networks-peerings-aws-automate-notes-content
        >
          <strong>
            {{t
              "components.networks.peerings.aws.automate.phase-2.notes.heading"
            }}
          </strong>
          <span>
            {{t "components.networks.peerings.aws.automate.phase-2.notes.one"}}
          </span>
        </Typography>
        <div
          class="networks-peerings-aws-automate__external-links"
          {{boxPadding "sm 0 sm 0"}}
        >
          <div {{boxPadding "0 md 0 0"}}>
            <HdsLinkStandalone
              data-test-networks-peerings-aws-automate-create-notes-external-link-quick-create
              @icon="external-link"
              @iconPosition="trailing"
              @text={{t
                "components.networks.peerings.aws.automate.notes.links.quick-create.text"
              }}
              @href={{t
                "components.networks.peerings.aws.automate.notes.links.quick-create.href"
              }}
              {{on "click" this.goToExternalAwsQuickCreateLink}}
            />
          </div>
          <div>
            <HdsLinkStandalone
              data-test-networks-peerings-aws-automate-notes-external-link-stacks
              @icon="external-link"
              @iconPosition="trailing"
              @text={{t
                "components.networks.peerings.aws.automate.notes.links.stacks.text"
              }}
              @href={{t
                "components.networks.peerings.aws.automate.notes.links.stacks.href"
              }}
              {{on "click" this.goToExternalAwsWorkingWithStacksLink}}
            />
          </div>
        </div>
        <div {{boxPadding "sm 0 lg 0"}}>
          <HdsButton
            @text={{t "components.networks.peerings.aws.automate.cta-text"}}
            @icon="external-link"
            @iconPosition="trailing"
            data-test-networks-peerings-aws-automate-cta
            {{on "click" this.launchQuickCreateStack}}
          />
        </div>
      </Item>
    {{/let}}
  </template>
}
