import { HdsTextBody } from '@hashicorp/design-system-components/components';
import Box from 'core/components/box';
import FlexGrid from 'core/components/flex-grid';
import Typography from 'core/components/typography';

import './tier-card.scss';

<template>
  <div class="tier-card" ...attributes>
    <Box @padding="0 lg md lg">
      <FlexGrid as |G|>
        <G.Item @xs="12" @sm="12" @md="12" @lg="12">
          <Typography @variant="h4" data-test-name>
            {{@name}}
          </Typography>
        </G.Item>
        <G.Item @xs="12" @sm="12" @md="12" @lg="12">
          <HdsTextBody
            data-test-description
            @color="var(--token-color-foreground-faint)"
            @weight="regular"
            @size="100"
            @tag="p"
          >
            {{@description}}
          </HdsTextBody>
        </G.Item>
        <G.Item @xs="12" @sm="12" @md="12" @lg="12">
          {{yield}}
        </G.Item>
      </FlexGrid>
    </Box>
  </div>
</template>
