import Box from 'core/components/box';
import FlexGrid from 'core/components/flex-grid';
import Typography from 'core/components/typography';

<template>
  <div class="tier-card tier-size-card" ...attributes>
    <Box @padding="0 lg 0 lg">
      <FlexGrid as |G|>
        <G.Item @xs="6" @sm="6" @md="6" @lg="6">
          <Typography @variant="h4" data-test-name>
            {{@name}}
          </Typography>
        </G.Item>
        <G.Item @xs="6" @sm="6" @md="6" @lg="6">
          {{#if @cost}}
            <Typography
              @variant="h4"
              class="tier-size-card__cost"
              data-test-cost={{@name}}
            >
              {{@cost}}
            </Typography>
          {{/if}}
        </G.Item>
        <G.Item @xs="12" @sm="12" @md="12" @lg="12">
          {{yield}}
        </G.Item>
      </FlexGrid>
    </Box>
  </div>
</template>
