/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { FileState } from 'ember-file-upload';

import config from 'hcp/config/environment';

/**
 *
 * `PageCensusReportsCreate` "uploads" documents for census processing.
 *
 *
 * ```
 * <Page::Census::Reports::Create />
 * ```
 *
 * @class PageCensusReportsCreate
 *
 */

const allowedTypes = ['application/json'];
const UPLOAD_PROGRESS_COLOR_MAPPING = {
  [FileState.Aborted]: 'warning',
  [FileState.Failed]: 'critical',
  [FileState.Queued]: null, // No loading bar progress yet.
  [FileState.TimedOut]: 'warning',
  [FileState.Uploaded]: 'success',
  [FileState.Uploading]: 'action',
};
const CENSUS_API_ENDPOINTS = {
  [config.DEPLOY_TARGETS.DEV]:
    'https://staging.reporting.hashicorp.services/v2/',
  [config.DEPLOY_TARGETS.INT]:
    'https://staging.reporting.hashicorp.services/v2/',
  [config.DEPLOY_TARGETS.PROD]: 'https://reporting.hashicorp.services/v2/',
};

/**
 * POSTs the contents of a selected file upload to a specified URL. This is used
 * in conjunction and as a patch to use with ember-file-upload's multi-file
 * select addon.
 *
 * @async
 * @function postDirectlyToUrl
 * @param {string} url - The URL to which the file will be posted.
 * @param {Object} [options={ method: 'POST' }] - The options for the fetch
 *     request, defaults to POST method.
 * @returns {Promise<Object>} A promise that resolves to the object itself after
 *     updating its state based on the response.
 *
 * The function first converts the file to text and sets the object's state to
 * the value of FileState.Uploading.
 * It then performs a fetch request to the specified URL using the provided
 * options and the file text as the body.
 * If the response status is any 200 state then the state is set to
 * FileState.Uploaded, 'isUploadComplete' is set to true, and 'progress' is set
 * to 100 to mimic a 100% complete uplpoad.
 * If the response status is outside this range, the state is set to
 * FileState.Failed, 'isUploadComplete' is set to false, and 'progress' is set
 * to 5 to mimic a failed upload with a small amount of progress bar visible.
 * Regardless of the outcome, the response is stored in 'this.data'.
 *
 * @throws {Error} Throws an error if the fetch request fails.
 */
async function postDirectlyToUrl(url, options = { method: 'POST' }) {
  const body = await this.file.text();
  let response;

  this.state = FileState.Uploading;

  try {
    response = await fetch(url, {
      ...options,
      body,
    });

    if (response.status >= 200 && response.status <= 299) {
      this.state = FileState.Uploaded;
      this.isUploadComplete = true;
      this.progress = 100;
    } else {
      this.state = FileState.Failed;
      this.isUploadComplete = false;
      this.progress = 5;
    }
  } catch (e) {
    this.state = FileState.Failed;
    this.isUploadComplete = false;
    this.progress = 5;

    if (macroCondition(isDevelopingApp())) {
      console.error('Census report failed to upload:', e);
    }
  }

  this.data = response;

  return this;
}

const FILE_STATE_REJECTED = 'REJECTED';

export default class PageCensusReportsCreateComponent extends Component {
  @service fileQueue;
  @service('config') appConfig;

  @tracked uploadedFiles = [];

  baseServiceHost =
    CENSUS_API_ENDPOINTS[config.environment] ??
    CENSUS_API_ENDPOINTS[config.DEPLOY_TARGETS.DEV];

  FileStateFailed = FileState.Failed;
  FileStateRejected = FILE_STATE_REJECTED;

  UPLOAD_PROGRESS_COLOR_MAPPING = UPLOAD_PROGRESS_COLOR_MAPPING;

  get queue() {
    return this.fileQueue.find('census-reports');
  }

  triggerFileInput(e) {
    e.preventDefault();

    const fileInput = document.getElementById('census-file-upload');

    if (fileInput) {
      fileInput.click();
    }
  }

  @action
  addToQueue(file) {
    // Validate that the file type is allowed.
    const isValid = this.validateFile(file);

    // Add the file to the queue and attach metadata.
    file.queue = this.queue;
    file.state = isValid ? FileState.Queued : FILE_STATE_REJECTED;
    file.timestamp = new Date();

    // Push the file to the top of the files that will display in the table.
    this.uploadedFiles = [file, ...this.uploadedFiles];

    // If it's valid, send it off.
    if (isValid) {
      return this.uploadReport.perform(file);
    }
  }

  @task({ maxConcurrency: 3, enqueue: true })
  *uploadReport(file) {
    // Patch the upload function with ours that simply issues a POST http call
    // to the census URL. The default `upload` method does a traditional file
    // upload.
    file.upload = postDirectlyToUrl;
    yield file.upload(`${this.baseServiceHost}`);
  }

  validateFile(file) {
    return allowedTypes.includes(file.type);
  }
}
