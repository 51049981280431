import Component from '@glimmer/component';
import { service } from '@ember/service';
import { assert } from '@ember/debug';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import {
  HdsButton,
  HdsBadge,
  HdsLinkStandalone,
} from '@hashicorp/design-system-components/components';
import * as Sentry from '@sentry/ember';
import Disclosure from 'core/components/disclosure';
import Flex from 'core/components/flex';
import Typography from 'core/components/typography';
import boxPadding from 'core/modifiers/box-padding';
import templateString from 'core/utils/template-string';

import { PEERING_ROUTE_NAMES } from '../../../../../../utils/network-routes.js';

const awsQuickCreateStackLink = templateString`https://${'awsTargetRegion'}.console.aws.amazon.com/cloudformation/home?region=${'awsTargetRegion'}#/stacks/create/review
?templateURL=${'templateURL'}
&stackName=${'stackName'}
&param_hcpApiAddr=${'hcpApiAddr'}
&param_hvnAccount=${'hvnAccount'}
&param_hvnCidrRange=${'hvnCidrRange'}
&param_hvnId=${'hvnId'}
&param_hvnOrganization=${'hvnOrganization'}
&param_hvnProject=${'hvnProject'}
&param_hvnRegion=${'hvnRegion'}
&param_hvnToken=${'hvnToken'}
`;
export default class NetworksPeeringsAwsCreateAutomatGuidedFlow extends Component {
  @service('config') appConfig;
  @service analytics;
  @service api;

  get peeringRouteNames() {
    return PEERING_ROUTE_NAMES;
  }

  launchQuickCreateStack = async () => {
    try {
      this.analytics.trackEvent(
        'external_ui_hashicorp_network_peering_launch_quick_create_button_clicked',
        {
          ...this.args.network,
        },
      );

      let { network } = this.args;

      const awsQuickCreateStackPage = window.open('', '_blank');

      // Create a single-use token for creating peering.
      const tokenResp = this.api.network.createToken(
        network.location.organizationId,
        network.location.projectId,
        network.id,
        {},
      );

      const { token } = await tokenResp;
      if (!token) {
        throw Error(`failed to create a single-use peering token: ${token}`);
      }
      if (
        !network ||
        !network.cidrBlock ||
        !network.providerNetworkData?.awsNetworkData?.accountId ||
        !network.location?.region?.region
      ) {
        throw new Error(`missing fields in network response: ${network}`);
      }

      // Populate a link to initiate the peering. Open in a new tab.
      const quickCreateStackLink = awsQuickCreateStackLink({
        // TODO: support for cross-region peering. Requires more space than the dropdown.
        awsTargetRegion: network.location.region.region,
        // When bumping this, check that it matches the latest (or previously supported) version of hcp-templates.
        // You can check that here: https://github.com/hashicorp/hcp-templates/blob/f0187080d9febc37a47482428a2000f1ec1a7b4a/.bumpversion.cfg#L2
        // Or here: https://github.com/hashicorp/hcp-templates/tags
        templateURL:
          'https://s3.us-west-2.amazonaws.com/hcp-peering/auto-vpc-peering.0.2.11.template',
        // We're taking the end of the token is here to add a bit of noise to avoid duplicate stack names.
        // A user might peer one HVN to multiple VPCs in a single account, and we don't have other unique info
        // here to make each stack name unique.
        stackName: `hcp-peering-${network.id}-${token
          .substring(token.length - 5)
          .replace('_', '')
          .replace('-', '')
          .toLowerCase()}`,
        hcpApiAddr:
          this.appConfig?.app?.baseServiceHost || 'http://localhost:28081',
        hvnAccount: network.providerNetworkData.awsNetworkData.accountId,
        hvnCidrRange: network.cidrBlock,
        hvnId: network.id,
        hvnOrganization: network.location.organizationId,
        hvnProject: network.location.projectId,
        hvnRegion: network.location.region.region,
        hvnToken: token,
      });

      awsQuickCreateStackPage.location.href = quickCreateStackLink;
    } catch (e) {
      assert(e);
      Sentry.eventFromException(e);
    }
  };

  <template>
    {{#let @item as |Item|}}
      <Item @xs="12" @sm="12" @md="10" @lg="10" {{boxPadding "xl" "0" "0"}}>
        <Typography
          class="hds-typography-body-300 hds-font-weight-semibold hds-foreground-strong"
          @variant="h4"
        >
          {{t
            "components.networks.peerings.aws.automate.guided-flow.automate.create.title"
          }}
        </Typography>
        <Typography
          class="hds-typography-body-100 hds-font-weight-regular hds-foreground-faint"
        >
          {{t
            "components.networks.peerings.aws.automate.guided-flow.automate.create.description"
          }}
        </Typography>
        <HdsLinkStandalone
          @size="small"
          @icon="learn-link"
          @iconPosition="trailing"
          @text={{t
            "components.networks.peerings.aws.automate.guided-flow.automate.create.learn-link"
          }}
          @href={{t
            "components.networks.peerings.aws.automate.guided-flow.automate.create.learn-link-url"
          }}
          {{boxPadding "md" "0" "0"}}
        />
      </Item>
      <Item @xs="12" @sm="12" @md="6" @lg="6" {{boxPadding "md" "0" "0"}}>
        <form class="hcp-hds-form">
          {{yield}}
          <div {{boxPadding "md" "0" "0"}}>
            <HdsBadge
              @text={{t
                "components.networks.peerings.aws.automate.guided-flow.automate.create.permissions"
              }}
              @color="highlight"
            />
          </div>
          <Typography>
            {{t
              "components.networks.peerings.aws.automate.guided-flow.automate.create.permissions-explanation"
            }}
          </Typography>
          <div {{boxPadding "md" "0" "lg"}}>
            <HdsBadge
              @icon="info"
              @text={{t
                "components.networks.peerings.aws.automate.guided-flow.automate.create.advisory"
              }}
            />
          </div>
          <Disclosure
            @label={{t
              "components.networks.peerings.aws.automate.guided-flow.automate.create.disclosure.title"
            }}
          >
            <div {{boxPadding "0" "0" "0" "lg"}}>
              <Typography
                class="hds-typography-body-100"
                {{boxPadding "lg" "0" "md" "0"}}
              >
                {{t
                  "components.networks.peerings.aws.automate.guided-flow.automate.create.disclosure.text"
                }}
              </Typography>
              <Typography class="hds-typography-body-100">
                {{t
                  "components.networks.peerings.aws.automate.guided-flow.automate.create.disclosure.instructions.on-page"
                }}
              </Typography>
              <ul>
                <li>
                  <Typography class="hds-typography-body-100">
                    {{t
                      "components.networks.peerings.aws.automate.guided-flow.automate.create.disclosure.instructions.select-vpc"
                    }}
                  </Typography>
                </li>
                <li>
                  <Typography class="hds-typography-body-100">
                    {{t
                      "components.networks.peerings.aws.automate.guided-flow.automate.create.disclosure.instructions.acknowledge-custom-names"
                    }}
                  </Typography>
                </li>
                <li>
                  <Typography class="hds-typography-body-100">
                    {{t
                      "components.networks.peerings.aws.automate.guided-flow.automate.create.disclosure.instructions.create-stack"
                    }}
                  </Typography>
                </li>
                <li>
                  <Typography class="hds-typography-body-100">
                    {{t
                      "components.networks.peerings.aws.automate.guided-flow.automate.create.disclosure.instructions.create-status"
                    }}
                  </Typography>
                </li>
                <li>
                  <Typography class="hds-typography-body-100">
                    {{t
                      "components.networks.peerings.aws.automate.guided-flow.automate.create.disclosure.instructions.deploy-clusters"
                    }}
                  </Typography>
                </li>
              </ul>
              <img
                src={{t
                  "components.networks.peerings.aws.automate.steps.quick-create-page.screenshot.imgSrc"
                }}
                width="100%"
                alt={{t
                  "components.networks.peerings.aws.automate.steps.quick-create-page.screenshot.alt"
                }}
                data-test-networks-peerings-aws-create-automate-instructions-screenshot
              />
            </div>
          </Disclosure>
          <Flex @gap="md" {{boxPadding "xl 0 lg 0"}}>
            <HdsButton
              @text={{t "components.networks.peerings.aws.automate.cta-text"}}
              @icon="external-link"
              @iconPosition="trailing"
              data-test-networks-peerings-aws-automate-guided-flow-cta
              {{on "click" this.launchQuickCreateStack}}
            />
            <HdsButton
              @text={{t "components.form.cancel"}}
              @color="secondary"
              @route={{this.peeringRouteNames.list}}
            />
          </Flex>
        </form>
      </Item>
    {{/let}}
  </template>
}
