/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { DescribeProviderResponseSecretTypeFromJSON, DescribeProviderResponseSecretTypeToJSON, Secrets20231128DescribeProviderResponseSchemaFromJSON, Secrets20231128DescribeProviderResponseSchemaToJSON, } from './';
export function Secrets20231128DescribeProviderResponseFromJSON(json) {
    return Secrets20231128DescribeProviderResponseFromJSONTyped(json, false);
}
export function Secrets20231128DescribeProviderResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'configSchema': !exists(json, 'config_schema') ? undefined : Secrets20231128DescribeProviderResponseSchemaFromJSON(json['config_schema']),
        'secretTypes': !exists(json, 'secret_types') ? undefined : (mapValues(json['secret_types'], DescribeProviderResponseSecretTypeFromJSON)),
    };
}
export function Secrets20231128DescribeProviderResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'title': value.title,
        'config_schema': Secrets20231128DescribeProviderResponseSchemaToJSON(value.configSchema),
        'secret_types': value.secretTypes === undefined ? undefined : (mapValues(value.secretTypes, DescribeProviderResponseSecretTypeToJSON)),
    };
}
