import Service, { inject as service } from '@ember/service';
import { Settings as LuxonSettings } from 'luxon';
import type { IntlService } from 'ember-intl';

export const LOCALE_EN_US = 'en-US';

export default class LocaleService extends Service {
  @service declare readonly intl: IntlService;

  #locale = LOCALE_EN_US;

  start() {
    // TODO: Detect preferred language.
    this.setLocale();
  }

  setLocale(locale = LOCALE_EN_US) {
    this.#locale = locale;

    this.intl.setLocale([this.#locale]);

    // TODO: This updates the locale used on future DateTime luxon objects but
    // it is not tied into the Ember rendering ecosystem and changing this value
    // does not guarantee that the dates already rendered outside of the Ember
    // zone will be updated with the new locale.
    LuxonSettings.defaultLocale = this.#locale;
  }

  get locale() {
    return this.#locale;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    locale: LocaleService;
  }
}
