import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import {
  HdsFormRadioGroup,
  HdsButton,
  HdsButtonSet,
} from '@hashicorp/design-system-components/components';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import boxPadding from 'core/modifiers/box-padding';

import { DropdownListItem } from '../../utils/filter-bar/dropdown-manager.ts';

import type { HdsDropdownElement } from '../../utils/filter-bar/index.ts';

export interface SingleSelectDropdownSignature {
  Args: {
    name: string;
    excludeUnselectedOption: boolean;
    onSubmit: (value: string[], dropdown: HTMLElement) => void;
    listItems: DropdownListItem[];
    dd: HdsDropdownElement;
    trackInteraction: () => void;
  };

  Blocks: {
    default: [];
  };

  Element: HTMLElement;
}

/**
 * The singleSelectDropdown component is a custom dropdown body
 * component which is preconfigured to render a dropdown with radio buttons
 * to select a single item from a list and return it to the caller in
 * an array. The component can be used by itself inside an Hds::Dropdown component or
 * within a FilterBar component instance.
 *
 * Usage:
 *  <FilterBar::SingleSelectDropdown
 *     @dd={{dd}}
 *     @listItems={{@listItems}}
 *     @onSubmit={{@onSubmit}}
 *     @selectedValues={{@selectedValues}}
 *     @trackInteraction={{@trackInteraction}}
 *  />
 *
 * An optional tracking callback can be provided to instrument the dropdown.
 *
 * The @selectedValues parameter allows items to be pre-selected on render.
 *
 * @class SingleSelectDropdownComponent
 */

export default class SingleSelectDropdown extends Component<SingleSelectDropdownSignature> {
  selectedItem = '';

  get noneSelected() {
    return !this.selectedItem.length;
  }

  executeOnSelect = () => {
    this.args.onSubmit([this.selectedItem], this.args.dd);
    this.args.trackInteraction();
  };

  instantiateDropdown = () => {
    const selectedOption = this.args.listItems.find(
      (listItem) => listItem.optionValue,
    );
    this.selectedItem = selectedOption?.optionValue ?? '';
  };

  onSelect = (evt: Event) => {
    const { value } = evt.target as HTMLInputElement;
    this.selectedItem = value;
  };

  onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && this.selectedItem) {
      this.executeOnSelect();
    }
  };

  <template>
    {{! @glint-expect-error }}
    <@dd.Generic {{didInsert this.instantiateDropdown}}>
      <HdsFormRadioGroup
        data-test-date-range-radio-group
        @name={{@name}}
        {{on "keyup" this.onKeyUp}}
        as |G|
      >
        <G.Legend />
        {{#unless @excludeUnselectedOption}}
          <G.RadioField
            data-test-none-selected-option
            @id="unselected"
            @value={{""}}
            checked={{this.noneSelected}}
            {{on "change" this.onSelect}}
            as |F|
          >
            <F.Label>
              {{t "vault-common.components.filter-bar.any"}}
            </F.Label>
          </G.RadioField>
        {{/unless}}
        {{#each @listItems as |listItem|}}
          <G.RadioField
            data-test-filterbar-single-select-option
            checked={{listItem.isSelected}}
            @value={{listItem.optionValue}}
            {{on "change" this.onSelect}}
            as |F|
          >
            <F.Label>
              {{listItem.optionLabel}}
            </F.Label>
          </G.RadioField>
        {{/each}}
      </HdsFormRadioGroup>
      <HdsButtonSet {{boxPadding "xs 0 xs 0"}}>
        <HdsButton
          data-test-request-filter-submit
          @text={{t "vault-common.components.filter-bar.submit-button-text"}}
          @size="small"
          {{on "click" this.executeOnSelect}}
        />
        <HdsButton
          data-test-request-filter-text-cancel
          @text={{t "vault-common.components.filter-bar.cancel-button-text"}}
          @size="small"
          @color="secondary"
          {{on "click" @dd.close}}
        />
      </HdsButtonSet>
      {{! @glint-expect-error }}
    </@dd.Generic>
  </template>
}
