import { helper } from '@ember/component/helper';

import { isAzureProvider } from '../utils/networks.js';

/**
 *
 * Returns true if given provider is azure
 *
 * Example usage in a Handlebars template:
 * ```
 * {{#if (networks-is-azure-provider @provider)}}
 *   <div>Show azure stuff</div>
 * {{/if}}
 * ```
 *
 * Example vanilla JS usage outside of a Handlebars template:
 * ```
 * let isAzureProvider = networksIsAzureProvider([provider]);
 * ```
 *
 * @param {Array} params - 0th param should be a string provider (aws, azure, etc)
 * @returns {Boolean} - `true` means the provider is azure
 */
export function networksIsAzureProvider(params = []) {
  let [provider] = params;

  return isAzureProvider(provider);
}

export default helper(networksIsAzureProvider);
