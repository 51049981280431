import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import decorateMembersWithIsManager from 'hcp/utils/group-members-is-manager-role';

export default class CloudAccessControlGroupsDetailMembersRoute extends Route {
  @service router;
  @service api;
  @service abilities;
  @service userContext;

  // since there is no organization-level members component, redirect to the group detail page
  async beforeModel() {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    if (!isProjectContext) {
      this.router.transitionTo('cloud.access-control.groups.detail');
    }
  }

  async model() {
    const { resource_name: resourceName } = this.paramsFor(
      'cloud.access-control.groups.detail'
    );
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const { project, organization } = this.userContext;

    let scimEnabled = false;
    if (variation('hcp-identity-scim-enabled')) {
      try {
        ({ isEnabled: scimEnabled } =
          await this.api.scim.scimServiceIsScimEnabled(organization.id));
      } catch (e) {
        if (macroCondition(isDevelopingApp())) {
          //eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }

    const { group } = await this.api.groups.groupsServiceGetGroup(resourceName);
    let { members } =
      await this.api.groups.groupsServiceListGroupMembers(resourceName);

    // We are only checking the group policy for member roles,
    // so if there are no members don't make this call
    if (members.length) {
      try {
        const { policy } =
          await this.api.resourceManager.resources.resourceServiceGetIamPolicy(
            resourceName
          );
        members = decorateMembersWithIsManager(members, policy);
      } catch (e) {
        //eslint-disable-next-line no-console
        if (macroCondition(isDevelopingApp())) {
          console.error(e);
        }
      }
    }
    if (isProjectContext) {
      return {
        group,
        isProjectContext,
        members,
        project,
        scimEnabled,
      };
    }
  }
}
