import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type { IntlService } from 'ember-intl';
import type FlashMessagesService from '../services/flash-messages.ts';

interface ImportToTerraformSignature {
  Args: {
    command: string;
  };
}

/**
 * Allows Terraform import commands to be copied to user's clipboard. When used
 * within the dropdown, must be used in conjunction with the CSS class
 * `.withImportToTerraform` placed on or the `Menu::Content`.
 * ```
 * <ImportToTerraform @command="terraform import..." />
 * ```
 * @class ImportToTerraform
 *
 * Terraform import command
 * @argument command
 * @type {string}
 */
export default class ImportToTerraformComponent extends Component<ImportToTerraformSignature> {
  @service declare readonly intl: IntlService;
  @service declare readonly flashMessages: FlashMessagesService;

  @action
  onSuccess() {
    this.flashMessages.success(
      this.intl.t('components.import-to-terraform.copied')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ImportToTerraform: typeof ImportToTerraformComponent;
    'import-to-terraform': typeof ImportToTerraformComponent;
  }
}
