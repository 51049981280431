import Component from '@glimmer/component';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { v4 as uuid } from 'uuid';
import { tracked } from 'tracked-built-ins';

// Components
import {
  HdsApplicationState,
  HdsButton,
  HdsButtonSet,
  HdsSeparator,
} from '@hashicorp/design-system-components/components';
import Flex from 'core/components/flex';
import ManageAccessFormPermissionSelectComponent from './permission-select.gts';
import ManageAccessGenericComponent from './../generic.gts';

// Helpers
import { t } from 'ember-intl';
import { fn, get, hash } from '@ember/helper';
import { and, lt, not, notEq, gt } from 'ember-truth-helpers';
import iamEnrichRolesWithServiceAndPersona from '../../../helpers/iam-enrich-roles-with-service-and-persona.ts';
import iamFilterAvailableRoles from '../../../helpers/iam-filter-available-roles.ts';
import iamFindServicesFromRoles from '../../../helpers/iam-find-services-from-roles.ts';
import iamIsPermissionDisabled from '../../../helpers/iam-is-permission-disabled.ts';
import iamSortServicesForSelect from '../../../helpers/iam-sort-services-for-select.ts';

// Modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { on } from '@ember/modifier';

// Utils
import parseRoleId, {
  BASIC_ROLE_KEY_UI_ONLY,
} from '../../../utils/parse-role-id.ts';
import PolicyManager from '../../../utils/policy-manager.ts';
import Permission from '../../../utils/permission.ts';
import { DISABLED_ROLE_ROLES_OWNER } from '../../../utils/disabled-roles.ts';

// Types
import type { ComponentLike, WithBoundArgs } from '@glint/template';
import type {
  HashicorpCloudResourcemanagerRole,
  HashicorpCloudResourcemanagerPolicy,
  HashicorpCloudResourcemanagerPolicyBindingMember,
} from '@clients/cloud-resource-manager';
import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';
import type { IPolicyManager } from '../../../utils/policy-manager.ts';
import type { IamParsedRole } from '../../../types/iam-parsed-role.ts';
import type { IamEnrichedRole } from '../../../types/iam-enriched-role.ts';
import type {
  IPermission,
  PermissionsMap,
} from '../../../types/permissions.ts';
import type {
  OnChangeRoleFunction,
  OnChangeServiceFunction,
} from '../../../types/permission-select.ts';
import type {
  OnAddPermissionFunction,
  OnChangeFunction,
  OnRemovePermissionFunction,
  SetPermissionInFormFunction,
} from '../../../types/edit-permissions.ts';
import type { ManageAccessGenericSignature } from '../generic.gts';

export interface ManageAccessFormEditPermissionsSignature {
  Element: HTMLElement;
  Args: {
    onChange: OnChangeFunction;
    policy: HashicorpCloudResourcemanagerPolicy;
    roles: HashicorpCloudResourcemanagerRole[];
    memberId: HashicorpCloudResourcemanagerPolicyBindingMember['memberId'];
    memberType: HashicorpCloudResourcemanagerPolicyBindingMember['memberType'];
  };
  Blocks: {
    default?: [];
    body: [
      {
        data: {
          currentRoleIds: Array<HashicorpCloudResourcemanagerRole['id']>;
          id: string;
          index: number;
          isPermissionDisabled: boolean;
          permission: IPermission;
          permissions: PermissionsMap;
        };
        Generic: ComponentLike<{
          Element: ManageAccessGenericSignature['Element'];
          Blocks: ManageAccessGenericSignature['Blocks'];
        }>;
        PermissionSelect: WithBoundArgs<
          typeof ManageAccessFormPermissionSelectComponent,
          | 'availableRoles'
          | 'basicServices'
          | 'enrichedRoles'
          | 'fineGrainedServices'
          | 'id'
          | 'isPermissionDisabled'
          | 'onChangeRole'
          | 'onChangeService'
          | 'permission'
          | 'permissions'
        >;
        removePermission: OnRemovePermissionFunction;
        removePermissionText: string;
      },
      number,
    ];
    footer: [
      {
        addAssignmentText: string;
        addPermission: OnAddPermissionFunction;
        currentRoleIds?: Array<HashicorpCloudResourcemanagerRole['id']>;
        currentServices?: Array<string>;
        nextAvailableRole: IamEnrichedRole;
        permissions: PermissionsMap;
      },
    ];
  };
}

export default class ManageAccessFormEditPermissionsComponent extends Component<ManageAccessFormEditPermissionsSignature> {
  @tracked EditedProjectPolicy: IPolicyManager | null = null;

  permissions: PermissionsMap = tracked(Map);

  didInsert = (): void => {
    this.didUpdateProjectPolicy();
  };

  didUpdateProjectPolicy = (): void => {
    const { policy } = this.args;

    if (policy) {
      this.EditedProjectPolicy = new PolicyManager({ policy });
      this.setupPermissionsForm();
    }
  };

  didUpdateMemberId = (): void => {
    this.setupPermissionsForm();
  };

  setupPermissionsForm = (): void => {
    const { roles = [], memberId } = this.args;

    this.permissions.clear();

    if (!memberId || !this.EditedProjectPolicy) {
      return;
    }

    const { roleIds = [] } =
      this.EditedProjectPolicy.getRolesByMemberId(memberId) ?? {};

    if (!roleIds?.length) {
      this.addEmptyPermission();
    }

    // ensure all roleIds have been registered in the API
    const apiRegisteredRoleIds = roleIds.filter((roleId) => {
      const roleIdRegistered = roles.some((role) => {
        return role.id === roleId;
      });

      if (macroCondition(isDevelopingApp())) {
        if (!roleIdRegistered) {
          console.warn(
            `[ManageAccess::Form::EditPermissions]: "${roleId}" was omitted 
              from the permissions form. It was found in the policy but does not 
              exist in @roles list. If you intend for this to be editable in the 
              form, please pass the role in the @roles argument. It might have 
              been filtered from the API response or might not be registered.`,
          );
        }
      }
      return roleIdRegistered;
    }) as string[];

    if (!apiRegisteredRoleIds.length) {
      return;
    }

    // We ignore the Owner role in the generated form state so that any
    // additional role can still be rendered even while being an Owner. If this
    // member is the owner, manually set them in the form before we generate the
    // rest of it.
    if (roleIds.includes(DISABLED_ROLE_ROLES_OWNER)) {
      this.setPermissionInForm({
        service: `${BASIC_ROLE_KEY_UI_ONLY}`,
        roleIds: [DISABLED_ROLE_ROLES_OWNER],
      });
    }

    const nonGroupedRolesKey = 'nonGroupedRoles';
    const groupedRoleIds = apiRegisteredRoleIds.reduce(
      (acc: { [key: string]: string[] }, roleId) => {
        const { service = '' } = parseRoleId(roleId);

        // Ignore the owner role. This is a special case so that the basic roles
        // can still render Owner + another basic role if set. This client will
        // still disallow setting another basic role but sometimes this is
        // allowed via outside policy setters.
        if (roleId === DISABLED_ROLE_ROLES_OWNER) {
          return acc;
        }

        acc[service] = acc[service] || [];
        acc[service].push(roleId);
        return acc;
      },
      {},
    );

    Object.entries(groupedRoleIds).forEach(([key, roleIds]) => {
      if (key === nonGroupedRolesKey) {
        // Iterate over all the nonGroupedRoles roles into one permission per
        // role id. We want to sort these roles before adding them to the form
        // to surface the basic roles to the top.
        roleIds
          .map((roleId) => {
            return parseRoleId(roleId);
          })
          .sort((a: IamParsedRole, b: IamParsedRole): number => {
            // Bubble the basic services keys to the top.
            if (a.service === BASIC_ROLE_KEY_UI_ONLY) return -1;
            if (b.service === BASIC_ROLE_KEY_UI_ONLY) return 1;

            return a.service!.localeCompare(b.service!);
          })
          .forEach(({ service, roleId }) => {
            if (service && roleId) {
              this.setPermissionInForm({ service, roleIds: [roleId] });
            }
          });
      } else {
        // All service grouped roles go into one Permission.
        const service = key;
        this.setPermissionInForm({ service, roleIds });
      }
    });

    if (this.sortedPermissions.size === 0) {
      this.addEmptyPermission();
    }
  };

  onChangeService: OnChangeServiceFunction = (
    id,
    permission,
    _availableRoles,
    event,
  ) => {
    const { value: service } = event.target as HTMLInputElement;
    const { memberId, memberType } = this.args;

    if (!this.EditedProjectPolicy) {
      return;
    }

    if (permission.roleIds?.length) {
      this.EditedProjectPolicy.removeMemberFromRole(
        { memberType, memberId },
        permission.roleIds,
      );

      this.onChange({ policy: this.EditedProjectPolicy.getRawPolicy() });
    }

    this.permissions.set(id, {
      ...permission,
      service,
      roleIds: [],
    });
  };

  onChangeRole: OnChangeRoleFunction = (id, permission, roleIds) => {
    const { memberId, memberType } = this.args;

    if (!this.EditedProjectPolicy) {
      return;
    }

    // Remove existing roleIds from permissions.
    if (permission.roleIds) {
      this.EditedProjectPolicy.removeMemberFromRole(
        { memberType, memberId },
        permission.roleIds,
      );
    }

    // Add new roleIds to permission.
    this.EditedProjectPolicy.addMemberToRole({ memberType, memberId }, roleIds);
    this.permissions.set(id, {
      ...permission,
      roleIds,
    });

    this.onChange({ policy: this.EditedProjectPolicy.getRawPolicy() });
  };

  setPermissionInForm: SetPermissionInFormFunction = (permission) => {
    const { memberId, memberType } = this.args;
    const { roleIds = [], service = '' } = permission;

    if (!this.EditedProjectPolicy) {
      return;
    }

    if (roleIds.length) {
      this.EditedProjectPolicy.addMemberToRole(
        { memberType, memberId },
        roleIds,
      );
    }

    this.permissions.set(
      uuid(),
      new Permission({
        roleIds,
        service,
      }),
    );
  };

  addEmptyPermission = () => {
    this.setPermissionInForm({ service: '', roleIds: [] });
  };

  addPermission: OnAddPermissionFunction = (permission) => {
    this.setPermissionInForm(permission);

    if (!this.EditedProjectPolicy) {
      return;
    }

    if (permission.roleIds?.length) {
      this.onChange({ policy: this.EditedProjectPolicy.getRawPolicy() });
    }
  };

  removePermission: OnRemovePermissionFunction = (id, permission) => {
    const { memberId, memberType } = this.args;

    this.permissions.delete(id);

    if (!this.EditedProjectPolicy) {
      return;
    }

    if (permission.roleIds && permission.roleIds.length > 0) {
      this.EditedProjectPolicy.removeMemberFromRole(
        { memberType, memberId },
        permission.roleIds,
      );

      this.onChange({ policy: this.EditedProjectPolicy.getRawPolicy() });
    }

    if (this.sortedPermissions.size === 0) {
      this.addEmptyPermission();
    }
  };

  onChange: OnChangeFunction = ({ policy }) => {
    const { onChange } = this.args;

    if (onChange && typeof onChange === 'function') {
      onChange({ policy });
    }
  };

  get sortedPermissions(): PermissionsMap {
    const { permissions } = this;

    const arr = [...permissions].map(([key, permission]) => {
      return {
        key,
        service: permission.service,
        roleIds: permission.roleIds,
      };
    });

    // HCPF-2043: Refactor this sorted getter to deal with not breaking apart
    // the Permission class. If we keep the Permission class together, something
    // gets thrown off and things begin to get funky. Don't sort for now but
    // still break this apart so that the form continues to function properly.
    const sorted = arr;

    return new Map(sorted.map(({ key, ...rest }) => [key, rest]));
  }

  get currentRoleIds() {
    // This is intentionally destructuring the permission as index 1 of the
    // resulting Set entries. The first item in this array, at index 0, is the
    // id but we don't need to use it in this getter.
    return Array.from(this.permissions).flatMap(([, permission]) => {
      return permission.roleIds;
    });
  }

  <template>
    <Flex
      @direction={{FlexDirection.Column}}
      @gap={{FlexGap.Lg}}
      {{didInsert this.didInsert}}
      {{didUpdate this.didUpdateProjectPolicy @policy}}
      {{didUpdate this.didUpdateMemberId @memberId}}
      ...attributes
    >
      {{#let (iamEnrichRolesWithServiceAndPersona @roles) as |enrichedRoles|}}
        {{#let this.sortedPermissions as |sortedPermissions|}}
          {{#let
            (iamFilterAvailableRoles enrichedRoles sortedPermissions)
            as |availableRoles|
          }}
            {{#let (iamFindServicesFromRoles enrichedRoles) as |services|}}
              {{#if (gt sortedPermissions.size 0)}}
                <div>
                  {{#let
                    (iamSortServicesForSelect services)
                    as |sortedServices|
                  }}
                    {{#each sortedPermissions as |entry index|}}
                      {{#if
                        (and (notEq index 0) (gt sortedPermissions.size 1))
                      }}
                        <HdsSeparator />
                      {{/if}}
                      {{#let
                        (get entry 0)
                        (get entry 1)
                        (iamIsPermissionDisabled (get entry 1))
                        as |id permission isPermissionDisabled|
                      }}
                        {{#if (has-block "body")}}
                          {{yield
                            (hash
                              data=(hash
                                currentRoleIds=this.currentRoleIds
                                id=id
                                index=index
                                isPermissionDisabled=isPermissionDisabled
                                permission=permission
                                permissions=sortedPermissions
                              )
                              Generic=(component ManageAccessGenericComponent)
                              PermissionSelect=(component
                                ManageAccessFormPermissionSelectComponent
                                availableRoles=availableRoles
                                basicServices=sortedServices.basic
                                enrichedRoles=enrichedRoles
                                id=id
                                isPermissionDisabled=isPermissionDisabled
                                onChangeRole=this.onChangeRole
                                onChangeService=this.onChangeService
                                permission=permission
                                permissions=sortedPermissions
                                fineGrainedServices=sortedServices.fineGrained
                              )
                              removePermission=this.removePermission
                              removePermissionText=(t
                                "manage-access.components.form.edit-permissions.remove"
                              )
                            )
                            index
                            to="body"
                          }}
                        {{else}}
                          <Flex
                            @alignItems={{AlignItem.End}}
                            @direction={{FlexDirection.Row}}
                            @gap={{FlexGap.Sm}}
                          >
                            <Flex
                              @alignItems={{AlignItem.Start}}
                              @direction={{FlexDirection.Row}}
                              @gap={{FlexGap.Sm}}
                            >
                              <ManageAccessFormPermissionSelectComponent
                                @availableRoles={{availableRoles}}
                                @basicServices={{sortedServices.basic}}
                                @enrichedRoles={{enrichedRoles}}
                                @id={{id}}
                                @isPermissionDisabled={{isPermissionDisabled}}
                                @onChangeRole={{this.onChangeRole}}
                                @onChangeService={{this.onChangeService}}
                                @permission={{permission}}
                                @permissions={{sortedPermissions}}
                                @fineGrainedServices={{sortedServices.fineGrained}}
                                data-test-manage-access-edit-permissions-select
                              />
                            </Flex>
                            <Flex>
                              {{#if
                                (and
                                  permission.service (not isPermissionDisabled)
                                )
                              }}
                                <HdsButton
                                  @color="tertiary"
                                  @icon="trash"
                                  @text={{t
                                    "manage-access.components.form.edit-permissions.remove-assignment"
                                  }}
                                  {{on
                                    "click"
                                    (fn this.removePermission id permission)
                                  }}
                                  data-test-manage-access-edit-permissions-remove-permission-button="{{id}}:{{permission.service}}"
                                />
                              {{/if}}
                            </Flex>
                          </Flex>
                        {{/if}}
                      {{/let}}
                    {{/each}}
                  {{/let}}
                </div>
                {{#let (get availableRoles 0) as |nextAvailableRole|}}
                  {{#if (has-block "footer")}}
                    <div>
                      {{yield
                        (hash
                          addAssignmentText=(t
                            "manage-access.components.form.edit-permissions.add-another-assignment"
                          )
                          addPermission=this.addPermission
                          currentRoleIds=this.currentRoleIds
                          currentServices=services
                          nextAvailableRole=nextAvailableRole
                          permissions=sortedPermissions
                        )
                        to="footer"
                      }}
                    </div>
                  {{else}}
                    {{#if (lt sortedPermissions.size services.length)}}
                      <HdsButtonSet>
                        <HdsButton
                          @color="tertiary"
                          @icon="plus"
                          @text={{t
                            "manage-access.components.form.edit-permissions.add-another-assignment"
                          }}
                          {{on "click" this.addEmptyPermission}}
                          data-test-manage-access-edit-permissions-add-permission-button
                        />
                      </HdsButtonSet>
                    {{/if}}
                  {{/if}}
                {{/let}}
              {{else}}
                <HdsApplicationState as |A|>
                  <A.Header
                    @title={{t
                      "manage-access.components.form.edit-permissions.no-roles-assigned"
                    }}
                  />
                  <A.Body
                    @text={{t
                      "manage-access.components.form.edit-permissions.this-member-has-no-permissions"
                    }}
                  />
                  <A.Body>
                    <HdsButtonSet>
                      <HdsButton
                        @color="tertiary"
                        @icon="plus"
                        @size="small"
                        @text={{t
                          "manage-access.components.form.edit-permissions.add-another-assignment"
                        }}
                        {{on "click" this.addEmptyPermission}}
                        data-test-manage-access-edit-permissions-empty-state-add-assignment-button
                      />
                    </HdsButtonSet>
                  </A.Body>
                </HdsApplicationState>
              {{/if}}
            {{/let}}
          {{/let}}
        {{/let}}
      {{/let}}
    </Flex>
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ManageAccessFormEditPermissionsComponent: typeof ManageAccessFormEditPermissionsComponent;
  }
}
