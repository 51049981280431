/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { getOwner } from '@ember/owner';
import { ORGANIZATION_UPDATED } from 'core/utils/consts/analytics-events/platform';

/**
 *
 * `PageOrganizationsRename` is a modal component for renaming an organization.
 *
 *
 * ```
 * <Page::Organizations::Rename
 *   @model={{hash organization=organization next='cloud.orgs.list'}}
 * />
 * ```
 *
 * @class PageOrganizationsRename
 *
 */

/**
 *
 * `model` is an object an `organization` and `next` key.
 * @argument model;
 * @type {Object}
 * @required
 *
 */

const DEFAULT_NEXT = 'cloud.orgs.list';

export default class PageOrganizationsRenameComponent extends Component {
  @service analytics;
  @service api;
  @service currentUser;
  @service flashMessages;
  @service intl;
  @service router;
  @service userContext;

  @tracked name = this.args?.model?.organization.name;
  @tracked isModalActive = true;

  get nextRoute() {
    let routeName = this.args?.model?.next || DEFAULT_NEXT;
    let route = getOwner(this).lookup(`route:${routeName}`);

    if (!route) {
      route = getOwner(this).lookup(`route:${DEFAULT_NEXT}`);
    }

    return route;
  }

  @action
  onClose() {
    // prevent the route transition from being called during integration tests
    return !this.isDestroying || !this.isDestroyed
      ? this.router.transitionTo(this.nextRoute.fullRouteName)
      : null;
  }

  @action
  onRenameError(error) {
    throw error;
  }

  @action
  onRenameSuccess({ organization: updatedOrganization }) {
    let nextRoute = this.nextRoute;

    this.isModalActive = false;

    this.flashMessages.success(
      this.intl.t('components.page.organizations.rename.success')
    );

    // Update the organization list with any updates.
    this.currentUser.organizations = this.currentUser.organizations
      .slice(0)
      .map((organization) => {
        if (organization.id === updatedOrganization.id) {
          return {
            ...organization,
            ...updatedOrganization,
          };
        }

        return organization;
      });

    // Update the current org selected if it's selected.
    if (this.userContext.organization.id === updatedOrganization.id) {
      this.userContext.setOrganization(updatedOrganization);
    }

    this.router.transitionTo(nextRoute.fullRouteName);

    // We should use the router service for this once this PR is merged.
    // via: https://github.com/emberjs/rfcs/issues/592
    nextRoute.refresh();
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    let orgId = this.args?.model?.organization?.id;
    let payload = {
      id: orgId,
      name: this.name,
    };
    let resp;

    this.analytics.trackEvent(ORGANIZATION_UPDATED, payload);

    try {
      resp = yield this.api.resourceManager.org.organizationServiceSetName(
        orgId,
        payload
      );

      this.onRenameSuccess({ ...resp });
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      this.onRenameError(e);
    }
  }
}
