import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { DateTime } from 'luxon';
import { t } from 'ember-intl';
import { or, eq } from 'ember-truth-helpers';
import {
  HdsFormRadioGroup,
  HdsFormTextInputField,
  HdsButton,
  HdsFormSelectField,
  HdsFormError,
  HdsButtonSet,
} from '@hashicorp/design-system-components/components';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import boxPadding from 'core/modifiers/box-padding';
import boxMargin from 'core/modifiers/box-margin';

import { DropdownListItem } from '../../utils/filter-bar/dropdown-manager.ts';
import {
  DateRange,
  MAX_ISO_DATE,
  MIN_ISO_DATE,
} from '../../utils/filter-bar/date-range.ts';

import type { HdsDropdownElement } from '../../utils/filter-bar/index.ts';
import type Owner from '@ember/owner';

import './date-range-dropdown.scss';

export interface DateRangeDropdownSignature {
  Args: {
    name: string;
    dd: HdsDropdownElement;
    listItems: DropdownListItem[];
    onSubmit: (
      value: string[],
      dropdown: HTMLElement,
      updateCustomOptions: object,
    ) => void;
    dateRange: DateRange;
    trackInteraction: () => void;
  };

  Blocks: {
    default?: [];
  };

  Element: HTMLElement;
}

/**
 * The DateRangeDropdown component is a dropdown body for display within an Hds
 * Dropdown component specifically meant for handling date/time range selections. The component can
 * be used on its own or within a FilterBar component instance.
 *
 * Usage:
 *  <FilterBar::DateRangeDropdown
 *     @name={{this.dropdownName}}
 *     @dd={{dd}}
 *     @listItems={{this.listItems}}
 *     @onSubmit={{this.onSubmit}}
 *     @dateRange={{this.dateRange}}
 *     @trackInteraction={{this.trackInteraction}}
 *   />
 *
 * The DateRangeComponent requires as an argument an instance of a DateRange
 * util class, which manages state and configuration, formats dates and times,
 * and handles interactions with the various sub-components of the
 * DateRangeDropdown component.
 *
 * On submit, the DateRangeDropdown passes the selected date range back to the
 * handler as a string containing unix timestamps separated by a colon (:)
 *
 * An optional tracking callback can be provided to instrument the dropdown.
 *
 * @class DateRangeDropdown
 */

export default class DateRangeDropdown extends Component<DateRangeDropdownSignature> {
  @tracked showTimePickers = false;
  /**
   * This generates the hour values and labels for the hour select box
   * in the custom date picker
   */
  hourSelectorList = new Array(24).fill('').map((_value, index) => {
    const hour = String(index).padStart(2, '0');
    return {
      label: `vault-common.components.filter-bar.date-range-dropdown.hours.${hour}`,
      value: `${hour}:00`,
    };
  });

  constructor(owner: Owner, args: DateRangeDropdownSignature['Args']) {
    super(owner, args);

    if (this.args.dateRange.startTime || this.args.dateRange.endTime) {
      this.showTimePickers = true;
    }
  }

  get timePickerToggleText() {
    return this.showTimePickers ? 'Date only' : 'Enter time';
  }

  get showDateRangePicker() {
    return (
      this.args.dateRange.pendingChanges.isCustom ||
      this.args.dateRange.isCustom
    );
  }

  get endDateValue() {
    return this.args.dateRange.lookbackOnly
      ? DateTime.now().toFormat('yyyy-MM-dd')
      : this.args.dateRange['endDate'];
  }

  get endTimeValue() {
    return this.args.dateRange.lookbackOnly
      ? DateTime.now().plus({ hour: 1 }).startOf('hour').toFormat('hh:mm')
      : this.args.dateRange['endTime'];
  }

  get anyOptionSelected() {
    return !this.args.dateRange.pendingChanges.dateRangeKey;
  }

  get earliestSelectableDate() {
    return Number.isInteger(this.args.dateRange.maxLookbackInDays)
      ? DateTime.now()
          .minus({ days: this.args.dateRange.maxLookbackInDays })
          .toISODate()
      : MIN_ISO_DATE;
  }

  get latestSelectableDate() {
    return Number.isInteger(this.args.dateRange.maxLookaheadInDays)
      ? DateTime.now()
          .plus({ days: this.args.dateRange.maxLookaheadInDays })
          .toISODate()
      : MAX_ISO_DATE;
  }

  get isDateRangeInvalid() {
    const { lookbackOnly, hasPendingChanges, pendingChanges } =
      this.args.dateRange;
    if (lookbackOnly) {
      return false;
    }
    const { startDate, endDate, startTime, endTime } = pendingChanges;
    if (hasPendingChanges && startDate && endDate) {
      return `${endDate}T${endTime}` < `${startDate}T${startTime}`;
    }
    return false;
  }

  get submitDisabled() {
    const {
      startDate,
      endDate,
      lookbackOnly,
      hasPendingChanges,
      pendingChanges: {
        startDate: newStartDate,
        endDate: newEndDate,
        isCustom,
      },
    } = this.args.dateRange;

    if (this.isDateRangeInvalid) {
      return true;
    }

    if (hasPendingChanges) {
      if (isCustom) {
        return !(
          (startDate || newStartDate) &&
          (endDate || newEndDate || lookbackOnly)
        );
      }

      return false;
    }
    return true;
  }

  resetPendingChanges = () => {
    this.args.dateRange.resetPendingChanges();
  };

  selectDateRangeOption = (evt: Event) => {
    const { value } = evt.target as HTMLInputElement;
    const dateRangeKey = value === 'default' ? '' : value;
    const isCustom = dateRangeKey === 'custom';
    this.args.dateRange.stagePendingChange('isCustom', isCustom);
    this.args.dateRange.stagePendingChange('dateRangeKey', dateRangeKey);
  };

  toggleTimePickers = () => {
    this.showTimePickers = !this.showTimePickers;
  };

  submitDateRange = () => {
    const dateRangeKey = this.args.dateRange.pendingChanges.dateRangeKey || '';
    this.args.onSubmit([dateRangeKey], this.args.dd, this.args.dateRange);
    this.args.trackInteraction?.();
  };

  setCustomDateOrTime = (type: string, evt: Event) => {
    const { value } = evt.target as HTMLInputElement;
    this.args.dateRange.setCustomDateOrTime(type, value);
  };

  cancel = (dropdown: { close: () => void }) => {
    dropdown.close();
  };

  onKeyup = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !this.submitDisabled) {
      this.submitDateRange();
    }
  };

  onDatePickerFocusOut = (e: FocusEvent) => {
    /**
     * There is a bug in Firefox that closes
     * the dropdown everytime the browser datepicker
     * opens. It is triggering some focusout logic in
     * the disclosure component that is not compatible
     * with the datepicker. The design system team is
     * aware and we should be able to remove this after
     * this ticket is finished:
     * https://hashicorp.atlassian.net/browse/HDS-1540
     *
     * Insights ticket to remove when the above is resolved
     * https://hashicorp.atlassian.net/browse/VAULT-13549
     *
     * This traps the focusout event when it originates
     * from the date fields to work around the above bug.
     */
    e.stopPropagation();
  };

  <template>
    {{! @glint-expect-error }}
    <@dd.Generic>
      <HdsFormRadioGroup
        data-test-date-range-radio-group
        @name={{@name}}
        {{didInsert this.resetPendingChanges}}
        {{on "keyup" this.onKeyup}}
        as |G|
      >
        <G.Legend>
          {{t
            "vault-common.components.filter-bar.date-range-dropdown.select-time-range"
          }}
        </G.Legend>
        <G.RadioField
          data-test-any-time-option
          @id="unselected"
          @value="default"
          checked={{this.anyOptionSelected}}
          {{on "change" this.selectDateRangeOption}}
          as |F|
        >
          <F.Label>
            {{t
              "vault-common.components.filter-bar.date-range-dropdown.any-time"
            }}
          </F.Label>
        </G.RadioField>
        {{#each @listItems as |listItem|}}
          {{#if (eq listItem.optionValue "custom")}}
            <G.RadioField
              data-test-custom-field-trigger
              checked={{listItem.isSelected}}
              @id="custom-range"
              @value={{listItem.optionValue}}
              {{on "change" this.selectDateRangeOption}}
              as |F|
            >
              <F.Label>
                {{t
                  "vault-common.components.filter-bar.date-range-dropdown.custom-range"
                }}
              </F.Label>
            </G.RadioField>
          {{else}}
            <G.RadioField
              data-test-date-range-preset="{{listItem.optionValue}}"
              checked={{or
                listItem.isSelected
                (eq listItem.optionValue @dateRange.dateRangeKey)
              }}
              @value={{listItem.optionValue}}
              {{on "change" this.selectDateRangeOption}}
              as |F|
            >
              <F.Label>
                {{listItem.optionLabel}}
              </F.Label>
            </G.RadioField>
          {{/if}}
        {{/each}}
      </HdsFormRadioGroup>
      {{#if this.showDateRangePicker}}
        <div
          data-test-custom-date-range-picker
          class="vi__custom-date-container"
          {{boxPadding "sm 0 0 md"}}
        >
          <div {{boxPadding "0 xs"}}>
            <HdsFormTextInputField
              data-test-start-date-picker
              min={{this.earliestSelectableDate}}
              max={{this.latestSelectableDate}}
              @type="date"
              @value={{@dateRange.startDate}}
              {{on "focusout" this.onDatePickerFocusOut}}
              {{on "change" (fn this.setCustomDateOrTime "startDate")}}
              as |F|
            >
              <F.Label>
                {{t
                  "vault-common.components.filter-bar.date-range-dropdown.start-date"
                }}
              </F.Label>
            </HdsFormTextInputField>
          </div>
          {{#if this.showTimePickers}}
            <div {{boxPadding "0 xs"}}>
              <HdsFormSelectField
                data-test-start-time-picker
                {{! @glint-expect-error }}
                @value={{@dateRange.startTime}}
                {{on "change" (fn this.setCustomDateOrTime "startTime")}}
                as |F|
              >
                <F.Label>
                  {{t
                    "vault-common.components.filter-bar.date-range-dropdown.start-time"
                  }}
                </F.Label>
                <F.Options>
                  {{#each this.hourSelectorList as |hour|}}
                    <option
                      value={{hour.value}}
                      selected={{eq @dateRange.startTime hour.value}}
                    >
                      {{t hour.label}}
                    </option>
                  {{/each}}
                </F.Options>
              </HdsFormSelectField>
            </div>
          {{/if}}
        </div>
        <div class="vi__custom-date-container" {{boxPadding "xs 0 0 md"}}>
          <div {{boxPadding "0 xs"}}>
            <HdsFormTextInputField
              data-test-end-date-picker
              min={{this.earliestSelectableDate}}
              max={{this.latestSelectableDate}}
              @type="date"
              @value={{this.endDateValue}}
              disabled={{@dateRange.lookbackOnly}}
              aria-invalid="{{if this.isDateRangeInvalid 'true' 'false'}}"
              @extraAriaDescribedBy={{if
                this.isDateRangeInvalid
                "date-range-invalid"
                ""
              }}
              {{on "focusout" this.onDatePickerFocusOut}}
              {{on "change" (fn this.setCustomDateOrTime "endDate")}}
              as |F|
            >
              <F.Label>
                {{t
                  "vault-common.components.filter-bar.date-range-dropdown.end-date"
                }}
              </F.Label>
            </HdsFormTextInputField>
          </div>
          {{#if this.showTimePickers}}
            <div {{boxPadding "0 xs"}}>
              <HdsFormSelectField
                data-test-end-time-picker
                {{! @glint-expect-error }}
                @value={{this.endTimeValue}}
                disabled={{@dateRange.lookbackOnly}}
                {{on "change" (fn this.setCustomDateOrTime "endTime")}}
                as |F|
              >
                <F.Label>
                  {{t
                    "vault-common.components.filter-bar.date-range-dropdown.end-time"
                  }}
                </F.Label>
                <F.Options>
                  {{#each this.hourSelectorList as |hour|}}
                    <option
                      value={{hour.value}}
                      selected={{eq this.endTimeValue hour.value}}
                    >
                      {{t hour.label}}
                    </option>
                  {{/each}}
                </F.Options>
              </HdsFormSelectField>
            </div>
          {{/if}}
        </div>
        {{#if this.isDateRangeInvalid}}
          <div {{boxMargin "md 0 0 0"}}>
            <HdsFormError
              id="date-range-invalid"
              data-test-error-invalid-date-range
            >
              {{t
                "vault-common.components.filter-bar.date-range-dropdown.invalid-date-range"
              }}
            </HdsFormError>
          </div>
        {{/if}}
        {{#if @dateRange.lookbackOnly}}
          <p class="hds-typography-body-100" {{boxMargin "md 0 0 lg"}}>
            {{t
              "vault-common.components.filter-bar.date-range-dropdown.lookback-only-message"
            }}
          </p>
        {{/if}}
        <button
          data-test-time-picker-toggle
          type="button"
          class="vi__time-picker-toggle"
          {{on "click" this.toggleTimePickers}}
          {{boxPadding "0 0 0 lg"}}
        >
          <p>
            {{this.timePickerToggleText}}
          </p>
        </button>
      {{/if}}
      <br />
      <hr />
      <HdsButtonSet {{boxPadding "xs 0 xs 0"}}>
        <HdsButton
          data-test-request-filter-submit
          disabled={{this.submitDisabled}}
          @text={{t "vault-common.components.filter-bar.submit-button-text"}}
          @size="small"
          {{on "click" this.submitDateRange}}
        />
        <HdsButton
          data-test-request-filter-text-cancel
          @text={{t "vault-common.components.filter-bar.cancel-button-text"}}
          @size="small"
          @color="secondary"
          {{on "click" (fn this.cancel @dd)}}
        />
      </HdsButtonSet>
      {{! @glint-expect-error }}
    </@dd.Generic>
  </template>
}
