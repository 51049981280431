import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { task } from 'ember-concurrency';
import { t } from 'ember-intl';

// Components
import AssigneeRoles from './assignee-roles.gts';
import GroupMembers from './group-members.gts';
import {
  HdsTabs,
  HdsFlyout,
} from '@hashicorp/design-system-components/components';

// Modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import boxPadding from 'core/modifiers/box-padding';

// Utils
import { and, eq } from 'ember-truth-helpers';

// Types
import type {
  HashicorpCloudCommonPaginationResponse,
  HashicorpCloudIamGroup,
  HashicorpCloudIamListGroupMembersResponse,
  HashicorpCloudIamPrincipal,
} from '@clients/cloud-iam';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

import type { HashicorpCloudResourcemanagerPolicyBindingMember } from '@clients/cloud-resource-manager';
import type ApiService from 'api/services/api';
import type { IntlService } from 'ember-intl';
import type AbilitiesService from 'ember-can/services/abilities';
import type { IamRoleLocation } from '../../../../types/iam-role-location.ts';
import type { ScopeLocationLink } from '../../../../types/scope-location-link.ts';
import type { RolesLocation } from '../../../../types/roles-location.ts';
import type { PageChange } from './group-members.gts';

export interface ManageAccessGroupRolesAndMembersFlyoutSignature {
  Element: HTMLElement;
  Args: {
    additionalRoleCount: number;
    groupResourceName?: string;
    groupRoleLocations: Array<IamRoleLocation>;
    groups: Array<HashicorpCloudIamGroup>;
    inheritedRoleLocations: Array<IamRoleLocation>;
    member?: HashicorpCloudResourcemanagerPolicyBindingMember;
    onClose: () => void;
    principal?: HashicorpCloudIamPrincipal;
    rolesLocations: Array<RolesLocation>;
    scopeLinks: Array<ScopeLocationLink>;
  };
  Blocks: {
    default?: [];
  };
}

type SelectedTab = 'roles' | 'members';

export default class ManageAccessGroupRolesAndMembersFlyoutComponent extends Component<ManageAccessGroupRolesAndMembersFlyoutSignature> {
  @service declare readonly abilities: AbilitiesService;
  @service declare readonly api: ApiService;
  @service declare readonly intl: IntlService;

  @tracked canListGroupMembers = false;
  @tracked canUpdateGroupMembers = false;
  @tracked
  nextPageToken:
    | HashicorpCloudCommonPaginationResponse['nextPageToken']
    | undefined = undefined;
  @tracked previousPageToken:
    | HashicorpCloudCommonPaginationResponse['previousPageToken']
    | undefined = undefined;
  @tracked selectedTab: SelectedTab = 'roles';
  @tracked state = 'close';

  get shouldShowGroupTab() {
    const { member, principal } = this.args;
    const isGroup =
      member?.memberType ===
      HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP;
    const hasMembers = principal?.group?.memberCount ? true : false;
    return isGroup && hasMembers;
  }

  get shouldShowRolesTab() {
    return this.args.additionalRoleCount > 0;
  }

  didInsert = (): void => {
    if (this.shouldShowGroupTab) {
      this.fetchGroupData.perform({});
    }
  };

  fetchGroupData = task(
    async (
      requestPagination: HashicorpCloudCommonPaginationResponse | null,
    ) => {
      const resourceName = this.args.groupResourceName;
      // if we know we don't want to display group tab, don't bother fetching this data
      if (!resourceName) {
        return;
      }

      let groupLevelPermissions: string[] | undefined = [];
      const { group } =
        await this.api.groups.groupsServiceGetGroup(resourceName);

      try {
        ({ allowedPermissions: groupLevelPermissions } =
          await this.api.resourceManager.authorization.authorizationServiceTestIamPermissions(
            {
              permissions: [
                'iam.groups.list-members',
                'iam.groups.update-members',
              ],
              resourceName: resourceName,
            },
          ));
      } catch (e) {
        if (macroCondition(isDevelopingApp())) {
          console.error(e);
        }
      }

      this.canListGroupMembers = this.abilities.can(
        'list-members in group',
        group,
        {
          groupLevelPermissions,
        },
      );

      this.canUpdateGroupMembers = this.abilities.can(
        'update-membership in group',
        group,
        {
          groupLevelPermissions,
        },
      );

      if (!this.canListGroupMembers) {
        return;
      }

      const membersResponse: HashicorpCloudIamListGroupMembersResponse =
        await this.api.groups.groupsServiceListGroupMembers(
          resourceName,
          undefined,
          requestPagination?.nextPageToken,
          requestPagination?.previousPageToken,
        );
      const { pagination: { previousPageToken, nextPageToken } = {} } =
        membersResponse;

      this.previousPageToken = previousPageToken;
      this.nextPageToken = nextPageToken;

      return membersResponse;
    },
  );

  handlePageChange = (pageChange: PageChange) => {
    const paginationArgs =
      pageChange === 'prev'
        ? { previousPageToken: this.previousPageToken }
        : { nextPageToken: this.nextPageToken };
    this.fetchGroupData.perform(paginationArgs);
  };

  <template>
    <HdsFlyout
      id="group-roles-and-members"
      @onClose={{@onClose}}
      @size="large"
      {{didInsert this.didInsert}}
      data-test-group-roles-and-members-flyout
      as |M|
    >
      <M.Header>
        {{#if (and this.shouldShowGroupTab this.shouldShowRolesTab)}}
          {{t
            "manage-access.components.flyout.group-roles-and-members.view-existing-roles-and-members"
          }}
        {{else if this.shouldShowGroupTab}}
          {{t
            "manage-access.components.flyout.group-roles-and-members.view-members"
          }}
        {{else}}
          {{t
            "manage-access.components.flyout.group-roles-and-members.view-existing-roles"
          }}
        {{/if}}
      </M.Header>
      <M.Body>
        {{#if (and this.shouldShowGroupTab this.shouldShowRolesTab)}}
          <HdsTabs data-test-group-roles-and-members-tabs as |T|>
            <T.Tab
              id="roles"
              @isSelected={{eq this.selectedTab "roles"}}
              data-test-group-roles-and-members-tab-roles
            >
              {{t
                "manage-access.components.flyout.group-roles-and-members.tabs.roles"
              }}
            </T.Tab>
            <T.Panel
              data-test-group-roles-and-members-tab-panel-roles
              {{boxPadding "lg 0"}}
            >
              <AssigneeRoles
                @inheritedRoleLocations={{@inheritedRoleLocations}}
                @groups={{@groups}}
                @groupRoleLocations={{@groupRoleLocations}}
                @rolesLocations={{@rolesLocations}}
                @scopeLinks={{@scopeLinks}}
              />
            </T.Panel>
            <T.Tab
              id="members"
              @isSelected={{eq this.selectedTab "members"}}
              data-test-group-roles-and-members-tab-members
            >
              {{t
                "manage-access.components.flyout.group-roles-and-members.tabs.members"
              }}
            </T.Tab>
            <T.Panel
              data-test-group-roles-and-members-tab-panel-members
              {{boxPadding "lg 0"}}
            >
              <GroupMembers
                @canListGroupMembers={{this.canListGroupMembers}}
                @canUpdateGroupMembers={{this.canUpdateGroupMembers}}
                @error={{this.fetchGroupData.last.isError}}
                @group={{@principal.group}}
                @handlePageChange={{this.handlePageChange}}
                @loading={{this.fetchGroupData.isRunning}}
                @membersResponse={{this.fetchGroupData.lastSuccessful.value}}
                @showExpandedTable={{false}}
              />
            </T.Panel>
          </HdsTabs>
        {{else if this.shouldShowGroupTab}}
          <GroupMembers
            @canListGroupMembers={{this.canListGroupMembers}}
            @canUpdateGroupMembers={{this.canUpdateGroupMembers}}
            @error={{this.fetchGroupData.last.isError}}
            @group={{@principal.group}}
            @handlePageChange={{this.handlePageChange}}
            @loading={{this.fetchGroupData.isRunning}}
            @membersResponse={{this.fetchGroupData.lastSuccessful.value}}
            @showExpandedTable={{false}}
          />
        {{else}}
          <AssigneeRoles
            @inheritedRoleLocations={{@inheritedRoleLocations}}
            @groups={{@groups}}
            @groupRoleLocations={{@groupRoleLocations}}
            @rolesLocations={{@rolesLocations}}
            @scopeLinks={{@scopeLinks}}
          />
        {{/if}}
      </M.Body>
    </HdsFlyout>
  </template>
}
