/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { macroCondition, isDevelopingApp } from '@embroider/macros';

export default class TfcMigrationModalComponent extends Component {
  @service api;

  @tracked showModal = false;

  @action
  async checkForMigration() {
    try {
      const { notification } =
        await this.api.tfcSynchronization.notificationsServiceGetNotification();
      this.showModal = !notification?.acknowledgedAt;
    } catch (e) {
      return;
    }
  }

  @action
  async close() {
    this.showModal = false;
  }

  @action
  async acknowledge() {
    try {
      await this.api.tfcSynchronization.notificationsServiceAcknowledgeNotification();
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        console.error(e);
      }
    } finally {
      this.close();
    }
  }
}
