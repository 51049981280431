/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVagrant20220930VagrantCloudLinkResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930VagrantCloudLinkResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930VagrantCloudLinkResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'require2fa': !exists(json, 'require2fa') ? undefined : json['require2fa'],
    };
}
export function HashicorpCloudVagrant20220930VagrantCloudLinkResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'require2fa': value.require2fa,
    };
}
