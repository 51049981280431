import { getOwner } from '@ember/owner';
import { action } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import setSentryUser from 'core/utils/sentry-user';
import SystemNotificationColors from 'common/utils/system-notification-colors';
import campaignAnalytics from '../utils/campaign-analytics';
import DEFAULT_FLAGS from '../utils/launch-darkly-defaults';
import { initialize, variation } from 'ember-launch-darkly';
import {
  isDevelopingApp,
  importSync,
  macroCondition,
  isTesting,
  getConfig,
} from '@embroider/macros';

const LD_TIMEOUT = 2000;
const LD_SHORTENED_TIMEOUT = 500;
const LD_TIMEOUT_HIT_KEY = 'hcp-ui-ld-backoff-timeout';

export default class ApplicationRoute extends Route {
  @service config;
  @service flashMessages;
  @service session;
  @service systemStatus;
  @service analytics;
  @service intl;
  @service router;
  @service locale;

  constructor() {
    super(...arguments);

    if (
      macroCondition(
        isDevelopingApp() && !isTesting() && getConfig('api').isMirageEnabled
      )
    ) {
      const { default: startMirage } = importSync('ember-mirage/start-mirage');
      const { default: makeServer } = importSync('api/mirage/config');
      // only load this service in dev
      const { default: ScenarioService } = importSync(
        'api/services/mirage-scenario'
      );

      const owner = getOwner(this);
      const server = startMirage(makeServer, {
        owner,
      });

      //
      // we have to manually register the service
      ScenarioService.register(owner).start(server);
    }
  }

  activate() {
    this.systemStatus.start();
  }

  deactivate() {
    this.systemStatus.stop();
  }

  async beforeModel(transition) {
    this.locale.start();

    await this.initializeLD();
    await this.session.setup();
    if (variation('hcp-ui-posthog')) {
      this.analytics.start('posthog', this.config.app.analytics);
    } else {
      this.analytics.start('segment', this.config.app.segment);
    }
    setSentryUser(getOwner(this));

    const campaign = campaignAnalytics(transition.to.queryParams);
    if (Object.keys(campaign).length) {
      this.analytics.context = { campaign };
    }

    let globalMessage = variation('hcp-ui-global-message');
    // This should be an Object from LaunchDarkly that will be passed into a
    // system notification as a means to alert all users, even on public pages,
    // of a potential system outage, etc.
    //
    // Ideally, this will be refactored out and the content will be sourced from
    // a headless CMS and only be flagged on/off via LD. Until then, we'll use
    // this object and attempt to type-check it before we pop a system banner.
    if (
      typeof globalMessage?.message === 'string' &&
      typeof globalMessage?.content === 'string'
    ) {
      let notificationType =
        typeof globalMessage?.type === 'string'
          ? globalMessage.type.toUpperCase()
          : '';

      this.intl.addTranslations('en-us', {
        'system-notifications': {
          'global-warning-message': {
            content: globalMessage.content,
            message: globalMessage.message,
          },
        },
      });
      this.flashMessages.systemNotification({
        id: 'system-notification-global-message',
        priority: 999, // Raise priority so that it overrides all other messages.
        color:
          SystemNotificationColors[notificationType] ||
          SystemNotificationColors.WARNING,
        message: 'system-notifications.global-warning-message.message',
        content: 'system-notifications.global-warning-message.content',
      });
    }

    return;
  }

  /**
   * Tries to initialize LaunchDarkly. If the request takes too long or fails, it will re-initialize
   * in local mode using the backup flags that are automatically updated via github actions.
   */
  async initializeLD() {
    const { clientSideId, ...options } = this.config.app.launchDarkly;
    const expiry = window.localStorage.getItem(LD_TIMEOUT_HIT_KEY);
    const now = Date.now();
    let timeout = LD_TIMEOUT;

    // if the expiry time exists and it's later than the current time
    // use a shorter timeout
    if (expiry && expiry > now) {
      timeout = LD_SHORTENED_TIMEOUT;
    }

    try {
      let timeoutId;

      // promise that will reject after a timeout and trigger the catch block
      const timeoutPromise = new Promise((_, reject) => {
        // eslint-disable-next-line ember/no-runloop
        timeoutId = later(() => {
          reject();
        }, timeout);
      });

      // The actual LaunchDarkly initialize call
      // SEE: https://www.npmjs.com/package/ember-launch-darkly#configuration
      const initializePromise = initialize(
        clientSideId,
        { key: 'anonymous' },
        options
      );

      await Promise.race([timeoutPromise, initializePromise]).finally(() =>
        // eslint-disable-next-line ember/no-runloop
        cancel(timeoutId)
      );
    } catch (e) {
      // set the expiry to 5 minutes from now
      // if expiry doesn't exist or it has already passed
      if (!expiry || expiry < now) {
        let expiryDate = new Date(now);

        window.localStorage.setItem(
          LD_TIMEOUT_HIT_KEY,
          expiryDate.setMinutes(expiryDate.getMinutes() + 5).valueOf()
        );
      }

      // initialize in local mode with the local defaults for flags
      await initialize(
        clientSideId,
        { key: 'anonymous' },
        { ...options, mode: 'local', localFlags: DEFAULT_FLAGS }
      );
    }
  }

  @action
  error(error) {
    // it's likely an error if all of these are true (instanceof Error check
    // isn't reliable)
    if (error && error.stack && error.message) {
      Sentry.captureException(error);
    }
    return true;
  }
}
