import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import type EnginesRouterService from 'ember-engines-router-service/services/router';
import type { Transition } from 'common/utils/ember';
import type Owner from '@ember/owner';

interface PaginationSignature {
  Element: HTMLDivElement;
  Args: {
    margin?: string;
    pagination?: {
      nextPageToken?: string;
      previousPageToken?: string;
    };
    onPageChange?(page: 'prev' | 'next'): void;
  };
}

/**
 *
 * `Pagination` renders a Toolbar component with pagination controls in it.
 *
 *
 * ```
 * <Pagination @pagination={{@model.pagination}} />
 * ```
 *
 * @class Pagination
 *
 */

/**
 * @typedef {Object} Pagination
 * @property {string} nextPageToken the next pagination token
 * @property {string} previousPageToken the previous pagination token
 */

/**
 * The pagination object from the api.
 * @argument pagination
 * @type {Pagination}
 * @required
 */

/**
 * Configurable margin value
 * @argument margin
 * @type {String} "sm 0 0 0" by default
 * @optional
 */

/**
 * Function that is called when the page changes
 * @argument onPageChange
 * @type {Function}
 * @optional
 */

export default class PaginationComponent extends Component<PaginationSignature> {
  @service declare readonly router: EnginesRouterService;

  @tracked isExitingRoute = false;

  constructor(owner: Owner, args: PaginationSignature['Args']) {
    super(owner, args);
    this.router.on('routeWillChange', this.routeWillChange);
  }

  willDestroy() {
    super.willDestroy();
    this.router.off('routeWillChange', this.routeWillChange);
  }

  /**
   * work around for error thrown from LinkTo when transitioning to a route higher in the tree
   * if the current route requires a dynamic segment, it is not necessary to provide it for a query param only transition
   * if a transition is initiated (outside of the component) to a higher route that does not contain the segment,
     the router will error generating the link for the pagination controls LinkTo components
   * remove the Hds::Pagination::Compact component from the DOM if a transition to a different route is initiated
  */
  routeWillChange: (transition: Transition) => void = (
    transition: Transition
  ) => {
    this.isExitingRoute = !transition.to?.name.endsWith(
      this.router.currentRouteName || ''
    );
  };

  get currentRouteName() {
    return this.router.currentRouteName;
  }

  get showPagination() {
    return (
      !this.isExitingRoute &&
      (this.args.pagination?.previousPageToken ||
        this.args.pagination?.nextPageToken)
    );
  }

  get paginationQueryFunction() {
    return (page: 'prev' | 'next') => {
      return {
        prev:
          page === 'prev' ? this.args.pagination?.previousPageToken : undefined,
        next: page === 'next' ? this.args.pagination?.nextPageToken : undefined,
      };
    };
  }

  get paginationIsDisabledPrev() {
    return !this.args.pagination?.previousPageToken;
  }

  get paginationIsDisabledNext() {
    return !this.args.pagination?.nextPageToken;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Pagination: typeof PaginationComponent;
    pagination: typeof PaginationComponent;
  }
}
