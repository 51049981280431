import Component from '@glimmer/component';

// Components
import Flex from 'core/components/flex';

// Helpers
import { not, or } from 'ember-truth-helpers';

// Utils
import { FlexDirection, FlexGap } from 'core/utils/flex';

import TableGroupMembers from '../../table/group-members.gts';
import { HdsPaginationCompact } from '@hashicorp/design-system-components/components';

// Types
import type {
  HashicorpCloudIamGroup,
  HashicorpCloudIamListGroupMembersResponse,
} from '@clients/cloud-iam';

export type PageChange = 'prev' | 'next';

export interface ManageAccessGroupMembersFlyoutSignature {
  Element: HTMLElement;
  Args: {
    canListGroupMembers: boolean;
    canUpdateGroupMembers?: boolean;
    error?: boolean;
    group?: HashicorpCloudIamGroup;
    handlePageChange?: (pageChange: PageChange) => void;
    membersResponse?: HashicorpCloudIamListGroupMembersResponse | null;
    loading?: boolean;
    showExpandedTable?: boolean;
  };
  Blocks: {
    default?: [];
  };
}

export default class GroupMembersComponent extends Component<ManageAccessGroupMembersFlyoutSignature> {
  <template>
    <Flex
      @direction={{FlexDirection.Column}}
      @gap={{FlexGap.Md}}
      data-test-paginated-group-members
    >
      <TableGroupMembers
        @canListGroupMembers={{@canListGroupMembers}}
        @canUpdateGroupMembers={{@canUpdateGroupMembers}}
        @error={{@error}}
        @group={{@group}}
        @loading={{@loading}}
        @members={{@membersResponse.members}}
        @showExpandedTable={{false}}
      />
      {{#if
        (or
          @membersResponse.pagination.previousPageToken
          @membersResponse.pagination.nextPageToken
        )
      }}
        <HdsPaginationCompact
          @onPageChange={{@handlePageChange}}
          @isDisabledPrev={{not @membersResponse.pagination.previousPageToken}}
          @isDisabledNext={{not @membersResponse.pagination.nextPageToken}}
          data-test-paginated-group-members-table-pagination
        />
      {{/if}}
    </Flex>
  </template>
}
