import type {
  ParsedResourceName,
  Options,
  PartRecord,
} from '../types/resource-name.ts';
import { IamFindResourceNamePart } from 'core/utils/resource-name';

export default function iamFindResourceNamePart(
  parsedResourceName: ParsedResourceName,
  { part }: Options,
): PartRecord | undefined {
  return IamFindResourceNamePart(parsedResourceName, { part });
}
