/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128PrincipalFromJSON, Secrets20231128PrincipalToJSON, } from './';
export function Secrets20231128AppFromJSON(json) {
    return Secrets20231128AppFromJSONTyped(json, false);
}
export function Secrets20231128AppFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : Secrets20231128PrincipalFromJSON(json['created_by']),
        'updatedBy': !exists(json, 'updated_by') ? undefined : Secrets20231128PrincipalFromJSON(json['updated_by']),
        'syncNames': !exists(json, 'sync_names') ? undefined : json['sync_names'],
        'secretCount': !exists(json, 'secret_count') ? undefined : json['secret_count'],
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
    };
}
export function Secrets20231128AppToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'project_id': value.projectId,
        'name': value.name,
        'description': value.description,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by': Secrets20231128PrincipalToJSON(value.createdBy),
        'updated_by': Secrets20231128PrincipalToJSON(value.updatedBy),
        'sync_names': value.syncNames,
        'secret_count': value.secretCount,
        'resource_name': value.resourceName,
        'resource_id': value.resourceId,
    };
}
