import Component from '@glimmer/component';

// Components
import { FlightIcon } from '@hashicorp/ember-flight-icons/components';
import { HdsTextBody } from '@hashicorp/design-system-components/components';
import Flex from 'core/components/flex';

// Helpers
import iamGetIconForService from '../../helpers/iam-get-icon-for-service.ts';
import iamGetLabelForService from '../../helpers/iam-get-label-for-service.ts';

// Utils
import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';
import parseRoleId from '../../utils/parse-role-id.ts';

// Modifiers
import style from 'ember-style-modifier';

// Types
import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

export interface ManageAccessServiceBadgeSignature {
  Element: HTMLElement;
  Args: {
    role: HashicorpCloudResourcemanagerRole;
  };
  Blocks: {
    default: [];
  };
}

export default class ManageAccessServiceBadgeComponent extends Component<ManageAccessServiceBadgeSignature> {
  get iconName() {
    const { service } = this.parsedRoleId;
    return iamGetIconForService(service);
  }

  get service() {
    const { service } = this.parsedRoleId;
    return service;
  }

  get parsedRoleId() {
    const { role = {} } = this.args;
    return parseRoleId(role.id);
  }

  <template>
    <Flex
      @alignItems={{AlignItem.Start}}
      @direction={{FlexDirection.Row}}
      @gap={{FlexGap.Xs}}
      data-test-manage-access-service-badge
      ...attributes
    >
      {{#let this.iconName as |iconName|}}
        {{#if iconName}}
          <span data-test-manage-access-role-icon>
            {{! @glint-expect-error: fix incoming icon type }}
            <FlightIcon @name={{this.iconName}} />
          </span>
        {{/if}}
      {{/let}}
      <HdsTextBody
        {{! This is here to align the text with the icon while still aligning
        the items at flex align items start. }}
        {{style margin-top="-2px"}}
        data-test-manage-access-service-badge-title
      >
        {{iamGetLabelForService this.service}}
      </HdsTextBody>
    </Flex>
  </template>
}
