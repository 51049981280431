import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { or } from 'ember-truth-helpers';
import { task } from 'ember-concurrency';
import FormInputsSlugId from 'core/components/form-inputs/slug-id';
import FormInputsRegionSelect from 'core/components/form-inputs/region-select';
import FormInputsSubmitButton from 'core/components/form-inputs/submit-button';

import NetworksFormInputsProviderCards from '../form-inputs/provider-cards.gjs';

/**
 *
 * `NetworksFormsCreate` description here.
 *
 *
 * ```
 *  <Networks::Forms::Create
 *    @formState={{formState}}
 *    @regions{{regionsMap}}
 *    @providers={{providers}}
 *    @onFormStateChange={{onFormStateChange}}
 *    @onSubmit={{onSubmit}}
 *    @formDisabled={{formDisabled}}
 *    as |Form FormErrors|
 *  >
 *    <!-- BYOC: Bring your own content (allows for differences in Consul VS Vault VS Networking contexts.) -->
 *
 *    <Form.NetworkId />
 *    <Form.RegionSelect />
 *    <Form.CidrBlock />
 *
 *    <Form.Submit @text='Create Network' />
 *  </Networks::Forms::Create>
 * ```
 *
 * @class NetworksFormsCreate
 *
 */

export default class NetworksFormsCreate extends Component {
  @tracked regions;

  constructor(owner, args) {
    super(owner, args);
    this.regions = args.regions[args.formState.provider] || [];
  }

  onSubmitHandlerChain = task(async (event) => {
    event.preventDefault();
    await this.args.onSubmit.perform();
  });

  onNetworkIdChange = ({ target }) => {
    this.args.onFormStateChange({ networkId: target.value });
  };

  onProviderChange = ({ target }) => {
    this.regions = this.args.regions[target.value] || [];
    this.args.onFormStateChange({
      provider: target.value,
      region: this.regions[0],
    });
  };

  onRegionChange = ({ target }) => {
    this.args.onFormStateChange({ region: target.value });
  };

  onCidrBlockChange = ({ target }) => {
    this.args.onFormStateChange({ cidrBlock: target.value });
  };

  <template>
    {{#let
      (or this.onSubmitHandlerChain.isRunning @formDisabled)
      as |disabled|
    }}
      <form
        data-test-form-network-create
        class="hcpForm"
        ...attributes
        {{on "submit" this.onSubmitHandlerChain.perform}}
      >
        {{yield
          (hash
            NetworkId=(component
              FormInputsSlugId
              value=@formState.networkId
              onChange=this.onNetworkIdChange
              disabled=disabled
            )
            NetworkProvider=(component
              NetworksFormInputsProviderCards
              onChange=this.onProviderChange
              providers=@providers
              selectedProvider=@formState.provider
              disabled=disabled
            )
            RegionSelect=(component
              FormInputsRegionSelect
              regions=this.regions
              provider=@formState.provider
              selectedRegion=@formState.region
              onChange=this.onRegionChange
              disabled=disabled
            )
            CidrBlock=(component
              FormInputsSlugId
              value=@formState.cidrBlock
              onChange=this.onCidrBlockChange
              disabled=disabled
            )
            Submit=(component FormInputsSubmitButton disabled=disabled)
          )
          this.onSubmitHandlerChain.last.error
        }}
      </form>
    {{/let}}
  </template>
}
