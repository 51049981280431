import Component from '@glimmer/component';
import { t } from 'ember-intl';

import {
  HdsTextBody,
  HdsRichTooltip,
} from '@hashicorp/design-system-components/components';
import Flex from 'core/components/flex';

import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';

export interface ManageAccessSourceProjectInfoSignature {
  Element: HTMLElement;
}

export default class ManageAccessSourceProjectInfoComponent extends Component<ManageAccessSourceProjectInfoSignature> {
  <template>
    <HdsRichTooltip
      data-test-manage-access-tooltip-source-project-info
      ...attributes
      as |RT|
    >
      <RT.Toggle
        @icon="info"
        @isInline={{true}}
        @size="medium"
        aria-label={{t
          "manage-access.components.tooltip.source-project-info.source-project-information"
        }}
        data-test-manage-access-tooltip-source-project-info-toggle
      />
      <RT.Bubble
        @placement="right"
        data-test-manage-access-tooltip-source-project-info-bubble
      >
        <Flex
          @alignItems={{AlignItem.Start}}
          @direction={{FlexDirection.Column}}
          @gap={{FlexGap.Md}}
        >
          <Flex
            @alignItems={{AlignItem.Center}}
            @direction={{FlexDirection.Row}}
            @gap={{FlexGap.Xs}}
            data-test-manage-access-tooltip-source-project-info-header
          >
            <HdsTextBody
              @size="200"
              @weight="semibold"
              data-test-manage-access-tooltip-source-project-info-title
            >
              {{t
                "manage-access.components.tooltip.source-project-info.source-project"
              }}
            </HdsTextBody>
          </Flex>
          <Flex
            @alignItems={{AlignItem.Start}}
            @direction={{FlexDirection.Column}}
            @gap={{FlexGap.Sm}}
            data-test-manage-access-tooltip-source-project-info-description
          >
            <HdsTextBody @size="200" @weight="regular">
              {{t
                "manage-access.components.tooltip.source-project-info.this-service-principal-is-created-outside"
              }}
            </HdsTextBody>
            <HdsTextBody @size="200" @weight="regular">
              {{t
                "manage-access.components.tooltip.source-project-info.this-assignee-will-have-access-to-current-project"
                htmlSafe=true
              }}
            </HdsTextBody>
          </Flex>
        </Flex>
      </RT.Bubble>
    </HdsRichTooltip>
  </template>
}
