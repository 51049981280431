import Component from '@glimmer/component';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { assert } from '@ember/debug';
import type Owner from '@ember/owner';

interface HvnSelectDisableSignature {
  Args: {
    disableNetworkOptions: () => void;
    ids: Array<string>;
  };
}

/**
 * Renderless logical component which triggers parent network options data model to mark Network objects with matching IDs as disabled.
 *
 * **NOTE:** _This component is NOT meant to be used independently, rather yielded back from a parent `<HvnSelect>` component._
 *
 * Example usage:
 *
 * ```
 * <FormInputs::HvnSelect
 *  @networks={{networks}}
 *  @selectedNetworkId={{selectedNetworkId}}
 *  @onChange={{onChange}}
 *  as |Hvns|
 * >
 *   <Hvns.Disable @ids={{array 'unavailable-network'}} />
 * </FormInputs::HvnSelect>
 * ```
 *
 *
 * @see {FormInputsHvnSelect}
 * @class FormInputsHvnSelectDisable
 *
 */

export default class FormInputsHvnSelectDisableComponent extends Component<HvnSelectDisableSignature> {
  /**
   * @argument disableNetworkOptions
   * @type {Function(Array.<String>)}
   */
  /**
   * @argument ids
   * @type {Array.<String>}
   */

  constructor(owner: Owner, args: HvnSelectDisableSignature['Args']) {
    super(owner, args);

    const { disableNetworkOptions, ids } = this.args;

    if (macroCondition(isDevelopingApp())) {
      assert(
        '<HvnSelect.Disable> Argument @disableNetworkOptions is expected to be a Function (passed down from parent <HvnSelect> component)',
        typeof disableNetworkOptions === 'function'
      );

      assert(
        '<HvnSelect.Disable> Argument @ids is expected to be an Array of String Network IDs',
        Array.isArray(ids)
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::HvnSelect::Disable': typeof FormInputsHvnSelectDisableComponent;
    'form-inputs/hvn-select/disable': typeof FormInputsHvnSelectDisableComponent;
  }
}
