// original low balance amount when default trial credits is maximum $50.00
export const BILLING_LOW_BALANCE = 10.0;

// updated low balance amount when default trial credits is maximum $500.00 on release of Flex 2
export const BILLING_LOW_BALANCE_UPDATED = 100.0;

export const BILLING_ACCOUNT_STATUS = Object.freeze({
  ACTIVE: 'ACTIVE',
  DELETING: 'DELETING',
  DELINQUENT: 'DELINQUENT',
  INCOMPLETE: 'INCOMPLETE',
  TRIAL: 'TRIAL',
});

export const BILLING_ON_DEMAND_STATUS = Object.freeze({
  ACTIVE: 'ON_DEMAND_ACTIVE',
  DELETING: 'ON_DEMAND_DELETING',
  INVALID: 'ON_DEMAND_INVALID',
  UNSET: 'ON_DEMAND_UNSET',
});

export const BILLING_ACCOUNT_STANDINGS = Object.freeze({
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  OVERDUE: 'OVERDUE',
  GOOD_STANDING: 'GOOD_STANDING',
  OPEN: 'OPEN',
  PAID: 'PAID',
  ROLLED_OVER: 'ROLLED_OVER',
  TRIAL: 'TRIAL',
  UNKNOWN: 'UNKNOWN', // This is UI-only.
  UPCOMING: 'UPCOMING',
});
