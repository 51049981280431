/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128AwsAssumeRoleDetailsFromJSON, Secrets20231128AwsAssumeRoleDetailsToJSON, Secrets20231128GcpServiceAccountImpersonationDetailsFromJSON, Secrets20231128GcpServiceAccountImpersonationDetailsToJSON, } from './';
export function Secrets20231128DynamicSecretFromJSON(json) {
    return Secrets20231128DynamicSecretFromJSONTyped(json, false);
}
export function Secrets20231128DynamicSecretFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'integrationName': !exists(json, 'integration_name') ? undefined : json['integration_name'],
        'defaultTtl': !exists(json, 'default_ttl') ? undefined : json['default_ttl'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'updatedById': !exists(json, 'updated_by_id') ? undefined : json['updated_by_id'],
        'awsAssumeRoleDetails': !exists(json, 'aws_assume_role_details') ? undefined : Secrets20231128AwsAssumeRoleDetailsFromJSON(json['aws_assume_role_details']),
        'gcpServiceAccountImpersonationDetails': !exists(json, 'gcp_service_account_impersonation_details') ? undefined : Secrets20231128GcpServiceAccountImpersonationDetailsFromJSON(json['gcp_service_account_impersonation_details']),
    };
}
export function Secrets20231128DynamicSecretToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'provider': value.provider,
        'integration_name': value.integrationName,
        'default_ttl': value.defaultTtl,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by_id': value.createdById,
        'updated_by_id': value.updatedById,
        'aws_assume_role_details': Secrets20231128AwsAssumeRoleDetailsToJSON(value.awsAssumeRoleDetails),
        'gcp_service_account_impersonation_details': Secrets20231128GcpServiceAccountImpersonationDetailsToJSON(value.gcpServiceAccountImpersonationDetails),
    };
}
