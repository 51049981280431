import Modifier from 'ember-modifier';
// This modifier adds a data-cv="id" attribute to an element. These attributes
// are used by our continuous verification framework and are intended to stay
// in the code in production.
//
// usage:
//
// <div {{cv "my-element"}}></div>
//
// which will render:
// <div data-cv="my-element"></div>
//
//
export default class CVTestIdModifier extends Modifier {
  modify(element, testId) {
    element.dataset.cv = testId;
  }
}
