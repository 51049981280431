/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVagrant20220930VagrantCloud2FaRequestFromJSON(json) {
    return HashicorpCloudVagrant20220930VagrantCloud2FaRequestFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930VagrantCloud2FaRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': !exists(json, 'username') ? undefined : json['username'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}
export function HashicorpCloudVagrant20220930VagrantCloud2FaRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
        'code': value.code,
    };
}
