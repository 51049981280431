import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';

// Components
import Flex from 'core/components/flex';
import { FlightIcon } from '@hashicorp/ember-flight-icons/components';
import {
  HdsAlert,
  HdsTable,
  HdsTooltipButton,
  HdsTextDisplay,
} from '@hashicorp/design-system-components/components';
import Loading from 'core/components/loading';

// Helpers
import { not } from 'ember-truth-helpers';

// Utils
import {
  AlignItem,
  FlexDirection,
  FlexGap,
  JustifyContent,
} from 'core/utils/flex';

// Types
import type { IntlService } from 'ember-intl';
import type {
  HashicorpCloudIamGroup,
  HashicorpCloudIamGroupMember,
} from '@clients/cloud-iam';

export interface DecoratedGroupMember extends HashicorpCloudIamGroupMember {
  isManager?: boolean;
}

export interface ManageAccessGroupMembersTableSignature {
  Element: HTMLElement;
  Args: {
    members?: Array<DecoratedGroupMember>;
    canListGroupMembers: boolean;
    canUpdateGroupMembers?: boolean;
    group?: HashicorpCloudIamGroup;
    loading?: boolean;
    error?: boolean;
    showExpandedTable?: boolean;
  };
  Blocks: {
    default?: [];
    applicationState?: [];
  };
}

export default class GroupMembersComponent extends Component<ManageAccessGroupMembersTableSignature> {
  @service declare readonly intl: IntlService;

  <template>
    <Flex
      @direction={{FlexDirection.Column}}
      @gap={{FlexGap.Md}}
      data-test-paginated-group-members
    >
      <Flex
        @direction={{FlexDirection.Row}}
        @justifyContent={{JustifyContent.SpaceBetween}}
      >
        <HdsTextDisplay
          @size="400"
          @weight="bold"
          data-test-paginated-group-members-header
        >
          {{t "manage-access.components.table.group-members.members"}}
        </HdsTextDisplay>
      </Flex>
      {{#if @error}}
        <HdsAlert
          @type="inline"
          @color="critical"
          @icon="alert-diamond"
          data-test-paginated-group-members-error
          as |A|
        >
          <A.Title data-test-paginated-group-members-error-title>
            {{t "manage-access.components.table.group-members.error.title"}}
          </A.Title>
          <A.Description data-test-paginated-group-members-error-description>
            {{t
              "manage-access.components.table.group-members.error.description"
            }}
          </A.Description>
        </HdsAlert>
      {{else if @loading}}
        <Loading data-test-paginated-group-members-loading as |L|>
          <L.Message>
            {{t "manage-access.components.table.group-members.loading"}}
          </L.Message>
        </Loading>
      {{else if (not @canListGroupMembers)}}
        <HdsAlert
          @type="inline"
          @color="warning"
          @icon="alert-triangle"
          data-test-paginated-group-members-no-permissions
          as |A|
        >
          <A.Title data-test-paginated-group-members-no-permissions-title>
            {{t
              "manage-access.components.table.group-members.no-permissions.title"
            }}
          </A.Title>
          <A.Description
            data-test-paginated-group-members-no-permissions-description
          >
            {{t
              "manage-access.components.table.group-members.no-permissions.description"
            }}
          </A.Description>
        </HdsAlert>
      {{else if (not @members)}}
        {{yield to="applicationState"}}
      {{else}}
        <HdsTable
          @caption={{t
            "manage-access.components.table.group-members.group-table.caption"
          }}
          data-test-paginated-group-members-table
        >
          <:head as |H|>
            <H.Tr>
              <H.Th data-test-paginated-group-members-table-name-header>
                {{t
                  "manage-access.components.table.group-members.group-table.headers.name"
                }}
              </H.Th>
              <H.Th data-test-paginated-group-members-table-email-header>
                {{t
                  "manage-access.components.table.group-members.group-table.headers.email"
                }}
              </H.Th>
              {{#if @showExpandedTable}}
                <H.Th data-test-paginated-group-members-table-group-role-header>
                  <Flex
                    @alignItems={{AlignItem.Center}}
                    @direction={{FlexDirection.Row}}
                    @gap={{FlexGap.Xs}}
                  >
                    {{t
                      "manage-access.components.table.group-members.group-table.headers.roles"
                    }}
                    <HdsTooltipButton
                      @text={{t
                        "manage-access.components.table.group-members.group-table.headers.roles-tooltip"
                      }}
                      aria-label={{t
                        "manage-access.components.table.group-members.group-table.headers.roles-tooltip-aria-lable"
                      }}
                    >
                      <FlightIcon @name="info" />
                    </HdsTooltipButton>
                  </Flex>
                </H.Th>
              {{/if}}
            </H.Tr>
          </:head>
          <:body as |B|>
            {{#each @members as |user|}}
              <B.Tr data-test-paginated-group-members-table-row={{user.name}}>
                <B.Td
                  data-test-paginated-group-members-table-name={{user.name}}
                >
                  {{user.name}}
                </B.Td>
                <B.Td
                  data-test-paginated-group-members-table-email={{user.email}}
                >
                  {{user.email}}
                </B.Td>
                {{#if @showExpandedTable}}
                  <B.Td data-test-paginated-group-members-table-group-role>
                    {{#if user.isManager}}
                      {{t
                        "manage-access.components.table.group-members.group-table.values.roles.manager"
                      }}
                    {{else}}
                      {{t
                        "manage-access.components.table.group-members.group-table.values.roles.member"
                      }}
                    {{/if}}
                  </B.Td>
                {{/if}}
              </B.Tr>
            {{/each}}
          </:body>
        </HdsTable>
      {{/if}}
    </Flex>
  </template>
}
