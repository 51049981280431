import { DateTime } from 'luxon';
import { assert } from '@ember/debug';

import BillingCredits from './billing-credits.js';
import BillingStatus from './billing-status.js';
import BillingFCPBalance from './billing-fcp-balance.js';
import { PRICING_MODEL } from '../constants/billing.js';
import { BILLING_ACCOUNT_STANDINGS } from '../constants/status.js';

// hours in day * minutes in hour * seconds in minute * ms in seconds
const millisecondsInDay = 24 * 60 * 60 * 1000;
const sevenDays = 7 * millisecondsInDay;
const thirtyDays = 30 * millisecondsInDay;

function getTimeUntil(date) {
  let until = DateTime.fromJSDate(date, { zone: 'utc' });
  return until.diff(DateTime.utc()).toObject();
}

function getTimeSince(date) {
  let from = DateTime.fromJSDate(date, { zone: 'utc' });
  return DateTime.utc().diff(from).toObject();
}

// TODO: Enable either private or static method Babel transpilation.
function isPaymentExpired({ expMonth, expYear }) {
  if (!expMonth || !expYear) {
    assert('Must provide expiry month and year');
  }
  let expiration = getPaymentExpiration({ month: expMonth, year: expYear });
  return DateTime.utc() > expiration;
}

// TODO: Enable either private or static method Babel transpilation.
function getPaymentExpiration({ month, year }) {
  return DateTime.fromObject({ month, year }).toUTC().endOf('month');
}

export default class Billing {
  #actions;
  #billingAccount;
  #onDemandBillingMethodDetails;
  #entitlementBillingMethodDetails;
  #flexibleConsumptionBillingMethodDetails;
  #fcp;
  #transitions;

  /**
   * @param {object} [seed={}]
   * @param {object} [seed.actions]
   * @param {object} [seed.billingAccount]
   * @param {string} [seed.billingAccount.id]
   * @param {object} [seed.billingAccount.status]
   * @param {boolean} [seed.billingAccount.status.isTrial]
   * @param {object} [seed.onDemandBillingMethodDetails]
   * @param {object} [seed.entitlementBillingMethodDetails]
   * @param {object} [seed.flexibleConsumptionBillingMethodDetails]
   * @param {object} [seed.fcp]
   * @param {object} [seed.transitions]
   */
  constructor({
    actions = null,
    billingAccount = null,
    onDemandBillingMethodDetails = null,
    entitlementBillingMethodDetails = null,
    flexibleConsumptionBillingMethodDetails = null,
    fcp = null,
    transitions = null,
  } = {}) {
    this.#actions = actions;
    this.#billingAccount = billingAccount;
    this.#onDemandBillingMethodDetails = onDemandBillingMethodDetails;
    this.#entitlementBillingMethodDetails = entitlementBillingMethodDetails;
    this.#flexibleConsumptionBillingMethodDetails =
      flexibleConsumptionBillingMethodDetails;
    this.#fcp = fcp;
    this.#transitions = transitions;
  }

  get actions() {
    return this.#actions;
  }

  get billingAccount() {
    return this.#billingAccount;
  }

  //#region allowed actions getters

  // These getters are used by service producers
  // to determine which actions related to resources
  // a billing account is allowed to perform.

  /** @type {boolean} */
  get canCreateResource() {
    return this.actions?.includes('ACTION_CREATE');
  }

  /** @type {boolean} */
  get canUseResource() {
    return this.actions?.includes('ACTION_USE');
  }
  //#endregion

  /** @type {BillingStatus} */
  get status() {
    return new BillingStatus(
      this.billingAccount?.status,
      this.billingAccount?.onDemandStatus,
    );
  }

  /** @type {BillingCredits} */
  get credits() {
    // estimatedRemainingCredits is an empty string if not OnDemand or Trial account
    return this.billingAccount?.estimatedRemainingCredits
      ? new BillingCredits(
          this.billingAccount?.estimatedRemainingCredits,
          this.billingAccount?.createdAt,
        )
      : null;
  }

  //#region pricing model getters

  /** @type {string} */
  get pricingModel() {
    return this.billingAccount?.pricingModel;
  }

  get pricingModelTransitions() {
    return this.#transitions;
  }

  /** @type {boolean} */
  get hasConsumptionPayment() {
    return this.pricingModel === PRICING_MODEL.FLEX;
  }

  // Will return true for both Trial and On Demand accounts
  // To check for On Demand only, use hasOnDemandPayment
  /** @type {boolean} */
  get hasTrialOrOnDemandPayment() {
    return this.pricingModel === PRICING_MODEL.PAYG;
  }

  // Both Trial and On Demand accounts will return PRICING_MODEL_PAYG
  // so we also need to check that the On Demand status is not `UNSET`
  // to confirm that it is not a Trial account
  /** @type {boolean} */
  get hasOnDemandPayment() {
    return (
      this.pricingModel === PRICING_MODEL.PAYG && !this.status.isOnDemandUnset
    );
  }

  get recentDowngradeFlexToPAYG() {
    let length = this.pricingModelTransitions?.length;
    // Must be at least two pricing model transitions for a downgrade to have occurred
    if (length <= 1) {
      return false;
    }

    let timeSinceTransition = getTimeSince(
      this.pricingModelTransitions[length - 2].end,
    ).milliseconds;

    return (
      this.pricingModelTransitions[length - 2].pricingModel ===
        PRICING_MODEL.FLEX &&
      this.pricingModelTransitions[length - 1].pricingModel ===
        PRICING_MODEL.PAYG &&
      timeSinceTransition <= sevenDays
    );
  }

  // Contract here refers to an Entitlement contract
  /** @type {boolean} */
  get hasContractPayment() {
    return this.pricingModel === PRICING_MODEL.ENTITLEMENT;
  }
  //#endregion

  //#region credit card getters

  /** @type {object|null} */
  get cardDetails() {
    return this.#onDemandBillingMethodDetails?.cardDetails || null;
  }

  /** @type {boolean} */
  get hasCardError() {
    return this.status?.isDelinquent || this.cardIsExpired;
  }

  /** @type {boolean} */
  get cardIsExpired() {
    return this.cardDetails && isPaymentExpired(this.cardDetails);
  }
  //#endregion

  //#region billing method detail getters

  /** @type {object|null} */
  get billingMethodDetails() {
    return this.#onDemandBillingMethodDetails?.billingMethod || null;
  }

  // We can return the entitlement contract's details from the
  // entitlementBillingMethodDetails for now, but will need to
  // consider the concept of latestEntitlementContract,
  // expired, future, etc. once the BE infrastructure supports this
  /** @type {object|null} */
  get entitlementContractDetails() {
    return this.hasContractPayment
      ? this.#entitlementBillingMethodDetails?.billingMethod
      : null;
  }
  //#endregion

  //#region Flexible Consumption plan getters

  /** @type {object|null} */
  // upcomingContract is the contract starting on some future date.
  // It returns null if there are no upcoming contracts.
  get upcomingContract() {
    return this.#fcp?.upcomingContract || null;
  }

  /** @type {object|null} */
  // latestContract will return the most recent contract that has already started,
  // including expired contracts if they exist.
  get latestContract() {
    return this.#fcp?.latestContract || null;
  }

  /** @type {boolean} */
  // hasExpiredContract will return the existence of expired contract(s), which indicates that the active contract is a renewal.
  get hasExpiredContract() {
    return this.#fcp?.hasExpiredContract;
  }

  /** @type {boolean} */
  get renewalRecentlyActivated() {
    if (
      this.latestContract &&
      this.latestContract.status === BILLING_ACCOUNT_STANDINGS.ACTIVE &&
      this.hasExpiredContract
    ) {
      let timeSinceActivation = getTimeSince(this.latestContract.activeFrom);

      return (
        this.hasConsumptionPayment &&
        timeSinceActivation.milliseconds <= sevenDays
      );
    }

    return false;
  }

  /** @type {boolean} */
  get contractExpiringSoon() {
    if (this.latestContract) {
      let timeUntilExpiration = getTimeUntil(this.latestContract.activeUntil);

      return (
        this.hasConsumptionPayment &&
        timeUntilExpiration.milliseconds <= thirtyDays &&
        timeUntilExpiration.milliseconds > 0
      );
    }

    return false;
  }

  /** @type {boolean} */
  get contractExpired() {
    if (this.latestContract) {
      let timeUntilExpiration = getTimeUntil(this.latestContract.activeUntil);
      return (
        this.hasConsumptionPayment &&
        this.latestContract &&
        timeUntilExpiration.milliseconds <= 0
      );
    }

    return false;
  }

  /** @type {boolean} */
  get expiredDepletedNoRenewal() {
    return (
      this.contractExpired &&
      !this.upcomingContract &&
      this.fcpBalance.estimatedRemainingBalance <= 0
    );
  }

  /** @type {boolean} */
  get depletedNoRenewal() {
    return (
      !this.upcomingContract && this.fcpBalance.estimatedRemainingBalance <= 0
    );
  }

  /** @type {BillingFCPBalance} */
  get fcpBalance() {
    return this.hasConsumptionPayment
      ? new BillingFCPBalance(
          this.latestContract?.flexDetails,
          this.#flexibleConsumptionBillingMethodDetails?.estimatedRemainingBalance,
        )
      : null;
  }
  //#endregion
}
