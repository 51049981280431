import Component from '@glimmer/component';
import { t } from 'ember-intl';

/**
 *
 * `NetworksCidrRecommendations` contain the list of recommendations on selecting an appropriate CIDR block for an HVN.
 *
 *
 * ```
 * <NetworksCidrRecommendations />
 * ```
 *
 * @class NetworksCidrRecommendations
 *
 */

export default class NetworksCidrRecommendations extends Component {
  <template>
    <ul class="bulleted" data-test-network-create-network-cidr-recommendations>
      <li>
        {{t
          "components.networks.cidr-recommendations.no-overlap"
          htmlSafe=true
          provider=@provider
        }}
      </li>
      <li>
        <span>
          {{t
            "components.networks.cidr-recommendations.change-default"
            htmlSafe=true
            provider=@provider
          }}
        </span>
      </li>
      <li>
        <span>
          {{t
            "components.networks.cidr-recommendations.address-space"
            htmlSafe=true
          }}
        </span>
      </li>
      <li>
        {{t "components.networks.cidr-recommendations.first-address"}}
      </li>
      <li>
        <span>
          {{t
            "components.networks.cidr-recommendations.prefix-range"
            htmlSafe=true
          }}
        </span>
      </li>
    </ul>
  </template>
}
