import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type FlashMessagesService from '../../services/flash-messages.ts';

interface SystemNotificationsContractExpirationSignature {
  Args: {
    flash: unknown;
  };
}

export default class SystemNotificationsContractExpirationComponent extends Component<SystemNotificationsContractExpirationSignature> {
  @service declare readonly flashMessages: FlashMessagesService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SystemNotifications::ContractExpiration': typeof SystemNotificationsContractExpirationComponent;
    'system-notifications/contract-expiration': typeof SystemNotificationsContractExpirationComponent;
  }
}
