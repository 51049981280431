/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { getOwner } from '@ember/owner';

import { supportTicketPrefillHref } from 'core/utils/support-ticket';
import IamPolicy from 'core/utils/iam-policy';
import { PROJECT_IAM_ASSIGNMENT } from 'core/utils/consts/analytics-events/platform';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';
import getProjectIamAssignmentTrackingMetadata from 'hcp/utils/iam/get-project-iam-assignment-tracking-metadata';

/**
 *
 * `Page::Users::Project::EditAssignment` is renders a project-level user
 * principal form for editing a project policy.
 *
 *
 * ```
 *   <Page::Users::Project::EditAssignment
 *    @organizationPolicy={{this.model.orgPolicy}}
 *    @project={{this.model.project}}
 *    @projectPolicy={{this.model.projectPolicy}}
 *    @redirectRoute={{this.model.previousRoute}}
 *    @refreshRoute="cloud.access-control.users.detail"
 *    @roles={{this.model.projectRoles}}
 *    @userPrincipal={{this.model.userPrincipal}}
 *   />
 * ```
 *
 * @class Page::Users::Project::EditAssignment
 *
 */

/**
 * The organization policy.
 * @argument organizationPolicy
 * @type {Object}
 */
/**
 * The project.
 * @argument project
 * @type {Object}
 */
/**
 * The project policy.
 * @argument projectPolicy
 * @type {Object}
 */
/**
 * The user principal.
 * @argument userPrincipal
 * @type {Object}
 */
/**
 * The user redirect route.
 * @argument redirectRoute
 * @type {Object}
 */
/**
 * The user refresh route.
 * @argument refreshRoute
 * @type {Object}
 */
/**
 * The api response from fetching organization-level roles.
 * @argument roles
 * @type {Array}
 */

export default class PageProjectUsersEditAssignmentComponent extends Component {
  @service flashMessages;
  @service api;
  @service intl;
  @service userContext;
  @service router;
  @service analytics;

  // eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked editedPolicy = this.args.projectPolicy;

  @tracked projectPolicy;
  @tracked organizationPolicy;

  constructor(owner, args) {
    super(owner, args);

    const { projectPolicy, organizationPolicy } = this.args;

    if (projectPolicy) {
      this.projectPolicy = new IamPolicy(projectPolicy);
    }

    if (organizationPolicy) {
      this.organizationPolicy = new IamPolicy(organizationPolicy);
    }
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @action
  onPolicyChange({ policy }) {
    this.editedPolicy = policy;
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();
    const { redirectRoute } = this.args;

    // We should use the router service for this once this PR is merged.
    // via: https://github.com/emberjs/rfcs/issues/592
    const container = getOwner(this);
    const route = container.lookup(`route:${redirectRoute}`);

    try {
      const policy = this.editedPolicy;
      yield this.api.resourceManager.project.projectServiceSetIamPolicy(
        this.userContext.project.id,
        {
          policy,
        }
      );

      this.flashMessages.success(
        this.intl.t(
          'components.page.users.project.edit-assignment.project-role-updated-successfully'
        )
      );

      const trackingMetadata = getProjectIamAssignmentTrackingMetadata(
        HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
        'edit',
        policy,
        this.args.projectPolicy,
        this.userContext?.project?.id,
        this.args?.userPrincipal?.id
      );

      this.analytics.trackEvent(PROJECT_IAM_ASSIGNMENT, trackingMetadata);

      if (route) {
        // We need to refresh this before transitioning because if this is a
        // child route, the parent route will not call the model hook again when
        // transitioning upward even if data was changed in the child.
        route.refresh();
        this.router.transitionTo(`${redirectRoute}`);
      }
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        // eslint-disable-next-line
        console.error(e);
      }
      this.flashMessages.error(
        this.intl.t(
          'components.page.users.project.edit-assignment.project-role-update-failed'
        ),
        {
          content: this.intl.t(
            'components.page.users.project.edit-assignment.something-went-wrong'
          ),
          linkIcon: 'help',
          linkIconPosition: 'leading',
          linkText: this.intl.t(
            'components.page.users.project.edit-assignment.contact-support'
          ),
          linkUrl: this.supportTicketHref,
        }
      );
    }
  }
}
