import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import Disclosure from 'core/components/disclosure';

/**
 *
 * A collapsing and stackable Disclosure-like UI that renders a title translation string
 * and yields it block content within its collapsible area
 *
 * ```
 * <Networks::Accordion
 *   @label={{label}}
 *   @isIntiallyOpen={{isIntiallyOpen}}
 * />
 * ```
 *
 * @class NetworksAccordion
 *
 */

/**
 * `label` is a optional string that is rendered inside of the <summary> element
 *  as an accordion header.
 * @argument title
 * @type {string}
 */

/**
 * `isIntiallyOpen` is a boolean that sets the accordion to an initial open or closed state on render
 * @argument isIntiallyOpen
 * @type {boolean}
 */

/**
 * `displayBadge` is a boolean that sets the accordion to display a badge
 * @argument displayBadge
 * @type {boolean}
 */

/**
 * `isSuccess` is a boolean that sets the accordion to display a Success badge
 * @argument isSuccess
 * @type {boolean}
 */

/**
 * `isOptional` is a boolean that sets the accordion to display an Optional badge
 * @argument isOptional
 * @type {boolean}
 */

export default class NetworksAccordion extends Component {
  get badge() {
    let { isSuccess, isOptional } = this.args;

    if (isSuccess && isOptional) {
      assert(
        '<Networks::Accordion/> was passed both isSuccess and isOptional as boolean true args, this is invalid',
      );
    }

    if (isSuccess) {
      return {
        text: 'Marked Complete',
        color: 'success',
        icon: 'check',
        size: 'small',
      };
    } else if (isOptional) {
      return {
        text: 'Optional',
        size: 'small',
      };
    } else {
      return null;
    }
  }

  <template>
    <Disclosure
      @isInitiallyOpen={{@isInitiallyOpen}}
      @label={{@label}}
      @openLabel={{@openLabel}}
      @badge={{if @displayBadge this.badge}}
      class="networks-connection-instructions-accordion"
      data-test-networks-connection-accordion
      ...attributes
    >
      {{yield}}
    </Disclosure>
  </template>
}
