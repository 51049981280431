import Service, { inject as service } from '@ember/service';
import { timeout, restartableTask } from 'ember-concurrency';
import { warn } from '@ember/debug';
import { action } from '@ember/object';
import window from 'ember-window-mock';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { isTesting } from '@embroider/macros';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

import Billing from 'billing-common/models/billing';
import { SystemNotifications } from '../utils/constants.ts';
import { PRICING_MODEL } from 'billing-common/constants/billing';
import { BILLING_ACCOUNT_STANDINGS } from 'billing-common/constants/status';

/**
 * Proxies to Billing Account API clients, to aggregate billing model info.
 * This service will replace the deprecated BillingAccountService.
 * NOTE: this service swallows errors and returns `null` empties in failure case.
 * @class BillingService
 */
export default class BillingService extends Service {
  @service api;
  @service abilities;
  @service config;
  @service flashMessages;
  @service router;
  @service userContext;

  constructor() {
    // Used in conjunction with the willDestroy hook to toggle polling.
    super(...arguments);
    document.addEventListener('visibilitychange', this.visibilityChange);
  }

  /**
   * @param {Billing} billing
   * @returns {Function}
   */
  createBillingNotification(billing) {
    return (notificationType, context) => {
      let canEdit = this.abilities.can('edit billing-account');
      let { content } = notificationType;
      return this.flashMessages.systemNotification(
        {
          ...notificationType,
          content: canEdit ? content : `${content}-contributor`,
          ...(canEdit
            ? { onAction: () => this.transitionToAddPaymentMethod(billing) }
            : {}),
        },
        context
      );
    };
  }

  /**
   * @param {string} orgId
   * @param {string} projectId
   * @param {string} country - two letter country code (example: "CA" for Canada); we default to 'US' on org creation
   * @returns {Billing}
   */
  async createDefaultBillingAccount(orgId, projectId, country) {
    const maxRetries = 5;
    try {
      let newDefaultAccount = {
        id: 'default-account',
        name: 'Default Account',
        projectIds: [projectId],
        country,
      };

      await this.createBillingAccount(orgId, newDefaultAccount, maxRetries);

      return this.getBilling(orgId, projectId);
    } catch (error) {
      if (macroCondition(isDevelopingApp())) {
        warn('billing service error', { id: 'service.billing.error' });
        console.error(error); // eslint-disable-line no-console
      }

      // if after 5 retries it still fails, return empty billing object
      // so we don't block page load for the portal
      return new Billing();
    }
  }

  async createBillingAccount(orgId, defaultBillingAccount, maxRetries) {
    for (let i = 0; i < maxRetries; i++) {
      try {
        await this.api.billing.account.billingAccountServiceCreate(
          orgId,
          defaultBillingAccount
        );

        // on success, don't continue retry
        break;
      } catch (error) {
        if (i === maxRetries - 1) {
          Sentry.captureException(error);

          if (macroCondition(isDevelopingApp())) {
            warn('billing service error', { id: 'service.billing.error' });
            console.error(error); // eslint-disable-line no-console
          }
        }
      }
    }
  }

  /**
   * @param {string} orgId
   * @param {string} projectId
   * @returns {Billing}
   */
  async getBilling(orgId, projectId) {
    let account;
    let actions;
    let hasExpiredContract;
    let latestContract;
    let transitions;
    let upcomingContract;
    try {
      account =
        await this.api.billing.account.billingAccountServiceGetByProject(
          orgId,
          projectId
        );

      actions = await this.listAllowedActions(orgId, projectId);

      transitions =
        await this.api.billing.account.billingAccountServiceGetPricingModelTransitions(
          orgId,
          account.billingAccount.id
        );

      // all account types can have an upcoming contract
      upcomingContract = await this.getUpcomingContract(orgId, account);

      if (account.billingAccount.pricingModel === PRICING_MODEL.FLEX) {
        ({ latestContract, hasExpiredContract } = await this.getLatestContract(
          orgId,
          account
        ));
      }

      return new Billing({
        ...account,
        ...actions,
        ...transitions,
        fcp: {
          hasExpiredContract,
          latestContract,
          upcomingContract,
        },
      });
    } catch (error) {
      if (macroCondition(isDevelopingApp())) console.error(error); // eslint-disable-line no-console

      // if after 5 retries it still fails, return empty billing object
      // so we don't block page load for the portal
      return new Billing();
    }
  }

  /**
   * @param {string} orgId
   * @param {string} billingAccountId
   * @returns {Billing}
   */
  async getBillingByAccountId(orgId, billingAccountId) {
    let account;
    let actions;
    let hasExpiredContract;
    let latestContract;
    let transitions;
    let upcomingContract;
    try {
      account = await this.api.billing.account.billingAccountServiceGet(
        orgId,
        billingAccountId
      );

      actions = await this.listAllowedActions(
        orgId,
        account.billingAccount.projectIds[0]
      );

      transitions =
        await this.api.billing.account.billingAccountServiceGetPricingModelTransitions(
          orgId,
          account.billingAccount.id
        );

      // all account types can have an upcoming contract
      upcomingContract = await this.getUpcomingContract(orgId, account);

      if (account.billingAccount.pricingModel === PRICING_MODEL.FLEX) {
        ({ latestContract, hasExpiredContract } = await this.getLatestContract(
          orgId,
          account
        ));
      }

      return new Billing({
        ...account,
        ...actions,
        ...transitions,
        fcp: {
          hasExpiredContract,
          latestContract,
          upcomingContract,
        },
      });
    } catch (error) {
      if (macroCondition(isDevelopingApp())) console.error(error); // eslint-disable-line no-console

      // if after 5 retries it still fails, return empty billing object
      // so we don't block page load for the portal
      return new Billing();
    }
  }

  /**
   * @param {string} orgId
   * @param {string} projectId
   * @param {string} billingAccountId
   * @returns {Billing}
   */
  async addProjectToBillingAccount(orgId, projectId, billingAccountId) {
    try {
      let { billingAccount } = await this.getBillingByAccountId(
        orgId,
        billingAccountId
      );
      billingAccount.projectIds.push(projectId);
      await this.api.billing.account.billingAccountServiceUpdate(
        orgId,
        billingAccountId,
        billingAccount
      );
      return await this.getBillingByAccountId(orgId, billingAccountId);
    } catch (error) {
      return new Billing();
    }
  }

  @restartableTask
  *pollBilling() {
    // use 1-based counter so we can use the counter for exponential backoff
    let errCounter = 1;
    while (true) {
      const { organization, project, projects } = this.userContext;
      const billingProject = project ? project : projects[0];
      const canViewBilling = this.abilities.can('view billing-account');

      if (organization && billingProject && canViewBilling) {
        try {
          this.userContext.billing = yield this.getBilling(
            organization?.id,
            billingProject?.id
          );
          errCounter = 1;
        } catch (e) {
          if (e && e.status === 401) {
            // if a user has been away from the tab long enough that their token
            // expired, we should reload the page so they can be redirected back
            // to sign in
            window.location.reload();
          }
          errCounter = errCounter + 1;
          if (macroCondition(isDevelopingApp())) {
            // eslint-disable-next-line
            console.error(e);
          }
        }
      }

      // When testing and canceling a EC task, a timer will never resolve and
      // cause the test to hang while waiting for a permanently hanging timeout.
      // This condition breaks the test out of that.
      // via: http://ember-concurrency.com/docs/testing-debugging/
      if (isTesting()) return;

      yield timeout(
        this.config?.app?.pollingInterval *
          (this.abilities.can('bill billing-account')
            ? isTesting()
              ? 0
              : 50
            : 1) *
          errCounter
      );
    }
  }

  async listAllowedActions(orgId, projectId) {
    let actions;
    try {
      actions =
        await this.api.billing.account.billingAccountServiceListAllowedActions(
          orgId,
          projectId
        );
    } catch (error) {
      if (macroCondition(isDevelopingApp())) {
        warn('billing service error', {
          id: 'service.billing.error',
        });
        console.error(`failed to fetch listAllowedActions ${error}`); // eslint-disable-line no-console
      } else {
        Sentry.captureException(error);
      }
      // return an empty array so we don't prevent return of the billing object
      actions = [];
    }
    return actions;
  }

  // getLatestContract retrieves a list of active and expired contracts and returns the latest one.
  async getLatestContract(orgId, account) {
    let contracts;
    let latestContract;

    const statusQueryParams = [
      BILLING_ACCOUNT_STANDINGS.ACTIVE,
      BILLING_ACCOUNT_STANDINGS.EXPIRED,
    ];

    ({ contracts } =
      await this.api.billing.contract.contractServiceListContracts(
        orgId,
        account.billingAccount.id,
        statusQueryParams
      ));

    // hasExpiredContract is used to detect a renewal. It does not count the active or upcoming contracts.
    const hasExpiredContract =
      contracts.filter(
        (contract) => contract.status === BILLING_ACCOUNT_STANDINGS.EXPIRED
      ).length > 0;

    if (contracts?.length >= 1) {
      // Only one active contract ever expected.
      latestContract = contracts.find(
        (contract) => contract.status === BILLING_ACCOUNT_STANDINGS.ACTIVE
      );

      if (contracts.length === 1) {
        // May be active or an expired contract.
        latestContract = contracts[0];
      } else {
        latestContract =
          contracts.reduce((a, b) => (a.activeUntil > b.activeUntil ? a : b)) ??
          null;
      }
    }

    return { latestContract, hasExpiredContract };
  }

  async getUpcomingContract(orgId, account) {
    let contracts;
    const statusQueryParams = [BILLING_ACCOUNT_STANDINGS.UPCOMING];

    ({ contracts } =
      await this.api.billing.contract.contractServiceListContracts(
        orgId,
        account.billingAccount.id,
        statusQueryParams
      ));

    if (contracts?.length >= 1) {
      // Only one upcoming contract ever expected.
      return contracts[0];
    } else {
      return null;
    }
  }

  resetNotifications() {
    for (const notification of Object.values(SystemNotifications)) {
      this.flashMessages.remove(notification.id);
    }
  }

  start() {
    warn('billing service starting', { id: 'service.billing.start' });
    this.pollBilling.perform();
  }

  stop() {
    warn('billing service stopping', { id: 'service.billing.stop' });
    this.pollBilling.cancelAll();
  }

  restart() {
    warn('billing service restarting', { id: 'service.billing.restart' });
    this.pollBilling.perform();
  }

  /**
   * @param {Billing} billing
   */
  transitionToAddPaymentMethod(billing) {
    this.router.transitionTo(
      'billing.accounts.account.payments.credit-card',
      billing.billingAccount.id,
      {
        queryParams: { org_id: billing.billingAccount.organizationId },
      }
    );
  }

  /**
   * @param {Billing} billing
   */
  triggerNotifications(billing) {
    let notify;

    this.resetNotifications();

    if (billing?.hasContractPayment || !billing?.billingAccount) {
      return;
    }

    notify = this.createBillingNotification(billing);

    if (billing?.hasConsumptionPayment) {
      let expirationDate = DateTime.fromJSDate(
        billing?.latestContract?.activeUntil
      );

      if (billing?.contractExpiringSoon && !billing?.upcomingContract) {
        notify(SystemNotifications.CONTRACT_EXPIRING, {
          expirationDate: expirationDate.toUTC().toFormat('MMM dd, yyyy'),
        });
      }

      if (billing?.contractExpired && !billing?.upcomingContract) {
        notify(SystemNotifications.CONTRACT_EXPIRED, {
          expirationDate: expirationDate.toUTC().toFormat('MMM dd, yyyy'),
        });
      }

      if (!variation('hcp-ui-billing-flex-2')) {
        if (billing?.fcpBalance?.isLow && !billing?.upcomingContract) {
          // if active account and no credit card on file and no credits remaining
          // show low balance system notification
          notify(SystemNotifications.FCP_LOW_BALANCE, {
            percentage: Math.round(
              billing?.fcpBalance?.percentageRemaining * 100
            ),
          });
        }
        if (billing?.fcpBalance?.isZero && !billing?.upcomingContract) {
          // if active account and no credit card on file and no credits remaining
          // show no payment method system notification
          notify(SystemNotifications.FCP_ZERO_BALANCE);
        }
      }
      return;
    }

    if (billing?.hasOnDemandPayment) {
      if (billing?.hasCardError) {
        notify(SystemNotifications.CARD_ERROR);
        return;
      }
    }

    // if credit card removed
    if (billing?.status?.isActive) {
      if (!billing?.cardDetails) {
        if (billing?.credits?.isLow) {
          // if active account and no credit card on file and no credits remaining
          // show low balance system notification
          notify(SystemNotifications.LOW_BALANCE);
        }
        if (billing?.credits?.isZero) {
          // if active account and no credit card on file and no credits remaining
          // show no payment method system notification
          notify(SystemNotifications.NO_PAYMENT_METHOD);
        }
      }
      return;
    }

    // if trial account and low credits, show low balance system notification
    if (billing?.credits?.isLow) {
      notify(SystemNotifications.LOW_BALANCE);
    }

    // if trial account and zero credits, show zero balance system notification
    if (billing?.credits?.isZero) {
      notify(SystemNotifications.ZERO_BALANCE);
    }
  }

  /**
   * @description An internal method that gets called via lifecycle hooks to
   *    pause polling while the document is hidden.
   */
  @action
  visibilityChange() {
    if (document.hidden) {
      this.stop();
    } else {
      this.start();
    }
  }

  willDestroy() {
    // Used in conjunction with the constructor to toggle polling.
    document.removeEventListener('visibilitychange', this.visibilityChange);
    super.willDestroy(...arguments);
  }
}
