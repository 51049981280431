/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointInputVariableFromJSON, HashicorpCloudWaypointInputVariableToJSON, HashicorpCloudWaypointRefAddOnDefinitionFromJSON, HashicorpCloudWaypointRefAddOnDefinitionToJSON, HashicorpCloudWaypointRefApplicationFromJSON, HashicorpCloudWaypointRefApplicationToJSON, HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceFromJSON, HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceCreateAddOnBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceCreateAddOnBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceCreateAddOnBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceFromJSON(json['namespace']),
        'application': !exists(json, 'application') ? undefined : HashicorpCloudWaypointRefApplicationFromJSON(json['application']),
        'definition': !exists(json, 'definition') ? undefined : HashicorpCloudWaypointRefAddOnDefinitionFromJSON(json['definition']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'variables': !exists(json, 'variables') ? undefined : (json['variables'].map(HashicorpCloudWaypointInputVariableFromJSON)),
    };
}
export function HashicorpCloudWaypointWaypointServiceCreateAddOnBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceToJSON(value.namespace),
        'application': HashicorpCloudWaypointRefApplicationToJSON(value.application),
        'definition': HashicorpCloudWaypointRefAddOnDefinitionToJSON(value.definition),
        'name': value.name,
        'variables': value.variables === undefined ? undefined : (value.variables.map(HashicorpCloudWaypointInputVariableToJSON)),
    };
}
