import ApiService from 'api/services/api';
import { appVersion } from 'hcp/helpers/app-version';
import ENV from 'hcp/config/environment';

ApiService.headers = function () {
  let sha = appVersion(null, { shaOnly: true });
  return {
    'x-portal-version': sha,
    'x-hcp-source-channel': `hcp-portal/${sha}`,
  };
};

ApiService.isRunningAgainstRemoteDev = !!ENV.isRunningAgainstRemoteDev;

export default ApiService;
