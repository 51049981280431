/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128LockOriginFromJSON, Secrets20231128LockOriginToJSON, } from './';
export function Secrets20231128LockStatusFromJSON(json) {
    return Secrets20231128LockStatusFromJSONTyped(json, false);
}
export function Secrets20231128LockStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lockOrigin': !exists(json, 'lock_origin') ? undefined : Secrets20231128LockOriginFromJSON(json['lock_origin']),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}
export function Secrets20231128LockStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lock_origin': Secrets20231128LockOriginToJSON(value.lockOrigin),
        'reason': value.reason,
    };
}
