import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { HashicorpCloudNetwork20200907AzureHopType } from '@clients/cloud-network';
import { CLOUD_RESOURCE_TYPE_I18N_MAPPING } from 'core/helpers/resource-type-display-name';
import { variation } from 'ember-launch-darkly';

import type { IntlService } from 'ember-intl';
import type { HashicorpCloudNetwork20200907HVNRoute } from '@clients/cloud-network';

const I18N_PREFIX = 'helpers.hvn-route-target-type-label';
const HOP_TYPE_TO_TRANSLATION_KEY: Record<
  HashicorpCloudNetwork20200907AzureHopType,
  string
> = Object.freeze({
  [HashicorpCloudNetwork20200907AzureHopType.APPLIANCE]: `${I18N_PREFIX}.appliance`,
  [HashicorpCloudNetwork20200907AzureHopType.NETWORKGATEWAY]: `${I18N_PREFIX}.network-gateway`,
});

/**
 * This helper returns a human-readable display name for a route target.
 *
 * If the route is making use of Azure hub/spoke topology, the label will show the
 * route's Azure next hop type.
 *
 * Otherwise, the label will show the same display value as the resource-type-display-name helper.
 */
export default class HvnRouteTargetTypeLabel extends Helper {
  @service declare readonly intl: IntlService;

  getAzureHubSpokeTargetDisplayName(
    hopType: HashicorpCloudNetwork20200907AzureHopType | undefined
  ): string | undefined {
    if (!hopType || !variation('hcp-ui-azure-hub-spoke')) {
      return;
    }

    const translationKey = HOP_TYPE_TO_TRANSLATION_KEY[hopType];
    if (translationKey) {
      return this.intl.t(translationKey);
    }
  }

  getResourceTypeDisplayName(type: string | undefined = ''): string {
    const translationKey = CLOUD_RESOURCE_TYPE_I18N_MAPPING[type];
    return translationKey ? this.intl.t(translationKey) : type;
  }

  /**
   * @param {array} [args] - 0th element of args must be a route object
   * @returns {string} - i18n translation of an hvn route target type for display
   */
  compute([route]: [HashicorpCloudNetwork20200907HVNRoute]): string {
    const fallback = '';
    if (!route) {
      return fallback;
    }

    // Special handling if this is an Azure route using hub spoke architecture
    const hubSpokeLabel = this.getAzureHubSpokeTargetDisplayName(
      route.azureRoute?.nextHopType
    );
    if (hubSpokeLabel) {
      return hubSpokeLabel;
    }

    // Use standard resource type label for all other routes
    return this.getResourceTypeDisplayName(route.target?.hvnConnection?.type);
  }
}
