import { assert } from '@ember/debug';
import { variation } from 'ember-launch-darkly';

export const BalanceThresholds = [0.05, 0.25, 0.5];

function roundHundredths(decimal) {
  return Math.round(decimal * 100) / 100;
}

export default class BillingFCPBalance {
  #initialAmount;
  #estimatedRemainingBalance;
  #recognizedConsumedAmount;
  #runningTotal;

  /**
   * @param {object} [seed={}]
   * @param {object} [seed.flexDetails]
   * @param {number} [seed.estimatedRemainingBalance]
   */
  constructor(flexDetails = {}, estimatedRemainingBalance = 0) {
    this.#initialAmount = parseFloat(flexDetails?.initialAmount);
    this.#estimatedRemainingBalance = parseFloat(estimatedRemainingBalance);
    this.#recognizedConsumedAmount = parseFloat(
      flexDetails?.recognizedConsumedAmount,
    );
    this.#runningTotal = parseFloat(flexDetails?.runningTotal);

    assert(
      `value: ${this.#initialAmount} must be valid number`,
      typeof this.#initialAmount === 'number' &&
        !Number.isNaN(this.#initialAmount),
    );
    assert(
      `value: ${this.#estimatedRemainingBalance} must be valid number`,
      typeof this.#estimatedRemainingBalance === 'number' &&
        !Number.isNaN(this.#estimatedRemainingBalance),
    );
  }

  get estimatedRemainingBalance() {
    return this.#estimatedRemainingBalance;
  }

  get recognizedConsumedAmount() {
    if (
      this.#recognizedConsumedAmount &&
      !isNaN(this.#recognizedConsumedAmount)
    ) {
      return this.#recognizedConsumedAmount;
    }
    // if recognizedConsumedAmount doesn't exist in consumption pool, default to calculating
    // via the initial amount and estimated remaining balance
    return this.#initialAmount - this.estimatedRemainingBalance;
  }

  get isLow() {
    return this.percentageConsumed >= 0.75 && this.percentageConsumed < 1;
  }

  get isZero() {
    return this.estimatedRemainingBalance <= 0;
  }

  get percentageRemaining() {
    let percentage = 1 - this.percentageConsumed;
    return roundHundredths(percentage);
  }

  get percentageConsumed() {
    //TODO: Leaving this temporarily feature-flagged until https://hashicorp.atlassian.net/browse/HCBV2-4333 is complete, after which point all Flex accounts should
    // have recognizedConsumedAmount populated.
    if (variation('hcp-ui-billing-flex-2')) {
      if (this.recognizedConsumedAmount <= this.#initialAmount) {
        let percentage = Math.abs(
          (this.recognizedConsumedAmount + this.#runningTotal) /
            this.#initialAmount,
        );
        return roundHundredths(percentage);
      }
    } else {
      if (this.estimatedRemainingBalance <= this.#initialAmount) {
        return Math.abs(
          (this.estimatedRemainingBalance - this.#initialAmount) /
            this.#initialAmount,
        );
      }
    }

    return 0;
  }

  // Returns the lowest matching balance threshold or -1 if no balance threshold has been matched.
  get balanceThreshold() {
    let matchedThreshold = -1;

    // Since the thresholds are guaranteed to be sorted in ascending order,
    // we can assume that the first matched threshold is the lowest possible match.
    BalanceThresholds.forEach((threshold) => {
      if (
        this.percentageRemaining > 0 &&
        this.percentageRemaining <= threshold &&
        this.percentageRemaining >= threshold - 0.05 // 5% allowance above threshold. (i.e. 50% matches 45% - 50% remaining / 50% - 55% consumed)
      ) {
        matchedThreshold = threshold;
        return matchedThreshold;
      }
    });

    return matchedThreshold;
  }
}
