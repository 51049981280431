/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVagrant20220930VagrantCloudAccountState;
(function (HashicorpCloudVagrant20220930VagrantCloudAccountState) {
    HashicorpCloudVagrant20220930VagrantCloudAccountState["UNKNOWNORUNSPECIFIED"] = "VAGRANT_CLOUD_ACCOUNT_STATE_UNKNOWN_OR_UNSPECIFIED";
    HashicorpCloudVagrant20220930VagrantCloudAccountState["LINKED"] = "VAGRANT_CLOUD_ACCOUNT_STATE_LINKED";
    HashicorpCloudVagrant20220930VagrantCloudAccountState["UNLINKED"] = "VAGRANT_CLOUD_ACCOUNT_STATE_UNLINKED";
})(HashicorpCloudVagrant20220930VagrantCloudAccountState || (HashicorpCloudVagrant20220930VagrantCloudAccountState = {}));
export function HashicorpCloudVagrant20220930VagrantCloudAccountStateFromJSON(json) {
    return HashicorpCloudVagrant20220930VagrantCloudAccountStateFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930VagrantCloudAccountStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVagrant20220930VagrantCloudAccountStateToJSON(value) {
    return value;
}
