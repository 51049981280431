import Component from '@glimmer/component';
import { or } from 'ember-truth-helpers';

// Components
import { FlightIcon } from '@hashicorp/ember-flight-icons/components';
import Flex from 'core/components/flex';
import { Type } from 'core/utils/consts/card-summary';
import CoreCardSummary from 'core/components/core/card/summary';
import {
  HdsCopyButton,
  HdsLinkStandalone,
  HdsTooltipButton,
} from '@hashicorp/design-system-components/components';
import Time from 'core/components/time';

// Helpers
import { t } from 'ember-intl';
import optionForSourceState from 'core/helpers/option-for-source-state';

// Utils
import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';
import type { HashicorpCloudIamGroup } from '@clients/cloud-iam';

export interface ManageAccessGroupsSummaryCardSignature {
  Element: HTMLElement;
  Args: {
    group: HashicorpCloudIamGroup;
    groupMembersRouteName?: string;
    truncatedResourceName?: string;
  };
  Blocks: {
    default: [];
  };
}

export default class ManageAccessGroupsSummaryCardComponent extends Component<ManageAccessGroupsSummaryCardSignature> {
  <template>
    <CoreCardSummary
      @type={{Type.Horizontal}}
      data-test-group-detail-summary-card
      as |Card|
    >
      <Card.Pair as |P|>
        <P.Key
          data-test-group-detail-summary-card-key
          data-test-group-detail-summary-card-key-type
        >
          {{t "manage-access.components.groups.summary-card.principal-type"}}
        </P.Key>
        <P.Value
          data-test-group-detail-summary-card-value
          data-test-group-detail-summary-card-value-type
        >
          {{#let (optionForSourceState "policy-type" "group") as |option|}}
            <Flex
              @alignItems={{AlignItem.Center}}
              @direction={{FlexDirection.Row}}
              @gap={{FlexGap.Xs}}
            >
              <span>
                {{! @glint-expect-error: fix incoming icon type }}
                <FlightIcon @name={{option.icon}} />
              </span>
              <span>
                {{t option.text}}
              </span>
            </Flex>
          {{/let}}
        </P.Value>
      </Card.Pair>
      <Card.Pair as |P|>
        <P.Key
          data-test-group-detail-summary-card-key
          data-test-group-detail-summary-card-key-members
        >
          {{t "manage-access.components.groups.summary-card.members"}}
        </P.Key>
        <P.Value
          data-test-group-detail-summary-card-value
          data-test-group-detail-summary-card-value-members
        >
          {{#if @groupMembersRouteName}}
            <HdsLinkStandalone
              @icon="arrow-right"
              @iconPosition="trailing"
              @text="{{or @group.memberCount 0}}"
              @route={{@groupMembersRouteName}}
            />
          {{else}}
            {{or @group.memberCount 0}}
          {{/if}}
        </P.Value>
      </Card.Pair>
      <Card.Pair as |P|>
        <P.Key
          data-test-group-detail-summary-card-key
          data-test-group-detail-summary-card-key-created
        >
          {{t "manage-access.components.groups.summary-card.created"}}
        </P.Key>
        <P.Value
          data-test-group-detail-summary-card-value
          data-test-group-detail-summary-card-value-created
        >
          <Time @date={{@group.createdAt}} @display="friendly-only" />
        </P.Value>
      </Card.Pair>
      <Card.Pair as |P|>
        <P.Key
          data-test-group-detail-summary-card-key
          data-test-group-detail-summary-card-key-resource-name
        >
          {{t "manage-access.components.groups.summary-card.resource-name"}}
        </P.Key>
        <P.Value
          data-test-group-detail-summary-card-value
          data-test-group-detail-summary-card-value-resource-name
        >
          {{#if @group.resourceName}}
            <Flex
              @alignItems={{AlignItem.Center}}
              @direction={{FlexDirection.Row}}
              @gap={{FlexGap.Xxs}}
            >
              {{@truncatedResourceName}}
              <HdsTooltipButton
                @text={{t
                  "manage-access.components.groups.summary-card.tooltip.text"
                  resourceName=@group.resourceName
                  htmlSafe=true
                }}
                aria-label={{t
                  "manage-access.components.groups.summary-card.tooltip.aria-label"
                }}
              >
                <FlightIcon @name="info" />
              </HdsTooltipButton>
              <HdsCopyButton
                @isIconOnly={{true}}
                @size="small"
                @text={{t
                  "manage-access.components.groups.summary-card.resource-name"
                }}
                @textToCopy={{@group.resourceName}}
              />
            </Flex>
          {{else}}
            —
          {{/if}}
        </P.Value>
      </Card.Pair>
    </CoreCardSummary>
  </template>
}
