function redactUrl(value) {
  return value.replace(/login\/callback.+/, 'login/callback/');
}

function redactObject(objectToRedact) {
  return Object.entries(objectToRedact).reduce((acc, [key, value]) => {
    const redactedValue =
      key.includes('url') && typeof value === 'string'
        ? redactUrl(value)
        : value;
    acc[redactUrl(key)] = redactedValue;
    return acc;
  }, {});
}
// this function is used in PostHog's before_send hook to redact information
// from the events that get sent to PostHog servers
//
// currently the main thing we redact is params from the login/callback URL.
// any additional URL sanitization needed could be added in the redactUrl function above.
export function redactEvent(event) {
  if (!event) {
    return null;
  }

  const redactedProperties = redactObject(event.properties || {});
  event.properties = redactedProperties;
  if (event.properties.$initial_person_info) {
    event.properties.$initial_person_info = {
      ...event.properties.$initial_person_info,
      u: redactUrl(event.properties.$initial_person_info.u),
    };
  }

  if (event.event === '$$heatmap') {
    // $heatmap data is keyed by url
    event.properties.$heatmap_data = redactObject(
      event.properties.$heatmap_data || {}
    );
  }

  const redactedSet = redactObject(event.$set || {});
  event.$set = redactedSet;

  const redactedSetOnce = redactObject(event.$set_once || {});
  event.$set_once = redactedSetOnce;

  return event;
}
