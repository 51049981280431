/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var GatewayVersionStatus;
(function (GatewayVersionStatus) {
    GatewayVersionStatus["SUPPORTED"] = "VERSION_SUPPORTED";
    GatewayVersionStatus["DEPRECATED"] = "VERSION_DEPRECATED";
    GatewayVersionStatus["OUTOFSUPPORT"] = "VERSION_OUT_OF_SUPPORT";
})(GatewayVersionStatus || (GatewayVersionStatus = {}));
export function GatewayVersionStatusFromJSON(json) {
    return GatewayVersionStatusFromJSONTyped(json, false);
}
export function GatewayVersionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function GatewayVersionStatusToJSON(value) {
    return value;
}
