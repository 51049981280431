/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128AwsIAMUserAccessKeyParamsFromJSON, Secrets20231128AwsIAMUserAccessKeyParamsToJSON, Secrets20231128AzureApplicationPasswordParamsFromJSON, Secrets20231128AzureApplicationPasswordParamsToJSON, Secrets20231128ConfluentCloudApiKeyParamsFromJSON, Secrets20231128ConfluentCloudApiKeyParamsToJSON, Secrets20231128GcpServiceAccountKeyParamsFromJSON, Secrets20231128GcpServiceAccountKeyParamsToJSON, Secrets20231128MongoDBAtlasSecretDetailsFromJSON, Secrets20231128MongoDBAtlasSecretDetailsToJSON, Secrets20231128MssqlUserPasswordDetailsFromJSON, Secrets20231128MssqlUserPasswordDetailsToJSON, Secrets20231128MysqlUserPasswordDetailsFromJSON, Secrets20231128MysqlUserPasswordDetailsToJSON, Secrets20231128PostgresParamsFromJSON, Secrets20231128PostgresParamsToJSON, Secrets20231128RandomParamsFromJSON, Secrets20231128RandomParamsToJSON, } from './';
export function Secrets20231128RotatingSecretFromJSON(json) {
    return Secrets20231128RotatingSecretFromJSONTyped(json, false);
}
export function Secrets20231128RotatingSecretFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'integrationName': !exists(json, 'integration_name') ? undefined : json['integration_name'],
        'rotationPolicyName': !exists(json, 'rotation_policy_name') ? undefined : json['rotation_policy_name'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'updatedById': !exists(json, 'updated_by_id') ? undefined : json['updated_by_id'],
        'awsAccessKeyDetails': !exists(json, 'aws_access_key_details') ? undefined : Secrets20231128AwsIAMUserAccessKeyParamsFromJSON(json['aws_access_key_details']),
        'azureApplicationPasswordDetails': !exists(json, 'azure_application_password_details') ? undefined : Secrets20231128AzureApplicationPasswordParamsFromJSON(json['azure_application_password_details']),
        'confluentCloudApiKeyDetails': !exists(json, 'confluent_cloud_api_key_details') ? undefined : Secrets20231128ConfluentCloudApiKeyParamsFromJSON(json['confluent_cloud_api_key_details']),
        'gcpServiceAccountKeyDetails': !exists(json, 'gcp_service_account_key_details') ? undefined : Secrets20231128GcpServiceAccountKeyParamsFromJSON(json['gcp_service_account_key_details']),
        'mongodbAtlasUserPasswordDetails': !exists(json, 'mongodb_atlas_user_password_details') ? undefined : Secrets20231128MongoDBAtlasSecretDetailsFromJSON(json['mongodb_atlas_user_password_details']),
        'postgresUserPasswordDetails': !exists(json, 'postgres_user_password_details') ? undefined : Secrets20231128PostgresParamsFromJSON(json['postgres_user_password_details']),
        'randomUserPasswordDetails': !exists(json, 'random_user_password_details') ? undefined : Secrets20231128RandomParamsFromJSON(json['random_user_password_details']),
        'twilioApiKeyDetails': !exists(json, 'twilio_api_key_details') ? undefined : json['twilio_api_key_details'],
        'mysqlUserPasswordDetails': !exists(json, 'mysql_user_password_details') ? undefined : Secrets20231128MysqlUserPasswordDetailsFromJSON(json['mysql_user_password_details']),
        'mssqlUserPasswordDetails': !exists(json, 'mssql_user_password_details') ? undefined : Secrets20231128MssqlUserPasswordDetailsFromJSON(json['mssql_user_password_details']),
    };
}
export function Secrets20231128RotatingSecretToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'provider': value.provider,
        'integration_name': value.integrationName,
        'rotation_policy_name': value.rotationPolicyName,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by_id': value.createdById,
        'updated_by_id': value.updatedById,
        'aws_access_key_details': Secrets20231128AwsIAMUserAccessKeyParamsToJSON(value.awsAccessKeyDetails),
        'azure_application_password_details': Secrets20231128AzureApplicationPasswordParamsToJSON(value.azureApplicationPasswordDetails),
        'confluent_cloud_api_key_details': Secrets20231128ConfluentCloudApiKeyParamsToJSON(value.confluentCloudApiKeyDetails),
        'gcp_service_account_key_details': Secrets20231128GcpServiceAccountKeyParamsToJSON(value.gcpServiceAccountKeyDetails),
        'mongodb_atlas_user_password_details': Secrets20231128MongoDBAtlasSecretDetailsToJSON(value.mongodbAtlasUserPasswordDetails),
        'postgres_user_password_details': Secrets20231128PostgresParamsToJSON(value.postgresUserPasswordDetails),
        'random_user_password_details': Secrets20231128RandomParamsToJSON(value.randomUserPasswordDetails),
        'twilio_api_key_details': value.twilioApiKeyDetails,
        'mysql_user_password_details': Secrets20231128MysqlUserPasswordDetailsToJSON(value.mysqlUserPasswordDetails),
        'mssql_user_password_details': Secrets20231128MssqlUserPasswordDetailsToJSON(value.mssqlUserPasswordDetails),
    };
}
