/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceFromJSON, HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceStartingActionBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceStartingActionBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceStartingActionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceFromJSON(json['namespace']),
        'actionRunId': !exists(json, 'action_run_id') ? undefined : json['action_run_id'],
        'groupName': !exists(json, 'group_name') ? undefined : json['group_name'],
    };
}
export function HashicorpCloudWaypointWaypointServiceStartingActionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceToJSON(value.namespace),
        'action_run_id': value.actionRunId,
        'group_name': value.groupName,
    };
}
