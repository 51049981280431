import { bind } from '@ember/runloop';
import { getOwner } from '@ember/owner';
import objectsAreEqual from 'ember-simple-auth/utils/objects-are-equal';
import { registerDestructor } from '@ember/destroyable';

import BaseStore from 'ember-simple-auth/session-stores/base';
import setCookie from 'hcp/utils/set-access-token-cookie';

export const SESSION_STORAGE_KEY = 'hcp-auth-session';
import {
  AUTH_CHANNEL_NAME,
  AUTH_CHANNEL_EVENTS,
  DENY_LIST,
} from '../utils/constants';
import { PRIMARY_IDENTITY, CONNECTION_STRATEGY } from '../authenticators/auth0';
const UPSTREAM_IDP_CLAIM = 'https://auth.hashicorp.com/idp';

import { task, race, timeout, waitForProperty } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

/**
 * `HCPStore` extends the ember-simple-auth (ESA) BaseStore.
 *  It customizes ESA's default session store.
 *  This allows us to customize the store and keep the data completely in memory.
 *  Before this, we kept data in the HybridStore which is a mix of putting data
 *  in localStorage and in-memory.
 *
 *  For now, this custom in-memory Store is behind a feature flag and we have preserved
 *  the existing localStorage Store logic when this feature flag is turned-off.
 *
 *  Once we confirm that our custom in-memory Store is in a stable state, we will
 *  remove the feature flag and remove all previous Store logic and our
 *  custom in-memory Store will be the main actor ✨ .
 *
 *  But wait there's more.
 *
 *  In the future there are plans to change this to Cookie Store 🍪.
 *  HCPIE-392 https://hashicorp.atlassian.net/browse/HCPIE-392
 *
 *  References:
 *  ESA Session Store types: https://github.com/mainmatter/ember-simple-auth#session-stores
 *  HCP-409 RFC: https://docs.google.com/document/d/1tbL3zdZCCgotJJsImNuZlEDJp9n970PCQ2U5uglNdVs/edit
 *
 * @class HCPStore
 * @module hcp/app/session-stores/application
 * @extends BaseStore
 */

export default class HCPStore extends BaseStore {
  get config() {
    return getOwner(this)?.lookup('service:config');
  }

  /**
   * data is updated in authenticator:cloud-idp restore() after a user is successfully authenticated
   * but it's initial value is empty.
   */
  @tracked data = { authenticated: {} };

  setCookie() {
    setCookie(...arguments);
  }

  /**
   * The localStorage key the store persists data in.
   * Here we are overriding the ember-silent-auth's default value `ember_simple_auth-session`
   * with the value of `SESSION_STORAGE_KEY`.
   *
   * @property key
   * @type String
   *
   * */
  key = SESSION_STORAGE_KEY;

  /**
   * HCPStore uses BroadcastChannels to communicate between browser tabs.
   * https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
   * @property channel
   * @type Object
   */
  channel = new BroadcastChannel(AUTH_CHANNEL_NAME);

  setupCalled = false;

  // we're doing this instead of a `constructor` because the config won't be defined
  // yet - calling setupHandlers() later allows time for the config service to be setup
  setupHandlers() {
    if (this.setupCalled) return;
    if (this.isDevOrTest()) {
      return;
    }
    // set up broadcast channel event handlers here
    // eslint-disable-next-line ember/no-runloop
    this.boundHandleBroadcastMessage = bind(this, this.handleBroadcastMessage);
    this.addEventListeners();
    registerDestructor(this, () => {
      this.removeEventListeners();
    });
    this.setupCalled = true;
  }

  /**
   * 'handleBroadcastMessage' is triggered when the Broadcast Channel message event is fired
   * (https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel/message_event).
   *
   * It then fires off code based on the event.data.type.
   *
   * There are three types that we are listening for:
   * RESTORE_SESSION, SYNC_SESSION and INVALIDATE_SESSION.
   *
   * @method handleBroadcastMessage
   * @param {Object} event
   */
  handleBroadcastMessage(event) {
    /**
     * Because `restore` is called by ember-simple-auth on application startup,
     * any time a new browser tab is opened we need to
     * listen for the data on RESTORE_SESSION shared via BroadcastChannel
     * (https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel), so
     * that we can avoid making auth requests on every `restore`. If we were to
     * auth on every `restore`, ember-simple-auth puts the app into an infinite
     * loop (until we're logged out).
     */
    if (event.data?.type === AUTH_CHANNEL_EVENTS.RESTORE_SESSION) {
      this.syncData();
    }

    /**
     * If the sessionStorage data has changed, SYNC_SESSION postMessage is sent in this.persist().
     * Listening for SYNC_SESSION skips the application having to go through persist() again
     */
    if (event.data?.type === AUTH_CHANNEL_EVENTS.SYNC_SESSION) {
      this.data = event.data?.payload?.data;
      this.trigger('sessionDataUpdated', this.data);
    }
    /**
     * INVALIDATE_SESSION postMessage is sent in authenticator:cloud-idp invalidate()
     */
    if (event.data?.type === AUTH_CHANNEL_EVENTS.INVALIDATE_SESSION) {
      this.data = { authenticated: {} };
      this.trigger('sessionDataUpdated', this.data);
    }
  }

  addEventListeners() {
    this.channel.addEventListener('message', this.boundHandleBroadcastMessage);
  }

  removeEventListeners() {
    this.channel.close();
    this.channel.removeEventListener(
      'message',
      this.boundHandleBroadcastMessage
    );
  }

  @task
  *waitForSession() {
    // anytime accessToken is set on this.data?.authenticated session object
    yield waitForProperty(this.data, 'authenticated.accessToken');
  }

  @task
  *timeout() {
    yield timeout(50);
  }

  async getUser(client, accessToken) {
    let user = await client.getUserInfo(accessToken);
    return {
      ...user,
      isSSO:
        user[CONNECTION_STRATEGY] === 'samlp' ||
        user[UPSTREAM_IDP_CLAIM] === 'oidc',
      primaryIdentity: user[PRIMARY_IDENTITY],
    };
  }

  get authenticator() {
    return getOwner(this).lookup(`authenticator:cloud-idp`);
  }

  isDevOrTest() {
    if (this.config?.environment === 'test') {
      return true;
    }
    if (this.config?.environment === 'development') {
      // if we're running against remote dev
      // this will be the value for baseServiceHost
      if (this.config?.app?.baseServiceHost === 'https://api.hcp.dev') {
        return false;
      } else {
        return true;
      }
    }
  }
  /**
   * restore() customizes ESA's default restore().
   * It is called after authenticator:cloud-idp restore() sends over this.data.
   * It checks if the application is in an iframe and if so - fix this to describe what is happening.
   * If not in an iframe, it sends a broadcast channel RESTORE_SESSION message
   * If the user was already authenticated and has the application is already opened in another web browser,
   * it just returns the sessionStorage Store (this.data)
   * However, if the users is going through sign-in it checks for authentication
   * and returns the sessionStorage Store object
   *
   * authenticated: {
   *   authenticator: 'authenticator:cloud-idp',
   *  accessToken: tokens.access_token,
   *  idToken: tokens.id_token,
   *  user,
   * }
   * @method restore
   * @returns Object
   */

  async restore(force = false) {
    this.setupHandlers();
    if (this.isDevOrTest()) {
      return;
    }
    // IMPORTANT: call this before anythihng else to ensure the iframe errors bubble properly
    let client = this.authenticator.client;
    await client.waitUntilReady();
    // we want to return early on the callback route or if we're in the iframe so that we don't do a full
    // auth _and_ a silent auth or
    // if the pathname includes whatever is in the DENY_LIST array then do return Promise.reject
    const shouldExcludedFromSilentAuth = DENY_LIST.some((path) =>
      location.pathname.startsWith(path)
    );

    if (shouldExcludedFromSilentAuth && force === false) {
      return Promise.reject();
    }
    // never do silent auth in an iframe
    if (window.self !== window.top) {
      return Promise.reject();
    }

    // if we're forcing a refresh, then we should always do a full auth, not get the token from other tabs
    if (force === false) {
      this.channel.postMessage({ type: AUTH_CHANNEL_EVENTS.RESTORE_SESSION });
      /**
       * Wait for either the accessToken to be set or for a timeout.
       * Whichever ones finishes first wins.
       */
      await race([this.waitForSession.perform(), this.timeout.perform()]);
      if (this.data?.authenticated?.accessToken) {
        /**
         * If a user is authenticated in another tab, just return this.data
         * instead of making calls outside of this block
         */
        return this.data;
      }
    }

    const tokens = await client.getTokensSilently({
      silent_auth: true,
    });
    const user = await this.getUser(client, tokens.access_token);
    return {
      authenticated: {
        authenticator: 'authenticator:cloud-idp',
        accessToken: tokens.access_token,
        idToken: tokens.id_token,
        user,
      },
    };
  }

  syncData() {
    this.channel.postMessage({
      type: AUTH_CHANNEL_EVENTS.SYNC_SESSION,
      payload: { data: this.data },
    });
  }
  /**
   * `persist()` checkeither syncs all of the sessionStorage Store data in each tab or sets the sessionStorage data
   * @method persist
   * @param {*} data
   * @returns
   */
  async persist(data) {
    if (!objectsAreEqual(data, this.data)) {
      // set session data on the instance
      this.data = data;
      this.setCookie(this.data?.authenticated?.accessToken);
      //only fire when the data changes
      this.syncData();
      this.trigger('sessionDataUpdated', data);
    }
    return this.data;
  }

  async clear() {
    localStorage.removeItem(this.key);
    this._lastData = {};
    return;
  }

  async _handleStorageEvent(e) {
    if (e.key === this.key) {
      const data = await this.restore();
      if (!objectsAreEqual(data, this._lastData)) {
        this._lastData = data;
        this.trigger('sessionDataUpdated', data);
      }
    }
  }
}
