import { inject as service } from '@ember/service';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { variation } from 'ember-launch-darkly';

import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

import IamPolicy from 'core/utils/iam-policy';
import PaginatedRoute from 'core/routes/paginated-route';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';
import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import findRoleLocationsFromPolicies from 'hcp/utils/iam/find-role-locations-from-policies';

export default class CloudAccessControlUsersListRoute extends PaginatedRoute {
  @service api;
  @service abilities;
  @service userContext;

  // WARNING: A @permissionGuard should not be used here. Users will not always
  // have iam permissions to query the user list but the list call should
  // be available to all users, even users with no role.
  async model({ pageSize, nextPageToken, previousPageToken }) {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    let { organization, project } = this.userContext;
    let batchPrincipals = [];

    let { userPrincipals, pagination } =
      await this.api.iam.iamServiceListUserPrincipalsByOrganization(
        organization.id,
        pageSize,
        nextPageToken,
        previousPageToken
      );

    if (variation('hcp-ui-group-permission-assignments-enabled')) {
      ({ principals: batchPrincipals } =
        await this.api.iam.iamServiceBatchGetPrincipals(
          organization.id,
          userPrincipals.map((userPrincipal) => userPrincipal.id),
          'PRINCIPAL_VIEW_FULL'
        ));
    }

    let projectRoles = [];
    let organizationRoles = [];

    if (this.abilities.can('list roles')) {
      if (isProjectContext) {
        const resourceName = `project/${project.id}`;
        const fetchAllRoles = fetchUntilEmpty(
          (...args) =>
            this.api.resourceManager.resources.resourceServiceListRoles(
              ...args
            ),
          'roles'
        );
        ({ roles: projectRoles } = await fetchAllRoles(
          resourceName,
          undefined,
          100
        ));

        const denyListedRoles = variation(
          'hcp-ui-fine-grained-blacklisted-roles'
        );
        projectRoles = filterBlacklistedRoles(projectRoles, denyListedRoles);
      } else {
        const fetchAllRoles = fetchUntilEmpty(
          (...args) =>
            this.api.resourceManager.org.organizationServiceListRoles(...args),
          'roles'
        );
        ({ roles: organizationRoles } = await fetchAllRoles(organization.id));

        const denyListedRoles = variation(
          'hcp-ui-fine-grained-blacklisted-roles'
        );
        organizationRoles = filterBlacklistedRoles(
          organizationRoles,
          denyListedRoles
        );
      }
    }

    let OrgPolicy;
    let orgPolicyResponse;

    let ProjectPolicy;
    let projectPolicyResponse;

    let policies = [];
    let scimEnabled = false;

    if (variation('hcp-identity-scim-enabled')) {
      try {
        ({ isEnabled: scimEnabled } =
          await this.api.scim.scimServiceIsScimEnabled(organization.id));
      } catch (e) {
        if (macroCondition(isDevelopingApp())) {
          //eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }

    // We have to wrap this in a try/catch because the call may fail based on
    // the user's RBAC role. If the user is not an owner, admin, or contributor,
    // then this call will fail. If this call fails then we'll use the absence
    // of the policy as a way to render different things in the list.
    try {
      ({ policy: orgPolicyResponse } =
        await this.api.resourceManager.org.organizationServiceGetIamPolicy(
          organization.id
        ));

      // If we have a policy, instantiate a IamPolicy class to get roles later.
      OrgPolicy = orgPolicyResponse
        ? new IamPolicy(orgPolicyResponse)
        : undefined;

      // Push the new policy into the policies array for determining inheritance
      // toward the end of the model hook.
      if (organization && OrgPolicy) {
        policies.push({
          link: {
            id: organization.id,
            name: organization.name,
            type: TYPE_ORGANIZATION,
          },
          policy: orgPolicyResponse,
        });
      }
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
    }

    if (isProjectContext) {
      // We have to wrap this in a try/catch because the call may fail based on
      // the user's RBAC role. If the user is not an owner, admin, or contributor,
      // then this call will fail. If this call fails then we'll use the absence
      // of the policy as a way to render different things in the list.
      try {
        ({ policy: projectPolicyResponse } =
          await this.api.resourceManager.project.projectServiceGetIamPolicy(
            project.id
          ));

        ProjectPolicy = projectPolicyResponse
          ? new IamPolicy(projectPolicyResponse)
          : undefined;

        // Push the new policy into the policies array for determining inheritance
        // toward the end of the model hook.
        if (project && ProjectPolicy) {
          policies.push({
            link: {
              id: project.id,
              name: project.name,
              type: TYPE_PROJECT,
            },
            policy: projectPolicyResponse,
          });
        }
      } catch (e) {
        if (macroCondition(isDevelopingApp())) {
          //eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }

    userPrincipals = await Promise.all(
      userPrincipals.map(async (userPrincipal) => {
        let groups = [];
        let groupIds = [];

        if (
          variation('hcp-ui-group-permission-assignments-enabled') &&
          Array.isArray(batchPrincipals) &&
          batchPrincipals.length
        ) {
          // Find the first batch principal by id. This object should have the
          // `groupIds` key attached because we queried the FULL principal type.
          ({ groupIds = [] } =
            batchPrincipals.find((p) => {
              return p.id == userPrincipal.id;
            }) ?? {});
        }

        if (
          variation('hcp-ui-group-permission-assignments-enabled') &&
          groupIds.length
        ) {
          // Now let's query every group that is attached to this user so that
          // we know the metadata about the group.
          const fetchAllGroups = fetchUntilEmpty(
            (...args) => this.api.groups.groupsServiceListGroups(...args),
            'groups'
          );
          ({ groups } = await fetchAllGroups(
            `organization/${organization.id}`,
            undefined,
            groupIds,
            100
          ));
        }

        const roleLocations = findRoleLocationsFromPolicies({
          principalIds: [
            userPrincipal.id,
            ...(variation('hcp-ui-group-permission-assignments-enabled')
              ? groupIds
              : []),
          ],
          groups,
          roles: isProjectContext ? projectRoles : organizationRoles,
          policies,
          userPrincipal,
        });

        const hasOrganizationRole = roleLocations.some((roleLocation) => {
          return roleLocation.link.type === TYPE_ORGANIZATION;
        });

        // Push a "No role" organization role for display purposes only. We
        // do this only here in the list route because the detail route has a
        // zero-state to handle this and also to keep the
        // findRoleLocationsFromPolicies function return pure.
        if (orgPolicyResponse && !hasOrganizationRole) {
          roleLocations.push({
            link: policies.find((p) => p.link.type === TYPE_ORGANIZATION)?.link,
            member: {
              memberId: userPrincipal.id,
              memberType:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
            // This renders an emdash. We could potentially add an i18n string
            // here to display "No organization role"
            roleId: undefined,
            userPrincipal,
          });
        }

        return {
          ...userPrincipal,
          roleLocations,
        };
      })
    );

    return {
      isProjectContext,
      organization,
      organizationRoles,
      orgPolicy: OrgPolicy,
      project,
      projectPolicy: ProjectPolicy,
      pagination,
      scimEnabled,
      userPrincipals,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetPagination();
    }
  }
}
