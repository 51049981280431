import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';

export default class CostEstimatorQuery extends Component {
  @service api;

  /** @type {Object} */
  @tracked error;

  /** @type {Object} */
  @tracked estimate;

  constructor(owner, args) {
    super(owner, args);
    this.query.perform();
  }

  get req() {
    let {
      customizedInput = [],
      network,
      packageItemSelection = [],
      packageKey,
    } = this.args;
    return {
      organizationId: network?.location?.organizationId,
      body: {
        projectId: network?.location?.projectId,
        region: network?.location?.region,
        packageItemSelection: Array.isArray(packageItemSelection)
          ? packageItemSelection
          : [packageItemSelection],
        packageKey,
        customizedInput: Array.isArray(customizedInput)
          ? customizedInput
          : [customizedInput],
      },
    };
  }

  query = task(async () => {
    try {
      this.estimate = await this.api.billing.product.productServiceEstimateCost(
        this.req.organizationId,
        this.req.body,
      );
      this.error = null;
    } catch (e) {
      this.estimate = null;
      this.error = e;
    }
  });

  <template>
    <noscript {{didUpdate this.query.perform this.req}}></noscript>
    {{yield this.estimate this.error this.query}}
  </template>
}
