/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128AzureClientSecretRequestFromJSON, Secrets20231128AzureClientSecretRequestToJSON, Secrets20231128AzureFederatedWorkloadIdentityRequestFromJSON, Secrets20231128AzureFederatedWorkloadIdentityRequestToJSON, Secrets20231128CapabilityFromJSON, Secrets20231128CapabilityToJSON, } from './';
export function SecretServiceCreateAzureIntegrationBodyFromJSON(json) {
    return SecretServiceCreateAzureIntegrationBodyFromJSONTyped(json, false);
}
export function SecretServiceCreateAzureIntegrationBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'federatedWorkloadIdentity': !exists(json, 'federated_workload_identity') ? undefined : Secrets20231128AzureFederatedWorkloadIdentityRequestFromJSON(json['federated_workload_identity']),
        'clientSecret': !exists(json, 'client_secret') ? undefined : Secrets20231128AzureClientSecretRequestFromJSON(json['client_secret']),
        'capabilities': !exists(json, 'capabilities') ? undefined : (json['capabilities'].map(Secrets20231128CapabilityFromJSON)),
    };
}
export function SecretServiceCreateAzureIntegrationBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'federated_workload_identity': Secrets20231128AzureFederatedWorkloadIdentityRequestToJSON(value.federatedWorkloadIdentity),
        'client_secret': Secrets20231128AzureClientSecretRequestToJSON(value.clientSecret),
        'capabilities': value.capabilities === undefined ? undefined : (value.capabilities.map(Secrets20231128CapabilityToJSON)),
    };
}
