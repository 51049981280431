import Component from '@glimmer/component';
import { get } from '@ember/helper';
import { eq, or, not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import Typography from 'core/components/typography';
import boxPadding from 'core/modifiers/box-padding';

import NetworksAccordion from './accordion.gjs';
import { PEERINGS_ALERTS } from '../../utils/peerings.js';
import { TGW_ATTACHMENTS_ALERTS } from '../../utils/tgw-attachments.js';
import './connection-instructions-accordion.scss';

/**
 *
 * `ConnectionInstructionsAccordion` renders a details element and message.
 *
 * Additional content can be passed in and rendered under the accordion message.
 *
 *
 * ```
 * <Networks::ConnectionInstructionsAccordion
 *   @connection={{@model.peering}}
 *   @id='instructions'
 *   @isInitiallyOpen={{true}}
 * >
 *   Your fancy content can go here
 * </Networks::ConnectionInstructionsAccordion>
 * ```
 *
 * This component has been customized so that network connection instructions
 * for either a Peering Connection or a TGW Attachment are rendered in an
 * accordion in the UI. Each connection is specific to a provider which is
 * currently AWS or Azure.
 *
 * The first and second keys specify the provider (`aws` or `azure`). The
 * proceeding keys within each of these are named to match connection API
 * response values for possible states (ie: `EXPIRED, FAILED,
 * PENDING_ACCEPTANCE`). In order for this component to render content, each
 * connection must include an `accordion` key. At this time, this component only
 * renders content when connection state is `PENDING, PENDING_ACCEPTANCE, or
 * ACTIVE`.
 *
 *
 * @class NetworksConnectionInstructionsAccordion
 *
 */

/**
 * `connection` is an object for a connection type that comes from the
 * cloud-network API.  It can either be a Peering or TGW Attachment.
 *
 * @argument connection
 * @type {object}
 */

/**
 * `id` is a required string that is necessary to open or close `<details />`.
 * It can also serve as an anchor target. For example, if there is a hyperlink
 * in a banner, a user can set href='#instructions' and it will take a user to
 * the instructions if this value is set to 'instructions'.
 *
 * @argument id
 * @type {string}
 */

/**
 * `isInitiallyOpen` is a boolean that sets the accordion open or closed on render.
 * @argument isInitiallyOpen
 * @type {boolean}
 */

export default class NetworksConnectionInstructionsAccordion extends Component {
  get provider() {
    let { connection } = this.args;
    return connection?.hvn?.location?.region?.provider;
  }

  // Returns a set of alerts based on the type of connection target.
  get alerts() {
    let { connection } = this.args;
    if (connection?.target?.awsTarget || connection?.target?.azureTarget) {
      return PEERINGS_ALERTS;
    }
    if (connection?.providerTgwAttachmentId) {
      return TGW_ATTACHMENTS_ALERTS;
    }
    return null;
  }

  <template>
    {{#if
      (not
        (or
          (eq @connection.state "FAILED")
          (eq @connection.state "REJECTED")
          (eq @connection.state "DELETING")
          (eq @connection.state "EXPIRED")
          (not @id)
        )
      )
    }}
      {{#let (get this.alerts this.provider) as |alerts|}}
        {{#let (get alerts @connection.state) as |alert|}}
          {{#if alert}}
            <NetworksAccordion
              id={{@id}}
              @label={{t alert.accordion.title}}
              @isInitiallyOpen={{@isInitiallyOpen}}
              data-test-networks-connection-instructions-accordion
            >
              <div {{boxPadding "0 0 md 0"}}>
                <Typography
                  class="hds-typography-body-200 hds-font-weight-regular hds-foreground-primary"
                  data-test-networks-connection-instructions-accordion-message
                >
                  {{t alert.accordion.message}}
                </Typography>
              </div>
              {{yield}}
              {{! TODO:  HCPE-1635 Add Confirm completion button  }}
              {{!-- <div role='aria-button' {{on 'toggle' this.setOpen}}>Confirm completion</div> --}}
            </NetworksAccordion>
          {{/if}}
        {{/let}}
      {{/let}}
    {{/if}}
  </template>
}
