import { t } from 'ember-intl';
import { and, not } from 'ember-truth-helpers';

import './footer.scss';

<template>
  <div
    class="cost-estimator-footer"
    ...attributes
    data-test-cost-estimator-footer-container
  >
    {{#if (has-block "controls")}}
      <div
        class="cost-estimator-footer__controls"
        data-test-cost-estimator-footer-controls
      >
        {{yield to="controls"}}
      </div>
      <div
        class="cost-estimator-footer__message"
        data-test-cost-estimator-footer-message
      >
        {{#if (has-block "message")}}
          {{yield to="message"}}
        {{else}}
          <p>
            {{t "billing-common.cost-estimator.footer.pay-as-you-go"}}
          </p>
          <p>
            {{t "billing-common.cost-estimator.footer.cost-is-based"}}
          </p>
        {{/if}}
      </div>
    {{/if}}
    {{#if (and (not @error) (has-block "left"))}}
      <div
        class="cost-estimator-footer__left-estimate"
        data-test-cost-estimator-footer-left-estimate
      >
        {{yield to="left"}}
      </div>
    {{/if}}
    {{#if (and (not @error) (has-block "right"))}}
      <div
        class="cost-estimator-footer__right-estimate"
        data-test-cost-estimator-footer-right-estimate
      >
        {{yield to="right"}}
      </div>
    {{/if}}
  </div>
</template>
