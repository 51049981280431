import {
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
} from 'common/utils/cloud-resource-types';

import { TGW_ROUTE_NAMES, PEERING_ROUTE_NAMES } from './network-routes.js';

export const CONNECTIONS = ['tgwAttachment', 'peering'];
export const CONNECTIONS_INFO = {
  tgwAttachment: {
    type: TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
    configPath: 'transit-gateway-attachments',
    info: {
      message:
        'components.networks.hvn-routes.create.form.target.info-alert.tgw-attachment.message',
      linkText:
        'components.networks.hvn-routes.create.form.target.info-alert.tgw-attachment.link-text',
    },
    ...TGW_ROUTE_NAMES,
  },
  peering: {
    type: TYPE_NETWORK_PEERING,
    configPath: 'peerings',
    info: {
      message:
        'components.networks.hvn-routes.create.form.target.info-alert.peering.message',
      linkText:
        'components.networks.hvn-routes.create.form.target.info-alert.peering.link-text',
    },
    ...PEERING_ROUTE_NAMES,
  },
};
