/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202ModelsSbomFormat;
(function (HashicorpCloudPacker20221202ModelsSbomFormat) {
    HashicorpCloudPacker20221202ModelsSbomFormat["CYCLONEDX"] = "CYCLONEDX";
    HashicorpCloudPacker20221202ModelsSbomFormat["SPDX"] = "SPDX";
})(HashicorpCloudPacker20221202ModelsSbomFormat || (HashicorpCloudPacker20221202ModelsSbomFormat = {}));
export function HashicorpCloudPacker20221202ModelsSbomFormatFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsSbomFormatFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsSbomFormatFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202ModelsSbomFormatToJSON(value) {
    return value;
}
