import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FlashMessagesService from '../services/flash-messages.ts';

interface NotificationsSignature {
  // Args: {};
}

/**
 *
 * `Notifications` utilizes the FlashMessage queue to render Notification
 * child elements.
 *
 *
 * ```
 * <Notifications />
 * ```
 *
 * @class Notifications
 *
 */

export default class NotificationsComponent extends Component<NotificationsSignature> {
  @service declare readonly flashMessages: FlashMessagesService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Notifications: typeof NotificationsComponent;
    notifications: typeof NotificationsComponent;
  }
}
