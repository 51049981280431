/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/owner';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { variation } from 'ember-launch-darkly';

/**
 *
 * `PageServicePrincipalsProjectList` lists the project level HCP Service Principals.
 *
 *
 * ```
 * <Page::ServicePrincipals::Project::List
 *  @organization={{@organization}}
 *  @project={{@project}}
 *  @policy={{@policy}}
 *  @servicePrincipals={{@servicePrincipals}}
 * />
 * ```
 *
 * @class PageServicePrincipalsProjectList
 */

export default class PageServicePrincipalsProjectListComponent extends Component {
  /**
   * @argument organization
   * @type {Object}
   * @required
   */
  /**
   * @argument project
   * @type {Object}
   * @required
   */
  /**
   * @argument policy
   * @type {Object}
   * @required
   */
  /**
   * @argument servicePrincipals
   * @type {Object}
   * @required
   */

  @service flashMessages;
  @service intl;
  @service router;
  @service api;

  @tracked showDeleteModal = false;
  @tracked returnFocusTo = null;
  @tracked servicePrincipalKeys = null;
  @tracked servicePrincipalWips = null;
  @tracked servicePrincipalToDelete = null;

  @task
  *confirmDeleteServicePrincipal(servicePrincipal, returnFocusTo) {
    try {
      let { keys } =
        yield this.api.servicePrincipal.servicePrincipalsServiceGetProjectServicePrincipal(
          servicePrincipal.organizationId,
          servicePrincipal.projectId,
          servicePrincipal.id
        );
      this.servicePrincipalKeys = keys;
    } catch (e) {
      this.servicePrincipalKeys = [];
      if (macroCondition(isDevelopingApp())) {
        console.error(e); // eslint-disable-line no-console
      }
    }

    try {
      let wips = [];
      const parentResourceName = `iam/project/${servicePrincipal.projectId}/service-principal/${servicePrincipal.name}`;
      if (variation('hcp-ui-workload-identity-aws')) {
        ({ providers: wips } =
          yield this.api.servicePrincipal.servicePrincipalsServiceListWorkloadIdentityProvider(
            parentResourceName
          ));
      }
      this.servicePrincipalWips = wips;
    } catch (e) {
      this.servicePrincipalWips = [];
      if (macroCondition(isDevelopingApp())) {
        console.error(e); // eslint-disable-line no-console
      }
    }

    this.showDeleteModal = true;
    this.servicePrincipalToDelete = servicePrincipal;
    this.returnFocusTo = returnFocusTo;
  }

  @task
  *deleteServicePrincipal() {
    let response;
    let servicePrincipal = this.servicePrincipalToDelete;

    if (servicePrincipal.projectId) {
      response =
        yield this.api.servicePrincipal.servicePrincipalsServiceDeleteProjectServicePrincipal(
          servicePrincipal.organizationId,
          servicePrincipal.projectId,
          servicePrincipal.id
        );
    } else {
      response =
        yield this.api.servicePrincipal.servicePrincipalsServiceDeleteOrganizationServicePrincipal(
          servicePrincipal.organizationId,
          servicePrincipal.id
        );
    }

    this.onDeleteServicePrincipalSuccess({
      response,
      servicePrincipal,
    });
  }

  @action
  onDeleteServicePrincipalSuccess() {
    this.resetDeleteServicePrincipal();

    // We should use the router service for this once this PR is merged.
    // via: https://github.com/emberjs/rfcs/issues/592
    let route = getOwner(this).lookup(`route:${this.router.currentRouteName}`);

    this.flashMessages.success(
      this.intl.t(
        'components.page.access-control.service-principals.delete-modal.success'
      )
    );

    return route.refresh();
  }

  @action
  resetDeleteServicePrincipal() {
    this.showDeleteModal = false;
    this.servicePrincipalToDelete = null;
    this.returnFocusTo = null;
  }
}
