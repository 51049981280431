import Service, { inject as service } from '@ember/service';

import {
  TYPE_PROJECT,
  TYPE_ORGANIZATION,
} from 'common/utils/cloud-resource-types';

import type AbilitiesService from 'ember-can/services/abilities';
import type { ScopeLocationLink } from '../types/scope-location-link';
import type { IResourceContext } from '../types/role-assignments-config.ts';
import type { Organization, Project } from 'core/services/user-context';

export default class ScopesService extends Service {
  @service declare readonly abilities: AbilitiesService;

  async fetch({
    organization,
    project,
    resource,
  }: {
    organization?: Organization;
    project?: Project;
    resource?: IResourceContext;
  }): Promise<{
    currentScopeLink?: ScopeLocationLink;
    scopeLinks: Array<ScopeLocationLink>;
  }> {
    const scopeLinks: Array<ScopeLocationLink> = [];

    if (
      this.abilities.can('view role assignments in organization') &&
      organization &&
      organization.id &&
      organization.name
    ) {
      scopeLinks.push({
        type: TYPE_ORGANIZATION,
        id: organization.id,
        name: organization.name,
      });
    }

    if (
      this.abilities.can('view role assignments in project') &&
      project &&
      project.id &&
      project.name
    ) {
      scopeLinks.push({
        type: TYPE_PROJECT,
        id: project.id,
        name: project.name,
      });
    }

    if (resource && resource.id && resource.name && resource.type) {
      scopeLinks.push({
        id: resource.id,
        name: resource.name,
        type: resource.type,
      });
    }

    // The order of this array determines scope hierachy.
    // The last item in the list is the current link.
    const currentScopeLink = scopeLinks.at(-1);

    return { currentScopeLink, scopeLinks };
  }
}
