import { AnalyticsBrowser } from '@segment/analytics-next';

export default class SegmentAnalyticsProvider {
  name = 'Segment';
  client = new AnalyticsBrowser();

  get anonymousId() {
    return this.client.instance?.user().anonymousId();
  }

  start(config) {
    const { enabled, WRITE_KEY, proxyDomain } = config;
    if (enabled) {
      this.client.load(
        {
          writeKey: WRITE_KEY,
          cdnURL:
            proxyDomain === 'segment.cloud.hashicorp.com'
              ? 'https://segment-assets.cloud.hashicorp.com'
              : 'https://cdn.segment.com',
        },
        {
          integrations: {
            'Segment.io': {
              apiHost: `${proxyDomain}/v1`,
            },
          },
        }
      );
    }
  }

  trackPageView(path, routeName, referrer) {
    this.client.page(path, {
      referrer,
      routeName,
    });
  }

  trackEvent(event, properties, options, callback) {
    this.client.track(event, properties, options, callback);
  }

  identifyUser(userId, traits, options, callback) {
    this.client.identify(userId, traits, options, callback);
  }
}
