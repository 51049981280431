import Component from '@glimmer/component';

import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';

// Components
import Flex from 'core/components/flex';
import { FlightIcon } from '@hashicorp/ember-flight-icons/components';
import {
  HdsAccordion,
  HdsAccordionForceStateValues,
  HdsBadgeCount,
  HdsButton,
  HdsTable,
  HdsTextBody,
  HdsTextDisplay,
} from '@hashicorp/design-system-components/components';

// Helpers
import set from 'ember-set-helper/helpers/set';
import { get } from '@ember/helper';
import { eq, or } from 'ember-truth-helpers';
import {
  entries,
  groupBy,
  findBy,
} from '@nullvoxpopuli/ember-composable-helpers';
import optionForSourceState from 'core/helpers/option-for-source-state';
import iamFindRoleFromRoles from '../../../../helpers/iam-find-role-from-roles.ts';

// Utils
import {
  AlignItem,
  FlexDirection,
  FlexGap,
  JustifyContent,
} from 'core/utils/flex';

// Modifiers
import { on } from '@ember/modifier';

// Types
import type { HashicorpCloudIamGroup } from '@clients/cloud-iam';
import type { IamRoleLocation } from '../../../../types/iam-role-location.ts';
import type { ScopeLocationLink } from '../../../../types/scope-location-link.ts';
import type { RolesLocation } from '../../../../types/roles-location.ts';

export interface ManageAccessAssigneeRolesFlyoutSignature {
  Element: HTMLElement;
  Args: {
    groups: Array<HashicorpCloudIamGroup>;
    groupRoleLocations: Array<IamRoleLocation>;
    inheritedRoleLocations: Array<IamRoleLocation>;
    rolesLocations: Array<RolesLocation>;
    scopeLinks: Array<ScopeLocationLink>;
  };
  Blocks: {
    default?: [];
  };
}

export default class AssigneeRolesComponent extends Component<ManageAccessAssigneeRolesFlyoutSignature> {
  @tracked state = HdsAccordionForceStateValues.Close;

  <template>
    <Flex
      @direction={{FlexDirection.Column}}
      @gap={{FlexGap.Md}}
      data-test-assignee-roles-flyout
    >
      <Flex
        @direction={{FlexDirection.Row}}
        @justifyContent={{JustifyContent.SpaceBetween}}
      >
        <HdsTextDisplay
          @size="400"
          @weight="bold"
          data-test-assignee-roles-flyout-header
        >
          {{t
            "manage-access.components.flyout.group-roles-and-members.assignee-roles.roles"
          }}
        </HdsTextDisplay>
        {{! TODO HCPF-2016: Disable until @hashicorp/design-system-components=^4.6.0}}
        {{#if false}}
          <HdsButton
            @color="tertiary"
            @icon={{if
              (eq this.state HdsAccordionForceStateValues.Open)
              "unfold-close"
              "unfold-open"
            }}
            @text={{if
              (eq this.state HdsAccordionForceStateValues.Open)
              (t
                "manage-access.components.flyout.group-roles-and-members.assignee-roles.collapse-all"
              )
              (t
                "manage-access.components.flyout.group-roles-and-members.assignee-roles.expand-all"
              )
            }}
            @size="small"
            {{on
              "click"
              (set
                this
                "state"
                (if
                  (eq this.state HdsAccordionForceStateValues.Open)
                  HdsAccordionForceStateValues.Close
                  HdsAccordionForceStateValues.Open
                )
              )
            }}
          />
        {{/if}}
      </Flex>
      <HdsAccordion
        @forceState={{this.state}}
        @size="large"
        data-test-assignee-roles-flyout-accordion
        as |A|
      >
        {{#if @inheritedRoleLocations}}
          {{#each
            (entries (groupBy "link.id" @inheritedRoleLocations))
            as |linkedRoleLocation|
          }}
            {{#let
              (get linkedRoleLocation 0) (get linkedRoleLocation 1)
              as |scopeId scopedRoleLocations|
            }}
              {{#let (findBy "id" scopeId @scopeLinks) as |scope|}}
                <A.Item data-test-assignee-roles-flyout-inherited-roles>
                  <:toggle>
                    <Flex
                      @alignItems={{AlignItem.Center}}
                      @direction={{FlexDirection.Row}}
                      @gap={{FlexGap.Xs}}
                    >
                      {{#let
                        (optionForSourceState "resource-type" scope.type)
                        as |resourceOption|
                      }}
                        <Flex
                          @alignItems={{AlignItem.Center}}
                          @direction={{FlexDirection.Row}}
                          @gap={{FlexGap.Xs}}
                        >
                          <FlightIcon
                            @color="var(--token-color-foreground-faint)"
                            {{! @glint-expect-error }}
                            @name={{resourceOption.icon}}
                          />
                          <span>{{scope.name}}</span>
                          {{! @glint-expect-error }}
                          <HdsBadgeCount @text={{scopedRoleLocations.length}} />
                        </Flex>
                      {{/let}}
                    </Flex>
                  </:toggle>
                  <:content>
                    <HdsTable
                      @caption={{t
                        "manage-access.components.flyout.group-roles-and-members.assignee-roles.inherited-assignments-from-scope-name"
                        scopeName=scope.name
                      }}
                      @valign="top"
                      data-test-inherited-role-assignments-list-table
                      data-test-inherited-role-assignments-list-scope-type={{scope.type}}
                      data-test-inherited-role-assignments-list-scope-name={{scope.name}}
                      data-test-inherited-role-assignments-list-scope-id={{scope.id}}
                    >
                      <:head as |H|>
                        <H.Tr>
                          <H.Th
                            @width="20%"
                            data-test-inherited-role-assignments-list-table-header
                          >
                            {{t
                              "manage-access.components.flyout.group-roles-and-members.assignee-roles.role"
                            }}
                          </H.Th>
                          <H.Th
                            data-test-inherited-role-assignments-list-table-header
                          >
                            {{t
                              "manage-access.components.flyout.group-roles-and-members.assignee-roles.description"
                            }}
                          </H.Th>
                        </H.Tr>
                      </:head>
                      <:body as |B|>
                        {{! @glint-expect-error }}
                        {{#each scopedRoleLocations as |roleLocation|}}
                          {{! @glint-expect-error }}{{! prettier-ignore }}
                          {{#let (iamFindRoleFromRoles (get (findBy "link.type" roleLocation.link.type @rolesLocations) "roles") roleId=roleLocation.roleId) as |role|}}
                            <B.Tr
                              data-test-inherited-role-assignments-list-table-row
                            >
                              <B.Td
                                data-test-inherited-role-assignments-list-table-cell
                                data-test-inherited-role-assignments-list-table-cell-title
                              >
                                {{role.title}}
                              </B.Td>
                              <B.Td
                                data-test-inherited-role-assignments-list-table-cell
                                data-test-inherited-role-assignments-list-table-cell-description
                              >
                                {{role.description}}
                              </B.Td>
                            </B.Tr>
                          {{/let}}
                        {{/each}}
                      </:body>
                    </HdsTable>
                  </:content>
                </A.Item>
              {{/let}}
            {{/let}}
          {{/each}}
        {{/if}}
        {{#if @groupRoleLocations}}
          <A.Item data-test-assignee-roles-flyout-group-roles>
            <:toggle>
              <Flex
                @alignItems={{AlignItem.Center}}
                @direction={{FlexDirection.Row}}
                @gap={{FlexGap.Xs}}
                data-test-assignee-roles-flyout-group-roles-toggle
              >
                {{#let
                  (optionForSourceState "policy-type" "GROUP")
                  as |option|
                }}
                  {{! @glint-expect-error }}
                  <FlightIcon @name={{option.icon}} />
                  <span>
                    {{t
                      "manage-access.components.flyout.group-roles-and-members.assignee-roles.group-assignments"
                    }}
                  </span>
                  <HdsBadgeCount @text={{@groupRoleLocations.length}} />
                {{/let}}
              </Flex>
            </:toggle>
            <:content>
              <Flex @direction={{FlexDirection.Column}} @gap={{FlexGap.Md}}>
                {{#each
                  (entries (groupBy "member.memberId" @groupRoleLocations))
                  as |groupRoleLocation|
                }}
                  {{#let
                    (get groupRoleLocation 0) (get groupRoleLocation 1)
                    as |groupId roleLocations|
                  }}
                    {{! @glint-expect-error }}{{! prettier-ignore }}
                    {{#each (entries (groupBy "link.id" roleLocations)) as |linkedRoleLocation|}}
                      {{#let
                        (get linkedRoleLocation 0) (get linkedRoleLocation 1)
                        as |scopeId scopedRoleLocations|
                      }}
                        {{#let (findBy "id" scopeId @scopeLinks) as |scope|}}
                          <Flex
                            @direction={{FlexDirection.Column}}
                            @gap={{FlexGap.Sm}}
                          >
                            <Flex
                              @alignItems={{AlignItem.Center}}
                              @direction={{FlexDirection.Row}}
                              @gap={{FlexGap.Sm}}
                            >
                              <HdsTextBody @size="300" @weight="medium">
                                {{t
                                  "manage-access.components.flyout.group-roles-and-members.assignee-roles.inherited-from"
                                }}
                              </HdsTextBody>
                                {{#let (optionForSourceState "resource-type" scope.type) as |option|}}
                                <Flex
                                  @alignItems={{AlignItem.Center}}
                                  @direction={{FlexDirection.Row}}
                                  @gap={{FlexGap.Xs}}
                                >
                                  <FlightIcon
                                    @color="var(--token-color-foreground-faint)"
                                    {{! @glint-expect-error }}
                                    @name={{option.icon}}
                                  />
                                  <HdsTextBody @size="100" @weight="regular">
                                    {{scope.name}}
                                  </HdsTextBody>
                                </Flex>
                              {{/let}}
                              <HdsTextBody
                                @color="faint"
                                @size="100"
                                @weight="regular"
                              >
                                /
                              </HdsTextBody>
                              {{#let
                                (optionForSourceState "policy-type" "GROUP")
                                as |option|
                              }}
                                <Flex
                                  @alignItems={{AlignItem.Center}}
                                  @direction={{FlexDirection.Row}}
                                  @gap={{FlexGap.Xs}}
                                >
                                  <FlightIcon
                                    @color="var(--token-color-foreground-faint)"
                                    {{! @glint-expect-error }}
                                    @name={{option.icon}}
                                  />
                                  <HdsTextBody @size="100" @weight="regular">
                                    {{#let
                                      (findBy "resourceId" groupId @groups)
                                      as |group|
                                    }}
                                      {{or group.displayName groupId}}
                                    {{/let}}
                                  </HdsTextBody>
                                </Flex>
                              {{/let}}
                            </Flex>
                            <HdsTable
                              @caption={{t
                                "manage-access.components.flyout.group-roles-and-members.assignee-roles.group-assignments"
                              }}
                              @valign="top"
                              data-test-group-role-assignments-list-table
                              data-test-group-role-assignments-list-scope-type={{scope.type}}
                              data-test-group-role-assignments-list-scope-name={{scope.name}}
                              data-test-group-role-assignments-list-scope-id={{scope.id}}
                            >
                              <:head as |H|>
                                <H.Tr>
                                  <H.Th
                                    @width="20%"
                                    data-test-group-role-assignments-list-table-header
                                  >
                                    {{t
                                      "manage-access.components.flyout.group-roles-and-members.assignee-roles.role"
                                    }}
                                  </H.Th>
                                  <H.Th
                                    data-test-group-role-assignments-list-table-header
                                  >
                                    {{t
                                      "manage-access.components.flyout.group-roles-and-members.assignee-roles.description"
                                    }}
                                  </H.Th>
                                </H.Tr>
                              </:head>
                              <:body as |B|>
                                {{! @glint-expect-error }}{{! prettier-ignore }}
                                {{#each scopedRoleLocations as |roleLocation|}}
                                    {{! @glint-expect-error }}{{! prettier-ignore }}
                                    {{#let (iamFindRoleFromRoles (get (findBy "link.type" roleLocation.link.type @rolesLocations) "roles") roleId=roleLocation.roleId) as |role|}}
                                    <B.Tr
                                      data-test-group-role-assignments-list-table-row
                                    >
                                      <B.Td
                                        data-test-group-role-assignments-list-table-cell
                                        data-test-group-role-assignments-list-table-cell-title
                                      >
                                        {{role.title}}
                                      </B.Td>
                                      <B.Td
                                        data-test-group-role-assignments-list-table-cell
                                        data-test-group-role-assignments-list-table-cell-description
                                      >
                                        {{role.description}}
                                      </B.Td>
                                    </B.Tr>
                                  {{/let}}
                                {{/each}}
                              </:body>
                            </HdsTable>
                          </Flex>
                        {{/let}}
                      {{/let}}
                    {{/each}}
                  {{/let}}
                {{/each}}
              </Flex>
            </:content>
          </A.Item>
        {{/if}}
      </HdsAccordion>
    </Flex>
  </template>
}
