/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128AwsAccessKeysRequestFromJSON, Secrets20231128AwsAccessKeysRequestToJSON, Secrets20231128AwsFederatedWorkloadIdentityRequestFromJSON, Secrets20231128AwsFederatedWorkloadIdentityRequestToJSON, Secrets20231128AzureClientSecretRequestFromJSON, Secrets20231128AzureClientSecretRequestToJSON, Secrets20231128AzureFederatedWorkloadIdentityRequestFromJSON, Secrets20231128AzureFederatedWorkloadIdentityRequestToJSON, Secrets20231128CapabilityFromJSON, Secrets20231128CapabilityToJSON, Secrets20231128ConfluentStaticCredentialsRequestFromJSON, Secrets20231128ConfluentStaticCredentialsRequestToJSON, Secrets20231128GcpFederatedWorkloadIdentityRequestFromJSON, Secrets20231128GcpFederatedWorkloadIdentityRequestToJSON, Secrets20231128GcpServiceAccountKeyRequestFromJSON, Secrets20231128GcpServiceAccountKeyRequestToJSON, Secrets20231128GitlabAccessTokenRequestFromJSON, Secrets20231128GitlabAccessTokenRequestToJSON, Secrets20231128HcpTerraformApiTokenRequestFromJSON, Secrets20231128HcpTerraformApiTokenRequestToJSON, Secrets20231128MongoDBAtlasStaticCredentialsRequestFromJSON, Secrets20231128MongoDBAtlasStaticCredentialsRequestToJSON, Secrets20231128MssqlStaticCredentialsRequestFromJSON, Secrets20231128MssqlStaticCredentialsRequestToJSON, Secrets20231128MysqlStaticCredentialsRequestFromJSON, Secrets20231128MysqlStaticCredentialsRequestToJSON, Secrets20231128PostgresStaticCredentialsRequestFromJSON, Secrets20231128PostgresStaticCredentialsRequestToJSON, Secrets20231128TwilioStaticCredentialsRequestFromJSON, Secrets20231128TwilioStaticCredentialsRequestToJSON, } from './';
export function SecretServiceCreateIntegrationBodyFromJSON(json) {
    return SecretServiceCreateIntegrationBodyFromJSONTyped(json, false);
}
export function SecretServiceCreateIntegrationBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'capabilities': !exists(json, 'capabilities') ? undefined : (json['capabilities'].map(Secrets20231128CapabilityFromJSON)),
        'awsFederatedWorkloadIdentity': !exists(json, 'aws_federated_workload_identity') ? undefined : Secrets20231128AwsFederatedWorkloadIdentityRequestFromJSON(json['aws_federated_workload_identity']),
        'gcpFederatedWorkloadIdentity': !exists(json, 'gcp_federated_workload_identity') ? undefined : Secrets20231128GcpFederatedWorkloadIdentityRequestFromJSON(json['gcp_federated_workload_identity']),
        'twilioStaticCredentials': !exists(json, 'twilio_static_credentials') ? undefined : Secrets20231128TwilioStaticCredentialsRequestFromJSON(json['twilio_static_credentials']),
        'mongoDbAtlasStaticCredentials': !exists(json, 'mongo_db_atlas_static_credentials') ? undefined : Secrets20231128MongoDBAtlasStaticCredentialsRequestFromJSON(json['mongo_db_atlas_static_credentials']),
        'awsAccessKeys': !exists(json, 'aws_access_keys') ? undefined : Secrets20231128AwsAccessKeysRequestFromJSON(json['aws_access_keys']),
        'gcpServiceAccountKey': !exists(json, 'gcp_service_account_key') ? undefined : Secrets20231128GcpServiceAccountKeyRequestFromJSON(json['gcp_service_account_key']),
        'gitlabAccessToken': !exists(json, 'gitlab_access_token') ? undefined : Secrets20231128GitlabAccessTokenRequestFromJSON(json['gitlab_access_token']),
        'confluentStaticCredentials': !exists(json, 'confluent_static_credentials') ? undefined : Secrets20231128ConfluentStaticCredentialsRequestFromJSON(json['confluent_static_credentials']),
        'azureClientSecret': !exists(json, 'azure_client_secret') ? undefined : Secrets20231128AzureClientSecretRequestFromJSON(json['azure_client_secret']),
        'azureFederatedWorkloadIdentity': !exists(json, 'azure_federated_workload_identity') ? undefined : Secrets20231128AzureFederatedWorkloadIdentityRequestFromJSON(json['azure_federated_workload_identity']),
        'postgresStaticCredentials': !exists(json, 'postgres_static_credentials') ? undefined : Secrets20231128PostgresStaticCredentialsRequestFromJSON(json['postgres_static_credentials']),
        'mysqlStaticCredentials': !exists(json, 'mysql_static_credentials') ? undefined : Secrets20231128MysqlStaticCredentialsRequestFromJSON(json['mysql_static_credentials']),
        'mssqlStaticCredentials': !exists(json, 'mssql_static_credentials') ? undefined : Secrets20231128MssqlStaticCredentialsRequestFromJSON(json['mssql_static_credentials']),
        'hcpTerraformApiToken': !exists(json, 'hcp_terraform_api_token') ? undefined : Secrets20231128HcpTerraformApiTokenRequestFromJSON(json['hcp_terraform_api_token']),
        'gatewayPoolId': !exists(json, 'gateway_pool_id') ? undefined : json['gateway_pool_id'],
    };
}
export function SecretServiceCreateIntegrationBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'provider': value.provider,
        'capabilities': value.capabilities === undefined ? undefined : (value.capabilities.map(Secrets20231128CapabilityToJSON)),
        'aws_federated_workload_identity': Secrets20231128AwsFederatedWorkloadIdentityRequestToJSON(value.awsFederatedWorkloadIdentity),
        'gcp_federated_workload_identity': Secrets20231128GcpFederatedWorkloadIdentityRequestToJSON(value.gcpFederatedWorkloadIdentity),
        'twilio_static_credentials': Secrets20231128TwilioStaticCredentialsRequestToJSON(value.twilioStaticCredentials),
        'mongo_db_atlas_static_credentials': Secrets20231128MongoDBAtlasStaticCredentialsRequestToJSON(value.mongoDbAtlasStaticCredentials),
        'aws_access_keys': Secrets20231128AwsAccessKeysRequestToJSON(value.awsAccessKeys),
        'gcp_service_account_key': Secrets20231128GcpServiceAccountKeyRequestToJSON(value.gcpServiceAccountKey),
        'gitlab_access_token': Secrets20231128GitlabAccessTokenRequestToJSON(value.gitlabAccessToken),
        'confluent_static_credentials': Secrets20231128ConfluentStaticCredentialsRequestToJSON(value.confluentStaticCredentials),
        'azure_client_secret': Secrets20231128AzureClientSecretRequestToJSON(value.azureClientSecret),
        'azure_federated_workload_identity': Secrets20231128AzureFederatedWorkloadIdentityRequestToJSON(value.azureFederatedWorkloadIdentity),
        'postgres_static_credentials': Secrets20231128PostgresStaticCredentialsRequestToJSON(value.postgresStaticCredentials),
        'mysql_static_credentials': Secrets20231128MysqlStaticCredentialsRequestToJSON(value.mysqlStaticCredentials),
        'mssql_static_credentials': Secrets20231128MssqlStaticCredentialsRequestToJSON(value.mssqlStaticCredentials),
        'hcp_terraform_api_token': Secrets20231128HcpTerraformApiTokenRequestToJSON(value.hcpTerraformApiToken),
        'gateway_pool_id': value.gatewayPoolId,
    };
}
