/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { typeOf } from '@ember/utils';

/**
 *
 * `PageUsersRemove` renders the modal for removing a user from an organization.
 *
 *
 * ```
 * <Page::Users::Remove
 *   @isActive={{showRemoveModal}}
 *   @organization={{@model.organization}}
 *   @onActiveChange={{resetRemoveUsers}}
 *   @onSuccess={{onRemoveUsersSuccess}}
 *   @policy={{@model.policy}}
 *   @returnFocusTo={{returnFocusTo}}
 *   @users={{users}}
 * />
 * ```
 *
 * @class PageUsersRemove
 *
 */

/**
 * This shows or hides the modal based on truthiness.
 * @argument isActive
 * @type {Boolean}
 */

/**
 * A callback function to be called on a failure to remove users.
 * @argument onError
 * @type {Function}
 */

/**
 * A callback function to be called on a successful removal of users.
 * @argument onSuccess
 * @type {Function}
 */

/**
 * The organization context of the users that are being removed.
 * @argument organization
 * @type {Object}
 */

/**
 * The organization policy document.
 * @argument policy
 * @type {Object}
 */

/**
 * A string for the element to return to.
 * @argument returnFocusTo
 * @type {String}
 */

/**
 * A list of users to remove.
 * @argument users
 * @type {Object}
 */

export default class PageUsersRemoveComponent extends Component {
  @service api;
  @service operation;

  @tracked emails = null;

  get userEmails() {
    let { users } = this.args;

    if (users.length > 3) {
      return null;
    }

    return users
      .map(({ email }, index, self) => {
        return `${
          self.length > 1 && index === self.length - 1 ? 'and ' : ''
        }${email}`;
      })
      .join(', ');
  }

  @task
  *removeUsers() {
    let { organization, users } = this.args;
    let successfullyDeleted = [];
    let failedDeleted = [];

    for (let user of users) {
      let resp;

      try {
        resp = yield this.api.iam.iamServiceDeleteOrganizationMembership(
          organization.id,
          user.id
        );

        successfullyDeleted.push({ resp, user });
      } catch (e) {
        if (macroCondition(isDevelopingApp())) {
          //eslint-disable-next-line no-console
          console.error(e);
        }

        failedDeleted.push({ error: e, resp, user });
      }
    }

    if (
      successfullyDeleted.length &&
      this.args.onSuccess &&
      typeOf(this.args.onSuccess) === 'function'
    ) {
      this.args.onSuccess({
        resp: successfullyDeleted.map((success) => success.resp),
        users: successfullyDeleted.map((success) => success.user),
      });
    }

    if (
      failedDeleted.length &&
      this.args.onError &&
      typeOf(this.args.onError) === 'function'
    ) {
      this.args.onError({
        error: failedDeleted.map((failed) => failed.error),
        resp: failedDeleted.map((failed) => failed.resp),
        users: failedDeleted.map((failed) => failed.user),
      });
    }
  }
}
