import Component from '@glimmer/component';
import { t } from 'ember-intl';
import {
  HdsAlert,
  HdsLinkStandalone,
} from '@hashicorp/design-system-components/components';
import boxPadding from 'core/modifiers/box-padding';

/**
 *
 * A banner like alert used to display static Learn page content
 * on the Peering Create page providing the user context
 *
 * ```
 * <Networks::Cards::Learn/>
 * ```
 *
 * @class NetworksCardsLearn
 *
 */

export default class NetworksCardsLearn extends Component {
  <template>
    <HdsAlert @type="inline" @icon="connection" ...attributes as |A|>
      <A.Title class="hds-typography-display-100 hds-font-weight-medium">
        {{t "components.networks.cards.learn.title"}}
      </A.Title>
      <A.Description class="hds-typography-body-100 hds-foreground-faint">
        {{t "components.networks.cards.learn.description"}}
      </A.Description>
      <A.LinkStandalone
        @icon="learn-link"
        @iconPosition="trailing"
        @text={{t "components.networks.cards.learn.how-does-this-work"}}
        @href={{t "components.networks.cards.learn.hdtw-link"}}
      />
      <A.Generic>
        <HdsLinkStandalone
          @icon="external-link"
          @iconPosition="trailing"
          @size="small"
          @text={{t "components.networks.cards.learn.learn-about-privacy"}}
          @href={{t "components.networks.cards.learn.lap-link"}}
          {{boxPadding "xs" "0" "0" "0"}}
        />
      </A.Generic>
    </HdsAlert>
  </template>
}
