/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudBoundary20211221ControllerConfigurationFromJSON(json) {
    return HashicorpCloudBoundary20211221ControllerConfigurationFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221ControllerConfigurationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authTokenTimeToLive': !exists(json, 'auth_token_time_to_live') ? undefined : json['auth_token_time_to_live'],
        'authTokenTimeToStale': !exists(json, 'auth_token_time_to_stale') ? undefined : json['auth_token_time_to_stale'],
    };
}
export function HashicorpCloudBoundary20211221ControllerConfigurationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'auth_token_time_to_live': value.authTokenTimeToLive,
        'auth_token_time_to_stale': value.authTokenTimeToStale,
    };
}
