/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import {
  isAwsProviderNetwork,
  isAzureProviderNetwork,
} from 'networks-common/utils/networks';
import { isHvnToHvnPeering } from 'networks-common/utils/peerings';
/**
 *
 * `PageHvnPeeringsDeletePeeringModal` renders the Delete Peering Modal.
 *
 *
 * ```
 * <Page::HvnPeerings::List::DeletePeeringModal
 *   @model={{model}}
 *   @peering={{model.peering}}
 *   @isOpen={{this.showDeleteModal}}
 *   @onActiveChange={{set this 'showDeleteModal'}}
 * />
 * ```
 *
 * @class PageHvnPeeringsDeletePeeringModal
 *
 */
/**
 *
 * `model` will be a model containing the peerings list, the current organization, and project.
 * @argument model;
 * @type {Object}
 *
 */
/**
 *
 * `peering` is the peering to delete.
 * @argument peering;
 * @type {Object}
 *
 */
/**
 *
 * `isOpen` is a boolean that controls whether the modal is shown or not
 * @argument isOpen;
 * @type {Boolean}
 *
 */
/**
 *
 * `openStateChanged` can optionally be provided when deletion request succeeds
 * @argument openStateChanged;
 * @type {Boolean}
 *
 */
export default class PageHvnPeeringsDeletePeeringModalComponent extends Component {
  @service api;
  @service operation;
  @service flashMessages;
  @service intl;
  @service analytics;

  get provider() {
    return this.args.model.network.location.region.provider;
  }

  get virtualSystemName() {
    let virtualSystemName;

    // HCP calls this HVN - HashiCorp Virtual Network
    if (isHvnToHvnPeering(this.args.peering)) {
      virtualSystemName = this.args.peering.target.hvnTarget.hvn.id;
    }
    // AWS calls this VPC - Virtual Private Cloud
    else if (isAwsProviderNetwork(this.args.model.network)) {
      virtualSystemName = this.args.peering.target.awsTarget.vpcId;
    }
    // Azure calls this VNet - Virtual Network
    else if (isAzureProviderNetwork(this.args.model.network)) {
      virtualSystemName = this.args.peering.target.azureTarget.vnetName;
    }
    return virtualSystemName;
  }

  get isHvnToHvnPeering() {
    return isHvnToHvnPeering(this.args.peering);
  }

  @task
  *deletePeering(evt) {
    evt.preventDefault();
    let { organization, project } = this.args.model;
    let { hvn, id } = this.args.peering;
    let operation;

    try {
      let resp = yield this.api.network.deletePeering(
        organization.id,
        project.id,
        hvn.id,
        id
      );
      this.flashMessages.success(
        this.intl.t('components.page.hvn-peerings.delete.success.title')
      );
      this.analytics.trackEvent(
        'external_ui_hashicorp_network_peering_delete_modal_delete_button_clicked',
        this.args.peering
      );
      operation = resp.operation;
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      // TODO - make this a flash message
      throw e;
    }
    this.args.openStateChanged(false);
    this.operation.addOperation(operation);
  }
}
