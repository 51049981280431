/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20231128HcpTerraformType;
(function (Secrets20231128HcpTerraformType) {
    Secrets20231128HcpTerraformType["WORKSPACE"] = "WORKSPACE";
    Secrets20231128HcpTerraformType["VARIABLESET"] = "VARIABLE_SET";
})(Secrets20231128HcpTerraformType || (Secrets20231128HcpTerraformType = {}));
export function Secrets20231128HcpTerraformTypeFromJSON(json) {
    return Secrets20231128HcpTerraformTypeFromJSONTyped(json, false);
}
export function Secrets20231128HcpTerraformTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20231128HcpTerraformTypeToJSON(value) {
    return value;
}
