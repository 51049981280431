/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { getValue } from '@glimmer/tracking/primitives/cache';
import { invokeHelper } from '@ember/helper';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { getOwner } from '@ember/application';

import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

import rbacRoles from 'core/helpers/rbac-roles';
import { TYPE_ORGANIZATION } from 'common/utils/cloud-resource-types';
import { supportTicketPrefillHref } from 'core/utils/support-ticket';
import IamPolicy from 'core/utils/iam-policy';

/**
 *
 * `PageUsersEditAssignment` allows the editing of a user principal's basic role.
 *
 * ```
 * <Page::Users::EditAssignment
 *   @organization={{this.model.organization}}
 *   @policy={{this.model.policy}}
 *   @redirectRoute={{this.model.redirectRoute}}
 *   @userPrincipal={{this.model.userPrincipal}}
 *   @scimEnabled={{this.model.scimEnabled}}
 * />
 * ```
 *
 * @class PageUsersEditAssignment
 *
 */
/**
 * The organization.
 * @argument organization
 * @type {Object}
 */
/**
 * The organization policy.
 * @argument organizationPolicy
 * @type {Object}
 */
/**
 * The user principal.
 * @argument userPrincipal
 * @type {Object}
 */
/**
 * A flag for if scim is enabled.
 * @argument scimEnabled
 * @type {Object}
 */

export default class PageUsersEditAssignmentComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;
  @service userContext;

  @tracked updatedRoleId = this.organizationRoleId;
  @tracked policy = new IamPolicy(this.args.organizationPolicy);

  get OrganizationPolicy() {
    return this.policy;
  }

  get organizationRoleId() {
    if (!this.OrganizationPolicy) {
      return undefined;
    }

    const { userPrincipal } = this.args;
    const { roleId } = this.OrganizationPolicy.getMemberById(userPrincipal.id);
    return roleId;
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    const {
      organization,
      redirectRoute = 'cloud.access-control.users.list',
      userPrincipal,
    } = this.args;

    if (this.organizationRoleId == this.updatedRoleId) {
      // Do nothing if the role has not changed.
      return;
    }

    // Get the current organization policy.
    const UpdatedOrganizationPolicy = this.OrganizationPolicy;

    if (!this.updatedRoleId) {
      // Remove the user from the policy if we're selecting no role.
      UpdatedOrganizationPolicy.removeMemberById(userPrincipal.id);
    } else {
      const { memberId } = UpdatedOrganizationPolicy.getMemberById(
        userPrincipal.id
      );
      if (!memberId) {
        // Add this member to the policy with their new role.
        UpdatedOrganizationPolicy.addMember(
          userPrincipal.id,
          HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
          this.updatedRoleId
        );
      } else {
        // Update the policy with the new role for this USER.
        UpdatedOrganizationPolicy.changeMemberRole(
          userPrincipal.id,
          this.updatedRoleId
        );
      }
    }

    const payload = {
      policy: UpdatedOrganizationPolicy.get(),
    };

    try {
      const { policy } =
        yield this.api.resourceManager.org.organizationServiceSetIamPolicy(
          organization.id,
          payload
        );

      // Update the local policy to get the new etag. Without this, the component
      // state will become out-of-date and will return conflicts when trying
      // to update.
      this.policy = new IamPolicy(policy);

      if (!this.updatedRoleId) {
        this.flashMessages.success(
          this.intl.t(
            'components.page.access-control.users.edit-assignment.org-role-updated-successfully'
          ),
          {
            actionText: this.intl.t(
              'components.page.access-control.users.edit-assignment.go-to-projects'
            ),
            content: this.intl.t(
              'components.page.access-control.users.edit-assignment.user-no-longer-has-role',
              {
                userName: userPrincipal.fullName || userPrincipal.email,
                htmlSafe: true,
              }
            ),
            onAction: () => {
              return this.router.transitionTo(
                'cloud.orgs.detail.projects',
                organization.id
              );
            },
            linkIcon: 'arrow-right',
            linkIconPosition: 'trailing',
            linkText: this.intl.t(
              'components.page.access-control.users.edit-assignment.learn-more'
            ),
            linkUrl:
              'https://developer.hashicorp.com/hcp/docs/hcp/admin/iam/users#project-role',
          }
        );
      } else {
        const role = getValue(
          invokeHelper(this, rbacRoles, () => {
            return {
              positional: [this.updatedRoleId],
              named: {
                active: true,
                type: TYPE_ORGANIZATION,
              },
            };
          })
        );

        const userName = userPrincipal.fullName || userPrincipal.email;
        this.flashMessages.success(
          this.intl.t(
            'components.page.access-control.users.edit-assignment.org-role-updated-successfully'
          ),
          {
            content: this.intl.t(
              'components.page.access-control.users.edit-assignment.the-role-for-user-is-now',
              {
                htmlSafe: true,
                roleLabel: role?.label || this.updatedRoleId,
                userName,
              }
            ),
          }
        );
      }

      const container = getOwner(this);
      const route = container.lookup(`route:${redirectRoute}`);

      if (route) {
        // We need to refresh this before transitioning because if this is a
        // child route, the parent route will not call the model hook again when
        // transitioning upward even if data was changed in the child.
        route.refresh();
        this.router.transitionTo(`${redirectRoute}`);
      }
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        // eslint-disable-next-line
        console.error(e);
      }
      this.flashMessages.error(
        this.intl.t(
          'components.page.access-control.users.edit-assignment.org-role-update-failed'
        ),
        {
          content: this.intl.t(
            'components.page.access-control.users.edit-assignment.something-went-wrong'
          ),
          linkIcon: 'help',
          linkIconPosition: 'leading',
          linkText: this.intl.t(
            'components.page.access-control.users.edit-assignment.contact-support'
          ),
          linkUrl: this.supportTicketHref,
        }
      );
    }
  }
}
