import Component from '@glimmer/component';
import { tracked, TrackedSet } from 'tracked-built-ins';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import {
  HdsFormTextInputField,
  HdsButton,
  HdsButtonSet,
  HdsFormLabel,
  HdsFormCheckboxGroup,
} from '@hashicorp/design-system-components/components';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import Button from 'core/components/button';
import boxPadding from 'core/modifiers/box-padding';

import type { HdsDropdownElement } from '../../utils/filter-bar/index.ts';

interface SearchHistoryItem {
  history: string;
  initiallySelected: boolean;
}

export interface TextSearchDropdownSignature {
  Args: {
    dd: HdsDropdownElement;
    textValue: string;
    selectedValues: string[];
    onSubmit: (value: string[], dropdown: HTMLElement) => void;
    resetDropdown: () => void;
    helperText: string;
    searchHistory: TrackedSet<string>;
    trackInteraction: () => void;
    validator?: (input: string) => string | undefined;
  };

  Blocks: {
    default: [];
  };

  Element: HTMLElement;
}

/**
 * The textSearchDropdown component is a custom dropdown body which allows the
 * user to enter freeform text for search and query purposes. Previous selections
 * from the current session can be displayed as well, and the component allows
 * multiple concurrent selections.
 *
 * The component can be used inside an Hds dropdown or within a FilterBar
 * component instance.
 *
 * Usage:
 *  <FilterBar::TextSearchDropdown
 *     @dd={{dd}}
 *     @onSubmit={{@onSubmit}}
 *     @textValue={{@optionValue}}
 *     @helperText={{@helperText}}
 *     @selectedValues={{@selectedValues}}
 *     @resetDropdown={{
 *       fn
 *       @resetDropdownSelection
 *       @id
 *     }}
 *     @searchHistory={{@selectionHistory}}
 *     @trackInteraction={{@trackInteraction}}
 *  />
 *
 * An optional tracking callback can be provided to instrument the dropdown.
 *
 * The @selectedValues parameter allows items to be pre-selected on render.
 *
 * @class TextSearchDropdownComponent
 */

export default class TextSearchDropdown extends Component<TextSearchDropdownSignature> {
  @tracked currentSearchText = '';
  @tracked checkedHistoryItems: string[] = [];
  @tracked searchHistoryItems: SearchHistoryItem[] = [];
  @tracked hasSearchChange = false;
  @tracked error?: string;

  get showClearButton() {
    return this.args.selectedValues.length;
  }

  get submitDisabled() {
    return !this.hasSearchChange;
  }

  initializeSearchHistoryCheckboxes = () => {
    if (!this.args.searchHistory.size) return;

    const currentlySelectedValues = this.args.selectedValues[0]?.split(',');
    const searchHistoryCheckboxItems = Array.from(
      this.args.searchHistory,
    ) as string[]; // purely for convenience, so we can use map instead of a for loop

    const searchHistoryItems = searchHistoryCheckboxItems.map((history) => {
      const initiallySelected =
        currentlySelectedValues?.includes(history) ?? false;
      if (initiallySelected) this.checkedHistoryItems.push(history);
      return {
        history,
        initiallySelected,
      };
    });

    this.searchHistoryItems = searchHistoryItems;
  };

  executeOnSelect = () => {
    if (this.args.validator && this.currentSearchText !== '') {
      const error = this.args.validator(this.currentSearchText);
      if (error) {
        this.error = error;
        return;
      }
    }
    this.args.onSubmit(
      [
        ...this.checkedHistoryItems,
        ...(this.currentSearchText && [this.currentSearchText]),
      ],
      this.args.dd,
    );
    this.currentSearchText = '';
    this.error = undefined;
    this.hasSearchChange = false;
    this.args.trackInteraction();
  };

  setSearchText = (evt: Event) => {
    // @ts-expect-error
    this.currentSearchText = evt.target.value;
    this.hasSearchChange = true;
  };

  resetText = () => {
    this.currentSearchText = '';
    this.checkedHistoryItems = [];
    this.args.onSubmit([''], this.args.dd);
    this.args.resetDropdown();
    this.hasSearchChange = false;
    this.args.trackInteraction();
  };

  onHistoryItemChecked = (evt: Event) => {
    const { value, checked } = evt.target as HTMLInputElement;
    checked
      ? (this.checkedHistoryItems = [...this.checkedHistoryItems, value])
      : (this.checkedHistoryItems = this.checkedHistoryItems.filter(
          (historyItem) => historyItem !== value,
        ));
    this.hasSearchChange = true;
  };

  onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !this.submitDisabled) {
      this.executeOnSelect();
    }
  };

  <template>
    <HdsFormTextInputField
      data-text-filter-string-search
      @value={{this.currentSearchText}}
      placeholder={{@helperText}}
      {{on "input" this.setSearchText}}
      {{on "keyup" this.onKeyUp}}
      as |F|
    >
      <F.Label>
        {{t
          "vault-common.components.filter-bar.text-search-dropdown.text-search-label"
        }}
      </F.Label>
      {{#if this.error}}
        <F.Error data-text-filter-string-error>
          {{this.error}}
        </F.Error>
      {{/if}}
    </HdsFormTextInputField>
    <div
      {{boxPadding "md 0 0 0"}}
      {{didInsert this.initializeSearchHistoryCheckboxes}}
    >
      <HdsFormLabel>
        {{t
          "vault-common.components.filter-bar.text-search-dropdown.search-history-label"
        }}
      </HdsFormLabel>
      {{#if this.searchHistoryItems.length}}
        <HdsFormCheckboxGroup {{boxPadding "0 0 md 0"}} as |G|>
          <G.Legend />
          {{#each this.searchHistoryItems as |searchHistoryItem|}}
            <G.CheckboxField
              data-test-search-text-history
              checked={{searchHistoryItem.initiallySelected}}
              @value={{searchHistoryItem.history}}
              {{on "change" this.onHistoryItemChecked}}
              as |F|
            >
              <F.Label data-test-search-text-history-label>
                {{searchHistoryItem.history}}
              </F.Label>
            </G.CheckboxField>
          {{/each}}
        </HdsFormCheckboxGroup>
      {{else}}
        <p data-test-empty-search-state class="vi__empty-search-text">
          {{t
            "vault-common.components.filter-bar.text-search-dropdown.empty-search"
          }}
        </p>
      {{/if}}
    </div>
    <hr />
    <HdsButtonSet {{boxPadding "xs 0"}}>
      <HdsButton
        data-test-request-filter-submit
        disabled={{this.submitDisabled}}
        @text={{t "vault-common.components.filter-bar.submit-button-text"}}
        @size="small"
        {{on "click" this.executeOnSelect}}
      />
      <HdsButton
        data-test-request-filter-text-cancel
        @text={{t "vault-common.components.filter-bar.cancel-button-text"}}
        @size="small"
        @color="secondary"
        {{on "click" @dd.close}}
      />
      {{#if this.showClearButton}}
        {{! @glint-expect-error }}
        <Button
          data-test-dropdown-reset-button
          class="filterbar-filter-clear"
          type="button"
          {{on "click" this.resetText}}
        >
          {{t "vault-common.components.filter-bar.clear-button-text"}}
        </Button>
      {{/if}}
    </HdsButtonSet>
  </template>
}
