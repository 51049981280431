// No external code loading possible (this disables all extensions such as
// Replay, Surveys, Exceptions etc.)
import posthog from 'posthog-js/dist/module.no-external';
import { redactEvent } from './posthog-redact.js';

export default class PosthogAnalyticsProvider {
  name = 'PostHog';
  organization = null;
  client = posthog;

  get anonymousId() {
    return this.client.get_distinct_id();
  }

  start(config) {
    const { enabled, API_KEY, api_host } = config;
    if (enabled) {
      this.client.init(API_KEY, {
        api_host,
        person_profiles: 'identified_only',
        persistence: 'memory',
        autocapture: false,
        disable_session_recording: true,
        advanced_disable_decide: true,
        capture_pageview: false,
        before_send: redactEvent,
      });
    }
  }

  trackPageView(path, routeName, referrer) {
    if (this.organization) {
      this.client.capture('$pageview', {
        route_name: routeName,
        $groups: {
          organization: this.organization,
        },
      });
    } else {
      this.client.capture('$pageview', {
        route_name: routeName,
      });
    }
  }

  trackEvent(event, properties, options, callback) {
    if (this.organization) {
      this.client.capture(event, {
        ...properties,
        $groups: {
          organization: this.organization,
        },
      });
    } else {
      this.client.capture(event, properties);
    }
  }

  identifyUser(userId, traits, options, callback) {
    const { requesting_user_id, organization, is_employee } = traits;
    this.organization = organization;
    this.client.identify(userId, {
      is_employee,
    });
  }
}
