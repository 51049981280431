import Component from '@glimmer/component';
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { eq } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsFormRadioCardGroup } from '@hashicorp/design-system-components/components';

import { ALL_CONNECTION_METHODS } from '../../../utils/connection-methods.js';
/**
 *
 * `NetworksFormInputsConnectionCards` renders the provider radio cards for AWS and Azure
 *
 *
 * ```
 * <Networks::FormInputs::ConnectionCards
 *   @onChange={{onChange}}
 *   @selectedConnectionMethod='quick-links'
 * />
 * ```
 *
 * @class NetworksFormInputsConnectionCards
 *
 */

export default class NetworksFormInputsConnectionCards extends Component {
  connectionContent =
    'components.networks.form-inputs.connection-method-cards.';

  get connectionMethods() {
    let { connectionMethods } = this.args;
    return connectionMethods || ALL_CONNECTION_METHODS;
  }

  <template>
    <div data-test-networks-form-inputs-connection-method-cards>
      <HdsFormRadioCardGroup
        @name="peering-connection-method"
        @alignment="center"
        as |G|
      >
        {{#each this.connectionMethods as |connectionMethod|}}
          <G.RadioCard
            @value={{connectionMethod.key}}
            @checked={{eq @selectedConnectionMethod connectionMethod.key}}
            data-test-networks-form-inputs-connection-method-cards-item={{connectionMethod.key}}
            {{on "change" @onChange}}
            as |R|
          >
            <R.Icon
              @name={{connectionMethod.icon}}
              @size="24"
              data-test-networks-form-inputs-connection-method-cards-icon={{connectionMethod.icon}}
            />
            <R.Label>
              {{t
                (concat this.connectionContent connectionMethod.key ".label")
              }}
            </R.Label>
            <R.Badge
              @text={{t
                (concat this.connectionContent connectionMethod.key ".time")
              }}
              @type="outlined"
            />
            <R.Description>
              {{t
                (concat this.connectionContent connectionMethod.key ".content")
                htmlSafe=true
              }}
            </R.Description>
          </G.RadioCard>
        {{/each}}
      </HdsFormRadioCardGroup>
    </div>
  </template>
}
