import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';

import type SessionService from 'ember-simple-auth/services/session';
import type CurrentUserService from '../services/current-user.ts';

interface UserMenuSignature {
  Args: {
    isExternal: boolean;
  };
  Element: HTMLElement;
}

/**
 *
 * `UserMenu` renders a `Menu` with information about the current user which is pulled from the `currentUser` service.
 *
 *
 * ```
 * <UserMenu />
 * ```
 *
 * @class UserMenu
 *
 */

export default class UserMenuComponent extends Component<UserMenuSignature> {
  /**
   *
   * `isExternal` when true, marks links as @isRouteExternal={{true}}.
   *              This is false by default and should be true when used in an engine.
   * @argument isExternal
   * @type {boolean}
   *
   */

  @service declare readonly currentUser: CurrentUserService;
  @service declare readonly session: SessionService;

  get accessToken() {
    if (variation('hcp-ui-copy-access-token')) {
      return 'hcp auth print-access-token';
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UserMenu: typeof UserMenuComponent;
    'user-menu': typeof UserMenuComponent;
  }
}
