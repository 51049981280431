import Service from '@ember/service';
import { timeout, dropTask } from 'ember-concurrency';
import { warn } from '@ember/debug';
import { isTesting } from '@embroider/macros';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import statuspageIo from 'core/utils/statuspage-io';
import { STATUSPAGE_IO_STATUS } from 'core/utils/constants';

/** @typedef {('OPERATIONAL'|'DEGRADED'|'OUTAGE'|'MAINTENANCE')} DisplayStatus */

/**
 * @typedef {Object} HcpSystemStatus
 * @property {StatuspageIoStatus} hcp
 * @property {StatuspageIoStatus} consul
 * @property {StatuspageIoStatus} packer
 * @property {StatuspageIoStatus} vault
 */

export const ONE_MINUTE_MS = 60 * 1000;

export const HCP_STATUS = {
  OPERATIONAL: 'OPERATIONAL',
  DEGRADED: 'DEGRADED',
  OUTAGE: 'OUTAGE',
  MAINTENANCE: 'MAINTENANCE',
};

export const STATUSPAGE_CARDINALITY_MAPPINGS = {
  [STATUSPAGE_IO_STATUS.MAJOR_OUTAGE]: 5,
  [STATUSPAGE_IO_STATUS.PARTIAL_OUTAGE]: 4,
  [STATUSPAGE_IO_STATUS.DEGRADED_PERFORMANCE]: 3,
  [STATUSPAGE_IO_STATUS.UNDER_MAINTENANCE]: 2,
  [STATUSPAGE_IO_STATUS.OPERATIONAL]: 1,
};

export const STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS = {
  [STATUSPAGE_IO_STATUS.OPERATIONAL]: HCP_STATUS.OPERATIONAL,
  [STATUSPAGE_IO_STATUS.DEGRADED_PERFORMANCE]: HCP_STATUS.DEGRADED,
  [STATUSPAGE_IO_STATUS.PARTIAL_OUTAGE]: HCP_STATUS.OUTAGE,
  [STATUSPAGE_IO_STATUS.MAJOR_OUTAGE]: HCP_STATUS.OUTAGE,
  [STATUSPAGE_IO_STATUS.UNDER_MAINTENANCE]: HCP_STATUS.MAINTENANCE,
  DEFAULT: null,
};

export const isIdOneOf = (idsAllowList) => (statusPageIoComponent) =>
  idsAllowList.includes(statusPageIoComponent?.id);

export const toStatus = (statusPageIoComponent) =>
  statusPageIoComponent?.status;

export const byStatusSeverityDsc = (statusA, statusB) => {
  const severityA = STATUSPAGE_CARDINALITY_MAPPINGS[statusA];
  const severityB = STATUSPAGE_CARDINALITY_MAPPINGS[statusB];
  return severityB - severityA;
};

export const deriveAggregateComponentStatus = (
  statuspageIoComponents,
  componentIds
) => {
  const [topSeverityStatus = null] = statuspageIoComponents
    .filter(isIdOneOf(componentIds))
    .map(toStatus)
    .sort(byStatusSeverityDsc);

  return topSeverityStatus;
};

export default class SystemStatusService extends Service {
  // NOTE: This must be overridden by an Ember instance initializer when the app boots.
  // SEE: hcp/app/instance-initializers/statuspage-io.js
  config = statuspageIo.NON_PRODUCTION;

  /** @type {DisplayStatus} */
  @tracked hcp = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;

  /** @type {DisplayStatus} */
  @tracked consul = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;

  /** @type {DisplayStatus} */
  @tracked packer = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;

  /** @type {DisplayStatus} */
  @tracked vault = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;

  get statuspageUrl() {
    return this.config.baseUrl;
  }

  /** @public */
  start() {
    warn('system-status service starting', {
      id: 'service.system-status.start',
    });

    this._pollStatus.perform();
  }

  /** @public */
  stop() {
    warn('system-status service stopping', {
      id: 'service.system-status.stop',
    });
    this._pollStatus.cancelAll();
  }

  /** @private */
  @dropTask *_pollStatus() {
    let errCounter = 1;

    while (true) {
      try {
        let {
          hcp = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT,
          consul = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT,
          packer = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT,
          vault = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT,
        } = yield this._fetchHcpSystemStatus();

        this.hcp =
          STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS[hcp] ||
          STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.consul =
          STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS[consul] ||
          STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.packer =
          STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS[packer] ||
          STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.vault =
          STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS[vault] ||
          STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;

        errCounter = 1;
      } catch (e) {
        if (macroCondition(isDevelopingApp())) console.error(e); // eslint-disable-line

        this.hcp = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.consul = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.packer = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;
        this.vault = STATUSPAGE_IO_TO_HCP_STATUS_MAPPINGS.DEFAULT;

        errCounter++;
      }

      if (isTesting()) return;
      yield timeout(ONE_MINUTE_MS * errCounter);
    }
  }

  /** Derives HCP system statuses from statuspage.io component data.
   * @returns {Promise<HcpSystemStatus>}
   * @private
   * @throws */
  async _fetchHcpSystemStatus() {
    let components = await this._fetchStatuspageIoComponents();

    let hcp = deriveAggregateComponentStatus(
      components,
      this.config.hcpComponentIds
    );
    let consul = deriveAggregateComponentStatus(
      components,
      this.config.consulComponentIds
    );
    let packer = deriveAggregateComponentStatus(
      components,
      this.config.packerComponentIds
    );
    let vault = deriveAggregateComponentStatus(
      components,
      this.config.vaultComponentIds
    );

    return { hcp, consul, packer, vault };
  }

  /**
   * @returns {Array[Object]}
   * @private */
  async _fetchStatuspageIoComponents() {
    let rawStatusComponents = await fetch(this.config.jsonApiUrl);
    let { components = [] } = await rawStatusComponents.json();

    return components;
  }
}
