import {
  ROLE_KEY_ADMIN,
  ROLE_KEY_CONTRIBUTOR,
  ROLE_KEY_VIEWER,
  ROLE_KEY_BROWSER,
  ROLE_KEY_SECRETS_MANAGER,
  ROLE_KEY_SECRETS_READER,
  ROLE_KEY_INTEGRATION_MANAGER,
  ROLE_KEY_INTEGRATION_READER,
} from 'core/utils/cloud-iam-rbac-roles';

import type {
  CategoryActionRecord,
  RoleDefinitionService,
} from '../../../types/role-definitions-flyout';

const I18N_PREFIX =
  'manage-access.components.flyout.role-definitions-flyout.project';

const ACTIONS: CategoryActionRecord<
  | 'CREATE_AND_DELETE_PROJECT_RESOURCES'
  | 'DELETE_PROJECT'
  | 'EDIT_PROJECT_PERMISSIONS'
  | 'MANAGE_PROJECT_SERVICE_PRINCIPALS'
  | 'VIEW_PROJECT'
  | 'VIEW_PROJECT_RESOURCES'
> = {
  CREATE_AND_DELETE_PROJECT_RESOURCES: {
    title: `${I18N_PREFIX}.actions.create-and-delete-project-resources`,
    permissions: [
      'secrets.apps.create',
      'secrets.apps.delete',
      'secrets.app-secrets.create',
      'secrets.app-secrets.delete',
      'consul.clusters.create',
      'consul.clusters.delete',
      'network.peerings.create',
      'network.peerings.delete',
      'packer.bucket.create',
      'packer.bucket.delete',
      'vagrant.box.create',
      'vagrant.box.delete',
    ],
  },
  DELETE_PROJECT: {
    title: `${I18N_PREFIX}.actions.delete-project`,
    permissions: ['resource-manager.projects.delete'],
  },
  EDIT_PROJECT_PERMISSIONS: {
    title: `${I18N_PREFIX}.actions.edit-project-permissions`,
    permissions: ['resource-manager.projects.set-iam-policy'],
  },
  MANAGE_PROJECT_SERVICE_PRINCIPALS: {
    title: `${I18N_PREFIX}.actions.manage-project-service-principals`,
    permissions: [
      'iam.service-principals.create',
      'iam.service-principals.update',
      'iam.service-principals.delete',
      'iam.service-principals.get',
      'iam.service-principals.list',
    ],
  },
  VIEW_PROJECT: {
    title: `${I18N_PREFIX}.actions.view-project`,
    permissions: ['resource-manager.projects.get'],
  },
  VIEW_PROJECT_RESOURCES: {
    title: `${I18N_PREFIX}.actions.view-project-resources`,
    permissions: ['resource-manager.resources.list'],
  },
};

export const PROJECT_SERVICES: Array<RoleDefinitionService> = [
  {
    id: 'basic',
    title: `${I18N_PREFIX}.all-services`,
    categories: [
      {
        title: `${I18N_PREFIX}.basic.basic-roles`,
        actions: [
          ACTIONS['VIEW_PROJECT'],
          ACTIONS['VIEW_PROJECT_RESOURCES'],
          ACTIONS['EDIT_PROJECT_PERMISSIONS'],
          ACTIONS['DELETE_PROJECT'],
          ACTIONS['CREATE_AND_DELETE_PROJECT_RESOURCES'],
          ACTIONS['MANAGE_PROJECT_SERVICE_PRINCIPALS'],
        ],
        columnRoleIds: [
          ROLE_KEY_ADMIN,
          ROLE_KEY_CONTRIBUTOR,
          ROLE_KEY_VIEWER,
          ROLE_KEY_BROWSER,
        ],
      },
    ],
  },
  // TODO HCPF-2018: fill in terraform columnRoleIds and actions permissions for comparison table
  // https://hashicorp.atlassian.net/browse/HCPF-2018
  // {
  //   id: 'terraform',
  //   categories: [
  //     {
  //       title: `${I18N_PREFIX}.terraform.categories.project-roles.project-roles`,
  //       actions: [
  //         {
  //           title: `${I18N_PREFIX}.terraform.categories.project-roles.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //     },
  //     {
  //       title: `${I18N_PREFIX}.terraform.categories.workspaces.workspaces`,
  //       actions: [
  //         {
  //           title: `${I18N_PREFIX}.terraform.categories.workspaces.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${I18N_PREFIX}.terraform.categories.group-management.group-management`,
  //       actions: [
  //         {
  //           title: `${I18N_PREFIX}.terraform.categories.group-management.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${I18N_PREFIX}.terraform.categories.run-access.run-access`,
  //       actions: [
  //         {
  //           title: `${I18N_PREFIX}.terraform.categories.run-access.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${I18N_PREFIX}.terraform.categories.variable-access.variable-access`,
  //       actions: [
  //         {
  //           title: `${I18N_PREFIX}.terraform.categories.variable-access.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${I18N_PREFIX}.terraform.categories.state-access.state-access`,
  //       actions: [
  //         {
  //           title: `${I18N_PREFIX}.terraform.categories.state-access.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${I18N_PREFIX}.terraform.categories.other-controls.other-controls`,
  //       actions: [
  //         {
  //           title: `${I18N_PREFIX}.terraform.categories.other-controls.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //   ],
  // },
  {
    id: 'secrets',
    title: `${I18N_PREFIX}.secrets.vault-secrets`,
    categories: [
      {
        title: `${I18N_PREFIX}.secrets.app`,
        actions: [
          {
            title: `${I18N_PREFIX}.secrets.project-roles.actions.read-secrets`,
            permissions: ['secrets.app-secrets.list', 'secrets.apps.list'],
          },
          {
            title: `${I18N_PREFIX}.secrets.project-roles.actions.create-edit-and-delete-secrets`,
            permissions: [
              'secrets.app-secrets.create',
              'secrets.app-secrets.update',
              'secrets.app-secrets.delete',
            ],
          },
          {
            title: `${I18N_PREFIX}.secrets.project-roles.actions.create-edit-and-delete-applications`,
            permissions: [
              'secrets.apps.create',
              'secrets.apps.update',
              'secrets.apps.delete',
            ],
          },
          {
            title: `${I18N_PREFIX}.secrets.project-roles.actions.create-edit-and-delete-hvs-role-assignments`,
            permissions: [
              'secrets.apps.set-iam-policy',
              'secrets.apps.get-iam-policy',
            ],
          },
          {
            title: `${I18N_PREFIX}.secrets.project-roles.actions.manage-project-service-principals`,
            permissions: ['iam.service-principals.update'],
          },
          {
            title: `${I18N_PREFIX}.secrets.project-roles.actions.create-and-manage-sync-integrations`,
            permissions: [
              'secrets.integrations.create',
              'secrets.integrations.update',
            ],
          },
          {
            title: `${I18N_PREFIX}.secrets.project-roles.actions.connect-sync-integrations`,
            permissions: ['secrets.sync-integrations.force'],
          },
          {
            title: `${I18N_PREFIX}.secrets.project-roles.actions.disconnect-sync-integrations`,
            permissions: ['secrets.sync-integrations.force'],
          },
        ],
        columnRoleIds: [ROLE_KEY_SECRETS_MANAGER, ROLE_KEY_SECRETS_READER],
      },
      {
        title: `${I18N_PREFIX}.secrets.integration`,
        actions: [],
        type: 'list',
        columnRoleIds: [
          ROLE_KEY_INTEGRATION_MANAGER,
          ROLE_KEY_INTEGRATION_READER,
        ],
      },
    ],
  },
  {
    id: 'resource-manager',
    title: `${I18N_PREFIX}.resource-manager.resource-manager`,
    categories: [
      {
        title: `${I18N_PREFIX}.project-roles`,
        actions: [
          ACTIONS['VIEW_PROJECT'],
          ACTIONS['VIEW_PROJECT_RESOURCES'],
          ACTIONS['EDIT_PROJECT_PERMISSIONS'],
          ACTIONS['DELETE_PROJECT'],
          ACTIONS['CREATE_AND_DELETE_PROJECT_RESOURCES'],
          ACTIONS['MANAGE_PROJECT_SERVICE_PRINCIPALS'],
        ],
        columnRoleIds: ['roles/resource-manager.project-iam-policies-admin'],
      },
    ],
  },
];
