/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20231128HcpTerraformCategory;
(function (Secrets20231128HcpTerraformCategory) {
    Secrets20231128HcpTerraformCategory["TERRAFORM"] = "TERRAFORM";
    Secrets20231128HcpTerraformCategory["ENV"] = "ENV";
})(Secrets20231128HcpTerraformCategory || (Secrets20231128HcpTerraformCategory = {}));
export function Secrets20231128HcpTerraformCategoryFromJSON(json) {
    return Secrets20231128HcpTerraformCategoryFromJSONTyped(json, false);
}
export function Secrets20231128HcpTerraformCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20231128HcpTerraformCategoryToJSON(value) {
    return value;
}
