/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ListAllowedActionsResponseActionFromJSON, ListAllowedActionsResponseActionToJSON, } from './';
export function Billing20201105ListAllowedActionsResponseFromJSON(json) {
    return Billing20201105ListAllowedActionsResponseFromJSONTyped(json, false);
}
export function Billing20201105ListAllowedActionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actions': !exists(json, 'actions') ? undefined : (json['actions'].map(ListAllowedActionsResponseActionFromJSON)),
    };
}
export function Billing20201105ListAllowedActionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'actions': value.actions === undefined ? undefined : (value.actions.map(ListAllowedActionsResponseActionToJSON)),
    };
}
