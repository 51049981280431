import Component from '@glimmer/component';
import { t, formatNumber } from 'ember-intl';
import { and, not, eq } from 'ember-truth-helpers';
import { FlightIcon } from '@hashicorp/ember-flight-icons/components';

import BillingCostEstimatorFooter from './footer.gjs';
import BillingCostEstimatorLabel from './label.gjs';
import BillingCostEstimatorAmount from './amount.gjs';
import BillingCostEstimatorPrice from './price.gjs';
import BillingCostEstimatorUnit from './unit.gjs';
import BillingCostEstimatorIcon from './icon.gjs';
import BillingCostEstimatorDifference from './difference.gjs';

const COST_ESTIMATOR_PERIOD_HOUR = 'HOUR';
const COST_ESTIMATOR_PERIOD_MONTH = 'MONTH';
const COST_ESTIMATOR_PERIOD_DEFAULT = COST_ESTIMATOR_PERIOD_HOUR;

const COST_ESTIMATOR_PERIOD_MAPPING = {
  [COST_ESTIMATOR_PERIOD_HOUR]: {
    id: COST_ESTIMATOR_PERIOD_HOUR,
    i18n: 'billing-common.cost-estimator.footer.per-hour',
    priceKey: 'totalHourlyPrice',
  },
  [COST_ESTIMATOR_PERIOD_MONTH]: {
    id: COST_ESTIMATOR_PERIOD_MONTH,
    i18n: 'billing-common.cost-estimator.footer.per-month',
    priceKey: 'totalMonthlyPrice',
  },
};

/**
 *
 * `CostEstimatorEditFooter` renders a cost estimator in the context of scaling
 * a cluster up or down. This renders the price difference based on @period.
 *
 *
 * ```
 * <CostEstimatorEditFooter
 *   @currentEstimate={{@currentEstimate}}
 *   @newEstimate={{@newEstimate}}
 *   @period={{@period}} // [MONTH|HOUR]
 * />
 * ```
 *
 * @class CostEstimatorEditFooter
 *
 */

/**
 * The period for which estimate to show. The options are HOUR or MONTH.
 * @argument period
 * @type {String}
 */

/**
 * The api data from the cost estimation API. This will be used as the left
 * operand when calculating the difference.
 * @argument newEstimate
 * @type {object}
 */

/**
 * The api data from the cost estimation API. This will be used as the right
 * operand when calculating the difference.
 * @argument currentEstimate
 * @type {object}
 */
export default class CostEstimatorEditFooter extends Component {
  get period() {
    let { period = COST_ESTIMATOR_PERIOD_DEFAULT } = this.args;
    return (
      COST_ESTIMATOR_PERIOD_MAPPING[period] ||
      COST_ESTIMATOR_PERIOD_MAPPING[COST_ESTIMATOR_PERIOD_DEFAULT]
    );
  }

  get newEstimatePrice() {
    let { newEstimate } = this.args;
    return newEstimate[this.period.priceKey];
  }

  get currentEstimatePrice() {
    let { currentEstimate } = this.args;
    return currentEstimate[this.period.priceKey];
  }

  <template>
    {{#let
      (not (eq @currentEstimate.totalHourlyPrice @newEstimate.totalHourlyPrice))
      as |showNewEstimate|
    }}
      <BillingCostEstimatorFooter
        @error={{@error}}
        ...attributes
        data-test-cost-estimator-edit-footer-container
      >
        <:controls>
          {{yield to="controls"}}
        </:controls>
        <:message>
          {{yield to="message"}}
        </:message>
        <:left>
          {{#if @currentEstimate}}
            <p class="text--muted" data-test-cost-estimator-footer-superlabel>
              {{t "billing-common.cost-estimator.footer.existing-plan"}}:
            </p>
            <BillingCostEstimatorLabel>
              {{@currentTierLabel}}
              -
              {{@currentSizeLabel}}
            </BillingCostEstimatorLabel>
            <BillingCostEstimatorAmount>
              {{#let this.currentEstimatePrice as |currentEstimatePrice|}}
                {{#if currentEstimatePrice}}
                  <BillingCostEstimatorPrice>
                    {{formatNumber
                      currentEstimatePrice
                      currency="USD"
                      style="currency"
                    }}
                  </BillingCostEstimatorPrice>
                {{/if}}
                <BillingCostEstimatorUnit>
                  {{t this.period.i18n}}
                </BillingCostEstimatorUnit>
                {{#if (and showNewEstimate @newEstimate)}}
                  <BillingCostEstimatorIcon>
                    <FlightIcon @size="24" @name="arrow-right" />
                  </BillingCostEstimatorIcon>
                {{/if}}
              {{/let}}
            </BillingCostEstimatorAmount>
          {{/if}}
        </:left>
        <:right>
          {{#if (and showNewEstimate @newEstimate @currentEstimate)}}
            <p class="text--muted" data-test-cost-estimator-footer-superlabel>
              {{t "billing-common.cost-estimator.footer.new-plan"}}:
            </p>
            <BillingCostEstimatorLabel>
              {{@newTierLabel}}
              -
              {{@newSizeLabel}}
            </BillingCostEstimatorLabel>
            <BillingCostEstimatorAmount>
              {{#let
                this.newEstimatePrice this.currentEstimatePrice
                as |newEstimatePrice currentEstimatePrice|
              }}
                {{#if newEstimatePrice}}
                  <BillingCostEstimatorPrice>
                    {{formatNumber
                      newEstimatePrice
                      currency="USD"
                      style="currency"
                    }}
                  </BillingCostEstimatorPrice>
                {{/if}}
                <BillingCostEstimatorUnit>
                  {{t this.period.i18n}}
                </BillingCostEstimatorUnit>
                <BillingCostEstimatorDifference
                  @currentEstimatePrice={{currentEstimatePrice}}
                  @newEstimatePrice={{newEstimatePrice}}
                />
              {{/let}}
            </BillingCostEstimatorAmount>
          {{/if}}
        </:right>
      </BillingCostEstimatorFooter>
    {{/let}}
  </template>
}
