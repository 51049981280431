/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

/**
 *
 * `IamGroupMembersListTable` shows a group's members.
 *
 *
 * ```
 * <Iam::Groups::Members::ListTable
 *  @group={{foo}}
 *  @rawGroupPolicy={{foo}}
 *  @groupLevelPermissions={{foo}}
 *  @scimEnabled={{true}}
 *  @isProjectContext={{false}}
 *  @members={{foo}}
 * />
 * ```
 *
 * @class IamGroupMembersListTable
 *
 */

export default class IamGroupMembersListTableComponent extends Component {
  /**
   * @argument group
   * @type {Object}
   * @required
   */
  /**
   * @argument rawGroupPolicy
   * @type {Object}
   * @required
   */
  /**
   * @argument groupLevelPermissions
   * @type {Array}
   * @required
   */
  /**
   * @argument scimEnabled
   * @type {Boolean}
   * @required
   */
  /**
   * @argument isProjectContext
   * @type {Boolean}
   * @optional
   */
  /**
   * @argument members
   * @type {Array}
   * @required
   */

  @service intl;
  @service abilities;

  @tracked memberToRemove;
  @tracked isRemoveMemberModalActive = false;

  @tracked memberToEditRole;
  @tracked isRoleModalActive = false;

  // eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked groupPolicy = this.args.rawGroupPolicy;

  get projectColumns() {
    return [
      {
        key: 'name',
        label: this.intl.t(
          'components.iam.groups.members.list-table.table.headings.name'
        ),
      },
      {
        key: 'email',
        label: this.intl.t(
          'components.iam.groups.members.list-table.table.headings.email'
        ),
      },
      {
        key: 'principalType',
        label: this.intl.t(
          'components.iam.groups.members.list-table.table.headings.type'
        ),
      },
      {
        key: 'groupMemberRole',
        label: this.intl.t(
          'components.iam.groups.members.list-table.table.headings.role'
        ),
      },
    ];
  }

  get columns() {
    const headers = [];
    headers.push({
      key: 'email',
      label: this.intl.t(
        'components.iam.groups.members.list-table.table.headings.email'
      ),
    });
    headers.push({
      key: 'principalType',
      label: this.intl.t(
        'components.iam.groups.members.list-table.table.headings.type'
      ),
    });
    headers.push({
      key: 'groupMemberRole',
      label: this.intl.t(
        'components.iam.groups.members.list-table.table.headings.role'
      ),
    });
    if (this.abilities.can('delete group')) {
      headers.push({
        key: '',
        label: '',
      });
    }
    return headers;
  }

  @action
  openRemoveModal(member) {
    this.isRemoveMemberModalActive = true;
    this.memberToRemove = member;
  }

  @action
  closeRemoveModal() {
    this.isRemoveMemberModalActive = false;
    this.memberToRemove = null;
  }
  @action
  openRoleModal(member) {
    this.isRoleModalActive = true;
    this.memberToEditRole = member;
  }

  @action
  closeRoleModal() {
    this.isRoleModalActive = false;
    this.memberToEditRole = null;
  }

  @action
  onPolicyUpdateSuccess(rawGroupPolicy) {
    this.groupPolicy = { ...rawGroupPolicy };
  }
}
