import Component from '@glimmer/component';

export interface ManageAccessGenericSignature {
  Element: HTMLElement;
  Blocks: {
    default?: [];
  };
}

export default class ManageAccessGenericComponent extends Component<ManageAccessGenericSignature> {
  <template>
    {{! template-lint-disable no-yield-only }}
    {{yield}}
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ManageAccessGenericComponent: typeof ManageAccessGenericComponent;
  }
}
