/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { warn } from '@ember/debug';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';

import {
  TYPE_CONSUL,
  TYPE_CONSUL_GLOBAL_NETWORK_MANAGER,
  TYPE_VAULT,
  TYPE_PACKER,
  TYPE_BOUNDARY,
  TYPE_WAYPOINT,
  TYPE_VAULT_SECRETS_APP,
  TYPE_VAULT_RADAR,
  TYPE_VAGRANT,
} from 'common/utils/cloud-resource-types';

import {
  ACTION_CREATE,
  PREFIX_BOUNDARY_CLUSTERS,
  PREFIX_CONSUL_CLUSTERS,
  PREFIX_PACKER_REGISTRIES,
  PREFIX_VAULT_CLUSTERS,
  PREFIX_VAULT_SECRETS,
  PREFIX_VAULT_RADAR,
  SECRETS_APP,
  WAYPOINT_NAMESPACES,
  VAULT_RADAR_USERS,
  VAULT_RADAR_ADMIN,
} from 'authz/utils/permission-types/index';
import { TENANT_STATE, TENANT_TYPE } from 'hcp/utils/vault-radar';

// each service has:
// {
// 'featureFlags': 'string[]',
// 'logo': 'string',
// 'routeList': 'string',
// 'routeCreate': 'string',
// 'translations': {
// },

const SERVICES = {
  vault: {
    logo: 'vault',
    resourceTypes: [TYPE_VAULT],
    canCreatePermissionString: `${PREFIX_VAULT_CLUSTERS}.${ACTION_CREATE}`,
    routeList: 'vault.clusters.list',
    routeCreate: 'vault.create.cluster',
    translations: {
      product: 'service-card.vault',
      headerResource: 'service-card.active-text',
      headerGetStarted: 'service-card.get-started-title',
      descriptionGetStarted: 'service-card.vault-get-started-text',
      descriptionGetStartedViewer:
        'service-card.cluster-get-started-viewer-text-project',
      descriptionActive: 'service-card.product-deployed-project',
      linkList: 'service-card.view-link',
      linkCreate: 'service-card.deploy-link',
    },
  },

  secrets: {
    logo: 'vault-secrets',
    resourceTypes: [TYPE_VAULT_SECRETS_APP],
    canCreatePermissionString: `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}.${ACTION_CREATE}`,
    routeList: 'secrets.overview',
    routeCreate: 'secrets.overview',
    translations: {
      product: 'service-card.secrets',
      headerResource: 'service-card.active-text',
      headerGetStarted: 'service-card.get-started-title',
      descriptionGetStarted: 'service-card.secrets-get-started-text',
      descriptionGetStartedViewer:
        'service-card.secrets-get-started-viewer-text',
      descriptionActive: 'service-card.secrets-active-text',
      linkList: 'service-card.view-link',
      linkCreate: 'service-card.deploy-link',
    },
  },

  radar: {
    logo: 'vault-radar',
    resourceTypes: [TYPE_VAULT_RADAR],
    canCreatePermissionString: `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_ADMIN}`,
    translations: {
      product: 'service-card.radar',
      headerResource: 'service-card.active-text',
      headerGetStarted: 'service-card.radar-get-started-title',
      descriptionGetStarted: 'service-card.radar-get-started-text',
      descriptionGetStartedViewer: 'service-card.radar-get-started-viewer-text',
      descriptionActive: 'service-card.radar-active-text-project',
      linkList: 'service-card.view-link',
      linkCreate: 'service-card.deploy-link',
    },
  },

  consul: {
    logo: 'consul',
    resourceTypes: [TYPE_CONSUL, TYPE_CONSUL_GLOBAL_NETWORK_MANAGER],
    canCreatePermissionString: `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE}`,
    routeList: 'consul.clusters.overview',
    routeCreate: 'consul.clusters.create',
    translations: {
      product: 'service-card.consul',
      headerResource: 'service-card.active-text',
      headerGetStarted: 'service-card.get-started-title',
      descriptionGetStarted: 'service-card.consul-get-started-text',
      descriptionGetStartedViewer:
        'service-card.cluster-get-started-viewer-text-project',
      descriptionActive: 'service-card.product-deployed-project',
      linkList: 'service-card.view-link',
      linkCreate: 'service-card.deploy-link',
    },
  },

  packer: {
    logo: 'packer',
    resourceTypes: [TYPE_PACKER],
    canCreatePermissionString: `${PREFIX_PACKER_REGISTRIES}.${ACTION_CREATE}`,
    routeList: 'packer',
    routeCreate: 'packer',
    translations: {
      product: 'service-card.packer',
      headerResource: 'service-card.active-text',
      headerGetStarted: 'service-card.get-started-title',
      descriptionGetStarted: 'service-card.packer-get-started-text',
      descriptionActive: 'service-card.packer-active-text-project',
      descriptionGetStartedViewer:
        'service-card.packer-no-registry-description-project',
      linkList: 'service-card.registry-view-link',
      linkListViewer: 'service-card.view-link',
      linkCreate: 'service-card.deploy-link',
    },
  },

  vagrant: {
    logo: 'vagrant',
    resourceTypes: [TYPE_VAGRANT],
    canCreatePermissionString: 'vagrant.registry.create',
    routeList: 'vagrant',
    routeCreate: 'vagrant',
    translations: {
      product: 'service-card.vagrant-box-registry',
      headerResource: 'service-card.active-text',
      headerGetStarted: 'service-card.vagrant-get-started-title',
      descriptionGetStartedViewer:
        'service-card.vagrant-get-started-viewer-text',
      descriptionGetStarted: 'service-card.vagrant-get-started-text',
      descriptionActive: 'service-card.vagrant-active-text-project',
      linkList: 'service-card.view-link',
      linkCreate: 'service-card.deploy-link',
    },
  },

  waypoint: {
    logo: 'waypoint',
    resourceTypes: [TYPE_WAYPOINT],
    canCreatePermissionString: `${WAYPOINT_NAMESPACES}.${ACTION_CREATE}`,
    routeList: 'waypoint',
    routeCreate: 'waypoint',
    translations: {
      product: 'service-card.waypoint',
      headerResource: 'service-card.active-text',
      headerGetStarted: 'service-card.get-started-title',
      descriptionGetStartedViewer:
        'service-card.waypoint-get-started-viewer-text',
      descriptionGetStarted: 'service-card.waypoint-get-started-text',
      descriptionActive: 'service-card.waypoint-active-text-project',
      linkList: 'service-card.view-link',
      linkCreate: 'service-card.deploy-link',
    },
  },

  terraform: {
    logo: 'terraform',
    routeCreate: 'terraform',
    translations: {
      product: 'service-card.terraform',
      headerGetStarted: 'service-card.terraform-get-started-title',
      descriptionGetStarted: 'service-card.terraform-get-started',
      linkCreate: 'service-card.view-link',
    },
  },

  boundary: {
    logo: 'boundary',
    resourceTypes: [TYPE_BOUNDARY],
    canCreatePermissionString: `${PREFIX_BOUNDARY_CLUSTERS}.${ACTION_CREATE}`,
    routeList: 'boundary',
    routeCreate: 'boundary',
    translations: {
      product: 'service-card.boundary',
      headerResource: 'service-card.active-text',
      headerGetStarted: 'service-card.boundary-get-started',
      descriptionGetStarted: 'service-card.boundary-get-started-text',
      descriptionGetStartedViewer:
        'service-card.boundary-get-started-viewer-text-project',
      descriptionActive: 'service-card.product-deployed-project',
      linkList: 'service-card.view-link',
      linkCreate: 'service-card.deploy-link',
    },
  },
};

// each variation is a function used to transform config based on
// any feature flags.
//
// @param launchDarkly: an instance of the LaunchDarkly service
// @param config: a copy of the config object from the services object
// @param appConfig: an instance of the config service
const CONFIG_VARIATIONS = {
  vault: (config) => {
    if (variation('hcp-rebrand-milestone-0')) {
      config.translations.product = 'service-card.vault-dedicated';
    }

    config.routeCreate = 'vault.clusters.list';
    return config;
  },
  radar: (config, options) => {
    const { projectId, tenantData = {}, appConfig, tenantLoading } = options;
    const { type = 'BETA', state = 'NEW' } = tenantData;
    const radarHref = variation('hcp-ui-vault-scanning-integration');
    const isBetaProvisionedFlow =
      variation('hcp-vault-radar-public-beta') &&
      state === TENANT_STATE.PROVISIONED;
    const isBetaNewFlow =
      variation('hcp-vault-radar-public-beta') &&
      state !== TENANT_STATE.PROVISIONED;

    if (
      variation('hcp-vault-radar-public-beta') &&
      type === TENANT_TYPE.BETA &&
      !tenantLoading
    ) {
      config.translations.releaseBadge = 'nav.project.radar-badge';
    }

    switch (true) {
      case isBetaProvisionedFlow:
        config.href = `${appConfig.app.vaultRadarDomain}/projects/${projectId}`;
        config.isHrefExternal = false;
        return config;
      case isBetaNewFlow || !radarHref:
        config.routeList = 'cloud.services.vault-radar';
        config.routeCreate = 'cloud.services.vault-radar';
        return config;
      default:
        // legacy vault-radar flow using the vault-scanning-integration toggle as url
        config.href = `${radarHref}/projects/${projectId}`;
        config.isHrefExternal = false;
        return config;
    }
  },
};

// each variation is a function used to transform translations based on
// any feature flags.
//
// @param launchDarkly: an instance of the LaunchDarkly service
// @param translations: a copy of the translations object from the services
//                      object after tokens have been translated.
const VARIATIONS = {};

/**
 *
 * `PageProjectsDashboardServiceCard` displays a dashboard for a Project.
 *
 *
 * ```
 * <Page::Projects::Dashboard::ServiceCard
 *   @resources={{model.vault.clusters}}
 *   @isActive={{true}}
 *   @product='vault'
 * />
 * ```
 *
 * @class PageProjectsDashboardServiceCard
 *
 */
/**
 *
 * `resources` is an array of items that a service concerns itself with like
 * Vault clusters, Consul clusters, etc.
 * @argument resources;
 * @type {Object}
 *
 */

/**
 *
 * `product` is string of the product that should be respresented in the card.
 * A corresponding config key should be found in the SERVICES config abover for
 * the given string as well.
 * @argument product;
 * @type {String}
 *
 */

/**
 *
 * `isActive` is used if the resource count is not to be used to determine
 * active status for the product.
 * @argument isActive;
 * @type {Boolean}
 *
 */

export default class PageProjectsDashboardServiceCardComponent extends Component {
  @service intl;
  @service userContext;
  @service permissions;
  @service vaultRadarTenant;
  @service('config') appConfig;

  get projectId() {
    return this.userContext?.project?.id;
  }

  get configVariationFn() {
    return CONFIG_VARIATIONS[this.args.product];
  }

  get config() {
    const serviceConfigCopy = { ...SERVICES[this.args.product] };
    if (this.configVariationFn) {
      return this.configVariationFn(serviceConfigCopy, {
        projectId: this.projectId,
        tenantData: this.vaultRadarTenant.tenantData,
        tenantLoading: this.vaultRadarTenant.loading,
        appConfig: this.appConfig,
      });
    }
    return serviceConfigCopy;
  }

  get variationFn() {
    return VARIATIONS[this.args.product];
  }

  get translations() {
    let { translations } = this.config;
    let strings = {};
    let context = {
      product: this.intl.t(translations.product),
      numResources: this.activeResources?.length,
      isActive: this.args.isActive,
    };

    for (let [key, value] of Object.entries(translations)) {
      strings[key] = this.intl.t(value, context);
    }

    if (this.variationFn) {
      strings = this.variationFn(strings);
    }

    return strings;
  }

  get resources() {
    return this.args?.resources || [];
  }

  @cached
  get activeResources() {
    const types = this.config?.resourceTypes ?? [];
    return this.resources.filter((item) => types.includes(item?.link?.type));
  }

  get isActive() {
    return this.activeResources.length > 0;
  }

  get isViewer() {
    if (!this.config.canCreatePermissionString) {
      warn(
        'config has not createAbilityKey, short circuiting lookup and assuming admin',
        this.config.createAbilityKey,
        {
          id: 'service-card-warn',
        }
      );
      return false;
    }
    // viewer is a user that doesn't have the given permission string
    return !this.permissions.has(this.config.canCreatePermissionString);
  }

  get href() {
    return this.config?.href || null;
  }

  get isHrefExternal() {
    return this.config?.isHrefExternal || false;
  }

  get route() {
    if (this.isActive || this.isViewer) {
      return this.config.routeList;
    } else {
      return this.config.routeCreate;
    }
  }

  get linkText() {
    // if `translations.linkListViewer` exists, use that when a Viewer and no items have been created
    if (!this.isActive && this.isViewer && this.translations.linkListViewer) {
      return this.translations.linkListViewer;
    } else if (this.isActive || this.isViewer) {
      return this.translations.linkList;
    } else {
      return this.translations.linkCreate;
    }
  }

  get featureIsOn() {
    if (!this.config.featureFlags?.length) {
      return true;
    }

    const featureFlags = Array.isArray(this.config.featureFlags)
      ? this.config.featureFlags
      : [this.config.featureFlags];

    return featureFlags.some((featureFlag) => variation(featureFlag));
  }
}
