/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApplicationIsTheApplicationWhoseWorkspaceWillBeUpgradedFromJSON, ApplicationIsTheApplicationWhoseWorkspaceWillBeUpgradedToJSON, HashicorpCloudWaypointInputVariableFromJSON, HashicorpCloudWaypointInputVariableToJSON, HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceFromJSON, HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceUpgradeApplicationTFWorkspaceBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceUpgradeApplicationTFWorkspaceBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceUpgradeApplicationTFWorkspaceBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceFromJSON(json['namespace']),
        'application': !exists(json, 'application') ? undefined : ApplicationIsTheApplicationWhoseWorkspaceWillBeUpgradedFromJSON(json['application']),
        'variables': !exists(json, 'variables') ? undefined : (json['variables'].map(HashicorpCloudWaypointInputVariableFromJSON)),
    };
}
export function HashicorpCloudWaypointWaypointServiceUpgradeApplicationTFWorkspaceBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': HashicorpCloudWaypointWaypointServiceCreateAddOnBodyNamespaceToJSON(value.namespace),
        'application': ApplicationIsTheApplicationWhoseWorkspaceWillBeUpgradedToJSON(value.application),
        'variables': value.variables === undefined ? undefined : (value.variables.map(HashicorpCloudWaypointInputVariableToJSON)),
    };
}
