/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { macroCondition, isDevelopingApp } from '@embroider/macros';

/**
 * `ScimTokensCreate` is a modal component containing logic to create a token for SCIM.
 *
 *
 * ```
 * <Scim::Tokens::Create
 *   @onCancel={{this.closeTokenModal}}
 *   @organization={{@organization}}
 * />
 * ```
 *
 * @class ScimTokenCreate
 *
 */

export default class ScimTokensCreateComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;

  @tracked createTokenError = false;
  @tracked modalStepOne = true;
  @tracked token = '';
  @tracked tokenDisplayName = '';

  @tracked form = {
    fields: {
      name: '',
    },
    validationErrors: {
      name: [],
    },
    fieldValidators: {
      name: [
        {
          message: this.intl.t(
            'components.scim.tokens.create.modal.form.error.empty'
          ),
          isValid: (v) => v && v !== '',
          subValidators: [
            {
              message: this.intl.t(
                'components.scim.tokens.create.modal.form.error.alphanumeric-start'
              ),
              isValid: (v) => this.nameStartsWithAlphanumeric.test(v),
            },
            {
              message: this.intl.t(
                'components.scim.tokens.create.modal.form.error.single-spaces'
              ),
              isValid: (v) => !this.nameContainsOnlySingleSpaces.test(v),
            },
            {
              message: this.intl.t(
                'components.scim.tokens.create.modal.form.error.alphanumeric-end'
              ),
              isValid: (v) => this.nameEndsWithAlphanumeric.test(v),
            },
            {
              message: this.intl.t(
                'components.scim.tokens.create.modal.form.error.invalid'
              ),
              isValid: (v) => !this.nameInvalidCharacters.test(v),
            },
            {
              message: this.intl.t(
                'components.scim.tokens.create.modal.form.error.invalid-length'
              ),
              isValid: (v) => v.length >= 3 && v.length <= 128,
            },
          ],
        },
      ],
    },
  };

  // Starts with a letter or number.
  nameStartsWithAlphanumeric = /^[a-z0-9]+/i;

  // Only uses one space between words
  nameContainsOnlySingleSpaces = /^.*\s{2,}.*$/;

  // Ends with a letter or number.
  nameEndsWithAlphanumeric = /[a-z0-9]+$/i;

  // May only contain letters, numbers, hypens, underscores, periods, or spaces between words.
  nameInvalidCharacters = /[^a-z0-9_\-.\s]/i;

  @action setName(evt) {
    this.form.fields.name = evt.target.value;
    this.validateForm();
  }

  validateForm({ name = false } = { name: true }) {
    if (name) {
      let nameErrors = this.runValidators(
        this.form.fieldValidators.name,
        this.form.fields.name
      );
      set(
        this.form,
        'validationErrors.name',
        nameErrors.map((v) => v.message)
      );
    }

    return !Object.keys(this.form.validationErrors).find(
      (k) => this.form.validationErrors[k].length
    );
  }

  runValidators(validators, value) {
    let failedValidators = validators.flatMap((v) => {
      if (!v.isValid(value)) {
        return v;
      }

      if (v.subValidators) {
        return this.runValidators(v.subValidators, value);
      }

      return [];
    });
    return failedValidators;
  }

  @task
  *createToken(evt) {
    evt.preventDefault();

    if (!this.validateForm({ name: true })) return;

    try {
      let { token } = yield this.api.scim.scimServiceCreateScimToken(
        this.args.organization.id,
        { displayName: this.form.fields.name }
      );
      this.token = token;
      this.tokenDisplayName = this.form.fields.name;
      this.flashMessages.success(
        this.intl.t('components.scim.tokens.create.success')
      );
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
        this.createTokenError = true;
      }
    }
    this.modalStepOne = false;
  }

  @action
  restartModal() {
    this.modalStepOne = true;
  }
}
