/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import {
  isAwsProviderNetwork,
  connectionPriority,
  sortNetworkConnectionsByStatePrecedence,
} from 'networks-common/utils/networks';
import {
  PEERING_PENDING_ACCEPTANCE_STATUS,
  PEERING_ACTIVE_STATUS,
} from 'networks-common/utils/peerings';
import { COMPLETED_CONFIGURATION_STEPS } from 'networks-common/utils/peerings';

/**
 *
 * `PageHvnsDetailView` renders the HashiCorp Virtual Networks Detail page.
 *
 *
 * ```
 * <Page::Hvns::Detail::View
 *   @model={{@model}}
 *   @canCreateConnection={{@canCreateConnection}}
 *   @canCreatePeerings={{@canCreatePeerings}}
 *   @canCreateTgwAttachments={{@canCreateTgwAttachments}}
 * />
 * ```
 *
 * @class PageHvnsDetailView
 *
 */
export default class PageHvnsDetailViewComponent extends Component {
  /**
   *
   * `model` is an object that contains a network response and dependencies response as attributes.
   * @argument model;
   * @type {Boolean}
   *
   */
  /**
   *
   * `canCreateConnection` is a boolean derived in the parent container that determines the logged-in
   * user's ability to create a connection; will be true when either canCreatePeerings OR canCreateTgwAttachments
   * is true
   * @argument canCreateConnection
   * @type {Boolean}
   *
   */
  /**
   *
   * `canCreatePeerings` is a boolean derived in the parent container that determines the logged-in
   * user's ability to create peering connections
   * @argument canCreatePeerings
   * @type {Boolean}
   *
   */
  /**
   *
   * `canCreateTgwAttachments` is a boolean derived in the parent container that determines the logged-in
   * user's ability to create tgw attachments
   * @argument canCreateTgwAttachments
   * @type {Boolean}
   *
   */
  @service api;
  @service operation;
  @service router;

  connectionPriority = connectionPriority;

  @tracked peeringToDelete = null;
  @tracked transitGatewayAttachmentToDelete = null;
  @tracked showDeleteModal = false;

  get provider() {
    return this.args.model.network.location.region.provider;
  }

  get isAws() {
    return isAwsProviderNetwork(this.args.model.network);
  }

  get networkConnections() {
    let peeringsAndTgws = [
      ...this.args.model.peerings.map((peering) => {
        let metadata = null;
        if (this.args.model.metadata) {
          let {
            metadata: {
              metadata: { peering: peeringsMetadata },
            },
          } = this.args.model;
          metadata = peeringsMetadata[peering.id];
          let state = peering.state;
          if (state === PEERING_ACTIVE_STATUS && metadata) {
            peering.state =
              COMPLETED_CONFIGURATION_STEPS.filter(
                (step) => !metadata.completion.includes(step)
              ).length > 0
                ? PEERING_PENDING_ACCEPTANCE_STATUS
                : state;
          }
        }
        return { ...peering, type: 'hvn_peering', metadata };
      }),
      ...this.args.model.tgwAttachments.map((tgw) => {
        return { ...tgw, type: 'hvn_transit_gateway_attachment' };
      }),
    ];

    return sortNetworkConnectionsByStatePrecedence(
      peeringsAndTgws,
      this.connectionPriority
    );
  }

  @task
  *deleteTransitGatewayAttachment() {
    let hvnId = this.args.model.network.id;
    let { organizationId, projectId } = this.args.model.network.location;
    let { id } = this.transitGatewayAttachmentToDelete;
    let operation;

    try {
      let resp = yield this.api.network.deleteTGWAttachment(
        organizationId,
        projectId,
        hvnId,
        id
      );
      operation = resp.operation;
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      // TODO - make this a flash message
      throw e;
    }
    this.showDeleteModal = false;
    this.operation.addOperation(operation);
    this.refreshRoute();
  }

  @action
  refreshRoute() {
    let route = getOwner(this).lookup(
      `route:${this.router.currentRoute.parent.name}`
    );
    return route.refresh();
  }
}
