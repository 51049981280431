import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { variation } from 'ember-launch-darkly';

const OIDC = 'OIDC';
const SAML = 'SAML';

export default class CloudOrgsDetailSsoRoute extends Route {
  @service api;
  @service abilities;
  @service intl;

  @service router;

  beforeModel() {
    if (
      this.abilities.cannot('CRUD sso') ||
      this.abilities.cannot('CRUD auth-connection')
    ) {
      this.router.transitionTo('cloud.orgs.detail.settings');
    }
  }

  async model() {
    const { organization } = this.modelFor('cloud.orgs.detail');
    let model = { org: organization };

    try {
      if (
        variation('hcp-identity-scim-enabled') &&
        this.abilities.can('get scim')
      ) {
        const { isEnabled } = await this.api.scim.scimServiceIsScimEnabled(
          organization.id
        );
        model.isEnabled = isEnabled;
      }

      let ssoType = await this.api.sso.sSOManagementServiceGetSSOType(
        organization?.id
      );

      if (ssoType.type === OIDC) {
        let { authConnections } =
          await this.api.authConfig.authConfigServiceGetAuthConnections(
            organization?.id
          );

        model.configs = authConnections;
        model.ssoType = OIDC;
        model.defaultRole = authConnections[0]?.defaultRole;
      }

      if (ssoType.type === SAML) {
        let { configurations = [] } =
          await this.api.sso.sSOManagementServiceListSSOConfigurations(
            organization?.id
          );

        model.configs = configurations;
        model.defaultRole = configurations[0].defaultRole;
        model.ssoType = SAML;
        // used for rendering a comma separated list of email domains
        model.lastDomainIndex = configurations[0].saml.emailDomains.length - 1;
      }
    } catch (e) {
      if (macroCondition(isDevelopingApp())) console.error(e);
    }

    return model;
  }
}
