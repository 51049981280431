import Service from '@ember/service';

// this is a stub of the segment service provided by ember-cli-segment
// https://github.com/josemarluedke/ember-cli-segment/blob/d7763912d0ea09a66657ddc34ada0a67d851c1b1/addon/instance-initializers/segment.js
//
// This is only here until we remove ember-cli-segment, right now the dependency is small so we can remove it after we've moved away from Segment
//
// We stub two functions so that https://github.com/josemarluedke/ember-cli-segment/blob/d7763912d0ea09a66657ddc34ada0a67d851c1b1/addon/instance-initializers/segment.js#L28C28-L28C46 the router actions don't fire
export default class StubSegmentService extends Service {
  isPageTrackEnabled = () => false;
  isIdentifyUserEnabled = () => false;
}
