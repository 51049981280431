import Component from '@glimmer/component';
import { get, concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { eq, notEq, or, not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsAlert } from '@hashicorp/design-system-components/components';
import Flex from 'core/components/flex';

import {
  PEERINGS_ALERTS,
  PEERING_ACTIVE_STATUS,
} from '../../utils/peerings.js';
import { TGW_ATTACHMENTS_ALERTS } from '../../utils/tgw-attachments.js';
import { CONNECTIONS_INFO } from '../../utils/hvn-routes.js';

/**
 *
 * `NetworksConnectionInstructionsBanner` renders the instructions banner and no routes banner for a Network connection. This component can be used with `<NetworksConnectionInstructionsAccordion />`.
 *
 *
 * ```
 * <Networks::ConnectionInstructionsBanner
 *   @connection={{@model.peering}}
 *   @routes={{@model.routes}}
 *   @id='instructions'
 * />
 * ```
 *
 * @class NetworksConnectionInstructionsBanner
 *
 */

export default class NetworksConnectionInstructionsBanner extends Component {
  connectionsInfo = CONNECTIONS_INFO;
  peeringActiveStatus = PEERING_ACTIVE_STATUS;

  goToAndShowInstructionsById = (elementId) => {
    document.getElementById(elementId).open = true;
    document.getElementById(elementId).scrollIntoView(true);
  };

  get providerText() {
    if (this.provider === 'aws') {
      return 'AWS';
    }

    if (this.provider === 'azure') {
      return 'Azure';
    }
    return '';
  }

  get noRoutesBanner() {
    let connectionCategory = '';
    let connectionType = '';
    let createHvnRoute = '';

    if (
      this.args.connection?.target?.awsTarget ||
      this.args.connection?.target?.azureTarget
    ) {
      connectionCategory = 'connection';
      connectionType = 'peering';
      createHvnRoute = this.connectionsInfo.peering.createRoute;
    }
    if (this.args.connection?.providerData) {
      connectionCategory = 'attachment';
      connectionType = 'transit gateway';
      createHvnRoute = this.connectionsInfo.tgwAttachment.createRoute;
    }

    return {
      connectionCategory,
      connectionType,
      createHvnRoute,
    };
  }

  get isHvnHvnPeering() {
    return Boolean(this.args.connection?.target?.hvnTarget?.hvn);
  }

  get provider() {
    return this.args.connection?.hvn?.location?.region?.provider;
  }

  get alerts() {
    if (
      this.args.connection?.target?.awsTarget ||
      this.args.connection?.target?.azureTarget
    ) {
      return PEERINGS_ALERTS;
    }
    if (this.args.connection?.providerData) {
      return TGW_ATTACHMENTS_ALERTS;
    }
    return '';
  }

  /**
   * `connection` is an object for a connection type that comes from the
   * cloud-network API.  It can either be a Peering or TGW Attachment.
   * @argument connection
   * @type {object}
   */

  /**
   * `routes` is an array of objects for hvn routes in a connection that comes
   * from the cloud-network API.  It can either be a Peering or TGW Attachment.
   * @argument routes
   * @type {array}
   */

  /**
   * `id` is the id that corresponds to the
   * `<Network::ConnectionInstructionsAccordion />` on the Connection detail
   * page.
   * @argument id
   * @type {string}
   */

  <template>
    <Flex
      @direction="column"
      @gap="lg"
      class="networks-connection-instructions-banner"
      ...attributes
    >
      {{#unless this.isHvnHvnPeering}}
        {{! Instruction Banners }}
        {{#let (get this.alerts this.provider) as |alerts|}}
          {{#let (get alerts @connection.state) as |alert|}}
            {{#if alert}}
              {{! Acceptance or Configuration Banners }}
              {{#if (notEq @connection.state this.peeringActiveStatus)}}
                <HdsAlert
                  @type="inline"
                  @color={{alert.banner.color}}
                  data-test-networks-connection-instructions-banner
                  data-test-networks-connection-instructions-banner-color={{alert.banner.color}}
                  as |A|
                >
                  <A.Title
                    data-test-networks-connection-instructions-banner-title
                  >
                    {{t alert.banner.title}}
                  </A.Title>
                  <A.Description
                    data-test-networks-connection-instructions-banner-message
                  >
                    {{t alert.banner.message}}
                  </A.Description>
                  {{#if
                    (not (or (eq alert.banner.color "critical") (not @id)))
                  }}
                    <A.LinkStandalone
                      @href={{concat "#" @id}}
                      @icon="arrow-down"
                      @iconPosition="trailing"
                      @isHrefExternal={{false}}
                      @text={{t alert.banner.link}}
                      data-test-networks-connection-instructions-banner-link
                      {{on "click" (fn this.goToAndShowInstructionsById @id)}}
                    />
                  {{/if}}
                </HdsAlert>
              {{/if}}
              {{! No Routes Banner }}
              {{#if (notEq alert.banner.color "critical")}}
                {{#if (eq @routes.length 0)}}
                  <HdsAlert
                    @type="inline"
                    @color="warning"
                    data-test-networks-connection-instructions-banner-no-routes
                    as |A|
                  >
                    <A.Title
                      data-test-networks-connection-instructions-banner-no-routes-title
                    >
                      {{t
                        "components.networks.hvn-routes.alerts.no-routes.banner.title"
                      }}
                    </A.Title>
                    <A.Description>
                      <span
                        data-test-networks-connection-instructions-banner-no-routes-message
                      >
                        {{t
                          "components.networks.hvn-routes.alerts.no-routes.banner.message"
                          connectionCategory=this.noRoutesBanner.connectionCategory
                          provider=this.providerText
                          connectionType=this.noRoutesBanner.connectionType
                          htmlSafe=true
                        }}
                      </span>
                    </A.Description>
                    <A.LinkStandalone
                      @icon="arrow-right"
                      @iconPosition="trailing"
                      @route={{this.noRoutesBanner.createHvnRoute}}
                      @text={{t
                        "components.networks.hvn-routes.alerts.no-routes.banner.link"
                      }}
                      data-test-networks-connection-instructions-banner-no-routes-create-route
                    />
                  </HdsAlert>
                {{/if}}
              {{/if}}
            {{/if}}
          {{/let}}
        {{/let}}
      {{/unless}}
      {{! End of Banners }}
    </Flex>
  </template>
}
