import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { macroCondition, isDevelopingApp } from '@embroider/macros';

export const HCP_LOCAL_STORAGE_INVITATION_TOKEN_KEY = 'hcp.invitationToken';

export default class AcceptInvitationService extends Service {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;
  @service userContext;

  invitation = null;

  get invitationToken() {
    return localStorage.getItem(HCP_LOCAL_STORAGE_INVITATION_TOKEN_KEY);
  }

  set invitationToken(token) {
    localStorage.setItem(HCP_LOCAL_STORAGE_INVITATION_TOKEN_KEY, token);
  }

  destroyInvitationToken() {
    return localStorage.removeItem(HCP_LOCAL_STORAGE_INVITATION_TOKEN_KEY);
  }

  async consumeInvite(invitationToken) {
    try {
      // Consume the invite token.
      let { invitation } =
        await this.api.invitation.invitationsServiceAcceptOrganizationInvitation(
          {
            invitationToken,
          }
        );
      this.destroyInvitationToken();
      this.invitation = invitation;
    } catch (e) {
      // TODO - should this route somewhere?
      this.acceptError(e);
    }
  }

  async maybeRedirectToOrg(organizations) {
    let { organizationId } = this.invitation ?? {};
    let { projects } = this.userContext;
    if (organizationId) {
      if (projects && projects.length) {
        this.router.transitionTo(
          'cloud.orgs.detail.projects.list',
          organizationId
        );
      } else {
        this.router.transitionTo('cloud.orgs.detail.index', organizationId);
      }

      let organization = organizations.find(({ id }) => {
        return id === organizationId;
      });
      this.acceptSuccess(organization.name);
    }
  }

  acceptSuccess(organizationName) {
    this.flashMessages.success(
      this.intl.t('components.page.accept-invitation.success.title'),
      {
        content: this.intl.t(
          'components.page.accept-invitation.success.message',
          {
            organizationName,
            htmlSafe: true,
          }
        ),
      }
    );
  }

  async acceptError(e) {
    if (macroCondition(isDevelopingApp())) {
      //eslint-disable-next-line no-console
      console.log(e);
    }

    let error = (await e.json?.()) ?? {};
    error = {
      ...error,
      ...e,
    };

    let baseErrorKey = 'components.page.accept-invitation.errors';
    let flashContent = {
      title: this.intl.t(`${baseErrorKey}.general.title`),
      content: this.intl.t(`${baseErrorKey}.general.content`),
    };

    // This error message check isn't pretty but we don't have a set of unique error
    // codes that are shared between front and backend to check for. See:
    // https://github.com/hashicorp/cloud-iam/blob/master/service/private/invitations/service_accept_organization_invitation.go#L94
    let alreadyAcceptedMessage = 'invitation has already been accepted';
    // lowercasing message just in case
    if (error.message?.toLowerCase?.() === alreadyAcceptedMessage) {
      flashContent = {
        title: this.intl.t(`${baseErrorKey}.already-accepted.title`),
        content: this.intl.t(`${baseErrorKey}.already-accepted.content`),
      };
    }
    this.flashMessages.error(flashContent.title, {
      content: capitalize(error.message) + '.',
    });
  }
}
