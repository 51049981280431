import packagesDataConsulServiceInstances from './packages-data-consul-service-instances.ts';

import type {
  BillingAvailablePackage,
  BillingPackageOption,
  PackageOptionDependency,
} from '../types/api.ts';

export default function packagesDataConsulServiceInstance(
  tier: BillingAvailablePackage | null,
  { size }: { size?: { key?: string } } = {},
): BillingPackageOption | undefined | null {
  if (!tier) return null;

  const options = packagesDataConsulServiceInstances(tier);

  const isOnlyOption =
    options.length === 1 && options[0]?.dependencies?.length === 0;
  if (isOnlyOption) {
    const [onlyOption] = options;

    return onlyOption;
  }

  if (size) {
    const isDependencySizeMatch = (d: PackageOptionDependency) =>
      d.itemKey === 'hashicorp.billing.dimension.size' &&
      d.optionKey === size.key;
    const sizeMatch = options.find((o) =>
      o.dependencies?.find(isDependencySizeMatch),
    );
    return sizeMatch || null;
  }

  return null;
}
