import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { supportTicketPrefillHref } from '../../../utils/support-ticket.js';
import { variation } from 'ember-launch-darkly';
import { isTesting } from '@embroider/macros';

import type UserContextService from '../../../services/user-context.ts';
import type { IntlService } from 'ember-intl';
import type Owner from '@ember/owner';

interface LayoutSidebarMainNavSignature {
  // Args: {};
}

interface TenantData {
  state?: string;
  type?: string;
}

interface VaultRadarTenantService {
  tenantData: TenantData;
  fetch(): Promise<TenantData>;
  loading: boolean;
}

interface ConfigService {
  app?: {
    vaultRadarDomain?: string;
  };
}

/**
 *
 * `Layout::SideBar::MainNav` wraps sidebar navigation components.
 *
 *
 * ```
 * <Layout::SideBar::MainNav>{{outlet}}</Layout::SideBar::MainNav>
 * ```
 *
 * @class Layout::SideBar::MainNav
 *
 */

export default class LayoutSidebarMainNavComponent extends Component<LayoutSidebarMainNavSignature> {
  @service declare readonly userContext: UserContextService;
  @service('resources.project') declare readonly projects: unknown;
  @service declare readonly intl: IntlService;
  @service declare readonly vaultRadarTenant: VaultRadarTenantService;
  @service declare readonly config: ConfigService;

  constructor(owner: Owner, args: LayoutSidebarMainNavSignature) {
    super(owner, args);

    if (!variation('hcp-vault-radar-public-beta')) {
      return;
    }

    this.vaultRadarTenant.fetch();
  }

  get shouldLinkToVaultRadar() {
    const { state } = this.vaultRadarTenant.tenantData;
    const isProvisioned =
      variation('hcp-vault-radar-public-beta') && state === 'PROVISIONED';
    const isLegacyFlow =
      variation('hcp-ui-vault-scanning-integration') &&
      !variation('hcp-vault-radar-public-beta');

    // By default, will link to vault-radar overview if loading
    return !this.vaultRadarTenant.loading && (isLegacyFlow || isProvisioned);
  }

  get vaultRadarUrl() {
    const { project } = this.userContext;

    // Still want the url passed from LD for legacy mode, however for public-beta
    // we use the env specific config to determine the vault-radar domain
    const vaultRadarUrl =
      variation('hcp-ui-vault-scanning-integration') ||
      this.config?.app?.vaultRadarDomain;
    return `${vaultRadarUrl}/projects/${project?.id}`;
  }

  get vaultRadarBadge() {
    const { type = 'BETA' } = this.vaultRadarTenant.tenantData || {};

    return variation('hcp-vault-radar-public-beta') &&
      type === 'BETA' &&
      !this.vaultRadarTenant.loading
      ? this.intl.t('nav.project.radar-badge')
      : null;
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  get shouldShowNav() {
    const { organization, project } = this.userContext;
    return organization?.id && project?.id;
  }

  get isTesting() {
    return isTesting();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::Sidebar::MainNav': typeof LayoutSidebarMainNavComponent;
    'layout/sidebar/main-nav': typeof LayoutSidebarMainNavComponent;
  }
}
