/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Billing20201105EstimateCostResponseFromJSON(json) {
    return Billing20201105EstimateCostResponseFromJSONTyped(json, false);
}
export function Billing20201105EstimateCostResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalHourlyPrice': !exists(json, 'total_hourly_price') ? undefined : json['total_hourly_price'],
        'totalMonthlyPrice': !exists(json, 'total_monthly_price') ? undefined : json['total_monthly_price'],
    };
}
export function Billing20201105EstimateCostResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total_hourly_price': value.totalHourlyPrice,
        'total_monthly_price': value.totalMonthlyPrice,
    };
}
