import NO_ROLE_ROLE from '../../../utils/no-role-role.ts';
import {
  ROLE_KEY_ADMIN,
  ROLE_KEY_CONTRIBUTOR,
  ROLE_KEY_VIEWER,
  ROLE_KEY_BROWSER,
  ROLE_KEY_SECRETS_MANAGER,
  ROLE_KEY_SECRETS_READER,
  ROLE_KEY_INTEGRATION_MANAGER,
  ROLE_KEY_INTEGRATION_READER,
} from 'core/utils/cloud-iam-rbac-roles';

import type {
  CategoryActionRecord,
  RoleDefinitionService,
} from '../../../types/role-definitions-flyout';

// TODO HCPF-2017: fill in ORGANIZATION_SERVICES with correct columnRoleIds based on permissions
// from the server. There is a ticket from BE to return permissions on org level roles.
// https://hashicorp.atlassian.net/browse/HCPF-2017

const I18N_PREFIX =
  'manage-access.components.flyout.role-definitions-flyout.organization';

const ACTIONS: CategoryActionRecord<
  | 'ADD_AND_DELETE_USERS'
  | 'CREATE_PROJECTS'
  | 'MANAGE_USER_PERMISSIONS'
  | 'MANAGE_SERVICE_PRINCIPALS'
  | 'MANAGE_GROUPS'
  | 'MANAGE_SSO_AND_SCIM_CONFIG'
  | 'MANAGE_BILLING_RESOURCES'
  | 'REQUEST_ORGANIZATION_DELETION'
  | 'VIEW_CURRENT_BILLING_STATUS'
  | 'VIEW_GROUPS'
  | 'VIEW_PROJECTS'
  | 'VIEW_PROJECT_RESOURCES'
  | 'VIEW_USERS'
> = {
  ADD_AND_DELETE_USERS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.add-and-delete-users`,
    permissions: ['iam.invitations.create', 'iam.users.delete'],
  },
  CREATE_PROJECTS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.create-projects`,
    permissions: ['resource-manager.projects.create'],
  },
  MANAGE_USER_PERMISSIONS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.manage-user-permissions`,
    permissions: ['resource-manager.organizations.set-iam-policy'],
  },
  MANAGE_SERVICE_PRINCIPALS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.manage-service-principals`,
    permissions: [
      'iam.service-principals.create',
      'iam.service-principals.update',
      'iam.service-principals.delete',
      'iam.service-principals.get',
      'iam.service-principals.list',
    ],
  },
  MANAGE_GROUPS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.manage-groups`,
    permissions: [
      'iam.groups.get-iam-policy',
      'iam.groups.set-iam-policy',
      'iam.groups.update',
      'iam.groups.update-members',
      'iam.groups.count',
      'iam.groups.get',
      'iam.groups.list',
      'iam.groups.list-members',
      'iam.groups.member-count',
    ],
  },
  MANAGE_SSO_AND_SCIM_CONFIG: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.manage-sso-configuration`,
    permissions: [
      'iam.sso-configurations.create',
      'iam.sso-configurations.delete',
      'iam.sso-configurations.get',
      'iam.sso-configurations.list',
      'iam.sso-configurations.update',
    ],
  },
  MANAGE_BILLING_RESOURCES: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.manage-billing-resources`,
    permissions: [
      'billing.accounts.assign-project',
      'billing.accounts.get',
      'billing.accounts.list',
      'billing.accounts.tokenize-payment-details',
      'billing.accounts.unassign-project',
      'billing.accounts.update',
      'billing.fcp.get-consumption-pool',
      'billing.fcp.list-consumption-pools',
      'billing.statements.get-statement',
      'billing.statements.list',
    ],
  },
  REQUEST_ORGANIZATION_DELETION: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.request-organization-deletion`,
    overrideColumnPermissionCheck: function (column) {
      if (!column?.id) {
        return false;
      }

      return ['roles/owner'].includes(column.id);
    },
    permissions: [],
  },
  VIEW_CURRENT_BILLING_STATUS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.view-current-billing-status`,
    permissions: [
      'billing.accounts.get',
      'billing.accounts.list',
      'billing.fcp.get-consumption-pool',
      'billing.fcp.list-consumption-pools',
      'billing.statements.get-statement',
      'billing.statements.list',
    ],
  },
  VIEW_GROUPS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.view-groups`,
    permissions: ['iam.groups.list'],
  },
  VIEW_PROJECTS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.view-projects`,
    permissions: ['resource-manager.projects.get'],
  },
  VIEW_PROJECT_RESOURCES: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.view-project-resources`,
    permissions: ['resource-manager.resources.list'],
  },
  VIEW_USERS: {
    icon: 'hcp',
    title: `${I18N_PREFIX}.actions.view-users`,
    permissions: ['iam.users.list'],
  },
};

export const ORGANIZATION_SERVICES: Array<RoleDefinitionService> = [
  {
    id: 'basic',
    title: `${I18N_PREFIX}.all-services`,
    categories: [
      {
        title: `${I18N_PREFIX}.basic.basic-role-definitions`,
        actions: [
          ACTIONS['ADD_AND_DELETE_USERS'],
          ACTIONS['MANAGE_USER_PERMISSIONS'],
          ACTIONS['VIEW_USERS'],
          ACTIONS['VIEW_GROUPS'],
          ACTIONS['MANAGE_SERVICE_PRINCIPALS'],
          ACTIONS['MANAGE_GROUPS'],
          ACTIONS['VIEW_CURRENT_BILLING_STATUS'],
          ACTIONS['CREATE_PROJECTS'],
          ACTIONS['VIEW_PROJECTS'],
          ACTIONS['VIEW_PROJECT_RESOURCES'],
          ACTIONS['REQUEST_ORGANIZATION_DELETION'],
          ACTIONS['MANAGE_SSO_AND_SCIM_CONFIG'],
          ACTIONS['MANAGE_BILLING_RESOURCES'],
        ],
        columnRoleIds: [
          ROLE_KEY_ADMIN,
          ROLE_KEY_CONTRIBUTOR,
          ROLE_KEY_VIEWER,
          ROLE_KEY_BROWSER,
          NO_ROLE_ROLE.id,
        ],
      },
    ],
  },
  {
    id: 'secrets',
    title: `${I18N_PREFIX}.secrets.vault-secrets`,
    categories: [
      {
        title: `${I18N_PREFIX}.secrets.app`,
        actions: [
          {
            title: `${I18N_PREFIX}.secrets.organization-roles.actions.read-secrets`,
            permissions: ['secrets.app-secrets.list', 'secrets.apps.list'],
          },
          {
            title: `${I18N_PREFIX}.secrets.organization-roles.actions.create-edit-and-delete-secrets`,
            permissions: [
              'secrets.app-secrets.create',
              'secrets.app-secrets.update',
              'secrets.app-secrets.delete',
            ],
          },
          {
            title: `${I18N_PREFIX}.secrets.organization-roles.actions.create-edit-and-delete-applications`,
            permissions: [
              'secrets.apps.create',
              'secrets.apps.update',
              'secrets.apps.delete',
            ],
          },
          {
            title: `${I18N_PREFIX}.secrets.organization-roles.actions.create-edit-and-delete-hvs-role-assignments`,
            permissions: [
              'secrets.apps.set-iam-policy',
              'secrets.apps.get-iam-policy',
            ],
          },
          {
            title: `${I18N_PREFIX}.secrets.organization-roles.actions.manage-project-service-principals`,
            permissions: ['iam.service-principals.update'],
          },
          {
            title: `${I18N_PREFIX}.secrets.organization-roles.actions.create-and-manage-sync-integrations`,
            permissions: [
              'secrets.integrations.create',
              'secrets.integrations.update',
            ],
          },
          {
            title: `${I18N_PREFIX}.secrets.organization-roles.actions.connect-sync-integrations`,
            permissions: ['secrets.sync-integrations.force'],
          },
          {
            title: `${I18N_PREFIX}.secrets.organization-roles.actions.disconnect-sync-integrations`,
            permissions: ['secrets.sync-integrations.force'],
          },
        ],
        columnRoleIds: [ROLE_KEY_SECRETS_MANAGER, ROLE_KEY_SECRETS_READER],
      },
      {
        title: `${I18N_PREFIX}.secrets.integration`,
        actions: [],
        type: 'list',
        columnRoleIds: [
          ROLE_KEY_INTEGRATION_MANAGER,
          ROLE_KEY_INTEGRATION_READER,
        ],
      },
    ],
  },
  {
    id: 'resource-manager',
    title: `${I18N_PREFIX}.resource-manager.resource-manager`,
    categories: [
      {
        title: `${I18N_PREFIX}.resource-manager.resource-manager-role-definitions`,
        actions: [
          ACTIONS['ADD_AND_DELETE_USERS'],
          ACTIONS['MANAGE_USER_PERMISSIONS'],
          ACTIONS['VIEW_USERS'],
          ACTIONS['VIEW_GROUPS'],
          ACTIONS['MANAGE_SERVICE_PRINCIPALS'],
          ACTIONS['MANAGE_GROUPS'],
          ACTIONS['VIEW_CURRENT_BILLING_STATUS'],
          ACTIONS['CREATE_PROJECTS'],
          ACTIONS['VIEW_PROJECTS'],
          ACTIONS['VIEW_PROJECT_RESOURCES'],
          ACTIONS['REQUEST_ORGANIZATION_DELETION'],
          ACTIONS['MANAGE_SSO_AND_SCIM_CONFIG'],
          ACTIONS['MANAGE_BILLING_RESOURCES'],
        ],
        columnRoleIds: ['roles/resource-manager.org-iam-policies-admin'],
      },
    ],
  },
  {
    id: 'billing',
    title: `${I18N_PREFIX}.billing.billing`,
    categories: [
      {
        title: `${I18N_PREFIX}.billing.billing-role-definitions`,
        actions: [
          ACTIONS['ADD_AND_DELETE_USERS'],
          ACTIONS['MANAGE_USER_PERMISSIONS'],
          ACTIONS['VIEW_USERS'],
          ACTIONS['VIEW_GROUPS'],
          ACTIONS['MANAGE_SERVICE_PRINCIPALS'],
          ACTIONS['MANAGE_GROUPS'],
          ACTIONS['VIEW_CURRENT_BILLING_STATUS'],
          ACTIONS['CREATE_PROJECTS'],
          ACTIONS['VIEW_PROJECTS'],
          ACTIONS['VIEW_PROJECT_RESOURCES'],
          ACTIONS['REQUEST_ORGANIZATION_DELETION'],
          ACTIONS['MANAGE_SSO_AND_SCIM_CONFIG'],
          ACTIONS['MANAGE_BILLING_RESOURCES'],
        ],
        columnRoleIds: ['roles/billing.billing-admin'],
      },
    ],
  },
  {
    id: 'iam',
    title: `${I18N_PREFIX}.iam.iam`,
    categories: [
      {
        title: `${I18N_PREFIX}.iam.iam-role-definitions`,
        actions: [
          ACTIONS['ADD_AND_DELETE_USERS'],
          ACTIONS['MANAGE_USER_PERMISSIONS'],
          ACTIONS['VIEW_USERS'],
          ACTIONS['VIEW_GROUPS'],
          ACTIONS['MANAGE_SERVICE_PRINCIPALS'],
          ACTIONS['MANAGE_GROUPS'],
          ACTIONS['VIEW_CURRENT_BILLING_STATUS'],
          ACTIONS['CREATE_PROJECTS'],
          ACTIONS['VIEW_PROJECTS'],
          ACTIONS['VIEW_PROJECT_RESOURCES'],
          ACTIONS['REQUEST_ORGANIZATION_DELETION'],
          ACTIONS['MANAGE_SSO_AND_SCIM_CONFIG'],
          ACTIONS['MANAGE_BILLING_RESOURCES'],
        ],
        columnRoleIds: ['roles/iam.group-admin', 'roles/iam.sso-admin'],
      },
    ],
  },
];
