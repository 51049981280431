/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import { action } from '@ember/object';
import { AWS_CONNECTIONS_INFO } from 'networks-common/utils/aws-connections';

import { HVN_ROUTE_NAMES } from 'networks-common/utils/network-routes';
/**
 *
 * `PageHvnRoutesTable` renders the table, zero-state, and modal for routes.
 *
 *
 * ```
 * <Page::HvnRoutes::Table
 *   @connection={{this.connection}}
 *   @model={{@model}}
 *   @isHvnHvnPeering={{true|false}}
 * />
 * ```
 *
 * @class PageHvnRoutesList
 *
 */

/**
 * `connection` is derived from the list and passed down.
 * @argument model;
 * @type {Object}
 *
 * `isHvnHvnPeering` signifies if the routes are foran hvn-hvn peering.
 * @argument isHvnHvnPeering;
 * @type {Boolean}
 *
 * `model` is the model sent from a connection type or from the routes tab within a network
 * @argument model;
 * @type {Object}
 */

export default class PageHvnRoutesListComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service operation;
  @service router;

  @tracked isOpen;
  @tracked showDeleteModal = false;
  @tracked allowDeleteRoute = false;
  @tracked hvnRouteToDelete;
  @tracked routeName = this.router.currentRouteName;

  hvnRouteNames = HVN_ROUTE_NAMES;
  connectionsInfo = AWS_CONNECTIONS_INFO;

  get connection() {
    const { connection } = this.args;
    return connection;
  }

  get connectionCreateHVN() {
    if (this.connection.type === AWS_CONNECTIONS_INFO.peering.type) {
      return AWS_CONNECTIONS_INFO.peering.createRoute;
    }
    if (this.connection.type === AWS_CONNECTIONS_INFO.tgwAttachment.type) {
      return AWS_CONNECTIONS_INFO.tgwAttachment.createRoute;
    }
    return null;
  }

  get zeroStateMessage() {
    if (this.connection?.type === AWS_CONNECTIONS_INFO.peering.type) {
      return this.intl.t(
        'components.page.hvn-routes.list.empty.target.peering.message',
        {
          provider: this.args.model.network.location.region.provider,
        }
      );
    }
    if (this.connection?.type === AWS_CONNECTIONS_INFO.tgwAttachment.type) {
      return this.intl.t(
        'components.page.hvn-routes.list.empty.target.tgw-attachment.message',
        {
          provider: this.args.model.network.location.region.provider,
        }
      );
    }
    return null;
  }

  @action
  setOpen(evt) {
    this.isOpen = document.getElementById(evt.target.id).open;
  }
  @task
  *deleteHvnRoute(evt) {
    evt.preventDefault();
    let { organizationId, projectId } = this.args.model.network.location;
    let hvnId = this.args.model.network.id;
    let { id } = this.hvnRouteToDelete;
    let operation;
    try {
      let resp = yield this.api.network.deleteHVNRoute(
        organizationId,
        projectId,
        hvnId,
        id
      );
      this.flashMessages.success(
        this.intl.t('components.page.hvn-routes.delete.success.title')
      );
      operation = resp.operation;
    } catch (e) {
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      throw e;
    }
    this.showDeleteModal = false;
    this.operation.addOperation(operation);
  }
}
