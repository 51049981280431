/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointRefVariableFromJSON, HashicorpCloudWaypointRefVariableToJSON, HashicorpCloudWaypointVariableFromJSON, HashicorpCloudWaypointVariableToJSON, TheNamespaceTheActionToBeStoredInFromJSON, TheNamespaceTheActionToBeStoredInToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceUpdateVariableBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceUpdateVariableBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceUpdateVariableBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : TheNamespaceTheActionToBeStoredInFromJSON(json['namespace']),
        'ref': !exists(json, 'ref') ? undefined : HashicorpCloudWaypointRefVariableFromJSON(json['ref']),
        'variable': !exists(json, 'variable') ? undefined : HashicorpCloudWaypointVariableFromJSON(json['variable']),
        'updateMask': !exists(json, 'update_mask') ? undefined : json['update_mask'],
    };
}
export function HashicorpCloudWaypointWaypointServiceUpdateVariableBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': TheNamespaceTheActionToBeStoredInToJSON(value.namespace),
        'ref': HashicorpCloudWaypointRefVariableToJSON(value.ref),
        'variable': HashicorpCloudWaypointVariableToJSON(value.variable),
        'update_mask': value.updateMask,
    };
}
