import BaseAbility from './base-ability';
import { variation } from 'ember-launch-darkly';
import { PREFIX_TERRAFORM_WORKSPACES } from '../utils/permission-types/index';

export default class TerraformWorkspaceAbility extends BaseAbility {
  prefix = PREFIX_TERRAFORM_WORKSPACES;

  /** @return {boolean} */
  get canList() {
    return variation('hcp-ui-tfc-workspaces-enabled');
  }

  get canSetIamPolicy() {
    return this.permissions.has('terraform.legacy.workspace-access-admin');
  }
}
