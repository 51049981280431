import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { on } from '@ember/modifier';
import { dasherize } from '@ember/string';
import { t } from 'ember-intl';
import { not } from 'ember-truth-helpers';
import {
  HdsFormCheckboxGroup,
  HdsButton,
  HdsButtonSet,
} from '@hashicorp/design-system-components/components';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import Button from 'core/components/button';
import boxMargin from 'core/modifiers/box-margin';
import boxPadding from 'core/modifiers/box-padding';

import { DropdownListItem } from '../../utils/filter-bar/dropdown-manager.ts';

import type { HdsDropdownElement } from '../../utils/filter-bar/index.ts';

export interface MultiSelectDropdownSignature {
  Args: {
    dd: HdsDropdownElement;
    listItems: DropdownListItem[];
    selectedValues: string[];
    onSubmit: (value: string[], dropdown?: HdsDropdownElement) => void;
    trackInteraction: () => void;
  };

  Blocks: {
    default: [];
  };

  Element: HTMLElement;
}

type Section = {
  title?: string;
  options: DropdownListItem[];
};

/**
 * The multiSelectDropdown component is a dropdown body for use inside an
 * Hds dropdown component, which is preconfigured to render a dropdown with checkboxes
 * to select multiple items from a list and return them to the caller in
 * an array. The component can be used within a standalone Hds dropdown component
 * or within a FilterBar component instance.
 *
 * Usage:
 *  <FilterBar::MultiSelectDropdown
 *     @name={{@dropdownName}}
 *     @dd={{dd}}
 *     @listItems={{@listItems}}
 *     @onSubmit={{@onSubmit}}
 *     @selectedValues={{@selectedValues}}
 *     @trackInteraction={{@trackInteraction}}
 *  />
 *
 * An optional tracking callback can be provided to instrument the dropdown.
 *
 * The @selectedValues parameter allows items to be pre-selected on render.
 *
 * @class MultiSelectDropdownComponent
 */

export default class MultiSelectDropdown extends Component<MultiSelectDropdownSignature> {
  @tracked selectedItems: string[] = [];
  @tracked hasChanges = false;

  get showClearButton() {
    return this.args.selectedValues.length;
  }

  instantiateSelections = () => {
    this.selectedItems = this.args.selectedValues;
  };

  onSelect = (evt: Event) => {
    const { value, checked } = evt.target as HTMLInputElement;
    checked
      ? (this.selectedItems = [...this.selectedItems, value])
      : (this.selectedItems = this.selectedItems.filter(
          (selectedItem) => selectedItem !== value,
        ));
    this.hasChanges = true;
  };

  handleSubmit = () => {
    const { onSubmit } = this.args;

    if (onSubmit && typeof onSubmit === 'function') {
      onSubmit(this.selectedItems, this.args.dd);
      this.args.trackInteraction?.();
      this.hasChanges = false;
    }
  };

  resetDropdown = () => {
    this.selectedItems = [];
    this.handleSubmit();
  };

  onKeyup = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  };

  get formattedSections() {
    // If no titles, just return the listItems as a single section
    if (!this.args.listItems.some((item) => item.optionType === 'TITLE')) {
      return [
        {
          options: this.args.listItems,
        },
      ];
    }

    // Else create sections based on the titles
    const sections: Section[] = [];
    let section: Section = { title: '', options: [] };

    for (let i = 0; i < this.args.listItems.length; i++) {
      if (this.args.listItems[i]?.optionType === 'CHECKBOX') {
        section.options.push(this.args.listItems[i] as DropdownListItem);
      }

      // If the next item is a title or we're at the last item in the list, start a new section
      if (
        this.args.listItems[i + 1]?.optionType === 'TITLE' ||
        i === this.args.listItems.length - 1
      ) {
        sections.push({ ...section });
        section = { title: '', options: [] };
      }
      if (this.args.listItems[i]?.optionType === 'TITLE') {
        section.title = this.args.listItems[i]?.optionLabel as string;
      }
    }

    return sections;
  }

  <template>
    {{! @glint-expect-error }}
    <@dd.Generic
      data-test-multi-select-dropdown
      {{didInsert this.instantiateSelections}}
      {{on "keyup" this.onKeyup}}
    >
      {{#each this.formattedSections as |section|}}
        <HdsFormCheckboxGroup as |G|>
          <G.Legend data-test-multi-select-legend>
            {{section.title}}
          </G.Legend>
          {{#each section.options as |option|}}
            <G.CheckboxField
              @id={{dasherize option.optionValue}}
              @value={{option.optionValue}}
              checked={{option.isSelected}}
              {{on "change" this.onSelect}}
              as |F|
            >
              <F.Label>
                {{option.optionLabel}}
              </F.Label>
            </G.CheckboxField>
          {{/each}}
        </HdsFormCheckboxGroup>
        <hr {{boxMargin "xs 0"}} />
      {{/each}}
      <HdsButtonSet {{boxPadding "xs 0"}}>
        <HdsButton
          data-test-request-filter-submit
          disabled={{not this.hasChanges}}
          @text={{t "vault-common.components.filter-bar.submit-button-text"}}
          @size="small"
          {{on "click" this.handleSubmit}}
        />
        <HdsButton
          data-test-request-filter-text-cancel
          @text={{t "vault-common.components.filter-bar.cancel-button-text"}}
          @size="small"
          @color="secondary"
          {{on "click" @dd.close}}
        />
        {{#if this.showClearButton}}
          {{! @glint-expect-error }}
          <Button
            data-test-dropdown-reset-button
            class="filterbar-filter-clear"
            type="button"
            {{on "click" this.resetDropdown}}
          >
            {{t "vault-common.components.filter-bar.clear-button-text"}}
          </Button>
        {{/if}}
      </HdsButtonSet>
      {{! @glint-expect-error }}
    </@dd.Generic>
  </template>
}
