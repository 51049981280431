import Component from '@glimmer/component';
import { concat } from '@ember/helper';
import { formatNumber } from 'ember-intl';
import { and } from 'ember-truth-helpers';
import { FlightIcon } from '@hashicorp/ember-flight-icons/components';

/**
 *
 * `CostEstimatorDifference` renders the difference between two estimate prices.
 *
 *
 * ```
 * <CostEstimatorDifference
 *   @currentEstimatePrice={{@currentEstimatePrice}}
 *   @newEstimatePrice={{@newEstimatePrice}}
 * />
 * ```
 *
 * @class CostEstimatorDifference
 *
 */

export default class CostEstimatorDifference extends Component {
  get difference() {
    let { currentEstimatePrice, newEstimatePrice } = this.args;
    let difference = newEstimatePrice - currentEstimatePrice;
    let isIncrease = difference > 0;
    return {
      isIncrease,
      value: isIncrease ? `+${difference}` : difference,
    };
  }

  <template>
    {{#if (and @currentEstimatePrice @newEstimatePrice)}}
      {{#let this.difference as |difference|}}
        <div
          class={{concat
            "cost-estimator-footer__estimate-difference "
            "text--info "
            (if this.difference.isIncrease "increasing" "decreasing")
          }}
          data-test-estimate-difference-container
        >
          <span
            class="cost-estimator-footer__estimate-difference-label"
            data-test-estimate-difference-label
          >
            {{formatNumber difference.value currency="USD" style="currency"}}
          </span>
          {{#let
            (if difference.isIncrease "chevron-up" "chevron-down")
            as |icon|
          }}
            <span
              class="cost-estimator-footer__estimate-difference-icon"
              data-test-estimate-difference-icon={{icon}}
            >
              <FlightIcon @name={{icon}} />
            </span>
          {{/let}}
        </div>
      {{/let}}
    {{/if}}
  </template>
}
