import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import { macroCondition, isDevelopingApp } from '@embroider/macros';
import {
  PREFIX_VAULT_RADAR,
  VAULT_RADAR_ADMIN,
  VAULT_RADAR_USERS,
} from 'authz/utils/permission-types/index';

const VAULT_RADAR_ADMIN_PERMISSION = `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_ADMIN}`;

export default class CloudVaultRadarRoute extends Route {
  @service api;
  @service vaultRadarTenant;
  @service flashMessages;
  @service router;
  @service intl;
  @service permissions;

  handleError = (error) => {
    const { project, organization } = this.modelFor('cloud.services');

    if (macroCondition(isDevelopingApp())) {
      //eslint-disable-next-line no-console
      console.error(error);
    }
    this.flashMessages.error(
      this.intl.t('components.page.vault-radar.toast.general.error')
    );
    this.router.transitionTo(
      'cloud.orgs.detail.projects.detail',
      organization.id,
      project.id
    );
  };

  async model() {
    const { project, organization } = this.modelFor('cloud.services');

    if (!variation('hcp-vault-radar-public-beta')) {
      return {
        project,
        organization,
      };
    }

    if (!this.permissions.has(VAULT_RADAR_ADMIN_PERMISSION)) {
      await this.vaultRadarTenant.fetch();

      return {
        tenantData: this.vaultRadarTenant.tenantData,
        project,
        organization,
      };
    }

    const tenantPromise = this.api.vaultRadar.tenant.createTenant(project.id, {
      location: {
        organizationId: organization.id,
      },
    });

    const existingTenantPromise =
      this.api.vaultRadar.tenant.listOrganizationTenants(
        project.id,
        organization.id
      );

    try {
      const resolvedData = await Promise.all([
        tenantPromise,
        existingTenantPromise,
      ]);

      const [tenantData, existingTenantData] = resolvedData;
      const { provisionedTenants } = existingTenantData;
      const castedTenantCount = Number.isFinite(parseInt(provisionedTenants))
        ? parseInt(provisionedTenants)
        : 0;

      const hasExistingTenant = castedTenantCount > 0;

      return {
        tenantData,
        hasExistingTenant,
        project,
        organization,
      };
    } catch (e) {
      this.handleError(e);
      return {
        project,
        organization,
      };
    }
  }
}
