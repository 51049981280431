import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { capitalize } from '@ember/string';
import boxMargin from 'core/modifiers/box-margin';
import { service } from '@ember/service';
import { eq, and, gt } from 'ember-truth-helpers';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import set from 'ember-set-helper/helpers/set';

import { PROJECT_SERVICES } from '../../../constants/flyout/role-definitions/project.ts';
import { ORGANIZATION_SERVICES } from '../../../constants/flyout/role-definitions/organization.ts';
import { RESOURCE_SERVICES } from '../../../constants/flyout/role-definitions/resource.ts';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';
import NO_ROLE_ROLE from '../../../utils/no-role-role.ts';

import ManageAccessTableRoleComparison from '../table/role-comparison.gts';
import {
  HdsFlyout,
  HdsTabs,
  HdsTextDisplay,
  HdsTextBody,
  HdsAccordion,
  HdsButton,
  HdsBadgeCount,
  HdsTable,
  HdsAccordionForceStateValues,
} from '@hashicorp/design-system-components/components';
import Flex from 'core/components/flex';
import { JustifyContent } from 'core/utils/flex';

import type { RolesLocation } from '../../../types/roles-location';
import type { IntlService } from 'ember-intl';
import optionForSourceState from 'core/helpers/option-for-source-state';
import type {
  RoleDefinitionCategory,
  RoleDefinitionService,
} from '../../../types/role-definitions-flyout';
import type { ScopeLocationLink } from '../../../types/scope-location-link';
import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

export interface ManageAccessRoleDefinitionFlyoutComponentSignature {
  Element: HTMLElement;
  Args: {
    rolesLocation: RolesLocation;
    currentScopeLink: ScopeLocationLink;
    onClose: () => void;
    service?: string;
  };
  Blocks: {
    default: [];
  };
}

export default class ManageAccessRoleDefinitionFlyoutComponent extends Component<ManageAccessRoleDefinitionFlyoutComponentSignature> {
  @service declare readonly intl: IntlService;

  @tracked state = HdsAccordionForceStateValues.Close;

  get services(): Array<RoleDefinitionService> {
    const { rolesLocation, currentScopeLink } = this.args;
    let services;

    switch (currentScopeLink.type) {
      case TYPE_ORGANIZATION: {
        services = ORGANIZATION_SERVICES;
        break;
      }
      case TYPE_PROJECT: {
        services = PROJECT_SERVICES;
        break;
      }
      default: {
        services = RESOURCE_SERVICES[currentScopeLink.type];
      }
    }

    if (!services) {
      return [];
    }

    return services.map((service) => {
      return {
        ...service,
        categories: service.categories.map(
          (category: RoleDefinitionCategory) => {
            return {
              ...category,
              actions: category.actions.map((action) => {
                return {
                  ...action,
                  title: this.intl.t(action.title),
                };
              }),
              roles: category.columnRoleIds
                .map(
                  (
                    roleId: HashicorpCloudResourcemanagerRole['id'],
                  ): HashicorpCloudResourcemanagerRole | undefined => {
                    if (roleId === NO_ROLE_ROLE.id) {
                      return {
                        id: NO_ROLE_ROLE.id,
                        permissions: [],
                        title: this.intl.t(
                          'manage-access.components.flyout.role-definitions-flyout.no-role',
                        ),
                      };
                    }
                    return rolesLocation?.roles?.find(
                      (role) => role.id === roleId,
                    );
                  },
                )
                .filter(
                  (role) => role !== undefined,
                ) as RoleDefinitionCategory['roles'],
              title: this.intl.t(category.title),
            };
          },
        ),
      };
    });
  }

  <template>
    <HdsFlyout
      id="role-defintions-flyout"
      @onClose={{@onClose}}
      @size="large"
      data-test-role-definitions-flyout
      as |M|
    >
      <M.Header>
        {{#let
          (optionForSourceState "resource-type" @currentScopeLink.type)
          as |option|
        }}
          {{t
            "manage-access.components.flyout.role-definitions-flyout.role-definitions-service"
            service=(t option.text)
          }}
        {{/let}}
      </M.Header>
      <M.Body>
        <HdsTabs data-test-role-definitions-tabs as |T|>
          {{#each this.services as |service|}}
            <T.Tab
              data-test-role-definitions-tab={{service.id}}
              @isSelected={{eq @service service.id}}
            >
              {{if service.title (t service.title) (capitalize service.id)}}
            </T.Tab>

            <T.Panel {{boxMargin "md 0 0 0"}}>
              <Flex
                @justifyContent={{JustifyContent.SpaceBetween}}
                {{boxMargin "md 0 md 0"}}
              >
                <HdsTextDisplay @tag="h2" @weight="bold" @size="400">
                  {{t
                    "manage-access.components.flyout.role-definitions-flyout.roles"
                  }}
                </HdsTextDisplay>
                {{! TODO HCPF-2016: Disable until @hashicorp/design-system-components=^4.6.0}}
                {{#if false}}
                  <HdsButton
                    @text={{if
                      (eq this.state "open")
                      (t
                        "manage-access.components.flyout.role-definitions-flyout.collapse-all"
                      )
                      (t
                        "manage-access.components.flyout.role-definitions-flyout.expand-all"
                      )
                    }}
                    @icon={{if
                      (eq this.state "open")
                      "unfold-close"
                      "unfold-open"
                    }}
                    @color="tertiary"
                    @size="small"
                    {{on
                      "click"
                      (set
                        this
                        "state"
                        (if
                          (eq this.state HdsAccordionForceStateValues.Close)
                          HdsAccordionForceStateValues.Close
                          HdsAccordionForceStateValues.Open
                        )
                      )
                    }}
                  />
                {{/if}}
              </Flex>
              {{#if (gt service.categories.length 1)}}
                <HdsAccordion @size="large" @forceState={{this.state}} as |A|>
                  {{#each service.categories as |category|}}
                    <A.Item {{boxMargin "0 0 xs 0"}}>
                      <:toggle>
                        {{category.title}}
                        <HdsBadgeCount
                          {{! @glint-expect-error }}
                          @text={{category.roles.length}}
                          {{boxMargin "0 xs 0 0"}}
                        />
                      </:toggle>
                      <:content>

                        {{#if (eq category.type "list")}}
                          <HdsTable @caption={{category.title}}>
                            <:head as |H|>
                              <H.Tr>
                                <H.Th>
                                  {{t
                                    "manage-access.components.flyout.role-definitions-flyout.role"
                                  }}
                                </H.Th>
                                <H.Th>
                                  {{t
                                    "manage-access.components.flyout.role-definitions-flyout.description"
                                  }}
                                </H.Th>
                              </H.Tr>
                            </:head>
                            <:body as |B|>
                              {{#each category.roles as |role|}}
                                <B.Tr>
                                  <B.Td>{{role.title}}</B.Td>
                                  <B.Td>{{role.description}}</B.Td>
                                </B.Tr>
                              {{/each}}
                            </:body>
                          </HdsTable>
                        {{else}}
                          {{#if category.roles}}
                            <ManageAccessTableRoleComparison
                              @roles={{category.roles}}
                              @actions={{category.actions}}
                            />
                          {{/if}}
                        {{/if}}
                      </:content>
                    </A.Item>
                  {{/each}}
                </HdsAccordion>
              {{else}}
                {{#each service.categories as |category|}}
                  {{#if category.roles}}
                    <ManageAccessTableRoleComparison
                      @roles={{category.roles}}
                      @actions={{category.actions}}
                    />
                  {{/if}}
                {{/each}}
              {{/if}}
              {{#if
                (and
                  (eq service.id "basic")
                  (eq @currentScopeLink.type TYPE_PROJECT)
                )
              }}
                <HdsTextBody
                  @tag="p"
                  class="hds-foreground-faint"
                  {{boxMargin "lg 0 lg 0"}}
                >
                  {{t
                    "manage-access.components.flyout.role-definitions-flyout.project.view-all-current-project-resources"
                  }}
                </HdsTextBody>
              {{/if}}
            </T.Panel>
          {{/each}}
        </HdsTabs>
      </M.Body>
    </HdsFlyout>
  </template>
}
