import { PREFIX } from './index.ts';

// HVS is the product root
const PRODUCT_HVS = 'hashicorp_vault_secrets';
// These are the primary HVS resources you can scope to
const RESOURCE_HVS_APP = 'app';
const RESOURCE_HVS_INTEGRATION = 'integration';
const RESOURCE_HVS_PLAN = 'plan';
const RESOURCE_HVS_PROJECT = 'project';
const RESOURCE_HVS_SECRET = 'secret';

/**
 * How to read this file
 * ---------------------
 * Each exported constant below represents an event sent to the data warehouse. Events can take metadata, and that metadata should try to be consistent
 * as time progresses (i.e. it's better if you do not add/remove metadata fields after they are established.)
 *
 * This file will use JSDoc style annotations to represent the metadata for the `trackEvent` call from the analytics service.
 * The intention is to help understand the expected metadata when reusing a tracking event.
 */

/**
 * ========================
 *   Generic interactions
 * ========================
 */

/**
 * User successfully completes a bulk import
 * @param {number} metadata.count - number of secrets imported
 * @param {'code' | 'file'} metadata.source - method used to import secrets
 * @param {string} metadata.sourceType - filetype of imported file ('.env') or 'inline'
 */
export const HVS_APP_BULK_IMPORT = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_bulk_import`;

/**
 * User clicks the 'reveal all' button at the top of the secret list page
 * @param {boolean} show - whether the user wants to show or hide values (true means 'reveal')
 */
export const HVS_SECRETS_LIST_REVEAL_ALL_BUTTON = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_user_reveal_all`;

/**
 * User clicks the 'copy' button for a given secret on the secrets list page
 * {no params}
 */
export const HVS_SECRETS_LIST_COPY_BUTTON = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_user_copy`;

/**
 * User clicks the 'reveal' button for a given secret on the secrets list page
 * @param {boolean} show - whether the user wants to show or hide the value (true means 'reveal')
 */
export const HVS_SECRETS_LIST_REVEAL_BUTTON = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_user_reveal`;

/**
 * User clicks the 'generate credentials' button for a given dynamic secret on the secrets list page
 * @param {string} provider - provider of the dynamic secret eg: 'aws', 'gcp'
 */
export const HVS_SECRETS_LIST_GENERATE_DYNAMIC_SECRET = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_user_generate_dynamic_secret`;

/**
 * User clicks the 'generate credentials' button for a given dynamic secret on the secrets list page
 * @param {string} provider - provider of the dynamic secret eg: 'aws', 'gcp'
 */
export const HVS_SECRETS_LIST_FAILED_GENERATE_DYNAMIC_SECRET = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_user__failed_generate_dynamic_failed`;
/**
 * User toggles between 'card' and 'table' views on the app list page
 * @param {'card' | 'list'} metadata.mode - mode the user intends to use
 */
export const HVS_APP_TOGGLE_LIST_VIEW = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_toggle_list_view`;

/**
 * ======================================
 *   App and Secret creation workflows
 * ======================================
 */

/**
 * User clicks the create button on the app create page form
 * {no params}
 */
export const HVS_APP_FORM_CREATE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_form_create`;

/**
 * User clicks the cancel button on the app create page
 * {no params}
 */
export const HVS_APP_FORM_CANCEL = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_form_cancel`;

/**
 * User clicks the create secret button on the apps list page
 * @param {string} metadata.appId - name of the app
 * @param {boolean} metadata.isFirstInApp - whether this is the app's first secret
 * @param {'create'|'edit'} metadata.mode - whether the component is in "create" (new secret) or "edit" (update secret) mode
 * @param {'kv' | 'rotating-twilio' | 'rotating-mongodb-atlas' | 'dynamic'} metadata.secretType - what type of secret the user is creating
 * @param {'aws' | 'gcp' | 'mongodb-atlas' | 'twilio'} metadata.provider - provider of the dynamic secret eg: 'aws', 'gcp'
 */
export const HVS_SECRET_INLINE_CREATE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_inline_create`;

/**
 * Successfully rotating a secret manually
 * @param {string} metadata.secretType - type of secret being rotated
 * @param {string} metadata.appId - name of the app
 */
export const HVS_SECRET_INLINE_ROTATE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_inline_rotate`;

/**
 * Manual secret rotation fails
 * @param {string} metadata.secretType - type of secret being rotated
 * @param {string} metadata.appId - name of the app
 */
export const HVS_SECRET_FAILED_INLINE_ROTATE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_failed_inline_rotate`;

/**
 * User clicks the delete button on the secret create form
 * @param {'kv' | 'rotating-twilio' | 'rotating-mongodb-atlas' | 'dynamic'} metadata.secretType - what type of secret the user is creating
 * @param {'aws' | 'gcp' | 'mongodb-atlas' | 'twilio'} metadata.provider - provider of the dynamic secret eg: 'aws', 'gcp'
 * @param {string} metadata.appId - name of the app
 */
export const HVS_SECRET_INLINE_DELETE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_inline_delete`;

/**
 * Secret deletion fails
 * @param {'kv' | 'rotating-twilio' | 'rotating-mongodb-atlas' | 'dynamic'} metadata.secretType - what type of secret the user is creating
 * @param {'aws' | 'gcp' | 'mongodb-atlas' | 'twilio'} metadata.provider - provider of the dynamic secret eg: 'aws', 'gcp'
 * @param {string} metadata.appId - name of the app
 */
export const HVS_SECRET_FAILED_INLINE_DELETE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_failed_inline_delete`;

/**
 * User clicks the cancel button on the secret create form
 * @param {string} metadata.appId - name of the app
 * @param {boolean} metadata.hasName - whether the user entered text in the name field before abandoning
 * @param {boolean} metadata.hasValue - whether the user entered text in the value field before abandoning
 * @param {'create'|'edit'} metadata.mode - whether the component is in "create" (new secret) or "edit" (update secret) mode
 * @param {'kv' | 'rotating-twilio' | 'rotating-mongodb-atlas' | 'dynamic'} metadata.secretType - what type of secret the user is creating
 * @param {'aws' | 'gcp' | 'mongodb-atlas' | 'twilio'} metadata.provider - provider of the dynamic secret eg: 'aws', 'gcp'
 */
export const HVS_SECRET_INLINE_CANCEL = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_SECRET}_inline_cancel`;

/**
 * User clicks the create sample app button
 */
export const HVS_SECRET_CREATE_SAMPLE_APP = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_create_sample_app`;

/**
 * ==========================
 *   Read first secret card
 * ==========================
 */

/**
 * User clicks the "read first secret" button
 * @param {string} metadata.appId - name of the app
 * @param {'header button' | 'inline'} metadata.trigger - where the user clicks to activate the flyout
 */
export const HVS_APP_TOGGLE_READ_FIRST_SECRET = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_toggle_read_first_secret`;

/**
 * User clicks the interface tabs at the top of the read first secret flyout
 * @param {string} metadata.appId - name of the app
 * @param {'api' | 'cli'} metadata.interface - which interface the user is clicking
 */
export const HVS_APP_READ_FIRST_SECRET_SELECT_INTERFACE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_read_first_secret_select_interface`;

/**
 * User clicks the copy button on any step in the read first secret flyout
 * @param {string} metadata.appId - name of app
 * @param {'api' | 'cli'} metadata.interface - name of interface the user is viewing
 * @param {string} metadata.step - which step is being copied (values like: '1.1' or '2')
 */
export const HVS_APP_READ_FIRST_SECRET_COPY = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_read_first_secret_snippet_copy`;

/**
 * User clicks the "generate credentials" button on the read first secret flyout
 * @param {string} metadata.appId - name of the app
 * @param {'successful' | 'genericError' |'quotaError' | 'default'} metadata.status - outcome of the generate operation
 */
export const HVS_APP_READ_FIRST_SECRET_GENERATE_CREDENTIALS = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_APP}_read_first_secret_generate_credentials`;

/**
 * ===========================
 *   Sync Integration Usage Flows
 * ===========================
 */

/**
 * User clicks one of the provider options on the integrations page
 * @param {string} metadata.appId - name of the app
 * @param {boolean} metadata.hasInstallation - whether the given integrationType has been configured with the provider yet
 * @param {string} metadata.integrationType - provider the user is selecting
 * @param {number} metadata.integrationCount - number of integrations (both attached and unattached) currently in the project
 */
export const HVS_INTEGRATION_PROVIDER_SELECT = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_provider_select`;

/**
 * User clicks create on a destination configure page
 * @param {string} metadata.appId - name of the app
 * @param {string} metadata.integrationType - sync integration provider
 * @param {string} metadata.provider - integration provider
 * @param {array} metadata.capabilities - list of capabilities for integration eg: ['dynamic']
 */
export const HVS_INTEGRATION_CREATE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_create`;

/**
 * User clicks cancel on a configure integration page
 * @param {string} metadata.appId - name of the app
 * @param {string} metadata.integrationType - selected integration provider
 * @param {string} metadata.provider - integration provider
 * @param {array} metadata.capabilities - list of capabilities for integration eg: ['dynamic']
 */
export const HVS_INTEGRATION_CANCEL = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_cancel`;

/**
 * User triggers a force sync against a destination
 * @param {string} metadata.appId - name of the app
 * @param {string} metadata.integrationType - selected integration provider
 * @param {string} metadata.destinationName - name of the target destination
 * @param {string} metadata.sourceRoute - route the user was on when they triggered the sync
 */
export const HVS_INTEGRATION_FORCE_SYNC = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_force_sync`;

/**
 * User disconnects an integration from an app
 * @param {string} metadata.appId - name of the app
 * @param {string} metadata.integrationType - selected integration provider
 * @param {string} metadata.destinationName - name of the target destination
 * @param {string} metadata.purgeSecrets - whether or not the user selected purging secrets
 * @param {string} metadata.sourceRoute - route the user was on when they triggered the disconnect
 */
export const HVS_INTEGRATION_DISCONNECT = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_disconnect`;

/**
 * User deletes an integration altogether
 * @param {string} metadata.destinationName - SYNC ONLY: name of the target destination
 * @param {string} metadata.integrationType - SYNC ONLY:selected integration provider
 * @param {string} metadata.purgeSecrets - SYNC ONLY: whether or not the user selected purging secrets
 * @param {string} metadata.sourceRoute - SYNC_ONLY: route the user was on when they triggered the disconnect
 * @param {number} metadata.attachedAppCount - SYNC_ONLY: number of apps the integration is attached to at time of deletion
 * @param {string} metadata.provider - integration provider
 * @param {array} metadata.capabilities - list of capabilities for integration eg: ['dynamic']
 */
export const HVS_INTEGRATION_DELETE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_delete`;

/**
 * User clicks "copy" on the AWS external ID
 * @param {string} metadata.appId - name of the app
 */
export const HVS_INTEGRATION_AWS_COPY_EXTERNAL_ID = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_aws_sm_copy_external_id`;

/**
 * User clicks the link to the "setup arn" docs
 * @param {string} metadata.appId - name of the app
 */
export const HVS_INTEGRATION_AWS_VISIT_DOCS = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_aws_sm_visit_aws_configure_docs`;

/**
 * User applies "filter" button on the project integrations page
 * @param {array} filteredIntegrations - array of integration types to show
 */
export const HVS_INTEGRATION_FILTER = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_INTEGRATION}_filter`;

/**
 * CommandBar event prefix
 */

export const COMMAND_BAR_EVENT_PREFIX = `${PREFIX}_${PRODUCT_HVS}_command_bar`;

/**
 * CommandBar allowed event names, events from CommandBar we want to send to segment
 * https://www.commandbar.com/docs/sdk/events/addeventsubscriber/
 */

export const COMMAND_BAR_ALLOWED_EVENT_NAMES = [
  'nudge_shown',
  'nudge_clicked',
  'nudge_completed',
  'survey_response',
  'nudge_dismissed',
  'preview_shown',
  'questlist_shown',
  'questlist_engagement',
  'questlist_item_engagement',
];

/**
 * ===========================
 *   Billing & Plan Workflows
 * ===========================
 */

/**
 * User clicks "confirm" on the Upgrade or Downgrade Tier Modal
 * @param {string} metadata.newTier - tier the user is changing to
 * @param {string} metadata.prevTier - tier the user is changing from
 */
export const HVS_PLAN_CHANGE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PLAN}_change`;

/**
 * User plays around with the Cost Estimator
 * @param {string} metadata.fieldName - Field name (estimatedApiCount or estimatedSecretCount) in estimator
 * @param {number} metadata.fieldValue - Field value in estimator
 */
export const HVS_PLAN_ESTIMATE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PLAN}_estimate`;

// User clicks "Contact Sales" link in Cost Estimator
export const HVS_PLAN_CONTACT_SALES = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PLAN}_contact_sales`;

// User clicks on “see detailed plan comparison”
export const HVS_PLAN_COMPARE = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PLAN}_compare`;

/**
 * User clicked Add/Edit Payment button
 * @param {string} metadata.action - 'add' or 'edit', depending if a user has an existing payment method
 */
export const HVS_PLAN_CHANGE_PAYMENT = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PLAN}_change_payment`;

// User clicks links in the Confirm Downgrade Modal
export const HVS_PLAN_DOWNGRADE_FEEDBACK = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PLAN}_downgrade_feedback`;
export const HVS_PLAN_DOWNGRADE_SCHEDULE_CALL = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PLAN}_downgrade_schedule_call`;

/**
 * ===========================
 *   Overview Page
 * ===========================
 */

// User clicked Manage dropdown on Overview page
export const HVS_PLAN_MANAGE_DROPDOWN = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PROJECT}_manage_dropdown`;

// User clicked Plans & Billing via Manage dropdown on Overview page
export const HVS_PLAN_DROPDOWN_BILLING_LINK = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PROJECT}_manage_dropdown_billing`;

/**
 * ===========================
 *   Lock & Unlock
 * ===========================
 */

// User clicked Lock/Unlock in Manage dropdown on Overview page
export const HVS_PLAN_LOCK = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PROJECT}_lock`;
export const HVS_PLAN_UNLOCK = `${PREFIX}_${PRODUCT_HVS}_${RESOURCE_HVS_PROJECT}_unlock`;
