import Component from '@glimmer/component';

// Components
import { FlightIcon } from '@hashicorp/ember-flight-icons/components';
import Flex from 'core/components/flex';
import { HdsTextBody } from '@hashicorp/design-system-components/components';

// Helpers
import { and, not, or } from 'ember-truth-helpers';

import iamGetIconForRoleId from '../../helpers/iam-get-icon-for-role-id.ts';
import iamGetIconForService from '../../helpers/iam-get-icon-for-service.ts';

// Utils
import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';
import parseRoleId from '../../utils/parse-role-id.ts';

// Modifiers
import style from 'ember-style-modifier';

// Types
import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

export interface ManageAccessRoleBadgeSignature {
  Element: HTMLElement;
  Args: {
    role: HashicorpCloudResourcemanagerRole;
    isTextOnly?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class ManageAccessRoleBadgeComponent extends Component<ManageAccessRoleBadgeSignature> {
  get iconName() {
    const { role } = this.args;
    const { service } = this.parsedRoleId;
    return iamGetIconForRoleId(role?.id) ?? iamGetIconForService(service);
  }

  get parsedRoleId() {
    const { role = {} } = this.args;
    return parseRoleId(role.id);
  }

  get isTextOnly() {
    const { isTextOnly = false } = this.args;
    return isTextOnly;
  }

  <template>
    <Flex
      @alignItems={{AlignItem.Start}}
      @direction={{FlexDirection.Row}}
      @gap={{FlexGap.Xs}}
      data-test-manage-access-role-badge
      ...attributes
    >
      {{#let this.iconName as |iconName|}}
        {{#if (and iconName (not this.isTextOnly))}}
          <span data-test-manage-access-role-icon>
            {{! @glint-expect-error: fix incoming icon type }}
            <FlightIcon @name={{this.iconName}} />
          </span>
        {{/if}}
      {{/let}}
      <HdsTextBody
        @color="primary"
        @size="200"
        @weight="regular"
        {{! This is here to align the text with the icon while still aligning
        the items at flex align items start. }}
        {{style margin-top="-2px"}}
        data-test-manage-access-role-badge-title
      >
        {{or @role.title @role.id "—"}}
      </HdsTextBody>
    </Flex>
  </template>
}
