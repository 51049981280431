import Service, { service } from '@ember/service';
import type AnalyticsService from 'core/services/analytics';
import type {
  CommandBarConfig,
  CommandBarInitSignature,
} from '../utils/command-bar-wrapper.ts';
import {
  COMMAND_BAR_EVENT_PREFIX,
  COMMAND_BAR_ALLOWED_EVENT_NAMES,
} from 'core/utils/consts/analytics-events/secrets';
import { isDevelopingApp, macroCondition } from '@embroider/macros';
export default class CommandBarService extends Service {
  @service declare readonly analytics: AnalyticsService;
  isRunning = false;
  analyticsHandler?: Promise<() => void>;

  initCommandBar?: CommandBarInitSignature;

  async loadCommandBarLibrary() {
    const { getInitFunction } = await import('../utils/command-bar-wrapper.ts');
    return getInitFunction();
  }

  async startWithRemoteConfig(configUrl: string) {
    /**
     * If no configUrl, fail gracefully, this is an enhancement and should not hinder any functionality on the page
     **/
    if (!configUrl) {
      return;
    }

    try {
      const response = await fetch(configUrl, { cache: 'no-cache' });
      if (!response.ok) {
        throw new Error('Failed to fetch command bar config');
      }
      const config = await response.json();
      await this.start(config);
    } catch (e) {
      /**
       * If we fail to fetch the config, fail silently but log error while developing
       */
      if (macroCondition(isDevelopingApp())) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  async start(config: CommandBarConfig) {
    /**
     * If no config, fail gracefully, this is an enhancement and should not hinder any functionality on the page
     **/
    if (!config) {
      return;
    }

    /**
     * If the command bar is already running, do not start it again
     */

    if (this.isRunning) {
      return;
    }

    if (!this.initCommandBar) {
      this.initCommandBar = await this.loadCommandBarLibrary();
    }

    await this.initCommandBar(config.organization?.id, {
      disable_analytics: true,
      config,
    });
    if (window.CommandBar) {
      /**
       * Since we use CommandBar in airgapped mode we need to track events manually
       */
      this.analyticsHandler = window.CommandBar.addEventSubscriber(
        (eventName, eventData) => {
          if (COMMAND_BAR_ALLOWED_EVENT_NAMES.includes(eventName)) {
            this.analytics.trackEvent(
              `${COMMAND_BAR_EVENT_PREFIX}_${eventName}`,
              eventData,
            );
          }
        },
      );
      await window.CommandBar.boot(
        this.analytics.anonymousId,
        {},
        {
          products: ['checklists', 'nudges'],
        },
      );

      this.isRunning = true;
    }
  }

  async stop() {
    if (window.CommandBar) {
      window.CommandBar.shutdown();
    }
    /**
     * Unsubscribe from analytics event listener
     */
    if (this.analyticsHandler) {
      const unsubscribe = await this.analyticsHandler;
      unsubscribe();
    }
    this.isRunning = false;
  }
}
