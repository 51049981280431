import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import * as resourceTypes from 'common/utils/cloud-resource-types';

const I18N_PREFIX_PRODUCT = 'helpers.product-display.hashicorp';
const I18N_PREFIX_RESOURCE = 'helpers.resource-type-display-name.hashicorp';

export const RESOURCE_TYPE_OPTION_CONSUL = {
  icon: `${I18N_PREFIX_PRODUCT}.consul.cluster.icon`,
  name: `${I18N_PREFIX_PRODUCT}.consul.cluster.name`,
  type: `${I18N_PREFIX_RESOURCE}.consul.cluster`,
};

export const RESOURCE_TYPE_OPTION_VAULT = {
  icon: `${I18N_PREFIX_PRODUCT}.vault.cluster.icon`,
  name: `${I18N_PREFIX_PRODUCT}.vault.cluster.name`,
  type: `${I18N_PREFIX_RESOURCE}.vault.cluster`,
};

export const RESOURCE_TYPE_OPTION_WEBHOOK = {
  icon: `${I18N_PREFIX_PRODUCT}.webhook.webhook.icon`,
  name: `${I18N_PREFIX_PRODUCT}.webhook.webhook.name`,
  type: `${I18N_PREFIX_RESOURCE}.webhook.webhook`,
};

export const RESOURCE_TYPE_OPTION_PACKER = {
  icon: `${I18N_PREFIX_PRODUCT}.packer.registry.icon`,
  name: `${I18N_PREFIX_PRODUCT}.packer.registry.name`,
  type: `${I18N_PREFIX_RESOURCE}.packer.registry`,
};

export const RESOURCE_TYPE_OPTION_PACKER_BUCKET = {
  icon: `${I18N_PREFIX_PRODUCT}.packer.bucket.icon`,
  name: `${I18N_PREFIX_PRODUCT}.packer.bucket.name`,
  type: `${I18N_PREFIX_RESOURCE}.packer.bucket`,
};

export const RESOURCE_TYPE_OPTION_VAULT_RADAR = {
  icon: `${I18N_PREFIX_PRODUCT}.vault-radar.scan-target.icon`,
  name: `${I18N_PREFIX_PRODUCT}.vault-radar.scan-target.name`,
  type: `${I18N_PREFIX_RESOURCE}.vault-radar.scan-target`,
};

export const RESOURCE_TYPE_OPTION_TERRAFORM_WORKSPACE = {
  icon: `${I18N_PREFIX_PRODUCT}.terraform.icon`,
  name: `${I18N_PREFIX_PRODUCT}.terraform.name`,
  type: `${I18N_PREFIX_RESOURCE}.terraform.workspace`,
};
export const RESOURCE_TYPE_OPTION_TERRAFORM_STACK = {
  icon: `${I18N_PREFIX_PRODUCT}.terraform.icon`,
  name: `${I18N_PREFIX_PRODUCT}.terraform.name`,
  type: `${I18N_PREFIX_RESOURCE}.terraform.stack`,
};

export const RESOURCE_TYPES_TO_DISPLAY_OPTION_MAPPING = {
  [resourceTypes.TYPE_NETWORK]: {
    icon: `${I18N_PREFIX_PRODUCT}.network.hvn.color-icon`,
    name: `${I18N_PREFIX_PRODUCT}.network.hvn.name`,
    type: `${I18N_PREFIX_RESOURCE}.network.hvn-short`,
  },
  [resourceTypes.TYPE_PACKER]: RESOURCE_TYPE_OPTION_PACKER,
  [resourceTypes.TYPE_PACKER_BUCKET]: RESOURCE_TYPE_OPTION_PACKER_BUCKET,
  [resourceTypes.TYPE_PACKER_VERSION]: RESOURCE_TYPE_OPTION_PACKER,
  [resourceTypes.TYPE_VAULT]: RESOURCE_TYPE_OPTION_VAULT,
  [resourceTypes.TYPE_VAULT_SNAPSHOT]: RESOURCE_TYPE_OPTION_VAULT,
  [resourceTypes.TYPE_CONSUL]: RESOURCE_TYPE_OPTION_CONSUL,
  [resourceTypes.TYPE_CONSUL_GLOBAL_NETWORK_MANAGER]:
    RESOURCE_TYPE_OPTION_CONSUL,
  [resourceTypes.TYPE_CONSUL_SNAPSHOT]: RESOURCE_TYPE_OPTION_CONSUL,
  [resourceTypes.TYPE_BOUNDARY]: {
    icon: `${I18N_PREFIX_PRODUCT}.boundary.cluster.icon`,
    name: `${I18N_PREFIX_PRODUCT}.boundary.cluster.name`,
    type: `${I18N_PREFIX_RESOURCE}.boundary.cluster`,
  },
  [resourceTypes.TYPE_WAYPOINT]: {
    icon: `${I18N_PREFIX_PRODUCT}.waypoint.color-icon`,
    name: `${I18N_PREFIX_PRODUCT}.waypoint.name`,
    type: `${I18N_PREFIX_RESOURCE}.waypoint`,
  },
  [resourceTypes.TYPE_VAGRANT]: {
    icon: `${I18N_PREFIX_PRODUCT}.vagrant.registry.icon`,
    name: `${I18N_PREFIX_PRODUCT}.vagrant.registry.name`,
    type: `${I18N_PREFIX_RESOURCE}.vagrant.registry`,
  },
  [resourceTypes.TYPE_VAULT_SECRETS_APP]: {
    icon: `${I18N_PREFIX_PRODUCT}.secrets.app.icon`,
    name: `${I18N_PREFIX_PRODUCT}.secrets.app.name`,
    type: `${I18N_PREFIX_RESOURCE}.secrets.app`,
  },
  [resourceTypes.TYPE_VAULT_SECRETS_INTEGRATION]: {
    icon: `${I18N_PREFIX_PRODUCT}.secrets.integration.icon`,
    name: `${I18N_PREFIX_PRODUCT}.secrets.integration.name`,
    type: `${I18N_PREFIX_RESOURCE}.secrets.integration`,
  },
  [resourceTypes.TYPE_VAULT_SECRETS_SYNC]: {
    icon: `${I18N_PREFIX_PRODUCT}.secrets.sync.icon`,
    name: `${I18N_PREFIX_PRODUCT}.secrets.sync.name`,
    type: `${I18N_PREFIX_RESOURCE}.secrets.sync`,
  },
  [resourceTypes.TYPE_VAULT_SECRETS_GATEWAY_POOL]: {
    icon: `${I18N_PREFIX_PRODUCT}.secrets.gateway-pool.icon`,
    name: `${I18N_PREFIX_PRODUCT}.secrets.gateway-pool.name`,
    type: `${I18N_PREFIX_RESOURCE}.secrets.gateway-pool`,
  },
  [resourceTypes.TYPE_SERVICE_PRINCIPAL]: {
    icon: `${I18N_PREFIX_PRODUCT}.iam.service-principal.icon`,
    name: `${I18N_PREFIX_PRODUCT}.iam.service-principal.name`,
    type: `${I18N_PREFIX_RESOURCE}.iam.service-principal`,
  },
  [resourceTypes.TYPE_WEBHOOK]: RESOURCE_TYPE_OPTION_WEBHOOK,
  [resourceTypes.TYPE_VAULT_RADAR]: RESOURCE_TYPE_OPTION_VAULT_RADAR,
  [resourceTypes.TYPE_TERRAFORM_WORKSPACE]:
    RESOURCE_TYPE_OPTION_TERRAFORM_WORKSPACE,
  [resourceTypes.TYPE_TERRAFORM_STACK]: RESOURCE_TYPE_OPTION_TERRAFORM_STACK,
};

export default class OptionForResourceService extends Helper {
  @service intl;

  compute([{ type, geo }]) {
    const option = RESOURCE_TYPES_TO_DISPLAY_OPTION_MAPPING[type];
    if (option) {
      let name = '';

      if (geo) {
        // check if a geo name exists, if not fall back to the standard "name" field
        const geoNameExists = this.intl.exists(`${option.name}-${geo}`);
        name = geoNameExists
          ? this.intl.t(`${option.name}-${geo}`)
          : this.intl.t(option.name);
      } else {
        name = this.intl.t(option.name);
      }

      return {
        ...option,
        name,
        icon: this.intl.t(option.icon),
        type: this.intl.t(option.type),
      };
    } else {
      return {
        icon: 'help',
        name: '',
      };
    }
  }
}
