/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import {
  HashicorpCloudResourcemanagerPolicyBindingMemberType,
  HashicorpCloudResourcemanagerResourceIDResourceType,
} from '@clients/cloud-resource-manager';

/**
 *
 * `IamInvitationsBasicRoleChart` is the wrapper component that connects the
 * toggle button and the chart.
 *
 *
 * ```
 * <Iam::Invitations::BasicRoleChart as |Chart|>
 *   <Chart.ToggleButton />
 * </Iam::Invitations::BasicRoleChart>
 * ```
 *
 * @class IamInvitationsBasicRoleChart
 *
 */

const CHART_CONTEXT_RESOURCE_TYPES = {
  [HashicorpCloudResourcemanagerResourceIDResourceType.ORGANIZATION]: {
    flyoutHeader:
      'iam.invitations.basic-role-chart.flyout.review-organization-role-definitions',
    toggleLabel:
      'iam.invitations.basic-role-chart.toggle.review-org-role-definitions',
    toggleIcon: 'help',
    permissionTableComponent:
      'iam/invitations/basic-role-chart/organization/permissions-table',
    [HashicorpCloudResourcemanagerPolicyBindingMemberType.SERVICEPRINCIPAL]: {
      description:
        'iam.invitations.basic-role-chart.flyout.service-principals-may-be-granted-additional-permissions',
      note: 'iam.invitations.basic-role-chart.flyout.service-principals-without-an-org-role',
      alertLink:
        'https://developer.hashicorp.com/hcp/docs/hcp/admin/iam/service-principal#project-level-service-principals',
      alertText:
        'iam.invitations.basic-role-chart.flyout.learn-more-about-service-principal-permissions',
    },
    [HashicorpCloudResourcemanagerPolicyBindingMemberType.USER]: {
      description:
        'iam.invitations.basic-role-chart.flyout.users-may-be-granted-additional-permissions',
      note: 'iam.invitations.basic-role-chart.flyout.users-without-an-org-role',
      alertLink:
        'https://developer.hashicorp.com/hcp/docs/hcp/admin/iam/users#user-permissions',
      alertText:
        'iam.invitations.basic-role-chart.flyout.learn-more-about-user-permissions',
    },
    [HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP]: {
      description:
        'iam.invitations.basic-role-chart.flyout.groups-may-be-granted-additional-permissions',
      note: 'iam.invitations.basic-role-chart.flyout.groups-without-an-org-role',
      alertLink:
        'https://developer.hashicorp.com/hcp/docs/hcp/admin/iam/groups',
      alertText:
        'iam.invitations.basic-role-chart.flyout.learn-more-about-group-permissions',
    },
  },
  [HashicorpCloudResourcemanagerResourceIDResourceType.PROJECT]: {
    flyoutHeader:
      'iam.invitations.basic-role-chart.flyout.review-project-role-definitions',
    toggleLabel:
      'iam.invitations.basic-role-chart.toggle.view-role-definitions',
    toggleIcon: 'help',
    permissionTableComponent:
      'iam/invitations/basic-role-chart/project/permissions-table',
    secretsPermissionTableComponent:
      'iam/invitations/basic-role-chart/services/secrets/permissions-table',
    [HashicorpCloudResourcemanagerPolicyBindingMemberType.SERVICEPRINCIPAL]: {
      description:
        'iam.invitations.basic-role-chart.flyout.service-principals-may-be-granted-additional-permissions',
      note: null,
      alertLink:
        'https://developer.hashicorp.com/hcp/docs/hcp/admin/iam/service-principal#project-level-service-principals',
      alertText:
        'iam.invitations.basic-role-chart.flyout.learn-more-about-service-principal-permissions',
    },
    [HashicorpCloudResourcemanagerPolicyBindingMemberType.USER]: {
      description:
        'iam.invitations.basic-role-chart.flyout.users-may-be-granted-additional-permissions',
      note: null,
      alertLink:
        'https://developer.hashicorp.com/hcp/docs/hcp/admin/iam/users#project',
      alertText:
        'iam.invitations.basic-role-chart.flyout.learn-more-about-user-permissions',
    },
    [HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP]: {
      description:
        'iam.invitations.basic-role-chart.flyout.groups-may-be-granted-additional-permissions',
      note: null,
      alertLink:
        'https://developer.hashicorp.com/hcp/docs/hcp/admin/iam/groups',
      alertText:
        'iam.invitations.basic-role-chart.flyout.learn-more-about-group-permissions',
    },
  },
};

export default class IamInvitationsBasicRoleChartComponent extends Component {
  /**
   * The member type that will set the context for this flyout.
   * @argument memberType
   * @type {HashicorpCloudResourcemanagerPolicyBindingMemberType}
   */

  @service intl;

  @tracked isFlyoutActive = false;

  @action
  toggleFlyout() {
    this.isFlyoutActive = !this.isFlyoutActive;
  }

  @action
  closeFlyout() {
    this.isFlyoutActive = false;
  }

  @action
  openFlyout() {
    this.isFlyoutActive = true;
  }

  get memberType() {
    const {
      memberType = HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
    } = this.args;
    const resourceType = this.resourceType;
    return (
      resourceType[memberType] ||
      resourceType[HashicorpCloudResourcemanagerPolicyBindingMemberType.USER]
    );
  }

  get resourceType() {
    const {
      resourceType = HashicorpCloudResourcemanagerResourceIDResourceType.ORGANIZATION,
    } = this.args;

    return (
      CHART_CONTEXT_RESOURCE_TYPES[resourceType] ||
      CHART_CONTEXT_RESOURCE_TYPES[
        HashicorpCloudResourcemanagerPolicyBindingMemberType.ORGANIZATION
      ]
    );
  }

  get permissionTableComponent() {
    const { permissionTableComponent } = this.resourceType;
    return permissionTableComponent;
  }

  get secretsPermissionTableComponent() {
    const { secretsPermissionTableComponent } = this.resourceType;
    return secretsPermissionTableComponent;
  }

  get description() {
    const { description } = this.memberType;
    if (!description) {
      return undefined;
    }
    return this.intl.t(description);
  }

  get flyoutHeader() {
    const { flyoutHeader } = this.resourceType;
    if (!flyoutHeader) {
      return undefined;
    }
    return this.intl.t(flyoutHeader);
  }

  get note() {
    const { note } = this.memberType;
    if (!note) {
      return undefined;
    }
    return this.intl.t(note);
  }

  get toggleLabel() {
    const { toggleLabel } = this.resourceType;
    if (!toggleLabel) {
      return undefined;
    }
    return this.intl.t(toggleLabel);
  }

  get toggleIcon() {
    const { toggleIcon } = this.resourceType;
    if (!toggleIcon) {
      return undefined;
    }
    return toggleIcon;
  }

  get alertLink() {
    const { alertLink } = this.memberType;
    if (!alertLink) {
      return undefined;
    }
    return alertLink;
  }

  get alertText() {
    const { alertText } = this.memberType;
    if (!alertText) {
      return undefined;
    }
    return this.intl.t(alertText);
  }
}
