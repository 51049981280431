/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import SystemNotificationColors from 'common/utils/system-notification-colors';
import type FlashObject from 'ember-cli-flash/flash/object';
import type Owner from '@ember/owner';

interface NotificationsNotificationSignature {
  Args: {
    flash: FlashObject;
    onAction: () => void;
  };
}

/**
 *
 * `NotificationsNotification` is the individual notification which renders
 * an Hds::Toast component which, in-turn, is wrapped by a FlashMessage
 * component and utilizes the same api. The first argument in your action
 * will be used as the string title of the notification. The second argument is
 * an object which can take `content`, `actionText`, and `onAction` properties.
 *
 * `content` is a string that is used below the "title" as "description".
 * `actionText` is a string that is used in an Hds::Button component.
 * `onAction` is a function that will be called when the user clicks the button
 *  and gets passed the close function for the alert.
 *
 * Available methods are 'success', 'info', 'warning', and 'error'.
 *
 * this.flashMessages.success('Success!');
 * this.flashMessages.warning('Warning!');
 * this.flashMessages.info('Info!');
 * this.flashMessages.error('Danger!');
 *
 * this.flashMessages.success('Successfully saved!', {
 *   content: 'Success content, baby!',
 *   actionText: 'Celebrate',
 *   onAction: function(close) {
 *     console.log('celebrate');
 *     return close();
 *   },
 * });
 *
 *
 * ```
 * <Notifications::Notification
 *   @flash={{flash}}
 * />
 * ```
 *
 * @class NotificationsNotification
 *
 */

export default class NotificationsNotificationComponent extends Component<NotificationsNotificationSignature> {
  constructor(owner: Owner, args: NotificationsNotificationSignature['Args']) {
    super(owner, args);
    if (this.args.flash) {
      // notice: this is not the same entity as the one used in the system notification
      // (see addons/core/package/src/utils/constants.ts)
      // so we have to manually extent the "flash" object to have a "color" property
      // @ts-expect-error: we are extending the object
      this.args.flash.color = this.colorMappingFlashToHDS(this.args.flash);
    }
  }

  colorMappingFlashToHDS(flash: FlashObject) {
    let color;

    // @ts-expect-error: we are extending the object
    switch (flash.type) {
      case 'success':
        color = SystemNotificationColors.SUCCESS;
        break;
      case 'warning':
        color = SystemNotificationColors.WARNING;
        break;
      case 'error':
        color = SystemNotificationColors.CRITICAL;
        break;
      default:
        color = SystemNotificationColors.NEUTRAL;
        break;
    }
    return color;
  }

  @action
  onAction(close: any) {
    // @ts-expect-error: we are extending the object
    const { onAction } = this.args.flash;

    if (onAction && typeof onAction == 'function') {
      // @ts-expect-error: we are extending the object
      this.args.flash.onAction(close);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Notifications::Notification': typeof NotificationsNotificationComponent;
    'notifications/notification': typeof NotificationsNotificationComponent;
  }
}
