import Component from '@glimmer/component';
import { LinkTo } from '@ember/routing';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsBadge } from '@hashicorp/design-system-components/components';
import { FlightIcon } from '@hashicorp/ember-flight-icons/components';
import Flex from 'core/components/flex';
import DefinitionList from 'core/components/definition-list';
import boxPadding from 'core/modifiers/box-padding';
import optionForSourceState from 'core/helpers/option-for-source-state';

import networksConnectionInfo from '../../helpers/networks-connection-info.js';
import networksConnectionSourceType from '../../helpers/networks-connection-source-type.js';

/**
 *
 * `NetworksConnectionDetails` renders the details table for a Network connection.
 *
 *
 * ```
 * <Networks::ConnectionDetails
 *   @connection={{@model.peering}}
 * />
 *
 * <Networks::ConnectionDetails
 *   @connection={{@model.tgwAttachment}}
 * />
 * ```
 *
 * @class NetworksConnectionDetails
 *
 */

export default class NetworksConnectionDetails extends Component {
  /**
   * `connection` is an object for a connection type that comes from the cloud-network API.  It can either be a Peering or TGW Attachment
   * @argument connection
   * @type {object}
   */

  get isAwsPeering() {
    return Boolean(this.args.connection?.target?.awsTarget?.accountId);
  }

  get isAzurePeering() {
    return Boolean(this.args.connection?.target?.azureTarget?.tenantId);
  }

  // TODO: copied from connection-instructions, should be shared helper probably
  get isHvnHvnPeering() {
    return Boolean(this.args.connection?.target?.hvnTarget?.hvn);
  }

  get isTgw() {
    return Boolean(this.args.connection?.providerData?.awsData?.tgwId);
  }

  <template>
    {{#let (networksConnectionInfo @connection) as |destination|}}
      <div
        class="networks-connection-details"
        data-test-networks-connection-details
        ...attributes
      >
        {{#if (or this.isAwsPeering this.isHvnHvnPeering)}}
          <DefinitionList {{boxPadding "md 0 xl 0"}} as |DL|>
            <DL.Key data-test-networks-connection-details-status>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.status"
              }}
            </DL.Key>
            <DL.Value>
              {{#let
                (optionForSourceState
                  (networksConnectionSourceType @connection) @connection.state
                )
                as |option|
              }}
                <HdsBadge
                  data-test-state
                  @text={{t option.text}}
                  @color={{option.color}}
                  @icon={{option.icon}}
                  @isIconOnly={{option.isIconOnly}}
                  @size={{option.size}}
                  @type={{option.type}}
                />
              {{/let}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-account>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.destination-account-id"
              }}
            </DL.Key>
            <DL.Value>
              {{#let
                (optionForSourceState "region" destination.provider)
                as |option|
              }}
                <Flex @alignItems="center" @direction="row" @gap="2xs">
                  <FlightIcon @name={{option.icon}} data-test-provider-logo />
                  <span>
                    {{destination.accountId}}
                  </span>
                </Flex>
              {{/let}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-vpc>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.destination-vpc"
              }}
            </DL.Key>
            <DL.Value data-test-networks-connection-details-vpc-value>
              {{#if this.isHvnHvnPeering}}
                <LinkTo
                  @route="cloud.orgs.detail.projects.detail.hvns.detail"
                  @model={{destination.hvnId}}
                >
                  {{destination.hvnId}}
                </LinkTo>
              {{else}}
                {{destination.vpcId}}
              {{/if}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-region>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.destination-region"
              }}
            </DL.Key>
            <DL.Value>
              {{destination.region}}
            </DL.Value>
          </DefinitionList>
        {{else if this.isAzurePeering}}
          <DefinitionList {{boxPadding "md 0 xl 0"}} as |DL|>
            <DL.Key data-test-networks-connection-details-status>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.status"
              }}
            </DL.Key>
            <DL.Value>
              {{#let
                (optionForSourceState "hvn_peering" @connection.state)
                as |option|
              }}
                <HdsBadge
                  data-test-state
                  @text={{t option.text}}
                  @color={{option.color}}
                  @icon={{option.icon}}
                  @isIconOnly={{option.isIconOnly}}
                  @size={{option.size}}
                  @type={{option.type}}
                />
              {{/let}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-tenant>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.destination-tenant-id"
              }}
            </DL.Key>
            <DL.Value>
              {{destination.accountId}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-subscription>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.destination-subscription-id"
              }}
            </DL.Key>
            <DL.Value>
              {{#let
                (optionForSourceState "region" destination.provider)
                as |option|
              }}
                <Flex @alignItems="center" @direction="row" @gap="2xs">
                  <FlightIcon @name={{option.icon}} data-test-provider-logo />
                  <span>
                    {{destination.subscriptionId}}
                  </span>
                </Flex>
              {{/let}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-resource-group>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.destination-resource-group-name"
              }}
            </DL.Key>
            <DL.Value>
              {{destination.resourceGroupName}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-vnet>
              {{t
                "components.page.hvn-peerings.detail.peering-details.labels.destination-vnet"
              }}
            </DL.Key>
            <DL.Value>
              {{destination.vnetName}}
            </DL.Value>
          </DefinitionList>
        {{else if this.isTgw}}
          <DefinitionList {{boxPadding "md 0 xl 0"}} as |DL|>
            <DL.Key data-test-networks-connection-details-status>
              {{t
                "components.page.hvn-transit-gateway-attachments.detail.attachment-details.labels.status"
              }}
            </DL.Key>
            <DL.Value class="hvnsTransitGatewayAttachmentsDetail__status">
              <ul>
                <li>
                  {{#let
                    (optionForSourceState
                      "hvn_transit_gateway_attachment" @connection.state
                    )
                    as |option|
                  }}
                    <HdsBadge
                      data-test-state
                      @text={{t option.text}}
                      @color={{option.color}}
                      @icon={{option.icon}}
                      @isIconOnly={{option.isIconOnly}}
                      @size={{option.size}}
                      @type={{option.type}}
                    />
                  {{/let}}
                </li>
              </ul>
            </DL.Value>
            <DL.Key data-test-networks-connection-details-owner>
              {{t
                "components.page.hvn-transit-gateway-attachments.detail.attachment-details.labels.transit-gateway-owner-id"
              }}
            </DL.Key>
            <DL.Value>
              {{#let
                (optionForSourceState "region" destination.provider)
                as |option|
              }}
                <Flex @alignItems="center" @direction="row" @gap="2xs">
                  <FlightIcon @name={{option.icon}} data-test-provider-logo />
                  <span>
                    {{destination.accountId}}
                  </span>
                </Flex>
              {{/let}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-gateway>
              {{t
                "components.page.hvn-transit-gateway-attachments.detail.attachment-details.labels.transit-gateway-id"
              }}
            </DL.Key>
            <DL.Value>
              {{destination.tgwId}}
            </DL.Value>
            <DL.Key data-test-networks-connection-details-region>
              {{t
                "components.page.hvn-transit-gateway-attachments.detail.attachment-details.labels.destination-region"
              }}
            </DL.Key>
            <DL.Value>
              {{destination.region}}
            </DL.Value>
          </DefinitionList>
        {{/if}}
      </div>
    {{/let}}
  </template>
}
