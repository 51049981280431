/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20231128LockOrigin;
(function (Secrets20231128LockOrigin) {
    Secrets20231128LockOrigin["UNKNOWNORIGIN"] = "UNKNOWN_ORIGIN";
    Secrets20231128LockOrigin["CUSTOMER"] = "CUSTOMER";
    Secrets20231128LockOrigin["BILLING"] = "BILLING";
})(Secrets20231128LockOrigin || (Secrets20231128LockOrigin = {}));
export function Secrets20231128LockOriginFromJSON(json) {
    return Secrets20231128LockOriginFromJSONTyped(json, false);
}
export function Secrets20231128LockOriginFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20231128LockOriginToJSON(value) {
    return value;
}
