import { assert } from '@ember/debug';
import { variation } from 'ember-launch-darkly';
import {
  BILLING_LOW_BALANCE,
  BILLING_LOW_BALANCE_UPDATED,
} from '../constants/status.js';
import { DateTime } from 'luxon';

const flex2ReleaseDate = DateTime.utc(2025, 3, 8);
export default class BillingCredits {
  #credits;
  #createdAt;

  constructor(credits = 0, createdAt) {
    this.#credits = parseFloat(credits);
    assert(
      `value: ${this.#credits} must be valid number`,
      typeof this.#credits === 'number' && !Number.isNaN(this.#credits),
    );
    this.#createdAt = DateTime.fromJSDate(createdAt, { zone: 'utc' });
    assert(
      `value: ${this.#createdAt} must be valid date`,
      this.#createdAt.toJSDate() instanceof Date,
    );
  }

  get isLow() {
    // if the Flex 2 feature flag is turned on and the billing account was created on/after the Flex 2 release date,
    // the low balance threshold is $100
    if (
      variation('hcp-ui-billing-flex-2') &&
      this.#createdAt >= flex2ReleaseDate
    ) {
      return this.#credits <= BILLING_LOW_BALANCE_UPDATED && !this.isZero;
    } else {
      // otherwise, the low balance threshold is $10
      return this.#credits <= BILLING_LOW_BALANCE && !this.isZero;
    }
  }

  get isZero() {
    return this.#credits === 0;
  }

  valueOf() {
    return this.#credits;
  }
}
