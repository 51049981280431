export function appRoutes(): Record<string, string> {
  return {
    'cloud.orgs.detail.projects.detail.index':
      'cloud.orgs.detail.projects.detail.index',
    home: 'cloud.orgs',
    cloud: 'cloud',
    cloudAccountSettings: 'cloud.account-settings',
    cloudAccessControl: 'cloud.access-control',
    signOut: 'sign-out',
    signIn: 'sign-in',
    signUp: 'sign-up',
    consul: 'consul',
    vault: 'vault.clusters.list',
    vaultDetail: 'vault.detail',
    secrets: 'secrets',
    secretsBilling: 'secrets.billing.edit',
    hvnsCreate: 'cloud.orgs.detail.projects.detail.hvns.create',
    hvnsDetail: 'cloud.orgs.detail.projects.detail.hvns.detail.index',
    hvnsDetailPeerings:
      'cloud.orgs.detail.projects.detail.hvns.detail.peerings.list',
    hvnsList: 'cloud.orgs.detail.projects.detail.hvns.list',
    peeringsCreate:
      'cloud.orgs.detail.projects.detail.hvns.detail.peerings.create',
    tgwAttachmentsCreate:
      'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.create',
    orgDetail: 'cloud.orgs.detail',
    billingAccounts: 'billing.accounts',
    paymentsCreditCard: 'billing.accounts.account.payments.credit-card',
    projects: 'cloud.orgs.detail.projects',
    servicePrincipals: 'cloud.access-control.service-principals.list',
    servicePrincipalsDetail: 'cloud.access-control.service-principals.detail',
    servicePrincipalsDetailEdit:
      'cloud.access-control.service-principals.detail.edit',
    servicePrincipalDetailWip:
      'cloud.access-control.service-principals.detail.workload-identity-providers',
    usersDetail: 'cloud.access-control.users.detail',
    usersInvite: 'cloud.access-control.users.invite',
    usersList: 'cloud.access-control.users.list',
    vagrantDiscover: 'vagrant-public.discover',
    groupMembersAdd: 'cloud.access-control.groups.detail.members.add',
    cloudRoleAssignmentsDetailEdit:
      'cloud.access-control.role-assignments.detail.edit',
    groupsDetail: 'cloud.access-control.groups.detail.index',
  };
}

export default function (options = { isApp: false }) {
  const routes = appRoutes();

  return {
    // this is a list of services that get shared to engines
    services: [
      '-portal',
      'analytics',
      'api',
      'billing',
      'breadcrumbs',
      'config',
      'currentUser',
      'flash-messages',
      'intl',
      'metadata.local-storage',
      'operation',
      'page-title',
      'permissions',
      'session',
      'user-context',
    ],
    // if it's an app we need the full mapping of routes  to keys,
    // if it's an engine externalRoutes is an array that is just the key names
    // of the externalRoutes
    externalRoutes: options.isApp ? routes : Object.keys(routes),
  };
}
